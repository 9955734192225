import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';

import { CloseNewEmailPage, EditEmailFromSearchResult, RemoveEmail } from "./../../emarketing.action";
import { IAppState } from "../../../store/store";
import { AuthService } from "../../../services/authservice";
import { EmarketingService } from "./../../emarketing.service";
import { IEmail, IContactEmail } from "./../../emarketing";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';

@Component({
  selector: 'app-emailsearchresult',
  templateUrl: './emailsearchresult.component.html',
  styleUrls: ['./emailsearchresult.component.css']
})
export class EmailSearchResultComponent implements OnInit, AfterViewInit, OnDestroy {

  private getSessionDataSubscription: Subscription;
  private getEmailSubscription: Subscription;
  private getContactsForEmailSubscription: Subscription;
  private emailDeleteSubscription: Subscription;

  private loginUserEmail: string;
  private dialogRef: any;
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  constructor(private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    private _eMarketingService: EmarketingService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef) { }

    openDialog(title: string, message: string, role: "alertdialog" | "dialog" = "dialog") {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.role = role;
      dialogConfig.data = { title: title, message: message, role: role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog };
      dialogConfig.panelClass = "dialog-body";
      this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
      return this.dialogRef;
    }

  @select(state => (state as IAppState).emarketing.email.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).emarketing.email.addEditView.showEditPage) showEditPage$;
  @select(state => (state as IAppState).emarketing.email.addEditView.showPreviewPage) showPreviewPage$;
  @select(state => (state as IAppState).emarketing.email.search.emails) emails$;

  ngOnInit() {
    this.getSessionDataSubscription = this._authService.GetSessionData().subscribe(email => {
      if (!!email) {
        this.loginUserEmail = email.email;
      }
    });
  }
  ngAfterViewInit() {
    LoadScript.gotoTop();
    var clearIntv = null;
    clearIntv = setInterval(() => {
      if (!!$("#no-more-tables > table").length) {
        clearInterval(clearIntv);
        FixedTableHeaderScript.fixMe($("table"));
        $(".up").click(function() {
          $("html, body").animate(
            {
              scrollTop: 0
            },
            2000
          );
        });
      }
    }, 2000);
  }
  ngOnDestroy() {
    if (!!this.getSessionDataSubscription) this.getSessionDataSubscription.unsubscribe();
    if (!!this.emailDeleteSubscription) this.emailDeleteSubscription.unsubscribe();
    if (!!this.getEmailSubscription) this.getEmailSubscription.unsubscribe();
    if (!!this.getContactsForEmailSubscription) this.getContactsForEmailSubscription.unsubscribe();
  }

  onEditClick(email: IEmail) {

    if (!!email.id && email.id.indexOf("00000000") < 0) {
      this.getEmailSubscription = this._eMarketingService.GetEmail(email.id)
        .subscribe((email: IEmail) => {
          this.getContactsForEmailSubscription = this._eMarketingService.GetContactsForEmail(email.id, "", (!!email.emailMarker ? email.emailMarker: ""))
          .map((recipients : IContactEmail[]) => {
            return new EditEmailFromSearchResult({
              id: email.id,
              subject: email.subject,
              textBody: email.textBody,
              htmlBody: email.htmlBody,
              widgets: email.widgets,
              selectedRecipientSourceType: null,
              selectedRecipientType: null,
              selectedTemplate: null,
              selectedWidgetType: null,
              selectedWidgetID: null,
              selectedContactList: null,
              selectedRecipientList: null,
              recipientSourceTypes: null,
              recipientTypes: null,
              templates: null,
              contactLists: null,
              recipientList: recipients,
              from: this.loginUserEmail,
              to: null,
              attachments: email.attachments,
              widgetTypes: email.widgetTypes,
              previewToEmails: null,
              usingRecipientContactID: null,
              scheduledDatetime: null,
              showEditPage: true,
              showPreviewPage: false,
              showNewPage: false
            });
          })
          .subscribe(action => {
            if (!!action.payload && !!action.payload.id && action.payload.id.indexOf("00000000") < 0) {
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            }
          },
          (error) => {
            console.log(error);
          });
        },
        (error) => {
          console.log(error);
        });
    }
  }
  onRemoveEmail(email: IEmail) {
    this.openDialog("Confirmation", "Are you sure you want to delete the email?", "dialog");
    this.dialogRef.afterClosed().subscribe(dResult => {

      if (!!dResult) {
        this._loader.show();
        this.emailDeleteSubscription = this._eMarketingService.RemoveEmail(email.id)
        .map((success: boolean) => {
          if (success) {
            return new RemoveEmail(email);
          }
          else {
            return new RemoveEmail({} as IEmail);
          }
        })
        .subscribe(action => {
          if (!!action && !!action.payload && !!action.payload.id && action.payload.id.indexOf("00000000") < 0) {
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            this.snackBar.open("Email deleted successfully!!", "Close", this.config);
          }
          this._loader.hide();
        },
        (error) => {
          this._loader.hide();
          this.openDialog("Error", (error.json().error || "Server Error"), "alertdialog");
        });
      }

    });
  }
  onClose() {
    let action = new CloseNewEmailPage(true);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    this._router.navigate(["/emails"]);
  }

}
