import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';

import { IAppState } from "../../../store/store";
import { AuthService } from "../../../services/authservice";
import { PropertyManagementService } from "./../../propertymanagement.service";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';
import { CloseCommercialTenantInvoiceResultPage } from "./../../propertymanagement.action"
import { Observable } from 'rxjs';
import { GridViewDataTable } from '../../components/gridview/gridview.component';
import { ICommercialTenantInvoiceSearchResult } from '../../propertymanagement';
import { DatePipe } from '@angular/common';
import "../../../../../../wwwroot/js/scripts.js";

declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'app-commercialtenantinvoicesearchresult',
  templateUrl: './commercialtenantinvoicesearchresult.component.html',
  styleUrls: ['./commercialtenantinvoicesearchresult.component.css']
})
export class CommercialTenantInvoiceSearchResultComponent implements OnInit, AfterViewInit, OnDestroy {
  private tenantInvoiceList: ICommercialTenantInvoiceSearchResult[];
  private tenantInvoiceSearchList: GridViewDataTable[][] = [];
  private showGridLoader: boolean = false;
  private showGrid: boolean = true;

  private getSessionData_Subcription: Subscription;
  private tenantInvoices_Subcription: Subscription;

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private _datePipe: DatePipe
  ) { }

  @select(state => (state as IAppState).propertymanagement.commercialTenantInvoice.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).propertymanagement.commercialTenantInvoice.search.tenantInvoices) tenantInvoices$;

  ngOnInit() {
    this.getSessionData_Subcription = this._authService.GetSessionData()
      .subscribe(session => {

        this.tenantInvoices_Subcription = this.tenantInvoices$.subscribe(tis =>
          this.tenantInvoiceSearchList = tis.map(item =>
            [
              { fieldName: "number", value: item.number, showText: item.number.toString(), header: "Invoice No", visible: true, sortable: true },
              { fieldName: "addressText", value: item.addressText, showText: item.addressText.toString(), header: "Property Address", visible: true, sortable: true  },
              { fieldName: "contactID", value: item.contactID, showText: item.contactID, header: "Tenant ID", visible: false, sortable: true },
              { fieldName: "contactName", value: item.debtorName, showText: item.debtorName, header: "Tenant", visible: true, sortable: true },
              { fieldName: "vacancyDate", value: item.vacancyDate, showText: !!item.vacancyDate ? this._datePipe.transform(item.vacancyDate, "dd/MM/yyyy") : "-", header: "Vacancy Date", visible: true, sortable: true },
              { fieldName: "mobile", value: item.mobilePhone, showText: (!!item.mobilePhone ? item.mobilePhone : "-"), header: "Moible", visible: true, showCommunicationLogPhoneIcon: true, showCommunicationLogSMSIcon: true, sortable: true },
              { fieldName: "workPhone", value: item.workPhone, showText: (!!item.workPhone ? item.workPhone : "-"), header: "Tenant W Ph", visible: true, showCommunicationLogPhoneIcon: true, sortable: true },
              { fieldName: "email", value: item.tenantEmail, showText: (!!item.tenantEmail ? item.tenantEmail : "-"), header: "Email", visible: false, showCommunicationLogEmailIcon: true, sortable: true },
              { fieldName: "invoicedToName", value: item.invoicedToName, showText: item.invoicedToName.toString(), header: "Pay To", visible: true, sortable: true },
              { fieldName: "showText", value: item.status, showText: item.status.toString(), header: "Status", visible: true, sortable: true },
              { fieldName: "propertyManagerName", value: item.propertyManagerName, showText: item.propertyManagerName.toString(), header: "Property Manager", visible: true, sortable: true },
              { fieldName: "totalAmount", value: item.totalAmount, showText: this.format2(item.totalAmount, '$'), header: "Total Amount", visible: true, sortable: true },
              { fieldName: "amountDue", value: item.amountDue, showText: this.format2(item.amountDue, '$'), header: "Amount Due", visible: true, sortable: true },
              { fieldName: "dueDate", value: item.dueDate, showText: this._datePipe.transform(item.dueDate, "dd/MM/yyyy"), header: "Due Date", visible: true, sortable: true },
              { fieldName: "daysPastDue", value: item.daysPastDue, showText: item.daysPastDue.toString(), header: "Days Past Due", visible: true, sortable: true },
              { fieldName: "targetTypeID", value: 36, showText: "36", header: "Target Type ID", visible: false, sortable: true },
              { fieldName: "staffID", value: session.staffID, showText: session.staffID, header: "Staff ID", visible: false, sortable: true },
              { fieldName: "mailMergeName", value: session.mailMergeName, showText: session.mailMergeName, header: "Sender Name", visible: false, sortable: true },
            ])
        );
      });
    this.showGridLoader = true;
  }

  ngAfterViewInit() {
    LoadScript.gotoTop();
    var clearIntv = null;
    clearIntv = setInterval(() => {
      if (!!$("#no-more-tables > table").length) {
        clearInterval(clearIntv);
        FixedTableHeaderScript.fixMe($("table"));
        $(".up").click(function () {
          $("html, body").animate(
            {
              scrollTop: 0
            },
            2000
          );
        });
      }
    }, 2000);
  }
  ngOnDestroy() {
    if (!!this.getSessionData_Subcription) this.getSessionData_Subcription.unsubscribe();
    if (!!this.tenantInvoices_Subcription) this.tenantInvoices_Subcription.unsubscribe();
  }

  onClose() {
    let action = new CloseCommercialTenantInvoiceResultPage(true);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    this._router.navigate(["/commercialtenantinvoices"])
  }

  format2(n, currency) {
    return currency + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }

}
