import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig, MatSelectChange } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { IAppState } from '../../store/store';
import { MaintenanceService } from '../maintenance.service';
import { AuthService, IDNameExtraPair, ServiceResponse } from '../../services/authservice';
import { LoaderService } from '../../core/loader/loader.service';
import { IJointTenant, IMaintenanceSelect, IRentalTenantPropertyAddress } from '../maintenance.state';
import { DialogBodyComponent, enDialogRole } from '../../dialog-body/dialog-body.component';
import { GetJointTenants, GetPropertyAddresses, GetSupplierServiceTypes } from '../maintenance.action';
import { ConfirmDialogComponent } from '../../components/confirmdialog/confirmdialog.component';
import { IManagementAgreement } from '../../propertymanagement/propertymanagement';
import { PropertyManagementService } from '../../propertymanagement/propertymanagement.service';

@Component({
  selector: 'app-savemaintenance',
  templateUrl: './savemaintenance.component.html',
  styleUrls: ['./savemaintenance.component.scss']
})
export class SaveMaintenanceComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: MaintenanceService,
    private _authService: AuthService,
    private _loader: LoaderService,
    private _propManagementService: PropertyManagementService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.model = new FormGroup({
      datetime: new FormControl(`${new Date().toYYYYMMDD()} ${new Date().toLocaleTimeString()}`),
      propertyAddress: new FormControl(null, Validators.required),
      selectedTenant: new FormControl(null as IJointTenant, Validators.required),
      selectedSupplierServiceType: new FormControl(null, Validators.required),
      callLogStatusID: new FormControl(null, Validators.required),
      notes: new FormControl(null, Validators.required),
      imgInput1: new FormControl(null),
      imgInput2: new FormControl(null),
      imgInput3: new FormControl(null)
    });
  }
  @Input() id: string;
  //#region Settings
  private snackbarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    horizontalPosition: "center",
    panelClass: ['notifyPanel']
  } as MatSnackBarConfig;
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  }
  //#endregion
  ngOnInit() {
    this.photo1Url = this.photo2Url = this.photo3Url = "/images/noimage.png";
    this.getSessionData_Subcription = this._authService.GetSessionData()
      .subscribe(session => {
        if (!!session) {
          this._sessionID = session.id;
          this.model.controls.propertyAddress.valueChanges.subscribe((address: string) => {
            if (!!address && address.length >= 3) {
              this.getPropertyAddressByAddressString_Subcription =
                this._service.GetPropertyAddressByAddressString(null, session.id, address)
                  .subscribe
                  ((data: ServiceResponse<IRentalTenantPropertyAddress[]>) => {
                    this._loader.hide()
                    if (data.isError) {
                      this.openDialog("Error", data.message, "alertdialog");
                    }
                    else {
                      let action = new GetPropertyAddresses(data.response)
                      this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                    }
                  },
                    error => {
                      this._loader.hide();
                      this.openDialog("Alert", error, "alertdialog");
                    }
                  );
            }
            else {
              let action = new GetPropertyAddresses([]);
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            }

          });



          this.getSupplierServiceTypesBySessionAgency_Subcription =
            this._service.GetSupplierServiceTypesBySessionAgency(null, session.id)
              .subscribe
              ((data: ServiceResponse<IDNameExtraPair<number, number | undefined | null>[]>) => {
                this._loader.hide()
                if (data.isError) {
                  this.openDialog("Error", data.message, "alertdialog");
                }
                else {
                  let action = new GetSupplierServiceTypes(data.response)
                  this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }
              },
                error => {
                  this._loader.hide();
                  this.openDialog("Alert", error, "alertdialog");
                }
              );

          if (!!this.id && this.id.indexOf("00000000-") < 0) {
            this._service.GetMaintenance(null, this.sessionID, this.id)
              .subscribe
              ((data: ServiceResponse<IMaintenanceSelect>) => {
                this._loader.hide()
                if (data.isError) {
                  this.openDialog("Error", data.message, "alertdialog");
                }
                else {

                  this.selectedProperty = {
                    propertyID: data.response.propertyID,
                    managementAgreementID: data.response.managementAgreementID,
                    rentalTenantID: data.response.rentalTenantID,
                    propertyAddress: data.response.propertyAddress
                  };
                  this.model.controls.propertyAddress.setValue(data.response.propertyAddress);
                  if (!!data.response.workOrderID && data.response.workOrderID.indexOf("00000000-") < 0) {
                    this.workOrderID = data.response.workOrderID;
                    this.workOrderNumber = data.response.workOrderNumber;
                  }
                  this.model.controls.callLogStatusID.setValue(data.response.statusID);
                  this.currentCallLogStatusID = data.response.statusID;
                  this.onPropertyAddressChange(this.selectedProperty);
                  setTimeout(() => {
                    let tenants = this.ngRedux.getState().maintenance.newMaintenance.tenants;
                    let tenant = tenants.find(tenant => tenant.id == data.response.callerContactID);
                    this.model.controls.selectedTenant.setValue(tenant);

                    let sst = this.ngRedux.getState().maintenance.newMaintenance.supplierServiceTypes.find(st => st.id == data.response.supplierServiceTypeID);
                    this.model.controls.selectedSupplierServiceType.setValue(sst);
                    this.savedNotes = data.response.text;
                    this.notes = data.response.text;
                    //this.model.controls.notes.setValue(data.response.text);

                  }, 600);

                  this.photo1FileName = !!data.response.imageName1 ? data.response.imageName1 : this.photo1FileName;
                  this.photo2FileName = !!data.response.imageName2 ? data.response.imageName2 : this.photo2FileName;
                  this.photo3FileName = !!data.response.imageName3 ? data.response.imageName3 : this.photo3FileName;

                  this.photo1Url = !!data.response.imageID1Url ? data.response.imageID1Url : this.photo1Url;
                  this.photo2Url = !!data.response.imageID2Url ? data.response.imageID2Url : this.photo2Url;
                  this.photo3Url = !!data.response.imageID3Url ? data.response.imageID3Url : this.photo3Url;
                }
              },
                error => {
                  this._loader.hide();
                  this.openDialog("Alert", error, "alertdialog");
                }
              );
          }

        }
      });
  }
  ngAfterViewInit() {
  }
  ngOnDestroy() {
    if (!!this.getPropertyAddressByAddressString_Subcription) this.getPropertyAddressByAddressString_Subcription.unsubscribe();
    if (!!this.getJointTenantsBySessionAgency_Subcription) this.getJointTenantsBySessionAgency_Subcription.unsubscribe();
    if (!!this.getSupplierServiceTypesBySessionAgency_Subcription) this.getSupplierServiceTypesBySessionAgency_Subcription.unsubscribe();
    if (!!this.getSessionData_Subcription) this.getSessionData_Subcription.unsubscribe();
    if (!!this.getManagementAgreements_Subscription) this.getManagementAgreements_Subscription.unsubscribe();
  }

  //#region Subcription Variables
  private getSessionData_Subcription: Subscription;
  private getJointTenantsBySessionAgency_Subcription: Subscription;
  private getSupplierServiceTypesBySessionAgency_Subcription: Subscription;
  private getPropertyAddressByAddressString_Subcription: Subscription;
  private getManagementAgreements_Subscription: Subscription;
  //#endregion

  //#region Private Variables
  private model: FormGroup;
  private dialogRef: any;
  private _sessionID: string;
  private selectedProperty: IRentalTenantPropertyAddress;
  private photo1FileName: string;
  private photo2FileName: string;
  private photo3FileName: string;
  private photo1Url: string;
  private photo2Url: string;
  private photo3Url: string;
  private savedNotes: string;
  private workOrderID: string;
  private workOrderNumber: number;
  private currentCallLogStatusID: number;
  private notes: string = '';
  //#endregion

  //#region Properties
  get sessionID(): string {
    if (this.route.routeConfig.path.indexOf("maintenance") >= 0 && !!this.route.snapshot.paramMap.get("maintenance") && this.route.snapshot.paramMap.get("maintenance").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("sessionID");
    }
    else {
      return this._sessionID;
    }
  }
  get departmentID(): string {
    if (this.route.routeConfig.path.indexOf("maintenance") >= 0 && !!this.route.snapshot.paramMap.get("departmentID") && this.route.snapshot.paramMap.get("departmentID").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("departmentID");
    }
    else {
      return this.ngRedux.getState().login.departmentID;
    }
  }
  get tenantMobile(): string {
    if (!!this.model.controls.selectedTenant && !!this.model.controls.selectedTenant.value)
      return (this.model.controls.selectedTenant.value as IJointTenant).tenantNumber;
    else
      return '';
  }
  get ownerName(): string {
    if (!!this.model.controls.selectedTenant && !!this.model.controls.selectedTenant.value)
      return (this.model.controls.selectedTenant.value as IJointTenant).ownerContactName;
    else
      return '';
  }
  get ownerMobile(): string {
    if (!!this.model.controls.selectedTenant && !!this.model.controls.selectedTenant.value)
      return (this.model.controls.selectedTenant.value as IJointTenant).ownerContactNumber;
    else
      return '';
  }
  get propertyManagerName(): string {
    if (!!this.model.controls.selectedTenant && !!this.model.controls.selectedTenant.value)
      return (this.model.controls.selectedTenant.value as IJointTenant).propertyManagerName;
    else
      return '';
  }
  //#endregion

  //#region Store Observables
  @select(state => (state as IAppState).maintenance.newMaintenance.propertyAddresses) propertyAddresses$
  @select(state => (state as IAppState).maintenance.newMaintenance.tenants) tenants$;
  @select(state => (state as IAppState).maintenance.newMaintenance.supplierServiceTypes) supplierServiceTypes$;
  //#endregion

  //#region Events
  onPropertyAddressChange(property) {
    if (!property) return;
    this.selectedProperty = property;
    this.model.controls.propertyAddress.setValue(property.propertyAddress);
    this._loader.show();
    this.getJointTenantsBySessionAgency_Subcription =
      this._service.GetJointTenantsBySessionAgency(null, this.sessionID, property.rentalTenantID, this.id)
        .subscribe
        ((data: ServiceResponse<IJointTenant[]>) => {
          this._loader.hide();
          if (data.isError) {
            this.openDialog("Error", data.message, "alertdialog");
          }
          else {
            let action = new GetJointTenants(data.response);
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          }
        },
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          }
        );
  }
  onPropertyDeselect() {
    if (!!this.id && this.id.indexOf('00000000-') < 0) {
      return;
    }
    this.selectedProperty = null;
    this.model.controls.propertyAddress.setValue(null);
    this.model.controls.selectedTenant.setValue(null);
    let action = new GetJointTenants([]);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
  }
  uploadPicture(event) {
    if (!event.target.files || !event.target.files[0]) return;
    var file = event.target.files[0];
    let fr = new FileReader();

    if (event.target.id == "imgInput1") {
      this.photo1FileName = file.name;
      fr.addEventListener("load", function (evt: any) {
        document.getElementById('img1').setAttribute("src", evt.target.result.toString());
      });
    }
    else if (event.target.id == "imgInput2") {
      this.photo2FileName = file.name;
      fr.addEventListener("load", function (evt: any) {
        document.getElementById('img2').setAttribute("src", evt.target.result.toString());
      });
    }
    else if (event.target.id == "imgInput3") {
      this.photo3FileName = file.name;
      fr.addEventListener("load", function (evt: any) {
        document.getElementById('img3').setAttribute("src", evt.target.result.toString());
      });
    }


    fr.readAsDataURL(file);
  }
  onNotesKeypress(event: KeyboardEvent) {
    if (this.savedNotes == this.model.controls.notes.value) {
      this.model.controls.notes.setValue(null);
    }
  }
  onCallLogStatusSelectionChange(event: MatSelectChange) {
    if (this.currentCallLogStatusID == 2 || this.currentCallLogStatusID == 8) {
      this.model.controls.callLogStatusID.setValue(this.currentCallLogStatusID);
    }
  }
  onSubmitClick(event) {
    if (!(!!this.departmentID && this.departmentID.indexOf('00000000-') < 0)) {
      this.snackBar.open("Department not found", "Close", this.snackbarConfig);
      return;
    }
    if (!(!!this.selectedProperty && !!this.selectedProperty.propertyID && this.selectedProperty.propertyID.indexOf('00000000-') < 0)) {
      this.snackBar.open("Please select property", "Close", this.snackbarConfig);
      return;
    }
    if (!(!!this.model.controls.selectedTenant.value && !!this.model.controls.selectedTenant.value.id && this.model.controls.selectedTenant.value.id.indexOf('00000000-') < 0)) {
      this.snackBar.open("Please select tenant", "Close", this.snackbarConfig);
      return;
    }
    if (!this.model.controls.selectedSupplierServiceType.value) {
      this.snackBar.open("Please select supplier service type", "Close", this.snackbarConfig);
      return;
    }
    if (!this.model.controls.notes.value && !this.notes) {
      this.snackBar.open("Please enter notes", "Close", this.snackbarConfig);
      return;
    }
    if (this.model.status == "VALID" || (this.model.controls.notes.invalid && (!!this.model.controls.notes.value || !!this.notes))) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        data: {
          title: "Confirmation",
          message: `Do you want to continue?`
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this._loader.show();
          this._service.SaveMaintenance
            (
              null,
              this.sessionID,
              this.departmentID,
              this.selectedProperty.managementAgreementID,
              this.selectedProperty.propertyID,
              `${new Date().toYYYYMMDD()} ${new Date().toLocaleTimeString()}`,
              this.model.controls.selectedTenant.value.id,
              this.model.controls.selectedSupplierServiceType.value.id,
              this.model.controls.callLogStatusID.value,
              this.model.controls.notes.value,
              this.photo1FileName,
              !!this.photo1FileName ? document.getElementById('img1').getAttribute("src") : undefined,
              this.photo2FileName,
              !!this.photo2FileName ? document.getElementById('img2').getAttribute("src") : undefined,
              this.photo3FileName,
              !!this.photo3FileName ? document.getElementById('img3').getAttribute("src") : undefined,
              this.id
            )
            .subscribe((data: ServiceResponse<IMaintenanceSelect>) => {
              if (data.isError) {
                this.openDialog("Error", data.message, "alertdialog");
              }
              else {
                if (!!data.response) {
                  this.selectedProperty = {
                    propertyID: data.response.propertyID,
                    managementAgreementID: data.response.managementAgreementID,
                    rentalTenantID: data.response.rentalTenantID,
                    propertyAddress: data.response.propertyAddress
                  };
                  this.model.controls.propertyAddress.setValue(data.response.propertyAddress);
                  this.onPropertyAddressChange(this.selectedProperty);
                  setTimeout(() => {
                    let tenants = this.ngRedux.getState().maintenance.newMaintenance.tenants;
                    let tenant = tenants.find(tenant => tenant.id == data.response.callerContactID);
                    this.model.controls.selectedTenant.setValue(tenant);

                    let sst = this.ngRedux.getState().maintenance.newMaintenance.supplierServiceTypes.find(st => st.id == data.response.supplierServiceTypeID);
                    this.model.controls.selectedSupplierServiceType.setValue(sst);
                    this.savedNotes = data.response.text;
                    //this.model.controls.notes.setValue(data.response.text);
                    this.model.controls.notes.setValue(null);

                  }, 600);

                  this.photo1FileName = !!data.response.imageName1 ? data.response.imageName1 : this.photo1FileName;
                  this.photo2FileName = !!data.response.imageName2 ? data.response.imageName2 : this.photo2FileName;
                  this.photo3FileName = !!data.response.imageName3 ? data.response.imageName3 : this.photo3FileName;

                  this.photo1Url = !!data.response.imageID1Url ? data.response.imageID1Url : this.photo1Url;
                  this.photo2Url = !!data.response.imageID2Url ? data.response.imageID2Url : this.photo2Url;
                  this.photo3Url = !!data.response.imageID3Url ? data.response.imageID3Url : this.photo3Url;



                  if (event.submitter.name == "callLogSave") {
                    if (!!this.workOrderID && this.workOrderID.indexOf('00000000-') <= 0) {
                      this.showWorkOrder = true;
                    }
                  }
                  else if (event.submitter.name == "callLogWOSave") {
                    this.getManagementAgreements_Subscription =
                      this._propManagementService.GetManagementAgreements
                        (
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          this.id
                        )
                        .subscribe((maData: ServiceResponse<IManagementAgreement[]>) => {
                          this._loader.hide();
                          if (maData.isError) {
                            this.openDialog("Error", maData.message, "alertdialog");
                          }
                          else {
                            if (!!maData.response && !!maData.response.length) {
                              this.managementAgreement = maData.response[0];
                              this.showWorkOrder = true;
                            }
                          }
                        },
                          error => {
                            this._loader.hide();
                            this.openDialog("Alert", error, "alertdialog");
                          });
                  }


                }
                this.openDialog("Success", "Maintenance Request Saved", "alertdialog");
              }
              this._loader.hide();
            },
              error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
                this._loader.hide();
              });

        }

      });
    }
  }
  //#endregion
  //#region Close Work Order Popup Dialog
  private managementAgreement: IManagementAgreement;
  private IsWorkOrderSaved: boolean = false;
  private closeOnOutsideClick: boolean = false;
  private showWorkOrder: boolean = false;
  onWorkOrderSave(event: any) {
    this.IsWorkOrderSaved = event;
    if (event) {
      //this.openDialog("Success", "Maintenance Request Saved", "alertdialog");
      //window.location.reload();
      this.showWorkOrder = false;
    }
  }
  onCloseWorkOrder(event: any) {
    if (!this.IsWorkOrderSaved) {
      this.showWorkOrder = !confirm("Work order not saved!! Still want to close?");
      this.closeOnOutsideClick = !this.showWorkOrder;
    }
    else {
      this.showWorkOrder = false;
    }
  }
  //#endregion
}
