export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const CHANGE_DEPARTMENT = 'CHANGE_DEPARTMENT';
export const CONTACT_GET_RENTALTENANT_SORTORDERS = 'CONTACT_GET_RENTALTENANT_SORTORDERS';
export const CONTACT_RENTALTENANT_SEARCH_REFRESH = 'CONTACT_RENTALTENANT_SEARCH_REFRESH';
export const CONTACT_RENTALTENANT_SEARCH = 'CONTACT_RENTALTENANT_SEARCH';
export const CONTACT_RENTALTENANT_SORTORDER_CHANGE = 'CONTACT_RENTALTENANT_SORTORDER_CHANGE';
export const CONTACT_SEARCH_TYPE_CHANGE = 'CONTACT_SEARCH_TYPE_CHANGE';
export const CONTACT_RENTALTENANT_SEARCH_ACTION = 'CONTACT_RENTALTENANT_SEARCH_ACTION';
export const CONTACT_RENTALTENANT_SEARCH_RESULT_SHOW = 'CONTACT_RENTALTENANT_SEARCH_RESULT_SHOW';
export const CONTACT_RENTALTENANT_SEARCH_RESULT_CLOSE = 'CONTACT_RENTALTENANT_SEARCH_RESULT_CLOSE';
