import { Component, ViewChild, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
//import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';


import { IAppState } from "../../store/store";
import { PropertyManagementService } from "./../propertymanagement.service";
import { LoaderService } from "./../../core/loader/loader.service";
import { IDNamePair, AuthService, ServiceResponse, IDNameExtraPair, SessionInfo } from "./../../services/authservice";
import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";
import { ILeaseRenewalStatisticsSearch, ILeaseRenewalStatisticsSearchResult } from '../propertymanagement';
import { GetLeaseRenewalStatisticsSearch, CloseLeaseRenewalStatisticsResultPage } from '../propertymanagement.action';

@Component({
  selector: 'app-leaserenewalstatistics',
  templateUrl: './leaserenewalstatistics.component.html',
  styleUrls: ['./leaserenewalstatistics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LeaseRenewalStatisticsComponent implements OnInit, OnDestroy {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: PropertyManagementService,
    private _authService: AuthService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {

    this.model = new FormGroup({
      selectedPropertyManagerIDs: new FormControl(null),
      completedDateFrom: new FormControl((new Date(new Date().setMonth(new Date().getMonth() - 1))).toYYYYMMDD(), Validators.required),
      completedDateTo: new FormControl(new Date().toYYYYMMDD(), Validators.required),
    });
  }

  //#region Private Variables
  private model: FormGroup;
  private propertyManagerIDs: IDNameExtraPair<string, boolean>[];
  private dialogRef: any;
  //#endregion

  //#region Subcription Variables
  private getSessionData_Subcription: Subscription;
  private getLeaseRenewalPropertyManagers_Subcription: Subscription;
  private getLeaseRenewalStatistics_Subcription: Subscription;
  //#endregion

  //#region Properties
  get calledByREM(): boolean {
    return (this.route.routeConfig.path.indexOf("leaserenewalstatistics") >= 0 && !!this.route.snapshot.paramMap.get("sessionID") && this.route.snapshot.paramMap.get("sessionID").indexOf('00000000-') < 0);
  }
  get database(): string {
    if (this.route.routeConfig.path.indexOf("leaserenewalstatistics") >= 0 && !!this.route.snapshot.paramMap.get("database")) {
      return this.route.snapshot.paramMap.get("database");
    }
    else {
      return null;
    }
  }
  get sessionID(): string {
    if (this.route.routeConfig.path.indexOf("leaserenewalstatistics") >= 0 && !!this.route.snapshot.paramMap.get("sessionID") && this.route.snapshot.paramMap.get("sessionID").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("sessionID");
    }
    else {
      return null;
    }
  }
  get departmentID(): string {
    if (this.route.routeConfig.path.indexOf("leaserenewalstatistics") >= 0 && !!this.route.snapshot.paramMap.get("departmentID") && this.route.snapshot.paramMap.get("departmentID").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("departmentID");
    }
    else {
      return null;
    }
  }
  get leaseRenewalUrl() {
    let u: string;
    u = `/leaserenewal/${this.database}/${this.sessionID}/${this.departmentID}`;
    return u;
  }
  get newLeasesCountUrl() {
    let u: string;
    u = `/newleasescount/${this.database}/${this.sessionID}/${this.departmentID}`;
    return u;
  }
  get leaseRenewalStatisticsUrl() {
    let u: string;
    u = `/leaserenewalstatistics/${this.database}/${this.sessionID}/${this.departmentID}`;
    return u;
  }
  //#endregion

  //#region State Variables
  @select(state => (state as IAppState).propertymanagement.leaseRenewalStatistics.search.showSearchResult) showSearchResult$;
  //#endregion

  //#region Settings
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  //#region Snackbar Config
  private snackbarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //#endregion

  //#region Multi-Select Dropdown Settings
  private dropdownSettings: any = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  //#endregion

  //#endregion

  ngOnInit() {
    this.getLeaseRenewalPropertyManagers_Subcription =
      this._service.GetLeaseRenewalPropertyManagers(this.database, this.sessionID)
        .subscribe((data: ServiceResponse<IDNameExtraPair<string, boolean>[]>) => {
          if (!data.isError) {
            this.propertyManagerIDs = data.response;
            let loggedInStaff = data.response.find(pm => pm.extra);
            if (!!loggedInStaff) {
              this.model.controls.selectedPropertyManagerIDs.setValue([loggedInStaff]);
            }
            else {
              this.model.controls.selectedPropertyManagerIDs.setValue(data.response);
            }
          }
          else {
            if (this.calledByREM) {
              console.error(data.message)
            }
            else {
              this.snackBar.open(data.message, "Close", this.snackbarConfig);
            }
          }
          this._loader.hide();
        }),
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      }
  }
  ngOnDestroy() {
    if (!!this.getSessionData_Subcription) this.getSessionData_Subcription.unsubscribe();
    if (!!this.getLeaseRenewalPropertyManagers_Subcription) this.getLeaseRenewalPropertyManagers_Subcription.unsubscribe();
    if (!!this.getLeaseRenewalStatistics_Subcription) this.getLeaseRenewalStatistics_Subcription.unsubscribe();
  }

  //#region Events
  onSubmitClick() {
    //Lease End Date From
    let completedDateFrom: Date | null = null;
    if (!!this.model.controls.completedDateFrom.value) {
      completedDateFrom = this.model.controls.completedDateFrom.value;
      console.log("completedDateFrom: ", completedDateFrom);
    }

    //Next Months
    let completedDateTo: Date | null = null;
    if (!!this.model.controls.completedDateTo.value) {
      completedDateTo = this.model.controls.completedDateTo.value;
      console.log("completedDateTo: ", completedDateTo);
    }

    //Property Managers
    let prManagerIDs: string[] = null;
    if (!!this.model.controls.selectedPropertyManagerIDs.value) {
      let selectedPropertyManagerIDs: IDNameExtraPair<string, boolean>[] = this.model.controls.selectedPropertyManagerIDs.value;
      if (!!selectedPropertyManagerIDs && !!selectedPropertyManagerIDs.length) {
        prManagerIDs = selectedPropertyManagerIDs.map(item => { return item.id });
      }
      console.log("prManagerIDs: ", prManagerIDs);
    }


    //#region Refresh Search Result Grid
    let action = new CloseLeaseRenewalStatisticsResultPage(true);
    this.ngRedux.dispatch({ type: action.type });
    if (!!this.database && !!this.database.length) {
      this._router.navigate([`/leaserenewalstatistics/${this.database}/${this.sessionID}/${this.departmentID}`]);
    }
    else {
      this._router.navigate(["/leaserenewalstatistics"]);
    }
    //#endregion

    this._loader.show();
    if (!!this.sessionID && !!this.departmentID) {
      this.getLeaseRenewalStatistics_Subcription =
        this._service
          .GetLeaseRenewalStatistics
          (
            this.database,
            this.sessionID,
            this.departmentID,
            completedDateFrom,
            completedDateTo,
            prManagerIDs
          )
          .map((data: ServiceResponse<ILeaseRenewalStatisticsSearchResult[]>) => {
            if (!data.isError) {
              return new GetLeaseRenewalStatisticsSearch(data.response);
            }
            else {
              Observable.throwError(data.message);
              this.openDialog("Error", data.message, "alertdialog");
            }
          })
          .subscribe(action => {
            if (!!action && !!action.payload && !!action.payload.length) {
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
              console.log("action.payload: ", action.payload);
              if (!!this.database)
                this._router.navigate([`/leaserenewalstatistics/${this.database}/${this.sessionID}/${this.departmentID}/search`]);
              else
                this._router.navigate([`/leaserenewalstatistics/search`]);
            }
            else {
              if (this.calledByREM) {
                console.log("No record found");
              }
              else {
                this.snackBar.open("No record found", "Close", this.snackbarConfig);
              }
              
            }
            this._loader.hide();
          }),
        error => {
          this._loader.hide();
          this.openDialog("Alert", error, "alertdialog");
        }
    }
    else {
      if (this.calledByREM) {
        console.log("Not available");
      }
      else {
        this.snackBar.open("Not available", "Close", this.snackbarConfig);
      }
      return;
    }

  }
  //#endregion
}
