import { ISearchVisitors, ISearchEnquirersFollowUp, ISearchListingEnquiry } from './reports';

export interface IReportsState {
    visitors: {
        search: ISearchVisitors
    },
    enquirersfollowup: {
        search: ISearchEnquirersFollowUp;
    },
    listingenquiries: {
      search: ISearchListingEnquiry
    }
}
export const INITIAL_REPORTS_STATE = (): IReportsState => ({
    visitors: {
        search: {
            staffIDs: [],
            selectedStaffIDs: [],
            showSearchResult: false
        } as ISearchVisitors
    },
    enquirersfollowup: {
        search: {
            staffs: [],
            selectedStaffIDs: [],
            selectedPropertyText: null,
            currentListing: true,
            followUpRequired: true,
            /*selectedMotivationFrom: 0,
            selectedMotivationTo: 0,*/
            interestLevels: [
                { id: 0, name: "Unspecified" },
                { id: 1, name: "Very Interested" },
                { id: 2, name: "Interested" },
                { id: 3, name: "May Be Interested" },
                { id: 4, name: "Undecided" },
                { id: 5, name: "Not Interested" }
            ],
            selectedInterestLevels: [],
            dateFrom: null,
            dateTo: null,
            enquiries: [],
            showSearchResult: false
        } as ISearchEnquirersFollowUp
    },
    listingenquiries: {
      search: {
        staffs: [],
        selectedStaffIDs: [],
        dateFrom: null,
        dateTo: null,
        appointmentStatuses: [],
        saleStatuses: [],
        listingEnquiries: [],
        showSearchResult: false
      }
    }
});
