import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from 'rxjs/Subscription';

import { IAppState } from "../../store/store";
import { EmarketingService } from "./../emarketing.service";
import { LoaderService } from "./../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../dialog-body/dialog-body.component';
import { IAddEditContactLists } from "./../emarketing";
import { LoadContactListsSearchPage, LoadNewContactListsPage } from "./../emarketing.action";
import "../../../../../wwwroot/js/scripts.js";
import { IDNamePair } from "./../../services/authservice";
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'app-contactlists',
  templateUrl: './contactlists.component.html',
  styleUrls: ['./contactlists.component.css']
})
export class ContactListsComponent implements OnInit, AfterViewInit, OnDestroy {

  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  private dialogRef: any;

  private subscription_GetEmailContactStatuses: Subscription;

  constructor(private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: EmarketingService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }

  @select(state => (state as IAppState).emarketing.contactlists.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).emarketing.contactlists.addEditView.showAddEditPage) showAddEditPage$;

  ngOnInit() {
    this._loader.show();
    this. subscription_GetEmailContactStatuses = this._service.GetEmailContactStatuses()
      .map((statuses: IDNamePair<number>[]) => {
        return new LoadContactListsSearchPage(statuses);
      })
      .subscribe(action => {
        this.ngRedux.dispatch({ type: action.type, payload: action.payload });
        this._loader.hide();
      },
      error => {
        this._loader.hide();
        this.openDialog("Error", (error.json().error || "Server Error"), "alertdialog");
      });
  }
  ngAfterViewInit() {
    LoadScript.init();
  }
  ngOnDestroy() {
    if (!!this.subscription_GetEmailContactStatuses) this.subscription_GetEmailContactStatuses.unsubscribe();
  }

  //#region Events
  onNew() {
    this._loader.show();
    this. subscription_GetEmailContactStatuses = this._service.GetEmailContactStatuses()
      .map((statuses: IDNamePair<number>[]) => {
        return new LoadNewContactListsPage(statuses);
      })
      .subscribe(action => {
        this.ngRedux.dispatch({ type: action.type, payload: action.payload });
        this._loader.hide();
        this._router.navigate(["/contactlists/new"]);
      },
      error => {
        this._loader.hide();
        this.openDialog("Error", (error.json().error || "Server Error"), "alertdialog");
      });    
  }
  //#endregion

}
