import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogData } from './confirmdialog.state';

@Component({
    selector: 'confirmdialog',
    templateUrl: './confirmdialog.component.html',
    styleUrls: ['./confirmdialog.component.css']
})
export class ConfirmDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData) { }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
    onConfirm(): void {
        this.dialogRef.close(true);
    }
}