import { IRecipientType, IEmailTemplate, IEmailTemplateField, IWidget, IEmailImage, IRemoveTemplateImageResponse, IEmailContactList, IContactEmail, IEmail, IEmailAttachment, IEmailSendPreviewEmailResponse, IEmailSendEmailResponse, IContactFilterField, IFilterItem, IAddEditContactLists } from "./emarketing";
import { Observable } from "rxjs/Observable";

import { Injectable, Inject, PLATFORM_ID, Injector } from "@angular/core";
import {
  Http,
  Headers,
  Response,
  RequestOptions,
  RequestMethod
} from "@angular/http";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
//import "rxjs/add/Observable/of";
//import "rxjs/add/operator/delay";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { IDNamePair } from "./../services/authservice";

@Injectable()
export class EmarketingService {
  constructor(
    private _http: Http,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  GetRecipientTypes(
    recipientSourceTypes: number[] = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384]
  ): Observable<IRecipientType[]> {
    return this._http
      .get("api/Emarketing/GetEmailRecipientTypes", {
        params: { recipientSourceTypes: recipientSourceTypes }
      })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetEmailTemplates(
    //recipientSourceTypes: number[],
    templateName: string,
    recipientSources: number
  ): Observable<IEmailTemplate[]> {
    return this._http
      .get("api/Emarketing/GetEmailTemplates", {
        params: {
          //recipientSourceTypes: recipientSourceTypes,
          templateName: templateName,
          recipientSources: recipientSources
        }
      })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetEmailAgencies(): Observable<IDNamePair<string>[]> {
    return this._http
      .get("api/Emarketing/GetEmailAgencies")
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetEmailTemplate(templateID: string): Observable<IEmailTemplate> {
    return this._http
      .get("api/Emarketing/GetEmailTemplate", {
        params: { templateID: templateID }
      })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  async GetTemplateChildFields(templateID: string, selectedField: string): Promise<IEmailTemplateField[]> {
    const response = await this._http
      .post("api/Emarketing/GetTemplateChildFields", {
        /*params: {*/ templateID: templateID, selectedField: selectedField //}
      }).toPromise()
      .catch((error: any) => error.json().error || "Server Error");
    return response.json();
  }
  AddWidgetToTemplate(templateID: string, selectedWidgetType: string): Observable<IWidget> {
    return this._http
      .get("api/Emarketing/AddWidgetToTemplate", {
        params: { templateID: templateID, selectedWidgetType: selectedWidgetType }
      })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  RemoveWidget(templateID: string, selectedWidgetID: string): Observable<boolean> {
    return this._http
      .get("api/Emarketing/RemoveWidget", {
        params: { templateID: templateID, selectedWidgetID: selectedWidgetID }
      })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetTemplateImageTypes(): Observable<IDNamePair<number>[]> {
    return this._http
      .get("api/Emarketing/GetTemplateImageTypes")
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetImages(templateID: string, imageTypeID: number, imageWidth: number, imageHeight: number): Observable<IEmailImage[]> {
    return this._http
      .get("api/Emarketing/GetImages", {
        params: { templateID: templateID, imageTypeID: imageTypeID, imageWidth: imageWidth, imageHeight: imageHeight }
      })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetImagePicture(imageSize: number, width: number, height: number, imageID: string): Observable<string> {
    return this._http
      .get("api/Login/GetImagePicture", {
        params: { imageSize: imageSize, width: width, height: height, imageID: imageID }
      })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  SaveTemplateImage(templateID: string, base64String: string, name: string, caption: string): Observable<IEmailImage> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Emarketing/SaveTemplateImage", { templateID: templateID, base64String: base64String, name: name, caption: caption }
        , options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  RemoveTemplateImage(templateID: string, templateImage: IEmailImage, imageWidth: number, imageHeight: number): Observable<IRemoveTemplateImageResponse> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Emarketing/RemoveTemplateImage", { templateID: templateID, templateImage: templateImage, imageWidth: imageWidth, imageHeight: imageHeight }
        , options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  SaveTemplate(templateID: string, templateName: string, description: string, subject: string, htmlBody: string, base64String: string): Observable<IEmailImage> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Emarketing/SaveTemplate", { templateID: templateID, templateName: templateName, description: description, subject: subject, htmlBody: htmlBody, base64String: base64String }
      /*, options*/)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetDepartments(selectedAgencyID: string): Observable<IDNamePair<string>[]> {
    return this._http
      .get("api/Emarketing/GetDepartments", { params: { selectedAgencyID: selectedAgencyID } })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetStaffs(selectedAgencyID: string): Observable<IDNamePair<string>[]> {
    return this._http
      .get("api/Emarketing/GetStaffs", { params: { selectedAgencyID: selectedAgencyID } })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  CreateTemplate(name: string, /*communicationReasonID: number,*/ availableFor: string, availableForID: string): Observable<string> {
    return this._http
      .post("api/Emarketing/CreateTemplate",
        {
          name: name,
          //communicationReasonID: communicationReasonID,
          availableFor: availableFor,
          availableForID: availableForID
        })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetEmailStatuses(): Observable<IDNamePair<number>[]> {
    return this._http
      .get("api/Emarketing/GetEmailStatuses")
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetRecipientSourceTypes(): Observable<IDNamePair<number>[]> {
    return this._http
      .get("api/Emarketing/GetRecipientSourceTypes")
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetContactLists(): Observable<IEmailContactList[]> {
    return this._http
      .get("api/Emarketing/GetContactLists")
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  CreateEmail(selectedRecipientSourceType: number, selectedRecipientType: number, selectedTemplate: string, selectedContactList: string): Observable<IEmail> {
    return this._http
      .post("api/Emarketing/CreateEmail",
        {
          selectedRecipientSourceType: selectedRecipientSourceType,
          selectedRecipientType: selectedRecipientType,
          selectedTemplate: selectedTemplate,
          selectedContactList: selectedContactList
        })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetContactsForEmail(emailID: string, nameOrEmail: string, fromMarker: string): Observable<IContactEmail[]> {
    return this._http
      .get("api/Emarketing/GetContactsForEmail", { params: { emailID: emailID, nameOrEmail: nameOrEmail, fromMarker: fromMarker } })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  AddAttachmentsToEmail(emailID: string, attachments: any): Observable<IEmailAttachment[]> {
    //let formData: FormData = new FormData();    
    //const headers = new Headers();
    /*headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');*/
    //headers.append('Content-Disposition', 'multipart/form-data');
    //formData.append("emailID", emailID);
    /*for(var index = 0; index < attachments.length; index++) {      
      formData.append(attachments[index].file.name + "_" + (index + 1), attachments[index].file, attachments[index].file.name);
    }*/

    return this._http
      .post("api/Emarketing/AddAttachmentsToEmail",
        {
          emailID: emailID,
          attachments: attachments
        })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  async SaveEmail(emailID: string, subject: string, textBody: string, htmlBody: string, attachments: IEmailAttachment[]): Promise<boolean> {
    const response = await this._http
      .post("api/Emarketing/SaveEmail",
        {
          emailID: emailID,
          subject: subject,
          textBody: textBody,
          htmlBody: htmlBody,
          attachments: attachments
        }).toPromise()
      .catch((error: any) => (error.json().error || "Server Error"));
      return response.json();
  }
  GetEmails(subject: string, maxResultsToReturn: number | null, fromMarker: string, createdByStaffs: string[], sentByStaffs: string[], statuses: number[]): Observable<IEmail[]> {
    return this._http
      .post("api/Emarketing/GetEmails", { 
          subject: subject,
          maxResultsToReturn: maxResultsToReturn,
          fromMarker: fromMarker,
          createdByStaffs: createdByStaffs,
          sentByStaffs: sentByStaffs,
          statuses: statuses
        })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetEmail(emailID: string): Observable<IEmail> {
    return this._http
      .post("api/Emarketing/GetEmail", { emailID: emailID })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  async GetEmailChildFields(emailID: string, selectedField: string): Promise<IEmailTemplateField[]> {
    const response = await this._http
      .get("api/Emarketing/GetEmailChildFields", {
        params: { emailID: emailID, selectedField: selectedField }
      }).toPromise()
      .catch((error: any) => error.json().error || "Server Error");
    return response.json();
  }
  SendPreviewEmail(emailID: string, previewToEmails: string, recipientContactID: string): Observable<IEmailSendPreviewEmailResponse> {
    return this._http
      .post("api/Emarketing/SendPreviewEmail", { emailID: emailID, previewToEmails: previewToEmails, recipientContactID: recipientContactID })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  SendEmail(emailID: string, scheduledDatetime: Date | null): Observable<IEmailSendEmailResponse> {
    return this._http
      .post("api/Emarketing/SendEmail", { emailID: emailID, scheduledDatetime: scheduledDatetime })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  RemoveEmail(emailID: string): Observable<boolean> {
    return this._http
      .post("api/Emarketing/RemoveEmail", { emailID: emailID })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  AddWidgetToEmail(emailID: string, widgetTypeID: number): Observable<IWidget> {
    return this._http
      .post("api/Emarketing/AddWidgetToEmail", { emailID: emailID, widgetTypeID: widgetTypeID })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  RemoveWidgetFromEmail(emailID: string, widgetID: string): Observable<boolean> {
    return this._http
      .post("api/Emarketing/RemoveWidgetFromEmail", { emailID: emailID, widgetID: widgetID })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  //#region Contact Lists
  GetEmailContactStatuses(): Observable<IDNamePair<number>[]> {
    return this._http
      .get("api/Emarketing/GetEmailContactStatuses")
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  async GetContactFilterFields(): Promise<IContactFilterField[]> {
    const response = await this._http
      .get("api/Emarketing/GetContactFilterFields")
      //.map((res: Response) => res.json())
      .toPromise()
      .catch((error: any) => (error.json().error || "Server Error"));
    return response.json();
  }
  SaveContactList(id: string | null, name: string, description: string, status: number, filter: IFilterItem): Observable<boolean> {
    return this._http
      .post("api/Emarketing/SaveContactList", { id: id, name: name, description: description, status: status, filter: filter })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  EditContactList(contactListID: string): Observable<IEmailContactList> {
    return this._http
      .post("api/Emarketing/EditContactList", { contactListID: contactListID })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  //#endregion
}
