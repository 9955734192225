import { ISearchVacantProperty, ISearchContacts, ISearchManagementAgreement, ITenantContactSearch, IManagementAgreement, ITenantContactSearchResult, IWorkOrderSearch, ITenantInvoiceSearch, ICommercialSearchManagementAgreement, ICommercialTenantContactSearch, ICommercialTenantContactSearchResult, ICommercialWorkOrderSearch, ICommercialTenantInvoiceSearch, ICommercialSearchContacts, ILeaseRenewalSearch, INewLeaseCountSearch, ILeaseRenewalStatisticsSearch } from './propertymanagement';

export interface IPropertyManagementState {
    contacts:{
      search: ISearchContacts
    },
    managementagreement: {
        search: ISearchManagementAgreement;
    },
    tenantcontact: {
      search: ITenantContactSearch
    },
    workorder: {
      search: IWorkOrderSearch
    },
    tenantInvoice: {
      search: ITenantInvoiceSearch
    },
    vacantpropertieslessthan14: {
      search: ISearchVacantProperty
    },
    vacantpropertiesgreaterthan14: {
      search: ISearchVacantProperty
    },
    commercialcontacts:{
      search: ICommercialSearchContacts
    },
    commercialmanagementagreement: {
      search: ICommercialSearchManagementAgreement;
    },
    commercialtenantcontact: {
      search: ICommercialTenantContactSearch
    },
    commercialworkorder: {
      search: ICommercialWorkOrderSearch;
    },
    commercialTenantInvoice: {
      search: ICommercialTenantInvoiceSearch;
    },
    leaseRenewal: {
      search: ILeaseRenewalSearch;
    },
    newLeasesCount: {
      search: INewLeaseCountSearch;
    },
    leaseRenewalStatistics: {
      search: ILeaseRenewalStatisticsSearch;
    }
}

export const INITIAL_PROPERTYMANAGEMENT_STATE = (): IPropertyManagementState => ({
  contacts:{
    search: {
      name: "",
      mobile: "",
      email: "",
      showSearchResult: false
    } as ISearchContacts
  },
    managementagreement: {
      search: {
        searchText: "",
        searchFields: 1,
        vacant: false,
        managementTypeIDs: [],
        statusIDs: [],
        selectedOwnerStatementGroupIDs: [],
        selectedPeriodicLeases: [],
        selectedPropertyManagerIDs: [],
        propertyManagerIDs: [],
        ownerStatementGroupIDs: [],
        periodicLeases: [],
        alertNotes: false,
        isCommercialManagementSystem: false,
        managementagreements: [],
        showSearchResult: false
      } as ISearchManagementAgreement
    },
    tenantcontact: {
      search:{
        searchText: "",
        statusIDs: [],
        searchFields: 1,
        propertyManagerIDs: [],
        hasOverdueInvoices: false,
        daysOverdue: 1,
        includeJointTenants: false,
        excludePeriodLeases: false,
        tenantContacts: [],
        showSearchResult: false,
        selectedPropertyManagerIDs: [],
        sortBy: 1
      } as ITenantContactSearch
    },
    workorder: {
      search: {
        statusIDs: [],
        serviceTypeIDs: [],
        dueDateFrom: null,
        dueDateTo: null,
        searchFields: 1,
        no: null,
        propertyManagerIDs: [],
        commercialServiceTypeIDs: [],
        isCommercialManagementSystem: false,
        workOrders: [],
        showSearchResult: false
      }as IWorkOrderSearch
  },
  tenantInvoice: {
    search: {
      selectedPropertyID: "",
      statusIDs: [],
      dateFrom: null,
      dateTo: new Date(),
      supplierServiceTypeID: null,
      number:  null,
      propertyManagerIDs: [],
      repeatingTypeID: null,
      recurringSeriesOnly: false,
      transactionReason: null,
      creditorTypeID: 3,
      tenantStatusIDs: [],
      nonIssued: null,
      tenantInvoices: [],
      showSearchResult: false,
      overdueDaysFrom:  null,
      overdueDaysTo: null,
      includeJointTenants: false,
      sortBy: 1
    } as ITenantInvoiceSearch
  },
  vacantpropertieslessthan14: {
    search: {
      vacantPropertySearchResult: []
    } as ISearchVacantProperty
  },
  vacantpropertiesgreaterthan14: {
    search: {
      vacantPropertySearchResult: []
    } as ISearchVacantProperty
  },
  commercialcontacts:{
    search: {
      name: "",
      mobile: "",
      email: "",      
      enquiryContactOnly: false,
      showSearchResult: false,
      contactSearchResults: [],
      contactCreation: {
        mobile: null,
        givenName: null,
        surname: null,
        email: null,
        notes: null,
        leadSources: []
      },
      showContactCreation: false,
    } as ICommercialSearchContacts
  },
  commercialmanagementagreement: {
    search: {
      searchText: "",
      searchFields: 1,
      vacant: false,
      managementTypeIDs: [],
      statusIDs: [],
      selectedOwnerStatementGroupIDs: [],
      selectedPeriodicLeases: [],
      selectedPropertyManagerIDs: [],
      propertyManagerIDs: [],
      ownerStatementGroupIDs: [],
      periodicLeases: [],
      alertNotes: false,
      isCommercialManagementSystem: false,
      managementagreements: [],
      showSearchResult: false
    } as ICommercialSearchManagementAgreement
  },
  commercialtenantcontact: {
    search:{
      searchText: "",
      statusIDs: [],
      searchFields: 1,
      propertyManagerIDs: [],
      hasOverdueInvoices: false,
      daysOverdue: 1,
      includeJointTenants: false,
      excludePeriodLeases: false,
      tenantContacts: [],
      showSearchResult: false,
      selectedPropertyManagerIDs: [],
      sortBy: 1
    } as ICommercialTenantContactSearch
  },
  commercialworkorder: {
    search: {
      statusIDs: [],
      serviceTypeIDs: [],
      dueDateFrom: null,
      dueDateTo: null,
      searchFields: 1,
      no: null,
      propertyManagerIDs: [],
      commercialServiceTypeIDs: [],
      isCommercialManagementSystem: false,
      workOrders: [],
      showSearchResult: false
    }as ICommercialWorkOrderSearch
  },
  commercialTenantInvoice: {
    search: {
      selectedPropertyID: "",
      statusIDs: [],
      dateFrom: null,
      dateTo: new Date(),
      supplierServiceTypeID: null,
      number:  null,
      propertyManagerIDs: [],
      repeatingTypeID: null,
      recurringSeriesOnly: false,
      transactionReason: null,
      creditorTypeID: 3,
      tenantStatusIDs: [],
      nonIssued: null,
      tenantInvoices: [],
      showSearchResult: false,
      overdueDaysFrom:  null,
      overdueDaysTo: null,
      includeJointTenants: false,
      sortBy: 1
    } as ICommercialTenantInvoiceSearch
  },
  leaseRenewal: {
    search: {
      selectedPropertyManagerIDs: [],
      selectedLeaseRenewalStatusIDs: [],
      leaseEndDateFrom: null,
      selectedNextMonths: 1,
      leaseEndDateBeforeFromDate: false,
      leaseRenewals: [],
      showSearchResult: false
    } as ILeaseRenewalSearch
  },
  newLeasesCount: {
    search: {
      selectedPropertyManagerIDs: [],
      completedDateFrom: null,
      completedDateTo: null,
      newLeasesCounts: [],
      showSearchResult: false
    } as INewLeaseCountSearch
  },
  leaseRenewalStatistics: {
    search: {
      selectedPropertyManagerIDs: [],
      completedDateFrom: null,
      completedDateTo: null,
      statistics: [],
      showSearchResult: false
    }
  }
});
