import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Sort, SortDirection } from '@angular/material/sort';

import { IAppState } from "../../../store/store";
import { AuthService } from "../../../services/authservice";
import { PropertyManagementService } from "./../../propertymanagement.service";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';
import {CloseWorkOrderResultPage } from "./../../propertymanagement.action"
import { IWorkOrderResult } from '../../propertymanagement';

@Component({
  selector: 'app-workordersearchresult',
  templateUrl: './workordersearchresult.component.html',
  styleUrls: ['./workordersearchresult.component.css']
})
export class WorkorderSearchResultComponent implements OnInit, OnDestroy {

  //#region Communicatio Log
  private getSessionData_Subscription: Subscription;
  private showCommunication: boolean;
  private actionCommunication: "tel" | "sms" | "mailto";
  private contactForCommunication: string;
  private contactID: string;
  private contactName: string;
  private mobile: string;
  private email: string;
  private targetTypeID: number;
  private staffID: string;
  private mailMergeName: string;
  private showSortingLoader: boolean = false;

  //#endregion

  private workOrders: IWorkOrderResult[] = [];
  private selectedWorkOrder: IWorkOrderResult;

  private workOrders_Subscription: Subscription;

  constructor
  (
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    //private _eMarketingService: PropertyManagementService,
    private _service: PropertyManagementService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer
  ) { }

  @select(state => (state as IAppState).propertymanagement.workorder.search.workOrders) workOrders$;
  @select(state => (state as IAppState).propertymanagement.workorder.search.showSearchResult) showSearchResult$

  private dialogRef: any;
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }

  ngOnInit() {
    this.getSessionData_Subscription = this._authService.GetSessionData()
    .subscribe(session => {
      this.staffID = session.staffID;
      this.mailMergeName = session.mailMergeName;
    });
    this.workOrders_Subscription = this.workOrders$
    .subscribe(wo => {
      this.workOrders = wo;
      this.loadPageData();
    });
  }
  ngAfterViewInit() {
    LoadScript.gotoTop();
    var clearIntv = null;
    clearIntv = setInterval(() => {
      if (!!$("#no-more-tables > table").length) {
        clearInterval(clearIntv);
        //FixedTableHeaderScript.fixMe($("table"));
        $(".up").click(function() {
          $("html, body").animate(
            {
              scrollTop: 0
            },
            2000
          );
        });
      }
    }, 2000);
  }
  ngOnDestroy() {
    if (!!this.getSessionData_Subscription) this.getSessionData_Subscription.unsubscribe();
    if (!!this.workOrders_Subscription) this.workOrders_Subscription.unsubscribe();
  }

  onClose() {
    let action = new CloseWorkOrderResultPage(true);
    this.ngRedux.dispatch({ type: action.type,payload:action.payload});
    this._router.navigate(["/workorders"])
  }
  // sanitizeSMS(wo: IWorkOrderResult) {
  //   //return this.sanitizer.bypassSecurityTrustUrl("sms:" + wo.supplierMobileNumber + "?body=" + this.getSMS(wo));
  //   return this.sanitizer.bypassSecurityTrustUrl("sms:" + wo.supplierMobileNumber);    
  // }
  sanitizeSMS(mobilePhone: string) {
    return this.sanitizer.bypassSecurityTrustUrl("sms:" + mobilePhone);
  }
  private getSMS(wo: IWorkOrderResult): string {
    return wo.number.toString();
  }

  onExpand(wo: IWorkOrderResult) {
    var timezoneOffset =  new Date().getTimezoneOffset();
    if (!wo.showCommunication) {
      this._loader.show();      
      this._service.CommunicationDetails(wo.id,3,timezoneOffset)
        .subscribe(data => {
          this._loader.hide();
          wo.communications = data;
          wo.showCommunication = !wo.showCommunication;
          console.log(data);
        }),
        error => {
          this._loader.hide();
          this.openDialog("Alert", error, "alertdialog");
        };
    }
    else {
      wo.showCommunication = !wo.showCommunication;
    }

  }

  onCommunicationClick(action: "tel" | "sms" | "mailto", row: IWorkOrderResult) {
    this.actionCommunication = action;
    if (action == "tel" || action == "sms") {
      this.contactForCommunication = row.supplierMobileNumber;
    }
    else if (action == "mailto") {
      this.contactForCommunication = row.supplierEmail;
    }
    this.contactID = row.supplierContactID;
    this.contactName = row.supplierName;
    this.mobile = row.supplierMobileNumber;
    this.email = row.supplierEmail;
    this.showCommunication = !this.showCommunication;
  }
  onSave(event: any) {
    if (!!event && !event.isError) {
      this.showCommunication = false;
    }
  }

  //#region Close Work Order Popup Dialog
  private IsWorkOrderSaved: boolean = false;
  private closeOnOutsideClick: boolean = false;
  private showWorkOrder: boolean = false;
  onShowEditClick(row: IWorkOrderResult) {
    debugger;
    this.selectedWorkOrder = row;
    this.showWorkOrder = true;
  }
  onWorkOrderSave(event: any) {
    this.IsWorkOrderSaved = event;
  }
  onCloseWorkOrder(event: any) {
    if (!this.IsWorkOrderSaved) {
      this.showWorkOrder = !confirm("Work order not saved!! Still want to close?");
      this.closeOnOutsideClick = !this.showWorkOrder;
      if (!this.showWorkOrder) {
        this.selectedWorkOrder = null;
      }
    }
    else {
      this.showWorkOrder = false;
      this.selectedWorkOrder = null;
    }
  }
  onAddWorkOrder() {
    this.showWorkOrder = true;
  }
  //#endregion

  //#region Pagination  
  private hasPagination: boolean = true;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: IWorkOrderResult[];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.pageData = this.workOrders;
    if (this.hasPagination) {
      this.pageData = this.workOrders.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }    
  }
  //#endegion


  //region FOR Mobile VIEW SORT
  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }

  //end region

  sortData(sort: Sort) {
    //this.showSortingLoader = true;
    this._loader.show();
    setTimeout(() => { 
      this.sortAllData(sort);
    }, 10)
  }

  private sortAllData(sort: Sort) {
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.workOrders.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.workOrders.length; innerIndex++) {
        switch(sort.active) {
          case "Date Issued": {
            if (isAsc) {
              if (this.workOrders[index].dateIssued > this.workOrders[innerIndex].dateIssued) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            else {
              if (this.workOrders[index].dateIssued < this.workOrders[innerIndex].dateIssued) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            break;
          }
          case "Number": {
            if (isAsc) {
              if (this.workOrders[index].number > this.workOrders[innerIndex].number) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            else {
              if (this.workOrders[index].number < this.workOrders[innerIndex].number) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            break;
          }
          case "Status": {
            if (isAsc) {
              if (this.workOrders[index].statusDetails > this.workOrders[innerIndex].statusDetails) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            else {
              if (this.workOrders[index].statusDetails < this.workOrders[innerIndex].statusDetails) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            break;
          }
          case "Property Address": {
            if (isAsc) {
              if (this.workOrders[index].address > this.workOrders[innerIndex].address) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            else {
              if (this.workOrders[index].address < this.workOrders[innerIndex].address) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            break;
          }
          case "Supplier": {
            if (isAsc) {
              if (this.workOrders[index].supplierName > this.workOrders[innerIndex].supplierName) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            else {
              if (this.workOrders[index].supplierName < this.workOrders[innerIndex].supplierName) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            break;
          }
          case "Service Type": {
            if (isAsc) {
              if (this.workOrders[index].serviceTypeName > this.workOrders[innerIndex].serviceTypeName) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            else {
              if (this.workOrders[index].serviceTypeName < this.workOrders[innerIndex].serviceTypeName) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            break;
          }
          case "Supplier Mobile": {
            if (isAsc) {
              if (this.workOrders[index].supplierMobileNumber > this.workOrders[innerIndex].supplierMobileNumber) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            else {
              if (this.workOrders[index].supplierMobileNumber < this.workOrders[innerIndex].supplierMobileNumber) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            break;
          }
          case "Onsite Contact": {
            if (isAsc) {
              if (this.workOrders[index].onSiteContactName > this.workOrders[innerIndex].onSiteContactName) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            else {
              if (this.workOrders[index].onSiteContactName < this.workOrders[innerIndex].onSiteContactName) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            break;
          }
          case "Supplier type": {
            if (isAsc) {
              if (this.workOrders[index].serviceTypeName > this.workOrders[innerIndex].serviceTypeName) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            else {
              if (this.workOrders[index].serviceTypeName < this.workOrders[innerIndex].serviceTypeName) {
                let temp = this.workOrders[index];
                this.workOrders[index] = this.workOrders[innerIndex];
                this.workOrders[innerIndex] = temp;
              }
            }
            break;
          }
        }
      }
    }
    //this.showSortingLoader = false;
    this.loadPageData();
    this._loader.hide();
  }
}
