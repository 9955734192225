import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { Sort, SortDirection } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AccumulationChartComponent, AccumulationChart, IAccLoadedEventArgs, AccumulationTheme } from '@syncfusion/ej2-angular-charts';
import { Browser, registerLicense } from '@syncfusion/ej2-base';

import { IAppState } from "../../../store/store";
import { AuthService, ServiceResponse } from "../../../services/authservice";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';
import { ConfirmDialogComponent } from '../../../components/confirmdialog/confirmdialog.component';
import { PropertyManagementService } from "./../../propertymanagement.service";
import { ILeaseRenewalStatisticsSearchResult } from '../../propertymanagement';
import { CloseLeaseRenewalStatisticsResultPage } from '../../propertymanagement.action';


@Component({
  selector: 'app-leaserenewalstatisticsresult',
  templateUrl: './leaserenewalstatisticsresult.component.html',
  styleUrls: ['./leaserenewalstatisticsresult.component.scss']
})
export class LeaseRenewalStatisticsResultComponent implements OnInit, OnDestroy {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private _datePipe: DatePipe,
    private _authService: AuthService,
    private _service: PropertyManagementService
  ) {
    registerLicense("ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5bdEFjWX1ac3ZRR2VV");
  }

  //#region Private Variables
  private readonly fontFace = "Comic Sans MS";
  private renewalStatistics: Object[] = [];
  private rentIncreases: Object[] = [];
  private newLeasePeriods: Object[] = [];
  //#endregion

  //#region Subcription Variables
  private getSessionData_Subcription: Subscription;
  private getLeaseRenewalPropertyManagers_Subcription: Subscription;
  private statistics$_Subcription: Subscription;
  //#endregion

  //#region Properties
  get calledByREM(): boolean {
    return (this.route.snapshot.parent.routeConfig.path.indexOf("leaserenewalstatistics") >= 0 && !!this.route.snapshot.parent.paramMap.get("sessionID") && this.route.snapshot.parent.paramMap.get("sessionID").indexOf('00000000-') < 0);
  }
  get database(): string {
    if (this.route.snapshot.parent.routeConfig.path.indexOf("leaserenewalstatistics") >= 0 && !!this.route.snapshot.parent.paramMap.get("database")) {
      return this.route.snapshot.parent.paramMap.get("database");
    }
    else {
      return null;
    }
  }
  get sessionID(): string {
    if (this.route.snapshot.parent.routeConfig.path.indexOf("leaserenewalstatistics") >= 0 && !!this.route.snapshot.parent.paramMap.get("sessionID") && this.route.snapshot.parent.paramMap.get("sessionID").indexOf('00000000-') < 0) {
      return this.route.snapshot.parent.paramMap.get("sessionID");
    }
    else {
      return null;
    }
  }
  get departmentID(): string {
    if (this.route.snapshot.parent.routeConfig.path.indexOf("leaserenewalstatistics") >= 0 && !!this.route.snapshot.parent.paramMap.get("departmentID") && this.route.snapshot.parent.paramMap.get("departmentID").indexOf('00000000-') < 0) {
      return this.route.snapshot.parent.paramMap.get("departmentID");
    }
    else {
      return null;
    }
  }
  get radomColorCode(): string {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  //#endregion

  //#region State Variables
  @select(state => (state as IAppState).propertymanagement.leaseRenewalStatistics.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).propertymanagement.leaseRenewalStatistics.search.statistics) statistics$;
  //#endregion

  ngOnInit() {
    this.statistics$_Subcription =
      this.statistics$.subscribe((result: ILeaseRenewalStatisticsSearchResult[]) => {
        if (!!result) {
          this.renewalStatistics = result.filter(rs => rs.reportNumber == 1).map(rs => {
            return { Label: rs.label, Leases: rs.totalCount, DataLabelMappingName: `${rs.label}: ${rs.totalCount}`, Color: this.radomColorCode }
          });

          this.rentIncreases = result.filter(rs => rs.reportNumber == 2).map(rs => {
            return { Label: `${rs.label}`, RentIncreases: rs.totalCount, DataLabelMappingName: rs.label, Color: this.radomColorCode }
            //return { Label: `Old Rent: ${rs.oldRent} and New Rent: ${rs.newRent}`, RentIncresePercent: rs.rentIncresePercent, DataLabelMappingName: `Old Rent: ${rs.oldRent} and New Rent: ${rs.newRent} Increase(%): ${rs.rentIncresePercent}%` }
          });

          this.newLeasePeriods = result.filter(rs => rs.reportNumber == 3).map(rs => {
            return { Label: `${rs.label}`, NewLeasePeriods: rs.totalCount, DataLabelMappingName: rs.label, Color: this.radomColorCode }
          });

          console.log(this.renewalStatistics);
          console.log(this.rentIncreases);
          console.log(this.newLeasePeriods);
        }
      });
  }
  ngOnDestroy() {
    if (!!this.statistics$_Subcription) this.statistics$_Subcription.unsubscribe();
  }

  //#region Charts
  private enableAnimation: boolean = true;

  //Initializing Legend
  public legendSettings: Object = {
    visible: false,
  };

  public tooltipOne: Object = {
    enable: true,
    format: '<b>${point.x}</b><br>Total Count: <b>${point.y}</b>',
    header: '',
    textStyle: {
      fontFamily: this.fontFace
    }
  };
  public tooltipTwo: Object = {
    enable: true,
    format: '<b>${point.x}</b><br>Total Count: <b>${point.y}</b>',
    header: '',
    textStyle: {
      fontFamily: this.fontFace
    }
  };
  public tooltipThree: Object = {
    enable: true,
    format: '<b>${point.x}</b><br>Total Count: <b>${point.y}</b>',
    header: '',
    textStyle: {
      fontFamily: this.fontFace
    }
  };

  public reportOneTitle: string = 'Lease Renewal Statistics';
  public reportTwoTitle: string = 'Rent Increase Percentage';
  public reportThreeTitle: string = 'Lease Periods';
  public titleStyle = {
    fontFamily: this.fontFace,
    fontStyle: 'italic',
    fontWeight: 'bold',
    color: "#E27F2D",
    size: '23px'
  }

  //#region Column (Bar) Chart
  private primaryXAxis: Object = {
    valueType: 'Category',
    interval: 1,
    labelStyle: {
      fontFamily: this.fontFace,
      fontWeight: 'bold',
      size: '14px'
    }
  };
  private primaryYAxis: Object = {
    title: 'Total Count',
    titleStyle: {
      fontFamily: this.fontFace,
      size: '14px'
    },
    labelStyle: {
      fontFamily: this.fontFace,
      fontWeight: 'bold',
      size: '14px'
    }
  };
  private marker: Object = {
    visible: true, width: 10, height: 10,
    dataLabel: {
      visible: true,
      //position: 'Top',
      //template: '<div>${point.x}</div><div>${point.y}</div>',
      border: { width: 0, color: 'red' },
      rx: 10,
      ry: 10,
      margin: {
        left: 5,
        right: 5,
        top: 5,
        bottom: 5
      },
      font: {
        fontWeight: '600',
        color: '#000',
        fontFamily: this.fontFace
      }
    }
  };
  private border?: Object = {
    color: 'brown',
    width: 0
  };
  //#endregion

  //#region Pie Chart   
 
  //Initializing Datalabel
  public dataLabel: Object = {
    visible: true,
    position: 'Outside', name: 'DataLabelMappingName',
    font: {
      fontWeight: '600',
      size: '14px',
      fontFamily: [this.fontFace]
    },
    connectorStyle: { length: '20px', type: 'Curve' },

  };
  // custom code start
  public load(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.accumulation.theme = <AccumulationTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');
  };
  // custom code end
  private startAngle: number = Browser.isDevice ? 55 : 35;
  private radius: string = Browser.isDevice ? '40%' : '70%'
  private explode: boolean = true;  
  //#endregion


  
  
  //#endregion

  //#region Events
  onClose() {
    let action = new CloseLeaseRenewalStatisticsResultPage(true);
    this.ngRedux.dispatch({ type: action.type });
    if (!!this.database && !!this.database.length) {
      this._router.navigate([`/leaserenewalstatistics/${this.database}/${this.sessionID}/${this.departmentID}`]);
    }
    else {
      this._router.navigate(["/leaserenewalstatistics"]);
    }
  }
  //#endregion

}
