import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from "@angular/material";

@Component({
  selector: 'app-dialog-body',
  templateUrl: './dialog-body.component.html',
  styleUrls: ['./dialog-body.component.css']
})
export class DialogBodyComponent implements OnInit {
  
  private role: enDialogRole = enDialogRole.alertdialog;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogBodyComponent>) { }

  ngOnInit() {
    this.role = this.data.role;
  }
  close(result: boolean) {
    this.dialogRef.close(result);
  }

}
export enum enDialogRole {
  alertdialog = 1,
  dialog
}