import { Component, OnInit } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from './store/store';
import { LOGIN, LOGOUT, IS_LOGGED_IN } from './store/actions';
import { ILogonState } from './store/logon';
import { AuthService, enImageSize, SessionInfo } from "./services/authservice";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  @select(state => state.login.isLoggedIn) isLoggedIn;

  model: ILogonState = {
    connectionStringName: null,
    loginID: null,
    password: null,
    rememberMe: null,
    rememberValue: null,
    userName: null,
    requiredForgotPassword: null,
    isLoggedIn: false,
    contactAccessible: false,
    trisetSystemID: 0,
    categoryID: 0,
    departmentID: "",
    isCommercial: false,
    departments: []
  };

  constructor(private ngRedux: NgRedux<IAppState>, private _service: AuthService) { }
  ngOnInit() {
    this._service.GetSessionData().map((data: SessionInfo) => {
      return { type: IS_LOGGED_IN, isLoggedIn: data.isLoggedIn, contactAccessible: data.contactAccessible, isCommercial: data.isCommercial };
    }).subscribe((action) => {
      this.ngRedux.dispatch(action);
    });
    console.log("isLoggedIn: ", this.isLoggedIn);
  }

}
