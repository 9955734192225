import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Sort, SortDirection } from '@angular/material/sort';

import { IAppState } from "../../../store/store";
import { AuthService } from "../../../services/authservice";
import { PropertyManagementService } from "./../../propertymanagement.service";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';
import { CloseCommercialContactSearchResultPage } from "./../../propertymanagement.action"
import { ICommercialContactsSearchResults } from '../../propertymanagement';
import { GetContactSearch } from "./../../propertymanagement.action";
import { DatePipe } from '@angular/common';
import { emailValidation } from "./../../../utility/autounsubcribe";

import "../../../../../../wwwroot/js/scripts.js";
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'app-commercialcontactsresults',
  templateUrl: './commercialcontactsresults.component.html',
  styleUrls: ['./commercialcontactsresults.component.css']
})
export class CommercialContactsResultsComponent implements OnInit ,AfterViewInit, OnDestroy {



  private dialogRef: any;
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  private contactSearchResult: ICommercialContactsSearchResults[] = [];
  private GetContactResult_Subscription: Subscription;

  //#region  Communication Log Variable
  private actionCommunication: "tel" | "sms" | "mailto";
  private showCommunication: boolean;
  private contactForCommunication: string;
  private contactID: string;
  private contactName: string;
  private mobile: string;
  private email: string;
  private targetTypeID: number;
  private staffID: string;
  private mailMergeName: string;

  private getSessionData_Subscription: Subscription;

  //#endregion

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    private _service: PropertyManagementService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private _datePipe: DatePipe
  ) { }

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }


  @select(state => (state as IAppState).propertymanagement.commercialcontacts.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).propertymanagement.commercialcontacts.search.contactSearchResults) contactSearchResults$;

  ngOnInit() {
    this.GetContactResult_Subscription =
    this.contactSearchResults$
      .subscribe(data => {
          if(!!data && data.length > 0){
            this.contactSearchResult = data;
            this.loadPageData();
          }
      });

      this.getSessionData_Subscription = this._authService.GetSessionData()
      .subscribe(session => {
        this.staffID = session.staffID;
        this.mailMergeName = session.mailMergeName;
      });

  }

  ngAfterViewInit() {
    LoadScript.init();
    LoadScript.gotoTop();
    var clearIntv = null;
    clearIntv = setInterval(() => {
      if (!!$("#no-more-tables > table").length) {
        clearInterval(clearIntv);
        //FixedTableHeaderScript.fixMe($("table"));
        $(".up").click(function () {
          $("html, body").animate(
            {
              scrollTop: 0
            },
            2000
          );
        });
      }
    }, 2000);
  }

  ngOnDestroy() {
    if (!!this.GetContactResult_Subscription) this.GetContactResult_Subscription.unsubscribe();
  }

  onClose() {
    let action = new CloseCommercialContactSearchResultPage(true);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    this._router.navigate(["/commercialcontacts"])
  }

  //region FOR Mobile VIEW SORT
  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }

  //end region


  sortData(sort: Sort) {
    this._loader.show();
    setTimeout(() => { 
      this.sortAllData(sort);
    }, 10)
  }
  private sortAllData(sort: Sort) {    
    const isAsc = sort.direction === 'asc';

    for (var index = 0; index < this.contactSearchResult.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.contactSearchResult.length; innerIndex++) {
        switch (sort.active) {
          case "Contact Name": {
            if (isAsc) {
              if (this.contactSearchResult[index].name > this.contactSearchResult[innerIndex].name) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            else {
              if (this.contactSearchResult[index].name < this.contactSearchResult[innerIndex].name) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            break;
          }
          case "Mobile": {
            if (isAsc) {
              if (this.contactSearchResult[index].mobilePhone > this.contactSearchResult[innerIndex].mobilePhone) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            else {
              if (this.contactSearchResult[index].mobilePhone < this.contactSearchResult[innerIndex].mobilePhone) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            break;
          }
          case "Email": {
            if (isAsc) {
              if (this.contactSearchResult[index].email > this.contactSearchResult[innerIndex].email) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            else {
              if (this.contactSearchResult[index].email < this.contactSearchResult[innerIndex].email) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            break;
          }
          case "Work Ph": {
            if (isAsc) {
              if (this.contactSearchResult[index].workPhone > this.contactSearchResult[innerIndex].workPhone) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            else {
              if (this.contactSearchResult[index].workPhone < this.contactSearchResult[innerIndex].workPhone) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            break;
          }
          case "Company": {
            if (isAsc) {
              if (this.contactSearchResult[index].name > this.contactSearchResult[innerIndex].name) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            else {
              if (this.contactSearchResult[index].name < this.contactSearchResult[innerIndex].name) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            break;
          }
          case "Surname": {
            if (isAsc) {
              if (this.contactSearchResult[index].name > this.contactSearchResult[innerIndex].name) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            else {
              if (this.contactSearchResult[index].name < this.contactSearchResult[innerIndex].name) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            break;
          }
          case "Given Name": {
            if (isAsc) {
              if (this.contactSearchResult[index].name > this.contactSearchResult[innerIndex].name) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            else {
              if (this.contactSearchResult[index].name < this.contactSearchResult[innerIndex].name) {
                let temp = this.contactSearchResult[index];
                this.contactSearchResult[index] = this.contactSearchResult[innerIndex];
                this.contactSearchResult[innerIndex] = temp;
              }
            }
            break;
          }
          }
        }
      }  
    this.loadPageData();
    let action = new GetContactSearch(this.contactSearchResult);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    //this.showSortingLoader = false;
    this._loader.hide();

  }

  sanitizeSMS(mobileNumber: string) {
    return this.sanitizer.bypassSecurityTrustUrl("sms:" + mobileNumber);
  }

  //#region Pagination  
  private hasPagination: boolean = true;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: ICommercialContactsSearchResults[];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.pageData = this.contactSearchResult;
    if (this.hasPagination) {
      this.pageData = this.contactSearchResult.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }    
  }
  //#endegion


  //#region Communication
  onCommunicationClick(action: "tel" | "sms" | "mailto", row: ICommercialContactsSearchResults,mobileNo: string) {
    this.actionCommunication = action;
    if (action == "tel" || action == "sms") {
      if(!!mobileNo) {
        this.contactForCommunication = mobileNo;
      }
      this.contactID = row.id;
      this.contactName = row.name;
      //this.mobile = row.ownerMobilePhone;
      this.mobile = this.contactForCommunication;
      this.email = row.email;
      this.showCommunication = !this.showCommunication;
    }
    else if (action == "mailto") {
      this.contactForCommunication = row.email;
      this.contactID = row.id;
      this.contactName = row.name;
      this.mobile = row.mobilePhone;
      this.email = row.email;
      this.showCommunication = !this.showCommunication;
    }    
  }

  onSave(event: any) {
    if (!!event && !event.isError) {
      this.showCommunication = false;
    }
  }

  onCommunicationExpand(ct: ICommercialContactsSearchResults) {    
    var timezoneOffset =  new Date().getTimezoneOffset();
    if (!ct.showCommunication) {
      ct.showCommunication = false;
      ct.showCommunicationLoader = true;
      ct.communications = [];
      this._service.CommunicationDetails(ct.id, 5,timezoneOffset)
        .subscribe(data => {
          ct.communications = data;
          ct.showCommunication = true;
          ct.showCommunicationLoader = false;
        }),
        (error: string) => {
          this.openDialog("Alert", error, "alertdialog");
        };
    }
    else {
      ct.showCommunication = !ct.showCommunication;
    }
  }
  //#endregion 

}
