import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Form, FormControl, Validators, FormGroup } from '@angular/forms';
import { NgRedux, select } from '@angular-redux/store';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";

import { IAppState } from "../../store/store";
import { PropertyManagementService } from "./../propertymanagement.service";
import { LoaderService } from "./../../core/loader/loader.service";
import { IDNamePair, AuthService, SessionInfo } from "./../../services/authservice";
import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";
import { ITenantContactSearchResult } from './../propertymanagement';
import { GetTanentContacts, GetPropertyManagerTContactSearchPage } from "./../propertymanagement.action";


import "../../../../../wwwroot/js/scripts.js";
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'tenantcontacts',
  templateUrl: './tenantcontacts.component.html',
  styleUrls: ['./tenantcontacts.component.css']
})
export class TenantContactsComponent implements OnInit, AfterViewInit, OnDestroy {

  private model: FormGroup;
  private dropdownSettings: any = {};
  private dialogRef: any;
  private statusItems: IDNamePair<number>[];
  private selectedStatusIDs: IDNamePair<number>[];

  private subscription_GetPropertyManagers: Subscription;
  private subscription_GetTenantContacts: Subscription;  
  private getSessionData_Subcription: Subscription;


  private propertyManagerIDs: IDNamePair<string>[];
  constructor(private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: PropertyManagementService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _authService: AuthService) {
    this.statusItems = [{ id: 1, name: "Current" }, { id: 2, name: "Old" }];
    this.selectedStatusIDs = this.statusItems.filter(st => st.id == 1)
    this.model = new FormGroup({
      searchText: new FormControl(this.ngRedux.getState().propertymanagement.tenantcontact.search.searchText),
      searchFields: new FormControl(this.ngRedux.getState().propertymanagement.tenantcontact.search.searchFields),
      statusIDs: new FormControl(this.ngRedux.getState().propertymanagement.tenantcontact.search.statusIDs, Validators.required),
      hasOverdueInvoices: new FormControl(this.ngRedux.getState().propertymanagement.tenantcontact.search.hasOverdueInvoices),
      daysOverdue: new FormControl(this.ngRedux.getState().propertymanagement.tenantcontact.search.daysOverdue),
      includeJointTenants: new FormControl(this.ngRedux.getState().propertymanagement.tenantcontact.search.includeJointTenants),
      excludePeriodLeases: new FormControl(this.ngRedux.getState().propertymanagement.tenantcontact.search.excludePeriodLeases),
      selectedPropertyManagerIDs: new FormControl(this.ngRedux.getState().propertymanagement.tenantcontact.search.selectedPropertyManagerIDs, Validators.required)
      //sortBy: new FormControl(this.ngRedux.getState().propertymanagement.tenantcontact.search.sortBy)
    })
  }

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  @select(state => (state as IAppState).propertymanagement.tenantcontact.search.propertyManagerIDs) propertyManagerIDs$;
  @select(state => (state as IAppState).propertymanagement.tenantcontact.search.includeJointTenants) includeJointTenants$;
  @select(state => (state as IAppState).propertymanagement.tenantcontact.search.daysOverdue) daysOverdue$;
  @select(state => (state as IAppState).propertymanagement.tenantcontact.search.hasOverdueInvoices) hasOverdueInvoices$;
  @select(state => (state as IAppState).propertymanagement.tenantcontact.search.excludePeriodLeases) excludePeriodLeases$;
  @select(state => (state as IAppState).propertymanagement.tenantcontact.search.showSearchResult) showSearchResult$;

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this._loader.show();

    this.getSessionData_Subcription = this._authService.GetSessionData()
      .subscribe((session: SessionInfo) => {
        this.subscription_GetPropertyManagers =
          this._service.GetPropertyManagers()
            .map((data: IDNamePair<string>[]) => {
              return new GetPropertyManagerTContactSearchPage(data);
            })
            .subscribe(action => {
              this.propertyManagerIDs = (!!action.payload.filter(staff => staff.id == session.staffID).length ? action.payload.filter(staff => staff.id == session.staffID) : null) || action.payload;
              this._loader.hide();
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            }),
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          }
      });
  }

  onSubmit() {
    if (this.model.status == "VALID") {
      var statusIDs: number = 0;
      if (!!this.model.controls.statusIDs.value) {
        let stIDs: IDNamePair<number>[] = this.model.controls.statusIDs.value;
        stIDs.forEach(type => {
          statusIDs = statusIDs | type.id;
        });
        var propertyManagerIDs: string[];
        if (!!this.model.controls.selectedPropertyManagerIDs.value) {
          let propManagerIds = this.model.controls.selectedPropertyManagerIDs.value;
          /*if (this.ngRedux.getState().propertymanagement.tenantcontact.search.propertyManagerIDs
            && this.ngRedux.getState().propertymanagement.tenantcontact.search.propertyManagerIDs.length == propManagerIds.length) {
            propertyManagerIDs = [];
          }
          else {
            propertyManagerIDs = propManagerIds.map(item => { return item.id });
          }*/
          propertyManagerIDs = propManagerIds.map(item => { return item.id });
        }
        if (!(!!propertyManagerIDs && !!propertyManagerIDs.length))
        {
          this.openDialog("Alert", "Please select property manager", "alertdialog");
          return;
        }

        this._loader.show();

        this.subscription_GetTenantContacts =
          this._service.GetTenantContacts(
            this.model.controls.searchText.value,
            this.model.controls.searchFields.value,
            statusIDs,
            propertyManagerIDs,
            this.model.controls.hasOverdueInvoices.value,
            this.model.controls.daysOverdue.value,
            this.model.controls.includeJointTenants.value,
            this.model.controls.excludePeriodLeases.value,
            null,
            null,
            null,
            1//parseInt(this.model.controls.sortBy.value)
          ).map((data: ITenantContactSearchResult[]) => {
            return new GetTanentContacts(data);
          }).subscribe(action => {
            this._loader.hide();
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            this._router.navigate(["/tenantcontacts/search"]);
          },
            error => {
              this._loader.hide();
              this.openDialog("Alert", error, "alertdialog");
            })
      }
    }
  }

  ngAfterViewInit() {
    LoadScript.init();
  }

  ngOnDestroy() {
    if (!!this.subscription_GetPropertyManagers) this.subscription_GetPropertyManagers.unsubscribe();
    if (!!this.getSessionData_Subcription) this.getSessionData_Subcription.unsubscribe();
    if (!!this.subscription_GetTenantContacts) this.subscription_GetTenantContacts.unsubscribe();

  }

  onPropertyManagerSelect(event: any) {
  }

  onStatusSelect(event: any) {
    console.log('event: ', event);
  }
}
