import { IDNameExtraPair, IDNamePair } from "../services/authservice";

export interface IMaintenanceSearch {
  lookFor: number;
  lookForFilter: string;
  selectedStatus: number;
  selectedPeriod: number;
  maintenances: IMaintenanceSearchResult[];
  lookForList: IDNamePair<string>[];
  showSearchResult: boolean;
}
export interface IMaintenanceSearchResult {
  id: string;
  agencyID: string;
  dateTime: Date;
  propertyID: string;
  addressID: string;
  propertyAddress: string;
  notes: string;
  statusID: number;
  callerContactID: string;
  callerContactName: string;
  callerContactNumber: string;
  propertyManagerStaffID: string;
  propertyManagerStaffName: string;
  propertyManagerContactNumber: string;
  supplierServiceTypeID: number;
  supplierServiceTypeName: number;
  showEdit: boolean;
}
export interface IMaintenanceSelect {
  id: string;
  agencyID: string;
  departmentID: string;
  itemID: string;
  propertyID: string;
  loggedByStaffID: string;
  propertyManagerStaffID: string;
  callNumber: number;
  statusID: number;
  dateTime: Date;
  callerContactID: string;
  recipientContactID: string;
  callTypeID: number;
  callerTypeID: number;
  callCategoryID: number;
  text: string;
  supplierServiceTypeID: number;
  addressID: string;
  propertyAddress: string;
  managementAgreementID: string;
  rentalTenantID: string;
  callerContactName: string;
  callerContactNumber: string;
  propertyManagerNameID: string;
  propertyManagerName: string;
  ownerContactID: string;
  ownerContactName: string;
  ownerContactNumber: string;
  imageID1: string;
  imageName1: string;
  imageID1Extension: string;
  imageID1Url: string;
  imageID2: string;
  imageName2: string;
  imageID2Extension: string;
  imageID2Url: string;
  imageID3: string;
  imageName3: string;
  imageID3Extension: string;
  imageID3Url: string;
  workOrderID: string;
  workOrderNumber: number;
}
export interface IJointTenant {
  id: string;
  name: string;
  tenantNumber: string;
  isPrimary: boolean;
  propertyManagerNameID: string;
  propertyManagerName: string;
  ownerContactID: string;
  ownerContactName: string;
  ownerContactNumber: string;  
}
export interface IRentalTenantPropertyAddress {
  propertyID: string;
  propertyAddress: string;
  managementAgreementID: string;
  rentalTenantID: string;
}
export interface INewMaintenance {
  propertyAddresses: IRentalTenantPropertyAddress[];
  tenants: IJointTenant[];
  supplierServiceTypes: IDNameExtraPair<number, number | undefined | null>[];
}
export interface IMaintenanceState {
  search: IMaintenanceSearch,
  newMaintenance: INewMaintenance
}

export const INITIAL_MAINTENANCE_STATE = (): IMaintenanceState => ({
  search: {
    lookFor: 1,
    lookForFilter: null,
    selectedStatus: 1,
    selectedPeriod: 1,
    maintenances: [],
    lookForList: [],
    showSearchResult: false
  },
  newMaintenance: {
    propertyAddresses: [],
    tenants: [],
    supplierServiceTypes: []
  }
});
