import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { IAppState } from "../../../store/store";
import { enImageSize, IDNamePair } from "./../../../services/authservice";
import { EmarketingService } from "./../../emarketing.service";
import { LoaderService } from "./../../../core/loader/loader.service";
import { GetTemplateDepartmentsAndStaffs, CloseNewTemplatePage } from "./../../emarketing.action";
import { IRecipientType, IEmailTemplate } from "./../../emarketing";
import { DialogBodyComponent, enDialogRole } from "./../../../dialog-body/dialog-body.component";
import { INITIAL_EMARKETING_STATE } from "./../../emarketing.state";
import "../../../../../../wwwroot/js/scripts.js";
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: "newtemplate",
  templateUrl: "./newtemplate.component.html",
  styleUrls: ["./newtemplate.component.css"]
})
export class NewTemplateComponent implements OnInit, AfterViewInit {
  private model: FormGroup;
  private selectedRecipientType: IRecipientType = {} as IRecipientType;
  private dialogRef: any;

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: EmarketingService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.model = new FormGroup({
      selectedRecipientType: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.selectedRecipientType, Validators.required),
      //selectedReason: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.selectedReason),
      selectedAgency: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.selectedAgency),
      templateName: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.template.name),
      selectedDepartment: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.selectedDepartment),
      selectedStaff: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.selectedStaff),
      availableFor: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.availableFor)
    });
  }

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  @select(state => (state as IAppState).emarketing.template.addEditView.recipientTypes) recipientTypes$;
  @select(state => (state as IAppState).emarketing.template.addEditView.agencies) agencies$;
  @select(state => (state as IAppState).emarketing.template.addEditView) addEditView$;
  @select(state => (state as IAppState).emarketing.template.addEditView.departments) departments$;
  @select(state => (state as IAppState).emarketing.template.addEditView.staffs) staffs$;

  ngOnInit() {
    // this._loader.show();
    // this._service
    //   .GetRecipientTypes()
    //   .map((data: IRecipientType[]) => {
    //     return new LoadNewTemplatePage(data);
    //   })
    //   .subscribe(action => {
    //     this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    //     this._loader.hide();
    //   });
    this.agencies$.subscribe(agencies => {
      if (!!agencies && !!agencies.length) {
        this.model.controls.selectedAgency.setValue(agencies[0].id);
        this.onAgencyChange(null);

        this.model.controls.selectedAgency.enable();
        this.model.controls.selectedDepartment.disable();
        this.model.controls.selectedStaff.disable();
      }
    });
  }
  ngAfterViewInit() {
    LoadScript.init();
  }
  onRecipientTypeChange(event: any) {
    var id = this.model.controls.selectedRecipientType.value;
    this.recipientTypes$.subscribe(rt => {
      var rTypes = (rt as IRecipientType[]).filter(r => r.id == id);
      if (!!rTypes && !!rTypes.length) {
        this.selectedRecipientType = rTypes[0];
      }
    });
  }
  onAgencyChange(event: any) {
    let id = this.model.controls.selectedAgency.value;
    this._service.GetDepartments(id).subscribe(dep => {
      this._service
        .GetStaffs(id)
        .map(stf => {
          return new GetTemplateDepartmentsAndStaffs({
            departments: dep,
            staffs: stf
          });
        })
        .subscribe(action => {
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
        });
    });
  }
  onOwnerTypeChange(type: any) {
    switch (this.model.controls.availableFor.value) {
      case "Agency":
        this.model.controls.availableFor.setValue("Agency");
        this.model.controls.selectedAgency.enable();
        this.model.controls.selectedDepartment.disable();
        this.model.controls.selectedStaff.disable();
        break;
      case "Department":
        this.model.controls.availableFor.setValue("Department");
        this.model.controls.selectedAgency.disable();
        this.model.controls.selectedDepartment.enable();
        this.model.controls.selectedStaff.disable();
        break;
      case "Staff":
        this.model.controls.availableFor.setValue("Staff");
        this.model.controls.selectedAgency.disable();
        this.model.controls.selectedDepartment.disable();
        this.model.controls.selectedStaff.enable();
        break;
    }
  }
  onSubmit() {
    if (!(!!this.model.controls.selectedRecipientType && !!this.model.controls.selectedRecipientType.value)) {
      this.openDialog("Alert", "Please select Recipient Type!!", "alertdialog");
      return false;
    }
    /*if (!(!!this.model.controls.selectedReason && !!this.model.controls.selectedReason.value)) {
      this.openDialog("Alert", "Please select Reason!!", "alertdialog");
      return false;
    }*/
    if (!(!!this.model.controls.templateName && !!this.model.controls.templateName.value)) {
      this.openDialog("Alert", "Please enter template name!!", "alertdialog");
      return false;
    }
    let availableForID: string = null;
    switch ((this.model.controls.availableFor.value + "").toLowerCase()) {
      case "agency":
        availableForID = this.model.controls.selectedAgency.value;
        break;
      case "department":
        availableForID = this.model.controls.selectedDepartment.value;
        break;
      case "staff":
        availableForID = this.model.controls.selectedStaff.value;
        break;
    }
    if (this.model.status == "VALID") {
      this._loader.show();
      this._service
        .CreateTemplate(
          this.model.controls.templateName.value,
          //this.model.controls.selectedReason.value,
          this.model.controls.availableFor.value,
          availableForID
        )
        .subscribe(templateID => {
          if (!!templateID && templateID.indexOf("00000000") < 0) {
            this.snackBar.open("Template created successfully", "close", this.config);
            this.model.reset();
            /*this._service
            .GetEmailAgencies()
            .subscribe((agencies: IDNamePair<string>[]) => {
              this._service
              .GetRecipientTypes()
              .map((data: IRecipientType[]) => {
                return new LoadNewTemplatePage({recipientTypes: data, agencies: agencies});
              })
              .subscribe(action => {
                this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                this._loader.hide();
                this._router.navigate(["/templates/new"]);
              });

            });*/

          }
          this._loader.hide();
        });
    }
  }
  onClose() {
    let action = new CloseNewTemplatePage(true);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    this._router.navigate(["/templates"]);
  }
}
