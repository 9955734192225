import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { Sort, SortDirection } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { IAppState } from "../../../store/store";
import { AuthService, ServiceResponse } from "../../../services/authservice";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DataTransferService } from '../../../services/datatransferservice';
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';
import { PropertyManagementService } from "./../../propertymanagement.service";
import { ILeaseRenewalSearchResult } from '../../propertymanagement';
import { CloseLeaseRenewalResultPage, GetLeaseRenewalSearch } from '../../propertymanagement.action';
import { ConfirmDialogComponent } from '../../../components/confirmdialog/confirmdialog.component';

@Component({
  selector: 'app-leaserenewalresult',
  templateUrl: './leaserenewalresult.component.html',
  styleUrls: ['./leaserenewalresult.component.scss']
})
export class LeaseRenewalResultComponent implements OnInit, OnDestroy {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private _datePipe: DatePipe,
    private _authService: AuthService,
    private _service: PropertyManagementService,
    private dataTransfer: DataTransferService
  ) { }

  @select(state => (state as IAppState).propertymanagement.leaseRenewal.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).propertymanagement.leaseRenewal.search.leaseRenewals) leaseRenewals$;


  //#region Dialog
  private dialogRef: any;
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  //#endregion

  //#region Private Variables
  private readonly leaseRenewalRoolUrl: string = "https://leaserenewal.com.au"/*https://leaserenewal-testing.multiarray.com*/;

  private showLeaseExpiry: boolean = false;
  private showChangeRentDialog: boolean = false;
  private leaseExpiryUrl: any;
  private connectionStringName: string;
  private agencyID: string;
  private ragent2SessionID: string;
  private trisetSystemID: number;
  private categoryID: number;
  private sharedFilter: {
    autoRefreshIntervalHandler: any,
    autoRefreshIntervalChildHandler: any,
    autoRefreshInMinute: string,
    prManagerIDs: string[],
    leaseEndDateFrom: Date | null,
    leaseEndDateTo: Date | null,
    processCompleted: boolean,
    bypass: boolean,
    lastAutoRefreshTime: Date,
    propertyID: string | null,
    convertPeriodicLeaseToNormalLease: boolean
  }
  private selectedLeaseRenewal: ILeaseRenewalSearchResult = null;
  private newLeaseProposedNewRent: number | null = null;
  //#endregion

  //#region Subcriptions
  private getSessionData_Subcription: Subscription;
  private getDepartment_Subcription: Subscription;
  private getAgencyBySessionID_Subcription: Subscription;
  private getLeaseRenewalLogs_Subscription: Subscription;
  private bypassLeaseRenewal_Subscription: Subscription;
  private getLeaseRenewals_Subcription: Subscription;
  private sharedData_Subcription: Subscription;
  private setLeaseRenewalSequence_Subcription: Subscription;
  private restartNegotiation_Subcription: Subscription;
  private changeRent_Subcription: Subscription;
  //#endregion

  //#region Pagination
  private leaseRenewals: ILeaseRenewalSearchResult[] = [];
  private hasPagination: boolean = true;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: ILeaseRenewalSearchResult[];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.leaseRenewals = this.ngRedux.getState().propertymanagement.leaseRenewal.search.leaseRenewals;
    this.pageData = this.leaseRenewals;
    if (this.hasPagination) {
      this.pageData = this.leaseRenewals.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }
  }
  //#endegion

  ngOnInit() {
    if (!!this.sessionID && !!this.departmentID) {
      this.getAgencyBySessionID_Subcription = this._authService.GetAgencyBySessionID(this.database, this.sessionID)
        .subscribe(ag => {
          if (!!ag && !ag.isError && !!ag.response) {
            this.agencyID = ag.response.id;
          }
        });
      this.getDepartment_Subcription = this._authService.GetDepartment(this.database, this.sessionID, this.departmentID)
        .subscribe(dept => {
          if (!!dept && !dept.isError) {
            this.trisetSystemID = dept.response.trisetSystemID;
            this.categoryID = dept.response.categoryID;
          }
        });
    }
    else {
      this.getSessionData_Subcription = this._authService.GetSessionData()
        .subscribe(session => {
          this.connectionStringName = session.connectionStringName;
          this.ragent2SessionID = session.id;
          this.trisetSystemID = session.trisetSystemID;
          this.categoryID = session.categoryID;
          this.agencyID = session.agencyID;
        });
    }
    this.leaseRenewals = this.ngRedux.getState().propertymanagement.leaseRenewal.search.leaseRenewals;
    this.loadPageData();

    this.sharedData_Subcription =
      this.dataTransfer.data$.subscribe(sd => {
        if (!!sd) {
          this.sharedFilter = sd;
        }
      });
  }
  ngOnDestroy() {
    if (!!this.getSessionData_Subcription) this.getSessionData_Subcription.unsubscribe();
    if (!!this.getAgencyBySessionID_Subcription) this.getAgencyBySessionID_Subcription.unsubscribe();
    if (!!this.getDepartment_Subcription) this.getDepartment_Subcription.unsubscribe();
    if (!!this.getLeaseRenewalLogs_Subscription) this.getLeaseRenewalLogs_Subscription.unsubscribe();
    if (!!this.bypassLeaseRenewal_Subscription) this.bypassLeaseRenewal_Subscription.unsubscribe();
    if (!!this.setLeaseRenewalSequence_Subcription) this.setLeaseRenewalSequence_Subcription.unsubscribe();
    if (!!this.restartNegotiation_Subcription) this.restartNegotiation_Subcription.unsubscribe();
    if (!!this.changeRent_Subcription) this.changeRent_Subcription.unsubscribe();
  }

  //#region Properties
  get calledByREM(): boolean {
    return (this.route.snapshot.parent.routeConfig.path.indexOf("leaserenewal") >= 0 && !!this.route.snapshot.parent.paramMap.get("sessionID") && this.route.snapshot.parent.paramMap.get("sessionID").indexOf('00000000-') < 0);
  }
  get database(): string {
    if (this.route.snapshot.parent.routeConfig.path.indexOf("leaserenewal") >= 0 && !!this.route.snapshot.parent.paramMap.get("database")) {
      return this.route.snapshot.parent.paramMap.get("database");
    }
    else {
      return null;
    }
  }
  get sessionID(): string {
    if (this.route.snapshot.parent.routeConfig.path.indexOf("leaserenewal") >= 0 && !!this.route.snapshot.parent.paramMap.get("sessionID") && this.route.snapshot.parent.paramMap.get("sessionID").indexOf('00000000-') < 0) {
      return this.route.snapshot.parent.paramMap.get("sessionID");
    }
    else {
      return null;
    }
  }
  get departmentID(): string {
    if (this.route.snapshot.parent.routeConfig.path.indexOf("leaserenewal") >= 0 && !!this.route.snapshot.parent.paramMap.get("departmentID") && this.route.snapshot.parent.paramMap.get("departmentID").indexOf('00000000-') < 0) {
      return this.route.snapshot.parent.paramMap.get("departmentID");
    }
    else {
      return null;
    }
  }
  get externalApplicationSessionID(): string {
    return !!this.sessionID ? this.sessionID : this.ragent2SessionID;
  }
  get isPeriodic(): boolean {
    return (!!this.sharedFilter && this.sharedFilter.convertPeriodicLeaseToNormalLease);
  }
  //#endregion

  //#region Functions
  waterChargesPM(waterUsageCondition: number | null): string {
    if (waterUsageCondition == 1) {
      return 'Usage and Supply';
    }
    else if (waterUsageCondition == 2) {
      return 'Supply Only';
    }
    else if (waterUsageCondition == 3) {
      return 'Usage Only';
    }
    else if (waterUsageCondition == 4) {
      return 'No Charge';
    }
    else {
      return '';
    }
  }
  proposedRentPeriod(proposedNewRentPeriod: number): string {
    if (proposedNewRentPeriod == 7)
      return "Weekly";
    else if (proposedNewRentPeriod == 14)
      return "Fortnightly";
    else if (proposedNewRentPeriod == 30)
      return "Monthly";
  }
  getSanitizedUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getHyperlink(pd: ILeaseRenewalSearchResult): SafeResourceUrl {
    let link: string = "";
    if (!!pd.ragent2SequenceHyperlinkID) {
      switch (pd.ragent2SequenceHyperlinkID) {
        case 0:
          link = `${this.leaseRenewalRoolUrl}/residential/staff/0/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 1:
          link = `${this.leaseRenewalRoolUrl}/residential/owner/1/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 2:
          link = `${this.leaseRenewalRoolUrl}/residential/staff/2/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 3:
          link = `${this.leaseRenewalRoolUrl}/residential/owner/3/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 4:
          link = `${this.leaseRenewalRoolUrl}/residential/tenant/4/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 5:
          link = `${this.leaseRenewalRoolUrl}/residential/staff/5/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 6:
          if (pd.leaseRenewalSequenceID == 10) {
            link = `${this.leaseRenewalRoolUrl}/residential/tenant/6/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          }
          else if (pd.leaseRenewalSequenceID == 11) {
            link = `${this.leaseRenewalRoolUrl}/residential/owner/6/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          }
          else if (pd.leaseRenewalSequenceID == 20) {
            link = `${this.leaseRenewalRoolUrl}/residential/tenant/6/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          }
          else if (pd.leaseRenewalSequenceID == 21) {
            link = `${this.leaseRenewalRoolUrl}/residential/owner/6/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          }
          break;
        case 7:
          link = `${this.leaseRenewalRoolUrl}/residential/staff/7/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 8:
          if (pd.leaseRenewalSequenceID == 18) {
            link = `${this.leaseRenewalRoolUrl}/residential/tenant/8/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          }
          else if (pd.leaseRenewalSequenceID == 19) {
            link = `${this.leaseRenewalRoolUrl}/residential/owner/8/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          }
          break;
        case 9:
          if (pd.leaseRenewalSequenceID == 17) {
          }
          else {
            link = `${this.leaseRenewalRoolUrl}/residential/staff/9/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          }
          break;
        case 101:
          link = `${this.leaseRenewalRoolUrl}/commercial/staff/101/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 102:
          //link = `${this.leaseRenewalRoolUrl}/commercial/tenant/102/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${pd.jointTenantContactID}/${this.externalApplicationSessionID}`;
          link = `${this.leaseRenewalRoolUrl}/commercial/staff/103/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 103:
          link = `${this.leaseRenewalRoolUrl}/commercial/staff/103/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 106:
          link = `${this.leaseRenewalRoolUrl}/commercial/staff/106/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 109:
          if (pd.leaseRenewalSequenceID == 114) {
            link = `${this.leaseRenewalRoolUrl}/commercial/staff/109/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          }
          else if (pd.leaseRenewalSequenceID == 117) {
            link = `${this.leaseRenewalRoolUrl}/commercial/owner/109/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          }
          break;
        case 111:
          link = `${this.leaseRenewalRoolUrl}/commercial/staff/111/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 113:
          link = `${this.leaseRenewalRoolUrl}/commercial/staff/113/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 115:
          if (!pd.optionPeriod && (!pd.leaseRenewalSequenceID || pd.leaseRenewalSequenceID == 125)) {
            link = `${this.leaseRenewalRoolUrl}/commercial/staff/113/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          }
          break;
        case 116:
          link = `${this.leaseRenewalRoolUrl}/commercial/staff/116/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 117:
          link = `${this.leaseRenewalRoolUrl}/commercial/staff/117/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 120:
          link = `${this.leaseRenewalRoolUrl}/commercial/staff/120/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 122:
          link = `${this.leaseRenewalRoolUrl}/commercial/staff/122/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
        case 901:
          link = `${this.leaseRenewalRoolUrl}/commercial/staff/901/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`;
          break;
      }
    }
    return !!link ? this.sanitizer.bypassSecurityTrustResourceUrl(link) : link;
  }
  shareData(data) {
    this.sharedFilter = data;
    this.dataTransfer.sendAnything(data) // to send something
  }
  autoRefreshIntervalChildHandler: any;
  startAutoRefresh(filter: { autoRefreshIntervalHandler: any, autoRefreshIntervalChildHandler: any, autoRefreshInMinute: string, prManagerIDs: string[], leaseEndDateFrom: Date | null, leaseEndDateTo: Date | null, processCompleted: boolean, bypass: boolean, lastAutoRefreshTime: Date, propertyID: string | null, convertPeriodicLeaseToNormalLease: boolean }) {
    if (!!filter) {
      this.search(filter);
      clearInterval(this.autoRefreshIntervalChildHandler);

      if (!!filter.autoRefreshInMinute) {
        this.autoRefreshIntervalChildHandler = setInterval(() => {
          //filter.autoRefreshIntervalChildHandler = this.autoRefreshIntervalChildHandler;
          this.search(filter);
        }, 1000 * 60 * parseInt(!!filter.autoRefreshInMinute ? filter.autoRefreshInMinute : "1"));

      }

      setTimeout(() => {
        filter.autoRefreshIntervalChildHandler = this.autoRefreshIntervalChildHandler;
        this.shareData(filter);
      }, 5000);
    }

  }
  search(filter: { autoRefreshIntervalHandler: any, autoRefreshIntervalChildHandler: any, autoRefreshInMinute: string, prManagerIDs: string[], leaseEndDateFrom: Date | null, leaseEndDateTo: Date | null, processCompleted: boolean, bypass: boolean, lastAutoRefreshTime: Date, propertyID: string | null, convertPeriodicLeaseToNormalLease: boolean }) {

    this._loader.show();
    if (!!this.sessionID && !!this.departmentID) {
      this.getLeaseRenewals_Subcription =
        this._service
          .GetLeaseRenewals
          (
            this.database,
            this.sessionID,
            this.departmentID,
            filter.leaseEndDateFrom,
            filter.leaseEndDateTo,
            filter.processCompleted,
            filter.bypass,
            filter.prManagerIDs,
            filter.propertyID,
            filter.convertPeriodicLeaseToNormalLease
          )
          .map((data: ServiceResponse<ILeaseRenewalSearchResult[]>) => {
            if (!data.isError) {
              return new GetLeaseRenewalSearch(data.response);
            }
            else {
              Observable.throwError(data.message);
              this.openDialog("Error", data.message, "alertdialog");
            }
          })
          .subscribe(action => {
            if (!!action && !!action.payload && !!action.payload.length) {
              filter.lastAutoRefreshTime = new Date();
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            }
            this._loader.hide();
          }),
        error => {
          this._loader.hide();
          this.openDialog("Alert", error, "alertdialog");
        }
    }
    else {
      this.getSessionData_Subcription = this._authService.GetSessionData()
        .subscribe(session => {

          this.getLeaseRenewals_Subcription =
            this._service
              .GetLeaseRenewals
              (
                this.database,
                session.id,
                session.departmentID,
                filter.leaseEndDateFrom,
                filter.leaseEndDateTo,
                filter.processCompleted,
                filter.bypass,
                filter.prManagerIDs,
                filter.propertyID,
                filter.convertPeriodicLeaseToNormalLease
              )
              .map((data: ServiceResponse<ILeaseRenewalSearchResult[]>) => {
                if (!data.isError) {
                  return new GetLeaseRenewalSearch(data.response);
                }
                else {
                  Observable.throwError(data.message);
                  this.openDialog("Error", data.message, "alertdialog");
                }
              })
              .subscribe(action => {
                if (!!action && !!action.payload && !!action.payload.length) {
                  filter.lastAutoRefreshTime = new Date();
                  this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }
                this._loader.hide();
              }),
            error => {
              this._loader.hide();
              this.openDialog("Alert", error, "alertdialog");
            }

        });
    }
  }
  getDaysBeforeEndDate(pd: ILeaseRenewalSearchResult): number {
    let date2: any = new Date();
    let date1: any = new Date(pd.leaseEndDate);
    var diffTime = Math.abs(date2 - date1);
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    //console.log(diffTime + " milliseconds");
    //console.log(diffDays + " days");

    return diffDays;
  }
  //#endregion

  //#region Events
  onClose() {
    let action = new CloseLeaseRenewalResultPage(true);
    this.ngRedux.dispatch({ type: action.type });
    if (!!this.database && !!this.database.length) {
      this._router.navigate([`/leaserenewal/${this.database}/${this.sessionID}/${this.departmentID}`]);
    }
    else {
      this._router.navigate(["/leaserenewal"]);
    }
  }
  onLeaseRenewal(event) {
    if (event == true) {
      this.sharedData_Subcription =
        this.dataTransfer.data$.subscribe(sd => {
          if (!!sd) {
            this.startAutoRefresh(sd);
            if (!!this.sharedData_Subcription) this.sharedData_Subcription.unsubscribe();
          }
        });
      this.showLeaseExpiry = false;
      if (!!this.sharedData_Subcription) this.sharedData_Subcription.unsubscribe();
    }
    /*if (event == true) {
      this.showLeaseExpiry = false;
    }*/
  }
  onLeaseExpiryClick(data: ILeaseRenewalSearchResult) {
    let hyperlinkID: number = 0;//!!data.leaseRenewalSequenceHyperlinkID ? data.leaseRenewalSequenceHyperlinkID : 0;
    if (!this.calledByREM) {
      this.getSessionData_Subcription = this._authService.GetSessionData().subscribe(s => {
        if (!!s) {
          this.showLeaseExpiry = true;
          this.leaseExpiryUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.leaseRenewalRoolUrl}/residential/staff/${hyperlinkID}/${s.connectionStringName}/${data.leaseRenewalID}`);

          this.dataTransfer.data$.subscribe(sd => {
            if (!!sd) {
              clearInterval(sd.autoRefreshIntervalHandler);
            }
          });
        }
      });
    }
    else {
      this.showLeaseExpiry = true;
      this.leaseExpiryUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.leaseRenewalRoolUrl}/residential/staff/${hyperlinkID}/${this.database}/${data.leaseRenewalID}`);

      this.dataTransfer.data$.subscribe(sd => {
        if (!!sd) {
          clearInterval(sd.autoRefreshIntervalHandler);
        }
      });
    }

  }
  onBypassChange(data: ILeaseRenewalSearchResult, event: any) {
    this.bypassLeaseRenewal_Subscription =
      this._service.BypassLeaseRenewal(this.database, this.sessionID, data.leaseRenewalID, event.checked)
        .subscribe(result => {
          if (result) {
            data.leaseRenewalByPassAutoProcessing = event.checked;
          }
        });
  }
  onLeaseExpiryCommercialClick(data: ILeaseRenewalSearchResult) {
    let hyperlinkID: number = 101;//!!data.leaseRenewalSequenceHyperlinkID ? data.leaseRenewalSequenceHyperlinkID : 0;
    if (!!data && !data.optionPeriod) {
      hyperlinkID = 0;
    }
    if (!this.calledByREM) {
      this.getSessionData_Subcription = this._authService.GetSessionData().subscribe(s => {
        if (!!s) {
          this.showLeaseExpiry = true;
          this.leaseExpiryUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.leaseRenewalRoolUrl}/commercial/staff/${hyperlinkID}/${s.connectionStringName}/${data.leaseRenewalID}/${data.propertyManagerID}`);

          this.dataTransfer.data$.subscribe(sd => {
            if (!!sd) {
              clearInterval(sd.autoRefreshIntervalHandler);
            }
          });

        }
      });
    }
    else {
      this.showLeaseExpiry = true;
      this.leaseExpiryUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.leaseRenewalRoolUrl}/commercial/staff/${hyperlinkID}/${this.database}/${data.leaseRenewalID}/${data.propertyManagerID}`);

      this.dataTransfer.data$.subscribe(sd => {
        if (!!sd) {
          clearInterval(sd.autoRefreshIntervalHandler);
        }
      });

    }

  }
  onLeaseRenewalSequence(pd: ILeaseRenewalSearchResult) {
    if (pd.leaseRenewalSequenceID == 16 || pd.leaseRenewalSequenceID == 17) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '250px',
        data: { title: "Confirmation", message: "Do you wish to resend confirmation email to owner and tenant?" }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._loader.show();
          this.setLeaseRenewalSequence_Subcription =
            this._service.SetLeaseRenewalSequence(this.database, this.sessionID, pd.leaseRenewalID, 22, false)
              .subscribe(data => {
                if (!data.isError) {
                  if (data.response) {
                    this._loader.hide();
                    window.open(`${this.leaseRenewalRoolUrl}/residential/staff/9/${!!this.database ? this.database : this.connectionStringName}/${pd.leaseRenewalID}/${this.externalApplicationSessionID}`, "_blank");
                  }
                }
                else {
                  Observable.throwError(data.message);
                  this.openDialog("Error", data.message, "alertdialog");
                }
                this._loader.hide();
              }),
            error => {
              this._loader.hide();
              this.openDialog("Alert", error, "alertdialog");
            };
        }
      });
    }
  }
  onRestartNegotiationClick(pd: ILeaseRenewalSearchResult) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { title: "Confirmation", message: "Are you sure you wish to delete all Negotiation and Restart?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._loader.show();
        this.restartNegotiation_Subcription =
          this._service.RestartNegotiation(this.database, this.sessionID, pd.leaseRenewalID)
            .subscribe(data => {
              if (!data.isError) {
                if (data.response) {
                  this.sharedData_Subcription =
                    this.dataTransfer.data$.subscribe(sd => {
                      if (!!sd) {
                        this.search(sd);
                        this.startAutoRefresh(sd);
                        if (!!this.sharedData_Subcription) this.sharedData_Subcription.unsubscribe();
                        this.loadPageData();
                      }
                    });
                }
              }
              else {
                Observable.throwError(data.message);
                this.openDialog("Error", data.message, "alertdialog");
              }
              this._loader.hide();
            }),
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          };
      }
    });
  }
  onShowChangeRentDialogClick(pd: ILeaseRenewalSearchResult) {
    this.showChangeRentDialog = true;
    this.selectedLeaseRenewal = pd;
    this.newLeaseProposedNewRent = pd.newLeaseProposedNewRent;
  }
  onChangeRentClick(event: any) {
    if (!!this.selectedLeaseRenewal) {

      if (!this.newLeaseProposedNewRent) {
        this.openDialog("Alert", "Please enter new rent amount", "alertdialog");
        return false;
      }
      if (this.selectedLeaseRenewal.newLeaseProposedNewRent == this.newLeaseProposedNewRent) {
        this.openDialog("Alert", "Please enter new rent amount", "alertdialog");
        return false;
      }

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '250px',
        data: { title: "Confirmation", message: "Are you sure you wish to change the rent amount?" }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._loader.show();
          this.changeRent_Subcription =
            this._service.ChangeRent(this.database, this.sessionID, this.selectedLeaseRenewal.leaseRenewalID, this.newLeaseProposedNewRent)
              .subscribe(data => {
                if (!data.isError) {
                  if (!!data.response) {
                    this.leaseRenewals = this.leaseRenewals.map((value, index) => {
                      if (value.leaseRenewalID == this.selectedLeaseRenewal.leaseRenewalID) {
                        value.newLeaseProposedNewRent = data.response.newLeaseProposedNewRent;
                      }
                      return value;
                    });

                    this.sharedData_Subcription =
                      this.dataTransfer.data$.subscribe(sd => {
                        if (!!sd) {
                          this.startAutoRefresh(sd);
                          if (!!this.sharedData_Subcription) this.sharedData_Subcription.unsubscribe();
                        }
                      });

                  }
                }
                else {
                  Observable.throwError(data.message);
                  this.openDialog("Error", data.message, "alertdialog");
                }
                this.showChangeRentDialog = false;
                this.selectedLeaseRenewal = null;
                this._loader.hide();
              }),
            error => {
              this._loader.hide();
              this.openDialog("Alert", error, "alertdialog");
              this.showChangeRentDialog = false;
              this.selectedLeaseRenewal = null;
            };
        }
      });





    }
  }
  onChangeRentVisibleChange(event: any) {
    this.showChangeRentDialog = false;
  }
  onOwnerClick(data: ILeaseRenewalSearchResult) {
    debugger;
    let hyperlinkID: number = 10;
    if (!this.calledByREM) {
      this.getSessionData_Subcription = this._authService.GetSessionData().subscribe(s => {
        if (!!s) {
          this.showLeaseExpiry = true;
          this.leaseExpiryUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.leaseRenewalRoolUrl}/residential/staff/${hyperlinkID}/${s.connectionStringName}/${data.leaseRenewalID}/${s.id}`);

          this.dataTransfer.data$.subscribe(sd => {
            if (!!sd) {
              clearInterval(sd.autoRefreshIntervalHandler);
            }
          });
        }
      });
    }
    else {
      this.showLeaseExpiry = true;
      this.leaseExpiryUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.leaseRenewalRoolUrl}/residential/staff/${hyperlinkID}/${this.database}/${data.leaseRenewalID}/${this.sessionID}`);

      this.dataTransfer.data$.subscribe(sd => {
        if (!!sd) {
          clearInterval(sd.autoRefreshIntervalHandler);
        }
      });
    }
  }
  onTenantClick(data: ILeaseRenewalSearchResult) {
    let hyperlinkID: number = 11;
    if (!this.calledByREM) {
      this.getSessionData_Subcription = this._authService.GetSessionData().subscribe(s => {
        if (!!s) {
          this.showLeaseExpiry = true;
          this.leaseExpiryUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.leaseRenewalRoolUrl}/residential/tenant/${hyperlinkID}/${s.connectionStringName}/${data.leaseRenewalID}/${s.id}`);

          this.dataTransfer.data$.subscribe(sd => {
            if (!!sd) {
              clearInterval(sd.autoRefreshIntervalHandler);
            }
          });
        }
      });
    }
    else {
      this.showLeaseExpiry = true;
      this.leaseExpiryUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.leaseRenewalRoolUrl}/residential/tenant/${hyperlinkID}/${this.database}/${data.leaseRenewalID}/${this.sessionID}`);

      this.dataTransfer.data$.subscribe(sd => {
        if (!!sd) {
          clearInterval(sd.autoRefreshIntervalHandler);
        }
      });
    }
  }
  //#endregion

  //#region Grid Data Sort
  clearSortTimeout: any = null;
  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }
  sortData(sort: Sort) {
    this._loader.show();
    this.clearSortTimeout = setTimeout(() => {
      this.sortAllData(sort);
    }, 10)
  }
  private sortAllData(sort: Sort) {
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.leaseRenewals.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.leaseRenewals.length; innerIndex++) {
        switch (sort.active) {
          case 'textFormatLS': {
            if (isAsc) {
              if (this.leaseRenewals[index].textFormatLS > this.leaseRenewals[innerIndex].textFormatLS) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (this.leaseRenewals[index].textFormatLS < this.leaseRenewals[innerIndex].textFormatLS) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'propertyManager': {
            if (isAsc) {
              if (`${this.leaseRenewals[index].propertyManagerStaffCode}` > `${this.leaseRenewals[innerIndex].propertyManagerStaffCode}`) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (`${this.leaseRenewals[index].propertyManagerStaffCode}` < `${this.leaseRenewals[innerIndex].propertyManagerStaffCode}`) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'leaseEndDate': {
            if (isAsc) {
              if (this.leaseRenewals[index].leaseEndDate > this.leaseRenewals[innerIndex].leaseEndDate) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (this.leaseRenewals[index].leaseEndDate < this.leaseRenewals[innerIndex].leaseEndDate) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'lastDate': {
            if (isAsc) {
              if (this.leaseRenewals[index].lastDate > this.leaseRenewals[innerIndex].lastDate) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (this.leaseRenewals[index].lastDate < this.leaseRenewals[innerIndex].lastDate) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'weeklyRentGSTInclusive': {
            if (isAsc) {
              if (this.leaseRenewals[index].weeklyRentGSTInclusive > this.leaseRenewals[innerIndex].weeklyRentGSTInclusive) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (this.leaseRenewals[index].weeklyRentGSTInclusive < this.leaseRenewals[innerIndex].weeklyRentGSTInclusive) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'primaryOwner': {
            if (isAsc) {
              if (`${this.leaseRenewals[index].primaryOwnerGivenName} ${this.leaseRenewals[index].primaryOwnerSurname}` > `${this.leaseRenewals[innerIndex].primaryOwnerGivenName} ${this.leaseRenewals[innerIndex].primaryOwnerSurname}`) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (`${this.leaseRenewals[index].primaryOwnerGivenName} ${this.leaseRenewals[index].primaryOwnerSurname}` < `${this.leaseRenewals[innerIndex].primaryOwnerGivenName} ${this.leaseRenewals[innerIndex].primaryOwnerSurname}`) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'primaryTenant': {
            if (isAsc) {
              if (`${this.leaseRenewals[index].jointTenantGivenName} ${this.leaseRenewals[index].jointTenantSurname}` > `${this.leaseRenewals[innerIndex].jointTenantGivenName} ${this.leaseRenewals[innerIndex].jointTenantSurname}`) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (`${this.leaseRenewals[index].jointTenantGivenName} ${this.leaseRenewals[index].jointTenantSurname}` < `${this.leaseRenewals[innerIndex].jointTenantGivenName} ${this.leaseRenewals[innerIndex].jointTenantSurname}`) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'lastDate': {
            if (isAsc) {
              if (this.leaseRenewals[index].lastDate > this.leaseRenewals[innerIndex].lastDate) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (this.leaseRenewals[index].lastDate < this.leaseRenewals[innerIndex].lastDate) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'leaseRenewalSequenceDescription': {
            if (isAsc) {
              if (this.leaseRenewals[index].leaseRenewalSequenceDescription > this.leaseRenewals[innerIndex].leaseRenewalSequenceDescription) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (this.leaseRenewals[index].leaseRenewalSequenceDescription < this.leaseRenewals[innerIndex].leaseRenewalSequenceDescription) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'rentalTenantTypeID': {
            if (isAsc) {
              if (this.leaseRenewals[index].rentalTenantTypeID > this.leaseRenewals[innerIndex].rentalTenantTypeID) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (this.leaseRenewals[index].rentalTenantTypeID < this.leaseRenewals[innerIndex].rentalTenantTypeID) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'optionPeriod': {
            if (isAsc) {
              if (this.leaseRenewals[index].optionPeriod > this.leaseRenewals[innerIndex].optionPeriod) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (this.leaseRenewals[index].optionPeriod < this.leaseRenewals[innerIndex].optionPeriod) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'gstExclusiveYearlyRent': {
            if (isAsc) {
              if (this.leaseRenewals[index].gstExclusiveYearlyRent > this.leaseRenewals[innerIndex].gstExclusiveYearlyRent) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (this.leaseRenewals[index].gstExclusiveYearlyRent < this.leaseRenewals[innerIndex].gstExclusiveYearlyRent) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
          case 'fileName': {
            if (isAsc) {
              if (this.leaseRenewals[index].fileName > this.leaseRenewals[innerIndex].fileName) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            else {
              if (this.leaseRenewals[index].fileName < this.leaseRenewals[innerIndex].fileName) {
                let temp = this.leaseRenewals[index];
                this.leaseRenewals[index] = this.leaseRenewals[innerIndex];
                this.leaseRenewals[innerIndex] = temp;
              }
            }
            break;
          }
        }
      }
    }
    this.loadPageData()
    this._loader.hide();
    clearTimeout(this.clearSortTimeout);
  }
  //#endregion

  //#region Grid Expand/Collapse
  onExpand(lr: ILeaseRenewalSearchResult) {
    if (!lr.showLog) {
      lr.showLog = false;
      lr.showLogLoader = true;
      lr.logs = [];
      this.getLeaseRenewalLogs_Subscription =
        this._service.GetLeaseRenewalLogs(this.database, this.sessionID, lr.leaseRenewalID)
          .subscribe(data => {
            if (!!data && !data.isError) {
              lr.logs = data.response.map(item => [
                { fieldName: "id", value: item.id, showText: (!!item.id ? item.id : "-"), header: "ID", visible: false },
                { fieldName: "leaseRenewalID", value: item.leaseRenewalID, showText: (!!item.leaseRenewalID ? item.leaseRenewalID : "-"), header: "Lease Renewal ID", visible: false },
                { fieldName: "proposedBy", value: item.proposedBy, showText: (!!item.proposedBy ? item.proposedBy : "-"), header: "Proposed By", visible: true, sortable: true },
                { fieldName: "proposedByContactID", value: item.proposedByContactID, showText: (!!item.proposedByContactID ? item.proposedByContactID : "-"), header: "Proposed By ContactID ID", visible: false, sortable: true },
                { fieldName: "proposedByContactName", value: item.proposedByContactName, showText: (!!item.proposedByContactName ? item.proposedByContactName : "-"), header: "Proposed By Contact Name", visible: true, sortable: true },
                { fieldName: "proposedLeasePeriodInMonths", value: item.proposedLeasePeriodInMonths, showText: (!!item.proposedLeasePeriodInMonths ? `${item.proposedLeasePeriodInMonths.toString()} Months` : (!!item.proposedLeasePeriodInWeeks ? `${item.proposedLeasePeriodInWeeks.toString()} Weeks` : "-")), header: "Proposed Lease Period", visible: true, sortable: true },
                { fieldName: "proposedNewRent", value: item.proposedNewRent, showText: (!!item.proposedNewRent ? "$" + item.proposedNewRent.toString() : "-"), header: "Proposed New Rent", visible: true },
                { fieldName: "proposedNewRentPeriod", value: item.proposedNewRentPeriod, showText: (!!item.proposedNewRentPeriod ? this.proposedRentPeriod(item.proposedNewRentPeriod) : "-"), header: "Proposed New Rent Period", visible: true, sortable: true },
                { fieldName: "waterUsageCondition", value: item.waterUsageCondition, showText: this.waterChargesPM(item.waterUsageCondition), header: "Water Usage", visible: true, sortable: true },
                { fieldName: "comment", value: item.comment, showText: (!!item.comment && !!item.resTenantVacatingNotes && (item.comment.toLowerCase().indexOf('vacate') >= 0 || item.comment.toLowerCase().indexOf('vacating') >= 0) ? `${item.comment}${String.fromCharCode(13)}_Tenant Vacating Notes - ${item.resTenantVacatingNotes}` : (!!item.comment ? item.comment : "-")), header: "Comment", visible: true, sortable: true },
                { fieldName: "dateTimeLocal", value: item.dateTimeLocal, showText: this._datePipe.transform(item.dateTimeLocal, "dd/MM/yyyy hh:mm a"), header: "Date", visible: true, sortable: true },
              ]);
              lr.showLog = true;
              lr.showLogLoader = false;
            }
            else {
              this.openDialog("Error", data.message, "alertdialog");
              Observable.throwError(data.message);
            }
          }),
        error => {
          this.openDialog("Alert", error, "alertdialog");
        };
    }
    else {
      lr.showLog = !lr.showLog;
    }

  }
  //#endregion

}
