import { Router, RouterModule } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { NgRedux, select } from "@angular-redux/store";
import { CookieService } from 'ngx-cookie';
import { IAppState } from "../store/store";
import { LOGIN, LOGOUT, IS_LOGGED_IN, CONTACT_RENTALTENANT_SEARCH_REFRESH } from "../store/actions";
import { ILogonState } from "../store/logon";
import {
  AuthService,
  enImageSize,
  SessionInfo,
  ServiceResponse
} from "./../services/authservice";
import { LoaderService } from './../core/loader/loader.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { EncryptionService } from './../services/encryptionservice';

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  @select(state => state.login.isLoggedIn) isLoggedIn;

  /*model: ILogonState = {
    connectionStringName: null,
    loginID: null,
    password: null,
    rememberMe: null,
    rememberValue: null,
    requiredForgotPassword: null,
    isLoggedIn: false
  };*/
  private currentYear: number;
  private connectionStringName: string = null;
  private loginID: string = null;
  private password: string = null;
  private showQRCode: boolean = true;
  private showLogin: boolean = true;
  private showAuthCode: boolean = false;
  private qrCodeUrl: string = null;
  private textCode: string = null;
  model: FormGroup;
  message: string = "";

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private _service: AuthService,
    private _loader: LoaderService,
    private fb: FormBuilder,
    private _cookieService: CookieService,
    private _ecryption: EncryptionService
  ) {
    this.model = this.fb.group({
      connectionStringName: [!!this._cookieService.get("connectionStringName") ? this._ecryption.decrypt(this._cookieService.get("connectionStringName")) : "", Validators.required],
      loginID: [!!this._cookieService.get("loginID") ? this._ecryption.decrypt(this._cookieService.get("loginID")) : "", Validators.required],
      password: [!!this._cookieService.get("password") ? this._ecryption.decrypt(this._cookieService.get("password")) : "", Validators.required],
      rememberMe: [""],
      rememberValue: [""],
      requiredForgotPassword: [false],
      isLoggedIn: [false],
      authenticationCode: [""]
    });
  }
  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this._service
      .GetSessionData()
      .map((data: SessionInfo) => {
        return {
          type: IS_LOGGED_IN,
          login: this.model,
          isLoggedIn: data.isLoggedIn,
          contactAccessible: data.contactAccessible,
          isCommercial: data.isCommercial
        };
      })
      .subscribe(action => {
        this._service.GetDepartments()
          .subscribe(deps => {
            this.ngRedux.dispatch({
              type: IS_LOGGED_IN,
              login: this.model,
              isLoggedIn: action.isLoggedIn,
              contactAccessible: action.contactAccessible,
              isCommercial: action.isCommercial,
              departments: deps
            });
            if (action.isLoggedIn) {
              //this._router.navigate(['/dashboard']);
              this._router.navigate(['/managementagreements']);
            }
          });
      });
    //this.ngRedux.dispatch({ type: LOGOUT, login: this.model });
  }
  onSubmit() {
    if (this.model.status == "VALID") {
      this.connectionStringName = this.model.controls.connectionStringName.value;
      this.loginID = this.model.controls.loginID.value;
      this.password = this.model.controls.password.value;
    }
    else if (!(!!this.connectionStringName && !!this.loginID && !!this.password)) {
      return;
    }
    //if (this.model.status == "VALID") {
    this._loader.show();
    this._service
      .Authenticate(
        /*this.model.controls.connectionStringName.value,
        this.model.controls.loginID.value,
        this.model.controls.password.value*/
        this.connectionStringName,
        this.loginID,
        this.password,
        this.model.controls.authenticationCode.value
      )
      .map((data: ServiceResponse<SessionInfo>) => {
        //return { type: LOGIN, isLoggedIn: data.isLoggedIn, userName: data.firstName, contactAccessible: data.contactAccessible, trisetSystemID: data.trisetSystemID, categoryID: data.categoryID, departmentID: data.departmentID, isCommercial: data.isCommercial };
        return data;
      })
      .subscribe(data => {
        if (!data.isError) {
          let action = data.response;

          this.showQRCode = action.showQRCode;
          this.showLogin = action.showLogin;
          this.showAuthCode = action.showAuthCode;
          this.qrCodeUrl = action.qrCodeUrl;
          this.textCode = action.textCode;
          if (!action.isSessionApiCreated) {
            this._loader.hide();
          }
          else {

            this._service.GetDepartments()
              .subscribe(deps => {
                if (!!deps) {
                  deps = deps.filter(f => f.categoryID == 1 && f.trisetSystemID == 8);
                }

                this.ngRedux.dispatch({ type: LOGIN, isLoggedIn: action.isLoggedIn, userName: action.userName, contactAccessible: action.contactAccessible, trisetSystemID: action.trisetSystemID, categoryID: action.categoryID, departmentID: action.departmentID, isCommercial: action.isCommercial, departments: deps });
                if (action.isLoggedIn) {
                  if (!!this.model.controls.rememberMe.value && this.model.controls.rememberMe.value) {
                    let exireDate = new Date();
                    exireDate.setFullYear(exireDate.getFullYear() + 1);
                    this._cookieService.put("connectionStringName", this._ecryption.encrypt(this.model.controls.connectionStringName.value), { expires: exireDate });
                    this._cookieService.put("loginID", this._ecryption.encrypt(this.model.controls.loginID.value), { expires: exireDate });
                    this._cookieService.put("password", this._ecryption.encrypt(this.model.controls.password.value), { expires: exireDate });
                  }
                  this.message = "";
                  this.ngRedux.dispatch({ type: CONTACT_RENTALTENANT_SEARCH_REFRESH });

                  if (action.trisetSystemID == 8 && action.categoryID == 1) {
                    //this._router.navigate(["/dashboard"]);
                    this._router.navigate(["/managementagreements"]);
                  }
                  else {
                    this._router.navigate(['/listingenquirystatus']);
                  }
                }
                else {
                  this.message = "Invalid username or password";
                }
                this._loader.hide();


              });
          }

        }
        else {
          this.message = data.message;
          this._loader.hide();
        }
      });
    //this.ngRedux.dispatch({ type: LOGIN, login: this.model });
    //}
  }
  onReload() {
    window.location.reload();
  }
}
