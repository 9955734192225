import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { Sort, SortDirection } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

import { IAppState } from "../../../store/store";
import { AuthService } from "../../../services/authservice";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';

import { GridViewDataTable } from './../../../propertymanagement/components/gridview/gridview.component';
import { CloseOpenPrivateInspectionResultPage, ShowOpenPrivateInspectionSearchPage } from './../../ofi.action';
import { IAppointmentDetailsSearchResult, IAppointmentChildDetails } from './../../ofi';
import { OfiService } from './../../ofi.service';
import { ConfirmDialogComponent } from './../../../components/confirmdialog/confirmdialog.component';


@Component({
  selector: 'app-openprivateinspectionsearchresult',
  templateUrl: './openprivateinspectionsearchresult.component.html',
  styleUrls: ['./openprivateinspectionsearchresult.component.css']
})
export class OpenPrivateInspectionSearchResultComponent implements OnInit, OnDestroy {

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private _datePipe: DatePipe,
    private _authService: AuthService,
    private _service: OfiService
  ) { 
    this.modelAppointmentPopup = new FormGroup({
      bookingStatusID: new FormControl(null),
      opinionOfListPrice: new FormControl(null),
      opinionPriceFrom: new FormControl(null),
      opinionPriceTo: new FormControl(null),
      opinionOfPresentation: new FormControl(null),
      interestLevel: new FormControl(null),
      suitability: new FormControl(null),
      followUpRequired: new FormControl(false, Validators.required),
      nextContactDate: new FormControl(null),
      notes: new FormControl(null)
    });
  }

  //#region Subscriptions
  private getSessionData_Subcription: Subscription;
  private getAppointmentDetails_Subscription: Subscription;
  private getAppointmentContact_Subscription: Subscription;
  private modifyAppointmentDetails_Subscription: Subscription;
  //#endregion

  //#region Private Variables
  private connectionStringName: string;
  //private appointmentID: string;
  private qrCodeText: string;
  private footerText: string;
  private modelAppointmentPopup: FormGroup;
  private selectedAppContact: IAppointmentChildDetails = {} as IAppointmentChildDetails;
  //#endregion

  //#region Snackbar Config
  private snackConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //#endregion

  @select(state => (state as IAppState).ofi.openprivateinspections.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).ofi.openprivateinspections.search.appointments) appointments$;

  ngOnInit() {
    this.getSessionData_Subcription = this._authService.GetSessionData().subscribe(s => {
      if (!!s) {
        this.connectionStringName = s.connectionStringName;
      }
    })

    this.appointments = this.ngRedux.getState().ofi.openprivateinspections.search.appointments;
    this.loadPageData();
  }
  ngOnDestroy() {
    if (!!this.getAppointmentDetails_Subscription) this.getAppointmentDetails_Subscription.unsubscribe();
    if (!!this.getAppointmentContact_Subscription) this.getAppointmentContact_Subscription.unsubscribe();
    if (!!this.modifyAppointmentDetails_Subscription) this.modifyAppointmentDetails_Subscription.unsubscribe();    
  }

  onClose() {
    let action = new CloseOpenPrivateInspectionResultPage(true);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    this._router.navigate(["/ofi"])
  }

  //#region Pagination
  private appointments: IAppointmentDetailsSearchResult[] = [];  
  private hasPagination: boolean = true;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: IAppointmentDetailsSearchResult[];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.pageData = this.appointments;
    if (this.hasPagination) {
      this.pageData = this.appointments.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }
  }
  //#endegion

  //#region Dialog
  private dialogRef: any;
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  //#endregion

  //#region Grid Data Sort
  clearSortTimeout: any = null;
  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }
  sortData(sort: Sort) {
    this._loader.show();
    this.clearSortTimeout = setTimeout(() => { 
      this.sortAllData(sort);
    }, 10)
  }
  private sortAllData(sort: Sort) {
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.appointments.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.appointments.length; innerIndex++) {
        switch (sort.active) {
          case 'startDateTime': {
            if (isAsc) {
              if (this.appointments[index].startDateTime > this.appointments[innerIndex].startDateTime) {
                let temp = this.appointments[index];
                this.appointments[index] = this.appointments[innerIndex];
                this.appointments[innerIndex] = temp;
              }
            }
            else {
              if (this.appointments[index].startDateTime < this.appointments[innerIndex].startDateTime) {
                let temp = this.appointments[index];
                this.appointments[index] = this.appointments[innerIndex];
                this.appointments[innerIndex] = temp;
              }
            }
            break;
          }
          case 'addressText': {
            if (isAsc) {
              if (this.appointments[index].addressText > this.appointments[innerIndex].addressText) {
                let temp = this.appointments[index];
                this.appointments[index] = this.appointments[innerIndex];
                this.appointments[innerIndex] = temp;
              }
            }
            else {
              if (this.appointments[index].addressText < this.appointments[innerIndex].addressText) {
                let temp = this.appointments[index];
                this.appointments[index] = this.appointments[innerIndex];
                this.appointments[innerIndex] = temp;
              }
            }
            break;
          }
          case 'staffName': {
            if (isAsc) {
              if (this.appointments[index].staffName > this.appointments[innerIndex].staffName) {
                let temp = this.appointments[index];
                this.appointments[index] = this.appointments[innerIndex];
                this.appointments[innerIndex] = temp;
              }
            }
            else {
              if (this.appointments[index].staffName < this.appointments[innerIndex].staffName) {
                let temp = this.appointments[index];
                this.appointments[index] = this.appointments[innerIndex];
                this.appointments[innerIndex] = temp;
              }
            }
            break;
          }
        }
      }
    }
    this.loadPageData() 
    this._loader.hide();
    clearTimeout(this.clearSortTimeout);
  }
  //#endregion

  //#region Grid Expand/Collapse
  private isSaved: boolean;
  onGetSelectedRecord(event: GridViewDataTable[]) {
    this.visible = true;

    this.modelAppointmentPopup.controls.opinionOfListPrice.setValue(null);
    this.modelAppointmentPopup.controls.opinionPriceFrom.setValue(null);
    this.modelAppointmentPopup.controls.opinionPriceTo.setValue(null);
    this.modelAppointmentPopup.controls.opinionOfPresentation.setValue(null);
    this.modelAppointmentPopup.controls.interestLevel.setValue(null);
    this.modelAppointmentPopup.controls.suitability.setValue(null);
    this.modelAppointmentPopup.controls.notes.setValue(null);
    this.modelAppointmentPopup.controls.followUpRequired.setValue(false);
    this.modelAppointmentPopup.controls.nextContactDate.setValue(null);
    
    if (!!event && !!event.length) {
      this.selectedAppContact.appointmentID = event.filter(ev => ev.fieldName == "appointmentID")[0].value as string;
      this.selectedAppContact.listingID = event.filter(ev => ev.fieldName == "listingID")[0].value as string;
      this.selectedAppContact.contactID = event.filter(ev => ev.fieldName == "contactID")[0].value as string;
      this.selectedAppContact.contactName = event.filter(ev => ev.fieldName == "contactName")[0].value as string;
      this.selectedAppContact.contactMobile = event.filter(ev => ev.fieldName == "contactMobile")[0].value as string;
      this.selectedAppContact.contactEmail = event.filter(ev => ev.fieldName == "contactEmail")[0].value as string;
      this.selectedAppContact.bookingStatusID =  event.filter(ev => ev.fieldName == "bookingStatusID")[0].value as number;

      this.selectedAppContact.showLoader = true;
      this.getAppointmentContact_Subscription =
      this._service
      .GetAppointmentContact(this.selectedAppContact.appointmentID, this.selectedAppContact.contactID)
      .subscribe(data => {
        if (!!data && !data.isError && !!data.response) {
          this.selectedAppContact.showLoader = false;
          this.modelAppointmentPopup.controls.bookingStatusID.setValue(data.response.bookingStatusID);
          this.modelAppointmentPopup.controls.opinionOfListPrice.setValue(data.response.opinionOfListPrice);
          this.modelAppointmentPopup.controls.opinionPriceFrom.setValue(data.response.opinionPriceFrom);
          this.modelAppointmentPopup.controls.opinionPriceTo.setValue(data.response.opinionPriceTo);
          this.modelAppointmentPopup.controls.opinionOfPresentation.setValue(data.response.opinionOfPresentation);
          this.modelAppointmentPopup.controls.interestLevel.setValue(data.response.interestLevel);
          this.modelAppointmentPopup.controls.suitability.setValue(data.response.suitability);
          this.modelAppointmentPopup.controls.notes.setValue((!!data.response.notes && !!data.response.notes.length) ? `\n${data.response.notes}` : null);
          this.modelAppointmentPopup.controls.followUpRequired.setValue(data.response.followUpRequired);
          this.modelAppointmentPopup.controls.nextContactDate.setValue(data.response.nextContactDate);          
        }
        else if (!!data && data.isError) {
          this.selectedAppContact.showLoader = false;
          if (!!data.message) {
            this.openDialog("Error", data.message, "alertdialog");
            Observable.throwError(data.message);
          }
        }
        else if (!!data && !data.isError && !data.response) {
          this.selectedAppContact.showLoader = false;          
          //this.openDialog("Empty Result", "No data found!", "alertdialog");
        }
      }),
      error => {
        this.selectedAppContact.showLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
    }
    console.log("event: ", event);
    console.log("selectedAppContact: ", this.selectedAppContact);
  }
  private getBookingStatus(status: number): string {
    if (status == 1)
      return "Booked";
    else if (status == 2)
      return "Attended";
    else
      return "Cancelled";
  }
  onExpand(ad: IAppointmentDetailsSearchResult) {
    if (!ad.showAppointmentContact) {
      ad.showAppointmentContact = false;
      ad.showAppointmentContactLoader = true;
      ad.details = [];
      this.getAppointmentDetails_Subscription = 
      this._service.GetAppointmentDetails(ad.id)
        .subscribe(data => {
          if (!!data && !data.isError) {
            ad.details = data.response.map(item => [
              { fieldName: "appointmentID", value: item.appointmentID, showText: (!!item.appointmentID ? item.appointmentID : "-"), header: "Appointment ID", visible: false },
              { fieldName: "listingID", value: item.listingID, showText: (!!item.listingID ? item.listingID : "-"), header: "Listing ID", visible: false },
              { fieldName: "contactID", value: item.contactID, showText: (!!item.contactID ? item.contactID : "-"), header: "ContactID ID", visible: false },
              { fieldName: "contactName", value: item.contactName, showText: (!!item.contactName ? item.contactName : "-"), header: "Name", visible: true },
              { fieldName: "bookingStatusID", value: item.bookingStatusID, showText: (!!item.bookingStatusID ? item.bookingStatusID.toString() : "-"), header: "bookingStatusID", visible: false },
              { fieldName: "bookingStatusID", value: item.bookingStatusID, showText: (!!item.bookingStatusID ? this.getBookingStatus(item.bookingStatusID) : "-"), header: "Booking Status", visible: true },
              //{ fieldName: null, value: null, showText: null, header: "QR Code", visible: true, showQRCodeButton: true },
              { fieldName: "contactMobile", value: item.contactMobile, showText: (!!item.contactMobile ? item.contactMobile : "-"), header: "Mobile", visible: true, showPhoneIcon: true, showSMSIcon: true },
              { fieldName: "contactEmail", value: item.contactEmail, showText: (!!item.contactEmail ? item.contactEmail : "-"), header: "Email", visible: true, showEmailIcon: true },
              { fieldName: "followUpRequired", value: item.followUpRequired, showText: ((!!item.followUpRequired && item.followUpRequired == true) ? "Yes" : "No"), header: "Follow Up Required", visible: true }
            ]);
            ad.showAppointmentContact = true;
            ad.showAppointmentContactLoader = false;
          }
          else {
            this.openDialog("Error", data.message, "alertdialog");
            Observable.throwError(data.message);
          }
        }),
        error => {
          this.openDialog("Alert", error, "alertdialog");
        };
    }
    else {
      ad.showAppointmentContact = !ad.showAppointmentContact;
    }

  }
  private visible: boolean = false;
  onCloseAppointmentEdit(event) {
    this.visible = !this.visible;
  }
  //#endregion
  //#region QR Code
  private showQRCode: boolean;
  onQRCodeClick(row: IAppointmentDetailsSearchResult) {
    this.showQRCode = true;
    //this.appointmentID = row.id;
    this.qrCodeText = `${row.id}`;
    this.footerText = `https://visitor.multiarray.com/openqrcode/${this.connectionStringName}/${this.qrCodeText}/true`;    
  }
  onCloseQRCode(event) {
    this.showQRCode = false;
  }
  //#endregion
  //#region Events
  onNextContactDate(event) {
    if (!!this.modelAppointmentPopup.controls.nextContactDate.value) {
      this.modelAppointmentPopup.controls.followUpRequired.setValue(true);
    }
    else {
      this.modelAppointmentPopup.controls.followUpRequired.setValue(false);
    }
  }
  onOpinionPriceFromChange(event) {
    this.modelAppointmentPopup.controls.opinionPriceTo.setValue(
    this.modelAppointmentPopup.controls.opinionPriceFrom.value);
  }
  //#endregion

  onAppointmentModify(event) {    
    /*const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { title: "Confirmation", message: "Are you sure?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {*/
        this.selectedAppContact.showLoader = true;
        this.modifyAppointmentDetails_Subscription =
        this._service
        .ModifyAppointmentDetails
        (
          this.selectedAppContact.appointmentID,
          this.selectedAppContact.contactID,
          parseInt(this.modelAppointmentPopup.controls.opinionOfListPrice.value),
          this.modelAppointmentPopup.controls.opinionPriceFrom.value,
          this.modelAppointmentPopup.controls.opinionPriceTo.value,
          parseInt(this.modelAppointmentPopup.controls.opinionOfPresentation.value),
          parseInt(this.modelAppointmentPopup.controls.interestLevel.value),
          parseInt(this.modelAppointmentPopup.controls.suitability.value),
          this.modelAppointmentPopup.controls.notes.value,
          this.modelAppointmentPopup.controls.followUpRequired.value,
          this.modelAppointmentPopup.controls.nextContactDate.value,
          this.modelAppointmentPopup.controls.bookingStatusID.value,
        )
        .subscribe(data => {
          if (!!data && !data.isError && data.response) {
            this.selectedAppContact.showLoader = false;
            this.snackBar.open("Successfully modified!", "Close", this.snackConfig);
            this.visible = false;
          }
          else if (!!data && data.isError) {
            this.selectedAppContact.showLoader = false;
            this.openDialog("Error", data.message, "alertdialog");
            Observable.throwError(data.message);            
          }
          else if (!!data && !data.isError && !data.response) {
            this.selectedAppContact.showLoader = false;
            this.snackBar.open("Modification not done!", "Close", this.snackConfig);
          }
        }),
        error => {
          this.selectedAppContact.showLoader = false;
          this.openDialog("Alert", error, "alertdialog");
        };
      /*}
    });*/

  }
}
