import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class DataTransferService {

  private data = new BehaviorSubject<any>([]);
  data$ = this.data.asObservable();

  sendAnything(data) {
    this.data.next(data);
  }

}
