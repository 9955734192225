import { IDNamePair } from "./../../../services/authservice";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { IAppState } from "../../../store/store";
import { EmarketingService } from "./../../emarketing.service";
import { LoaderService } from "./../../../core/loader/loader.service";
import {
  CloseEmailTemplatesSearchResult,
  EditTempate
} from "./../../emarketing.action";
import {
  IEmarketingState,
  INITIAL_EMARKETING_STATE
} from "./../../emarketing.state";
import { IEmailTemplate, IAddEditTemplate } from "./../../emarketing";

declare var $: JQueryStatic;
declare var LoadScript: any;
declare var FixedTableHeaderScript: any;

@Component({
  selector: "templatesearchresult",
  templateUrl: "./templatesearchresult.component.html",
  styleUrls: ["./templatesearchresult.component.css"]
})
export class TemplateSearchResultComponent implements OnInit, AfterViewInit {
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: EmarketingService,
    private _loader: LoaderService
  ) { }

  @select(
    state => (state as IAppState).emarketing.template.search.recipientTypes
  )
  recipientTypes$;
  @select(state => (state as IAppState).emarketing) emarketing$;
  @select(state => (state as IAppState).emarketing.template.search.templates)
  templates$;
  @select(
    state => (state as IAppState).emarketing.template.addEditView.showEditPage
  )
  showEditPage$;
  @select(state => (state as IAppState).emarketing.template.addEditView)
  addEditView$;

  ngOnInit() { }

  ngAfterViewInit() {
    LoadScript.gotoTop();
    var clearIntv = null;
    clearIntv = setInterval(() => {
      if (!!$("#no-more-tables > table").length) {
        clearInterval(clearIntv);
        FixedTableHeaderScript.fixMe($("table"));
        $(".up").click(function () {
          $("html, body").animate(
            {
              scrollTop: 0
            },
            2000
          );
        });
      }
    }, 2000);
  }

  onClose() {
    // this.emarketing$.subscribe(emark => {
    //   let action = new GetEmailTemplates({
    //     templateName: (emark as IEmarketingState).template.search.templateName,
    //     selectedRecipientTypes: (emark as IEmarketingState).template.search.selectedRecipientTypes,
    //     recipientTypes: (emark as IEmarketingState).template.search.recipientTypes,
    //     templates: (emark as IEmarketingState).template.search.templates,
    //     showSearchResult: false
    //   });
    //   this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    //   this._router.navigate(["/templates"]);
    // });
    let action = new CloseEmailTemplatesSearchResult(true);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    //this._router.navigate(["/dashboard"]);
    this._router.navigate(["/managementagreements"]);
  }
  onEditClick(template: IEmailTemplate) {
    // this.addEditView$.subscribe(temp => {
    //   (temp as IAddEditTemplate).template.id = template.id;
    //   (temp as IAddEditTemplate).showEditPage = true;
    // });
    if (!!template.id && template.id.indexOf("00000000") < 0) {
      this._loader.show();
      let addEditView = INITIAL_EMARKETING_STATE().template.addEditView;
      this.recipientTypes$.subscribe(rt => {
        this._service
          .GetEmailAgencies()
          .subscribe((agencies: IDNamePair<string>[]) => {
            this._service
              .GetEmailTemplate(template.id)
              .map((templ: IEmailTemplate) => {
                addEditView.agencies = agencies;
                addEditView.recipientTypes = rt;
                addEditView.template = templ;
                addEditView.showEditPage = false;
                return new EditTempate(addEditView);
              })
              .subscribe(action => {
                if (
                  !!action.payload.template &&
                  !!action.payload.template.id &&
                  action.payload.template.id.indexOf("00000000") < 0
                ) {
                  addEditView.showEditPage = true;
                  this.ngRedux.dispatch({
                    type: action.type,
                    payload: action.payload
                  });
                  this._router.navigate([
                    "/templates/templatesearchresult/edit"
                  ]);
                  this._loader.hide();
                }
              });
          });
      });
    }
  }
}
