import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { Sort, SortDirection } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import * as XLSX from 'xlsx';

import { IAppState } from "../../../store/store";
import { AuthService, ServiceResponse } from "../../../services/authservice";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';
import { PropertyManagementService } from "./../../propertymanagement.service";
import { INewLeaseCountSearchResult } from '../../propertymanagement';
import { CloseNewLeasesCountResultPage, GetNewLeasesCountSearch } from '../../propertymanagement.action';
import { ConfirmDialogComponent } from '../../../components/confirmdialog/confirmdialog.component';

@Component({
  selector: 'app-leaserenewalnewleasescountresult',
  templateUrl: './leaserenewalnewleasescountresult.component.html',
  styleUrls: ['./leaserenewalnewleasescountresult.component.scss']
})
export class LeaseRenewalNewLeasesCountResultComponent implements OnInit, OnDestroy {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private _datePipe: DatePipe,
    private _authService: AuthService,
    private _service: PropertyManagementService,
    private matIconRegistry: MatIconRegistry
  ) {
    this.matIconRegistry.addSvgIcon(
      "excel",
      this.sanitizer.bypassSecurityTrustResourceUrl("/images/excel.svg")
    );
  }

  //#region State Variables
  @select(state => (state as IAppState).propertymanagement.newLeasesCount.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).propertymanagement.newLeasesCount.search.newLeasesCounts) newLeasesCounts$;
  //#endregion

  //#region Subcriptions
  private getLeaseRenewalLogs_Subscription: Subscription;
  private getLeaseRenewal_Subscription: Subscription;
  private saveLeaseRenewalWeeklyRent_Subscription: Subscription;
  //#endregion

  //#region Private Variables
  private showRentChange: boolean;
  private newWeeklyRent: number;
  private selectedLeaseRenewalID: string;
  //#endregion

  //#region Properties
  get calledByREM(): boolean {
    return (this.route.snapshot.parent.routeConfig.path.indexOf("newleasescount") >= 0 && !!this.route.snapshot.parent.paramMap.get("sessionID") && this.route.snapshot.parent.paramMap.get("sessionID").indexOf('00000000-') < 0);
  }
  get database(): string {
    if (this.route.snapshot.parent.routeConfig.path.indexOf("newleasescount") >= 0 && !!this.route.snapshot.parent.paramMap.get("database")) {
      return this.route.snapshot.parent.paramMap.get("database");
    }
    else {
      return null;
    }
  }
  get sessionID(): string {
    if (this.route.snapshot.parent.routeConfig.path.indexOf("newleasescount") >= 0 && !!this.route.snapshot.parent.paramMap.get("sessionID") && this.route.snapshot.parent.paramMap.get("sessionID").indexOf('00000000-') < 0) {
      return this.route.snapshot.parent.paramMap.get("sessionID");
    }
    else {
      return null;
    }
  }
  get departmentID(): string {
    if (this.route.snapshot.parent.routeConfig.path.indexOf("newleasescount") >= 0 && !!this.route.snapshot.parent.paramMap.get("departmentID") && this.route.snapshot.parent.paramMap.get("departmentID").indexOf('00000000-') < 0) {
      return this.route.snapshot.parent.paramMap.get("departmentID");
    }
    else {
      return null;
    }
  }
  //#endregion

  //#region Dialog
  private dialogRef: any;
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  //#endregion

  //#region Pagination
  private newLeasesCounts: INewLeaseCountSearchResult[] = [];
  private hasPagination: boolean = true;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: INewLeaseCountSearchResult[];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.pageData = this.newLeasesCounts;
    if (this.hasPagination) {
      this.pageData = this.newLeasesCounts.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }
  }
  //#endregion

  ngOnInit() {
    this.newLeasesCounts = this.ngRedux.getState().propertymanagement.newLeasesCount.search.newLeasesCounts;
    this.loadPageData();
  }
  ngOnDestroy() {
    if (!!this.getLeaseRenewalLogs_Subscription) this.getLeaseRenewalLogs_Subscription.unsubscribe();
    if (!!this.getLeaseRenewal_Subscription) this.getLeaseRenewal_Subscription.unsubscribe();
    if (!!this.saveLeaseRenewalWeeklyRent_Subscription) this.saveLeaseRenewalWeeklyRent_Subscription.unsubscribe();
  }

  //#region Grid Data Sort
  clearSortTimeout: any = null;
  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }
  sortData(sort: Sort) {
    this._loader.show();
    this.clearSortTimeout = setTimeout(() => {
      this.sortAllData(sort);
    }, 10)
  }
  private sortAllData(sort: Sort) {
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.newLeasesCounts.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.newLeasesCounts.length; innerIndex++) {
        switch (sort.active) {
          case 'completedDate': {
            if (isAsc) {
              if (this.newLeasesCounts[index].completedDate > this.newLeasesCounts[innerIndex].completedDate) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].completedDate < this.newLeasesCounts[innerIndex].completedDate) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'oldLeaseEnd': {
            if (isAsc) {
              if (this.newLeasesCounts[index].oldLeaseEnd > this.newLeasesCounts[innerIndex].oldLeaseEnd) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].oldLeaseEnd < this.newLeasesCounts[innerIndex].oldLeaseEnd) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'propertyAddress': {
            if (isAsc) {
              if (this.newLeasesCounts[index].propertyAddress > this.newLeasesCounts[innerIndex].propertyAddress) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].propertyAddress < this.newLeasesCounts[innerIndex].propertyAddress) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'newLeasePeriod': {
            if (isAsc) {
              if (this.newLeasesCounts[index].newLeasePeriod > this.newLeasesCounts[innerIndex].newLeasePeriod) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].newLeasePeriod < this.newLeasesCounts[innerIndex].newLeasePeriod) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'newRentScheduled': {
            if (isAsc) {
              if (this.newLeasesCounts[index].newRentScheduled > this.newLeasesCounts[innerIndex].newRentScheduled) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].newRentScheduled < this.newLeasesCounts[innerIndex].newRentScheduled) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'newRent': {
            if (isAsc) {
              if (this.newLeasesCounts[index].newRent > this.newLeasesCounts[innerIndex].newRent) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].newRent < this.newLeasesCounts[innerIndex].newRent) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'rentIncrease': {
            if (isAsc) {
              if (this.newLeasesCounts[index].rentIncrease > this.newLeasesCounts[innerIndex].rentIncrease) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].rentIncrease < this.newLeasesCounts[innerIndex].rentIncrease) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'newRentStartDate': {
            if (isAsc) {
              if (this.newLeasesCounts[index].newRentStartDate > this.newLeasesCounts[innerIndex].newRentStartDate) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].newRentStartDate < this.newLeasesCounts[innerIndex].newRentStartDate) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'newRentWeekly': {
            if (isAsc) {
              if (this.newLeasesCounts[index].newRentWeekly > this.newLeasesCounts[innerIndex].newRentWeekly) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].newRentWeekly < this.newLeasesCounts[innerIndex].newRentWeekly) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'newRentIncreaseWeekly': {
            if (isAsc) {
              if (this.newLeasesCounts[index].newRentIncreaseWeekly > this.newLeasesCounts[innerIndex].newRentIncreaseWeekly) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].newRentIncreaseWeekly < this.newLeasesCounts[innerIndex].newRentIncreaseWeekly) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'onTenantCorner': {
            if (isAsc) {
              if (this.newLeasesCounts[index].onTenantCorner > this.newLeasesCounts[innerIndex].onTenantCorner) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].onTenantCorner < this.newLeasesCounts[innerIndex].onTenantCorner) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'onLandlord': {
            if (isAsc) {
              if (this.newLeasesCounts[index].onLandlord > this.newLeasesCounts[innerIndex].onLandlord) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].onLandlord < this.newLeasesCounts[innerIndex].onLandlord) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'propertyManagerStaffCode': {
            if (isAsc) {
              if (`${this.newLeasesCounts[index].propertyManagerStaffCode}` > `${this.newLeasesCounts[innerIndex].propertyManagerStaffCode}`) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (`${this.newLeasesCounts[index].propertyManagerStaffCode}` < `${this.newLeasesCounts[innerIndex].propertyManagerStaffCode}`) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }          
          case 'tenantNames': {
            if (isAsc) {
              if (this.newLeasesCounts[index].tenantNames > this.newLeasesCounts[innerIndex].tenantNames) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].tenantNames < this.newLeasesCounts[innerIndex].tenantNames) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'ownerNames': {
            if (isAsc) {
              if (this.newLeasesCounts[index].ownerNames > this.newLeasesCounts[innerIndex].ownerNames) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].ownerNames < this.newLeasesCounts[innerIndex].ownerNames) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'ownerMobile': {
            if (isAsc) {
              if (this.newLeasesCounts[index].ownerMobile > this.newLeasesCounts[innerIndex].ownerMobile) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            else {
              if (this.newLeasesCounts[index].ownerMobile < this.newLeasesCounts[innerIndex].ownerMobile) {
                let temp = this.newLeasesCounts[index];
                this.newLeasesCounts[index] = this.newLeasesCounts[innerIndex];
                this.newLeasesCounts[innerIndex] = temp;
              }
            }
            break;
          }
        }
      }
    }
    this.loadPageData()
    this._loader.hide();
    clearTimeout(this.clearSortTimeout);
  }
  //#endregion

  //#region Events
  onClose() {
    let action = new CloseNewLeasesCountResultPage(true);
    this.ngRedux.dispatch({ type: action.type });
    if (!!this.database && !!this.database.length) {
      this._router.navigate([`/newleasescount/${this.database}/${this.sessionID}/${this.departmentID}`]);
    }
    else {
      this._router.navigate(["/newleasescount"]);
    }
  }
  onCompletedClick(row: INewLeaseCountSearchResult) {
    this.showRentChange = true;
    this.getLeaseRenewalLogs_Subscription =
      this._service.GetLeaseRenewal(this.database, this.sessionID, row.leaseRenewalID)
        .subscribe(data => {
          if (!!data && !data.isError) {
            if (!!data.response) {
              this.selectedLeaseRenewalID = data.response.id;
              this.newWeeklyRent = data.response.newLeaseProposedNewRent;
            }
          }
          else {
            this.openDialog("Error", data.message, "alertdialog");
            Observable.throwError(data.message);
          }
        }),
      error => {
        this.openDialog("Alert", error, "alertdialog");
      };

  }
  onLeaseRenewal() {
    this.showRentChange = !this.showRentChange;
  }
  onSaveRentChanged() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { title: "Confirmation", message: "Do you want to change weekly rent?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._loader.show();
        this.saveLeaseRenewalWeeklyRent_Subscription =
          this._service.SaveLeaseRenewalWeeklyRent(this.database, this.sessionID, this.selectedLeaseRenewalID, this.newWeeklyRent)
            .subscribe(data => {
              if (!!data && !data.isError) {
                if (!!data.response) {
                  this.newWeeklyRent = null;
                  this.selectedLeaseRenewalID = null;
                  this.showRentChange = false;
                }
              }
              else {
                this.openDialog("Error", data.message, "alertdialog");
                Observable.throwError(data.message);
              }
              this._loader.hide();
            }),
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          };
      }
    });
  }
  //#endregion

  //#region Grid Expand/Collapse
  onExpand(lr: INewLeaseCountSearchResult) {
    if (!lr.showLog) {
      lr.showLog = false;
      lr.showLogLoader = true;
      lr.logs = [];
      this.getLeaseRenewalLogs_Subscription =
        this._service.GetLeaseRenewalLogs(this.database, this.sessionID, lr.leaseRenewalID)
          .subscribe(data => {
            if (!!data && !data.isError) {
              lr.logs = data.response.map(item => [
                { fieldName: "id", value: item.id, showText: (!!item.id ? item.id : "-"), header: "ID", visible: false },
                { fieldName: "leaseRenewalID", value: item.leaseRenewalID, showText: (!!item.leaseRenewalID ? item.leaseRenewalID : "-"), header: "Lease Renewal ID", visible: false },
                { fieldName: "proposedBy", value: item.proposedBy, showText: (!!item.proposedBy ? item.proposedBy : "-"), header: "Proposed By", visible: true, sortable: true },
                { fieldName: "proposedByContactID", value: item.proposedByContactID, showText: (!!item.proposedByContactID ? item.proposedByContactID : "-"), header: "Proposed By ContactID ID", visible: false, sortable: true },
                { fieldName: "proposedByContactName", value: item.proposedByContactName, showText: (!!item.proposedByContactName ? item.proposedByContactName : "-"), header: "Proposed By Contact Name", visible: true, sortable: true },
                { fieldName: "proposedLeasePeriodInMonths", value: item.proposedLeasePeriodInMonths, showText: (!!item.proposedLeasePeriodInMonths ? `${item.proposedLeasePeriodInMonths.toString()} Months` : (!!item.proposedLeasePeriodInWeeks ? `${item.proposedLeasePeriodInWeeks.toString()} Weeks` : "-")), header: "Proposed Lease Period", visible: true, sortable: true },
                { fieldName: "proposedNewRent", value: item.proposedNewRent, showText: (!!item.proposedNewRent ? "$" + item.proposedNewRent.toString() : "-"), header: "Proposed New Rent", visible: true },
                { fieldName: "proposedNewRentPeriod", value: item.proposedNewRentPeriod, showText: (!!item.proposedNewRentPeriod ? this.proposedRentPeriod(item.proposedNewRentPeriod) : "-"), header: "Proposed New Rent Period", visible: true, sortable: true },
                { fieldName: "waterUsageCondition", value: item.waterUsageCondition, showText: this.waterChargesPM(item.waterUsageCondition), header: "Water Usage", visible: true, sortable: true },
                { fieldName: "comment", value: item.comment, showText: (!!item.comment ? item.comment : "-"), header: "Comment", visible: true, sortable: true },
                { fieldName: "dateTimeLocal", value: item.dateTimeLocal, showText: this._datePipe.transform(item.dateTimeLocal, "dd/MM/yyyy hh:mm a"), header: "Date", visible: true, sortable: true },
              ]);
              lr.showLog = true;
              lr.showLogLoader = false;
            }
            else {
              this.openDialog("Error", data.message, "alertdialog");
              Observable.throwError(data.message);
            }
          }),
        error => {
          this.openDialog("Alert", error, "alertdialog");
        };
    }
    else {
      lr.showLog = !lr.showLog;
    }

  }
  //#endregion

  //#region Functions
  waterChargesPM(waterUsageCondition: number | null): string {
    if (waterUsageCondition == 1) {
      return 'Usage and Supply';
    }
    else if (waterUsageCondition == 2) {
      return 'Supply Only';
    }
    else if (waterUsageCondition == 3) {
      return 'Usage Only';
    }
    else if (waterUsageCondition == 4) {
      return 'No Charge';
    }
    else {
      return '';
    }
  }
  proposedRentPeriod(proposedNewRentPeriod: number): string {
    if (proposedNewRentPeriod == 7)
      return "Weekly";
    else if (proposedNewRentPeriod == 14)
      return "Fortnightly";
    else if (proposedNewRentPeriod == 30)
      return "Monthly";
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('tblNewLeasesCount');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');

  }
  //#endregion
}
