import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";

import { IAppState } from "../../store/store";
import { PropertyManagementService } from "./../propertymanagement.service";
import { LoaderService } from "./../../core/loader/loader.service";
import { IDNamePair, AuthService, ServiceResponse } from "./../../services/authservice";
import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";
import { GetContactSearch,ShowContactSearchPage,CloseContactSearchResultPage} from "./../propertymanagement.action";

import "../../../../../wwwroot/js/scripts.js";
import { IContactsSearchResults } from '../propertymanagement';


declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit,AfterViewInit, OnDestroy {

  private model: FormGroup;
  private dialogRef: any;
  private currentStaffID: string;
  private GetContactSearch_Subscription: Subscription;
  private GetSessionData_Subscription: Subscription;
  constructor(
      private ngRedux: NgRedux<IAppState>,
      private _router: Router,
      private route: ActivatedRoute,
      private _service: PropertyManagementService,
      private _authSercice: AuthService,
      private _loader: LoaderService,
      private snackBar: MatSnackBar,
      private dialog: MatDialog
    ) 
    {
        this.model = new FormGroup({
            name: new FormControl(this.ngRedux.getState().propertymanagement.contacts.search.name),
            mobile: new FormControl(this.ngRedux.getState().propertymanagement.contacts.search.mobile),
            email : new FormControl(this.ngRedux.getState().propertymanagement.contacts.search.email),

        });
    }

  
    openDialog(
      title: string,
      message: string,
      role: "alertdialog" | "dialog" = "dialog"
    ) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.role = role;
      dialogConfig.data = {
        title: title,
        message: message,
        role:
          role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
      };
      dialogConfig.panelClass = "dialog-body";
      this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
      return this.dialogRef;
    }
    private config = {
      duration: 5000,
      verticalPosition: "bottom",
      panelClass: "notifyPanel"
    } as MatSnackBarConfig;


    @select(state=> (state as IAppState).propertymanagement.contacts.search.showSearchResult) showSearchResult$

  ngOnInit() {
      this.GetSessionData_Subscription = 
      this._authSercice.GetSessionData()
      .subscribe(data => {
        this.currentStaffID = data.staffID;
      })
  }

  ngOnDestroy(){
      if(!! this.GetSessionData_Subscription) this.GetSessionData_Subscription.unsubscribe();
      if(!!this.GetContactSearch_Subscription) this.GetContactSearch_Subscription.unsubscribe();
  }

  ngAfterViewInit(){
    LoadScript.init();
  }

  onSubmit(){
      this._loader.show()
      this.GetContactSearch_Subscription =
      this._service.GetContactSearchDetails
      (
        this.model.controls.name.value,
        this.model.controls.mobile.value,
        this.model.controls.email.value,
        this.currentStaffID
      )
      .subscribe((data: ServiceResponse<IContactsSearchResults[]>) =>{
        this._loader.hide()
        if(data.isError){
          this.openDialog("Error", data.message, "alertdialog");
        }
        else{
          let action = new GetContactSearch(data.response)
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this._router.navigate(["/contacts/search"]);
        }
      },
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      });
  }

}
