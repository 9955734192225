import { Injectable, Inject, PLATFORM_ID, Injector, OnDestroy } from "@angular/core";
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BaseService } from '../services/baseservice';
import { 
    IDashboardItem, 
    IPropertiesTenantsInArrearsDetails, 
    IPropertiesLeaseExpiryOverdueDetails, 
    IPropertiesVacantDetails, 
    IPropertiesGainedDetails, 
    IPropertiesLostDetails, 
    IPropertiesTenantInvoicesOverdueWithinDaysDetails, 
    IPropertiesPeriodicLeasesDetails, 
    IPropertiesLeasedDaysDetails,
    IPropertiesLeaseRenewedDaysDetails,
    IPropertiesUnderManagementDetails,
    IPropertiesInspectionOverdueDetails,
    IPropertiesInspectionDueDetails,
    IPropertiesTenantVacatingWithinDaysDetails,
    IPropertiesOutstandingWorkOrderDaysDetails,
    IPropertiesAgreementRenewalDueDetails,
    IPropertiesAgreementExpiredDetails
} from './home';
import { NgRedux, select } from "@angular-redux/store";

import {
    GetPropertiesUnderManagement,
    GetPropertiesGained,
    GetPropertiesLost,
    GetPropertiesAgreementRenewalDue,
    GetPropertiesPeriodicLeases,
    GetPropertiesAgreementExpired,
    GetPropertiesAverageWeeklyRent,
    GetPropertiesVacantGraterThan14,
    GetPropertiesVacantLessThan14,
    GetPropertiesOutstandingWorkOrder3To14Days,
    GetPropertiesOutstandingWorkOrderGraterThan14Days,
    GetPropertiesLeaseRenewedDays,
    GetPropertiesLeasedDays,
    GetPropertiesTenantInvoicesOverdueWithin2To14Days,
    GetPropertiesTenantInvoicesOverdueGreaterThan14Days,
    GetPropertiesLeaseExpiryOverdue,
    GetPropertiesLeaseExpiryDueWithinDays,
    GetPropertiesTenantVacatingWithinDays,
    GetPropertiesInspectionOverdue,
    GetPropertiesInspectionDueWithin7Days,
    GetPropertiesInspectionDueWithin30Days,
    GetPropertiesTenantsInArrearsGraterThan3,
    GetPropertiesTenantsInArrearsGraterThan10,
    GetPropertiesAverageCommissionPercent
} from './home.action';
import { IAppState } from '../store/store';
import { LoaderService } from '../core/loader/loader.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { enDialogRole, DialogBodyComponent } from '../dialog-body/dialog-body.component';
import {
    Http,
    Headers,
    Response,
    RequestOptions,
    RequestMethod
  } from "@angular/http";


@Injectable()
export class HomeService extends BaseService implements OnDestroy {
    dialogRef: any;
    

    constructor(
        private _http: HttpClient,
        @Inject(PLATFORM_ID) private platformID: Object,
        private ngRedux: NgRedux<IAppState>,
        private _loader: LoaderService,
        private dialog: MatDialog) {
        super(_http, platformID);
    }

    openDialog(
        title: string,
        message: string,
        role: "alertdialog" | "dialog" = "dialog"
    ) {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.role = role;
        dialogConfig.data = {
            title: title,
            message: message,
            role:
                role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
        };
        dialogConfig.panelClass = "dialog-body";
        this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
        return this.dialogRef;
    }

    //#region Dashboard
    GetPropertiesUnderManagement
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesUnderManagement", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesGained
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesGained", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesLost
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesLost", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesAgreementRenewalDue
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesAgreementRenewalDue", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesVacantLessThan14
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesVacant", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }
    GetPropertiesVacantGraterThan14
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesVacant", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 14
        });
    }
    GetPropertiesAgreementExpired
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesAgreementExpired", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesPeriodicLeases
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesPeriodicLeases", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesAverageWeeklyRent
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesAverageWeeklyRent", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesOutstandingWorkOrder3To14Days
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesOutstandingWorkOrderDays", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesOutstandingWorkOrderGraterThan14Days
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesOutstandingWorkOrderDays", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 14
        });
    }

    GetPropertiesLeaseRenewedDays
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesLeaseRenewedDays", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesLeasedDays
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesLeasedDays", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesTenantInvoicesOverdueWithin2To14Days
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesTenantInvoicesOverdueWithinDays", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesTenantInvoicesOverdueGreaterThan14Days
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesTenantInvoicesOverdueWithinDays", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 14
        });
    }

    GetPropertiesLeaseExpiryOverdue
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesLeaseExpiryOverdue", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesLeaseExpiryDueWithinDays
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesLeaseExpiryDueWithinDays", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesTenantVacatingWithinDays
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesTenantVacatingWithinDays", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesInspectionOverdue
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesInspectionOverdue", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesInspectionDueWithin7Days
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesInspectionDue", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 7
        })
    }

    GetPropertiesInspectionDueWithin30Days
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesInspectionDue", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: null
        });
    }

    GetPropertiesTenantsInArrearsGraterThan3
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesTenantsInArrears", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    GetPropertiesTenantsInArrearsGraterThan10
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesTenantsInArrears", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 10
        });
    }

    GetPropertiesAverageCommissionPercent
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        return this.GetAll<IDashboardItem>("api/Home/GetPropertiesAverageCommissionPercent", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }

    //#region Dashboard Child Details
    GetPropertiesTenantsInArrearsGraterThan3Details
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesTenantsInArrearsDetails[]> {
        return this.GetAll<IPropertiesTenantsInArrearsDetails[]>("api/Home/GetPropertiesTenantsInArrearsDetails", { 
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 3
        });
    }
    GetPropertiesTenantsInArrearsGraterThan10Details
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesTenantsInArrearsDetails[]> {
        return this.GetAll<IPropertiesTenantsInArrearsDetails[]>("api/Home/GetPropertiesTenantsInArrearsDetails", { 
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 10 
        });
    }
    GetPropertiesLeaseExpiryOverdueDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesLeaseExpiryOverdueDetails[]> {
        return this.GetAll<IPropertiesLeaseExpiryOverdueDetails[]>("api/Home/GetPropertiesLeaseExpiryOverdueDetails", { 
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }
    GetPropertiesLeaseExpiryDueWithinDaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesLeaseExpiryOverdueDetails[]> {
        return this.GetAll<IPropertiesLeaseExpiryOverdueDetails[]>("api/Home/GetPropertiesLeaseExpiryDueWithinDaysDetails", { 
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }
    GetPropertiesVacantLessThanEqual14DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesVacantDetails[]> {
        return this.GetAll<IPropertiesVacantDetails[]>("api/Home/GetPropertiesVacantDetails", { 
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 14,
            daysAfter: false 
        });
    }
    GetPropertiesVacantGraterThan14DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesVacantDetails[]> {
        return this.GetAll<IPropertiesVacantDetails[]>("api/Home/GetPropertiesVacantDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 14,
            daysAfter: true 
        });
    }
    GetPropertiesGainedLast6MonthsDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesGainedDetails[]> {
        return this.GetAll<IPropertiesGainedDetails[]>("api/Home/GetPropertiesGainedDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }
    GetPropertiesLostLast6MonthsDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesLostDetails[]> {
        return this.GetAll<IPropertiesLostDetails[]>("api/Home/GetPropertiesLostDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }
    GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesTenantInvoicesOverdueWithinDaysDetails[]> {
        return this.GetAll<IPropertiesTenantInvoicesOverdueWithinDaysDetails[]>("api/Home/GetPropertiesTenantInvoicesOverdueWithinDaysDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            fromDays: 2,
            toDays: 14
        });
    }
    GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesTenantInvoicesOverdueWithinDaysDetails[]> {
        return this.GetAll<IPropertiesTenantInvoicesOverdueWithinDaysDetails[]>("api/Home/GetPropertiesTenantInvoicesOverdueWithinDaysDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 14
        });
    }
    GetPropertiesPeriodicLeasesDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesPeriodicLeasesDetails[]> {
        return this.GetAll<IPropertiesPeriodicLeasesDetails[]>("api/Home/GetPropertiesPeriodicLeasesDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }
    GetPropertiesLeasedLast30DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesLeasedDaysDetails[]> {
        return this.GetAll<IPropertiesLeasedDaysDetails[]>("api/Home/GetPropertiesLeasedDaysDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 30 
        });
    }
    GetPropertiesLeaseRenewedLast30DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesLeaseRenewedDaysDetails[]> {
        return this.GetAll<IPropertiesLeaseRenewedDaysDetails[]>("api/Home/GetPropertiesLeaseRenewedDaysDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 30 
        });
    }
    GetPropertiesUnderManagementDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesUnderManagementDetails[]> {
        return this.GetAll<IPropertiesUnderManagementDetails[]>("api/Home/GetPropertiesUnderManagementDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 30 
        });
    }
    GetPropertiesInspectionOverdueDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesInspectionOverdueDetails[]> {
        return this.GetAll<IPropertiesInspectionOverdueDetails[]>("api/Home/GetPropertiesInspectionOverdueDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }
    GetPropertiesInspectionDueWithin7DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesInspectionDueDetails[]> {
        return this.GetAll<IPropertiesInspectionDueDetails[]>("api/Home/GetPropertiesInspectionDueDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 7 
        });
    }
    GetPropertiesInspectionDueWithin30DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesInspectionDueDetails[]> {
        return this.GetAll<IPropertiesInspectionDueDetails[]>("api/Home/GetPropertiesInspectionDueDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 30 
        });
    }
    GetPropertiesTenantVacatingWithin30DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesTenantVacatingWithinDaysDetails[]> {
        return this.GetAll<IPropertiesTenantVacatingWithinDaysDetails[]>("api/Home/GetPropertiesTenantVacatingWithinDaysDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            fromDays: 0,
            toDays: 30
        });
    }
    GetPropertiesOutstandingWorkOrderDue3To14DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesOutstandingWorkOrderDaysDetails[]> {
        return this.GetAll<IPropertiesOutstandingWorkOrderDaysDetails[]>("api/Home/GetPropertiesOutstandingWorkOrderDaysDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            fromDays: -14,
            toDays: -3
        });
    }
    GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesOutstandingWorkOrderDaysDetails[]> {
        return this.GetAll<IPropertiesOutstandingWorkOrderDaysDetails[]>("api/Home/GetPropertiesOutstandingWorkOrderDaysDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            days: 14 
        });
    }
    GetPropertiesAgreementRenewalDueWithin90DaysDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesAgreementRenewalDueDetails[]> {
        return this.GetAll<IPropertiesAgreementRenewalDueDetails[]>("api/Home/GetPropertiesAgreementRenewalDueDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID,
            months: 3 
        });
    }
    GetPropertiesAgreementExpiredDetails
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ): Observable<IPropertiesAgreementExpiredDetails[]> {
        return this.GetAll<IPropertiesAgreementExpiredDetails[]>("api/Home/GetPropertiesAgreementExpiredDetails", {
            connectionStringName: connectionStringName,
            sessionID: sessionID,
            agencyID: agencyID,
            departmentID: departmentID,
            staffID: staffID
        });
    }
    //#endregion

    //#endregion

    //#region Subscriptions of Dashboard Statistics
    private subcription_GetPropertiesUnderManagement: Subscription;
    private subcription_GetPropertiesGained: Subscription;
    private subcription_GetPropertiesLost: Subscription;
    private subcription_GetPropertiesAgreementRenewalDue: Subscription;
    private subcription_GetPropertiesVacantLessThan14: Subscription;
    private subcription_GetPropertiesVacantGraterThan14: Subscription;
    private subcription_GetPropertiesAgreementExpired: Subscription;
    private subcription_GetPropertiesPeriodicLeases: Subscription;
    private subcription_GetPropertiesAverageWeeklyRent: Subscription;



    private subcription_GetPropertiesOutstandingWorkOrder3To14Days: Subscription;
    private subcription_GetPropertiesOutstandingWorkOrderGraterThan14Days: Subscription;
    private subcription_GetPropertiesLeaseRenewedDays: Subscription;
    private subcription_GetPropertiesLeasedDays: Subscription;
    private subcription_GetPropertiesTenantInvoicesOverdueWithin2To14Days: Subscription;
    private subcription_GetPropertiesTenantInvoicesOverdueGreaterThan14Days: Subscription;
    private subcription_GetPropertiesLeaseExpiryOverdue: Subscription;
    private subcription_GetPropertiesLeaseExpiryDueWithinDays: Subscription;
    private subcription_GetPropertiesTenantVacatingWithinDays: Subscription;
    private subcription_GetPropertiesInspectionOverdue: Subscription;
    private subcription_GetPropertiesInspectionDueWithin7Days: Subscription;
    private subcription_GetPropertiesInspectionDueWithin30Days: Subscription;
    private subcription_GetPropertiesTenantsInArrearsGraterThan3: Subscription;
    private subcription_GetPropertiesTenantsInArrearsGraterThan10: Subscription;
    private subcription_GetPropertiesAverageCommissionPercent: Subscription;
    
    /**
     * Reload all the dashboard statistics
     */
    ReloadDashboard
    (
        connectionStringName: string = null,
        sessionID: string = null,
        agencyID: string = null,
        departmentID: string = null,
        staffID: string = null
    ) {
        this._loader.show();
        this.subcription_GetPropertiesUnderManagement =
            this.GetPropertiesUnderManagement
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesUnderManagement(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesGained =
            this.GetPropertiesGained
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesGained(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesLost =
            this.GetPropertiesLost
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesLost(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesAgreementRenewalDue =
            this.GetPropertiesAgreementRenewalDue
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesAgreementRenewalDue(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesVacantLessThan14 =
            this.GetPropertiesVacantLessThan14
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesVacantLessThan14(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };

        this._loader.show();
        this.subcription_GetPropertiesVacantGraterThan14 =
            this.GetPropertiesVacantGraterThan14
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesVacantGraterThan14(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesPeriodicLeases =
            this.GetPropertiesPeriodicLeases
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesPeriodicLeases(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesAgreementExpired =
            this.GetPropertiesAgreementExpired
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesAgreementExpired(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesAverageWeeklyRent =
            this.GetPropertiesAverageWeeklyRent
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesAverageWeeklyRent(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };

        this._loader.show();
        this.subcription_GetPropertiesOutstandingWorkOrder3To14Days =
            this.GetPropertiesOutstandingWorkOrder3To14Days
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesOutstandingWorkOrder3To14Days(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };

        this._loader.show();
        this.subcription_GetPropertiesOutstandingWorkOrderGraterThan14Days =
            this.GetPropertiesOutstandingWorkOrderGraterThan14Days
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesOutstandingWorkOrderGraterThan14Days(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };

        this._loader.show();
        this.subcription_GetPropertiesLeaseRenewedDays =
            this.GetPropertiesLeaseRenewedDays
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesLeaseRenewedDays(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesLeasedDays =
            this.GetPropertiesLeasedDays
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesLeasedDays(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesTenantInvoicesOverdueWithin2To14Days =
            this.GetPropertiesTenantInvoicesOverdueWithin2To14Days
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesTenantInvoicesOverdueWithin2To14Days(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };

        this._loader.show();
        this.subcription_GetPropertiesTenantInvoicesOverdueGreaterThan14Days =
            this.GetPropertiesTenantInvoicesOverdueGreaterThan14Days
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesTenantInvoicesOverdueGreaterThan14Days(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesLeaseExpiryOverdue =
            this.GetPropertiesLeaseExpiryOverdue
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesLeaseExpiryOverdue(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesLeaseExpiryDueWithinDays =
            this.GetPropertiesLeaseExpiryDueWithinDays
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesLeaseExpiryDueWithinDays(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesTenantVacatingWithinDays =
            this.GetPropertiesTenantVacatingWithinDays
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesTenantVacatingWithinDays(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesInspectionOverdue =
            this.GetPropertiesInspectionOverdue
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesInspectionOverdue(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesInspectionDueWithin7Days =
            this.GetPropertiesInspectionDueWithin7Days
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesInspectionDueWithin7Days(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesInspectionDueWithin30Days =
            this.GetPropertiesInspectionDueWithin30Days
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesInspectionDueWithin30Days(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesTenantsInArrearsGraterThan3 =
            this.GetPropertiesTenantsInArrearsGraterThan3
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesTenantsInArrearsGraterThan3(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesTenantsInArrearsGraterThan10 =
            this.GetPropertiesTenantsInArrearsGraterThan10
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesTenantsInArrearsGraterThan10(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
        this._loader.show();
        this.subcription_GetPropertiesAverageCommissionPercent =
            this.GetPropertiesAverageCommissionPercent
                (
                    connectionStringName,
                    sessionID,
                    agencyID,
                    departmentID,
                    staffID
                )
                .map((data: IDashboardItem) => {
                    return new GetPropertiesAverageCommissionPercent(data);
                })
                .subscribe(action => {
                    this._loader.hide();
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }),
            error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
            };
    }

    ngOnDestroy() {
        if (!!this.subcription_GetPropertiesUnderManagement) this.subcription_GetPropertiesUnderManagement.unsubscribe();
        if (!!this.subcription_GetPropertiesGained) this.subcription_GetPropertiesGained.unsubscribe();
        if (!!this.subcription_GetPropertiesLost) this.subcription_GetPropertiesLost.unsubscribe();
        if (!!this.subcription_GetPropertiesAgreementRenewalDue) this.subcription_GetPropertiesAgreementRenewalDue.unsubscribe();
        if (!!this.subcription_GetPropertiesVacantLessThan14) this.subcription_GetPropertiesVacantLessThan14.unsubscribe();
        if (!!this.subcription_GetPropertiesPeriodicLeases) this.subcription_GetPropertiesPeriodicLeases.unsubscribe();
        if (!!this.subcription_GetPropertiesAgreementExpired) this.subcription_GetPropertiesAgreementExpired.unsubscribe();
        if (!!this.subcription_GetPropertiesAverageWeeklyRent) this.subcription_GetPropertiesAverageWeeklyRent.unsubscribe();

        if (!!this.subcription_GetPropertiesOutstandingWorkOrder3To14Days) this.subcription_GetPropertiesOutstandingWorkOrder3To14Days.unsubscribe();
        if (!!this.subcription_GetPropertiesOutstandingWorkOrderGraterThan14Days) this.subcription_GetPropertiesOutstandingWorkOrderGraterThan14Days.unsubscribe();
        if (!!this.subcription_GetPropertiesLeaseRenewedDays) this.subcription_GetPropertiesLeaseRenewedDays.unsubscribe();
        if (!!this.subcription_GetPropertiesLeasedDays) this.subcription_GetPropertiesLeasedDays.unsubscribe();
        if (!!this.subcription_GetPropertiesAverageWeeklyRent) this.subcription_GetPropertiesAverageWeeklyRent.unsubscribe();
        if (!!this.subcription_GetPropertiesTenantInvoicesOverdueWithin2To14Days) this.subcription_GetPropertiesTenantInvoicesOverdueWithin2To14Days.unsubscribe();
        if (!!this.subcription_GetPropertiesTenantInvoicesOverdueGreaterThan14Days) this.subcription_GetPropertiesTenantInvoicesOverdueGreaterThan14Days.unsubscribe();
        if (!!this.subcription_GetPropertiesLeaseExpiryOverdue) this.subcription_GetPropertiesLeaseExpiryOverdue.unsubscribe();
        if (!!this.subcription_GetPropertiesLeaseExpiryDueWithinDays) this.subcription_GetPropertiesLeaseExpiryDueWithinDays.unsubscribe();
        if (!!this.subcription_GetPropertiesTenantVacatingWithinDays) this.subcription_GetPropertiesTenantVacatingWithinDays.unsubscribe();
        if (!!this.subcription_GetPropertiesInspectionOverdue) this.subcription_GetPropertiesInspectionOverdue.unsubscribe();
        if (!!this.subcription_GetPropertiesInspectionDueWithin7Days) this.subcription_GetPropertiesInspectionDueWithin7Days.unsubscribe();
        if (!!this.subcription_GetPropertiesInspectionDueWithin30Days) this.subcription_GetPropertiesInspectionDueWithin30Days.unsubscribe();
        if (!!this.subcription_GetPropertiesTenantsInArrearsGraterThan3) this.subcription_GetPropertiesTenantsInArrearsGraterThan3.unsubscribe();
        if (!!this.subcription_GetPropertiesTenantsInArrearsGraterThan10) this.subcription_GetPropertiesTenantsInArrearsGraterThan10.unsubscribe();
        if (!!this.subcription_GetPropertiesAverageCommissionPercent) this.subcription_GetPropertiesAverageCommissionPercent.unsubscribe();
    }
    //#endregion

}
