import { IDNamePair } from "../services/authservice";

export interface ISearchTemplate {
    templateName: string;
    selectedRecipientTypes: number[];
    recipientTypes: IRecipientType[];
    templates: IEmailTemplate[];
    showSearchResult: boolean;
}
export interface IRecipientType {
    id: number;
    name: string;
    communicationReasons: ICommunicationReason[];
}
export interface ICommunicationReason {
    id: number;
    name: string;
    isTransactional: boolean;
    recipientTypeID: number;
    recipientTypeName: string;
}
export interface IEmailTemplate {
    id: string;
    agencyID: string;
    name: string;
    description: string;
    subject: string;
    ownerType: enEmailTemplateOwnerType;
    ownerName: string;
    ownerID: string | null;
    /*communicationReasonID: number;
    communicationReasonName: string;
    communicationReasonIsTransactional: boolean;
    communicationReasonRecipientTypeID: number;
    communicationReasonRecipientTypeName: string;*/
    recipientSources: enRecipientSource;
    thumbnailImageID: string | null;
    thumbnailImageURL: string;
    htmlBody: string;
    canCopy: boolean;
    canEdit: boolean;
    canDelete: boolean;
    saveTemplateStatus: boolean;
    widgetTypes: IWidgetType[];
    widgets: IWidget[];
}
export interface IWidgetType {
    id: string;
    name: string;
    description: string;
    iconImageID: string | null;
    iconImageURL: string;
}
export interface IWidget {
    id: string;
    settings: IWidgetSetting[];
    widgetType: number;
    templateHTML: string;
}
export interface IWidgetSetting {
    dataType: enWidgetSettingDataType;
    id: string;
    value: string;
    widgetTypeSettingID: number;
}
export interface IEmailImage {
    id: string;
    imageID: string;
    templateID: string;
    name: string;
    caption: string;
    width: number;
    height: number;
    url: string;
    imageUrl: string;
}
export interface IAddEditTemplate {
    name: string;
    selectedRecipientType: IRecipientType;
    selectedWidgetType: IWidgetType;
    selectedWidgetID: string;
    //selectedReason: number;
    selectedAgency: string | null;
    selectedDepartment: string | null;
    selectedStaff: string | null;
    recipientTypes: IRecipientType[];
    agencies: IDNamePair<string>[];
    departments: IDNamePair<string>[];
    staffs: IDNamePair<string>[];
    availableFor: string;
    template: IEmailTemplate;
    selectedTemplateField: string;
    selectedGetImagesFor: number;
    templateImages: IEmailImage[];
    selectedTemplateImage: IEmailImage;
    showEditPage: boolean;
    showNewPage: boolean;
    templateImageTypes: IDNamePair<number>[];
    templateEmailImages: IEmailImage[];
}
export interface IEmailTemplateField {
    id: string;
    name: string;
    parentTemplateFieldID: string;
    isField: boolean;
    isList: boolean;
    description: string;
    fieldName: string;
    fieldTypeID: string;
    hasChildren: boolean;
}
export interface IRemoveTemplateImageResponse {
    templateImageRemoved: boolean;
    templateImages: IEmailImage[];
}
//#region Emails
export interface ISearchEmail {
    subject: string;
    selectedStatuses: number[];
    selectedCreatedBys: string[];
    selectedSentBys: string[];
    maxResultsToReturn: number;
    emailMarker: string;
    staffs: IDNamePair<string>[];
    statuses: IDNamePair<number>[];
    emails: IEmail[];
    showSearchResult: boolean;
}
export interface IAddEditEmail {
    id: string;
    subject: string;
    textBody: string;
    htmlBody: string;
    widgets: IWidget[];
    selectedRecipientSourceType: number;
    selectedRecipientType: number;
    selectedTemplate: string;
    selectedWidgetType: number;
    selectedWidgetID: string;
    selectedContactList: string;
    selectedRecipientList: IContactEmail[];
    recipientSourceTypes: IDNamePair<number>[];
    recipientTypes: IRecipientType[];
    templates: IEmailTemplate[];
    contactLists: IEmailContactList[];
    recipientList: IContactEmail[];
    //isNew: boolean;
    from: string;
    to: string[];
    attachments: IEmailAttachment[];
    widgetTypes: IWidgetType[];
    //step: number;
    previewToEmails: string;
    usingRecipientContactID: string;
    scheduledDatetime: Date | null;
    showEditPage: boolean;
    showPreviewPage: boolean;
    showNewPage: boolean;
}
export interface IEmail {
    id: string;
    agencyID: string;
    subject: string;
    statusID: number;
    statusName: string;
    createdByID: string;
    createdByName: string;
    creationDate: Date;
    sentByID: string;
    sentByName: string;
    sentDate: Date | null;
    emailMarker: string;
    attachments: IEmailAttachment[];
    htmlBody: string;
    numberOfRecipients: number;
    textBody: string;
    widgets: IWidget[];
    widgetTypes: IWidgetType[];
}
export interface IEmailAttachment {
    id: string;
    name: string;
    size: number;
    url: string;
    isImage: boolean;
}
export interface IContactEmail {
    id: string;
    name: string;
    emailAddress: string;
    rowNumber: number;
}
export interface IEmailContactList {
    id: string;
    name: string;
    description: string;
    autoSubscribeNewContacts: boolean;
    status: number;
    statusText: string;
    filter: IContactFilterRuleSet;
    subscribed: boolean;
}
export interface IFilterItem {
    id: string;
}
export interface IContactFilterRuleSet extends IFilterItem {
    condition: enRuleSetCondition;
    items: IFilterItem[];
}
export interface IContactFilterRule extends IFilterItem {
    contactFilterFieldID: number;
    _operator: enRuleOperator;
    value: string;
}
export interface IEmailSendPreviewEmailResponse {
    sendResponseID: string;
    sendStatusCode: enHttpStatusCode;
    sendReasonPhrase: string;
    totalAcceptedRecipients: number;
    totalRejectedRecipients: number;
    sendResponseContent: string;
}
export interface IEmailSendEmailResponse {
    status: enEmailStatus;
    totalRecipients: number;
}
//#endregion

//#region Contact Lists
export interface ISearchContactLists {
    statuses: IDNamePair<number>[];
    contactLists: IEmailContactList[];
    showSearchResult: boolean;
}
export interface IAddEditContactLists {
    contactListID: string;
    name: string;
    description: string;
    autoSubscribeNewContacts: boolean;
    status: number;
    contactListCreated: boolean;
    contactListUpdated: boolean;
    filter: IContactFilterRuleSet;
    statuses: IDNamePair<number>[];
    contactLists: IEmailContactList[];
    showAddEditPage: boolean;
}
export interface IContactFilterField {
    id: number;
    name: string;
    options: IContactFilterFieldOption[];
    type: enContactFilterFieldType;
}
export interface IContactFilterFieldOption {
    name: string;
    value: string;    
}
//#endregion

//#region Enums
export enum enEmailTemplateOwnerType {
    unspecified = 0,
    system,
    agency,
    department,
    staff
}
export enum enRecipientSource {
    unknown = 0,
    contact = 1,
    buyer = 2,
    staff = 4,
    property = 8,
    appraisal = 16,
    listing = 32,
    contract = 64,
    suppliers = 128,
    owners = 256,
    tenants = 512,
    managementAgreements = 1024,
    contactList = 2048,
    listingEnquiry = 4096,
    generalEnquiry = 8192,
    appraisalRequest = 16384,
}
export enum enWidgetSettingDataType {
    unknown = 0,
    string = 1,
    number = 2,
    boolean = 3,
    date = 4,
    listingID = 20,
    contactID = 30,
    staffID = 40,
    agencyID = 50,
    departmentID = 60,
}
export enum enRuleSetCondition {
    unknown = -1,
    and = 0,
    or = 1,
}
export enum enRuleOperator {
    unknown = 0,
    equals = 1,
    notEquals = 2,
    greaterThan = 3,
    greaterThanOrEqualTo = 4,
    lessThan = 5,
    lessThanOrEqualTo = 6,
    contains = 7,
    like = 8,
    in = 9,
    notIn = 10,
    isNull = 11,
    isNotNull = 12,
}
export enum enHttpStatusCode {
    continue = 100,
    switchingProtocols = 101,
    ok = 200,
    created = 201,
    accepted = 202,
    nonAuthoritativeInformation = 203,
    noContent = 204,
    resetContent = 205,
    partialContent = 206,
    multipleChoices = 300,
    ambiguous = 300,
    movedPermanently = 301,
    moved = 301,
    found = 302,
    redirect = 302,
    seeOther = 303,
    redirectMethod = 303,
    notModified = 304,
    useProxy = 305,
    unused = 306,
    temporaryRedirect = 307,
    redirectKeepVerb = 307,
    badRequest = 400,
    unauthorized = 401,
    paymentRequired = 402,
    forbidden = 403,
    notFound = 404,
    methodNotAllowed = 405,
    notAcceptable = 406,
    proxyAuthenticationRequired = 407,
    requestTimeout = 408,
    conflict = 409,
    gone = 410,
    lengthRequired = 411,
    preconditionFailed = 412,
    requestEntityTooLarge = 413,
    requestUriTooLong = 414,
    unsupportedMediaType = 415,
    requestedRangeNotSatisfiable = 416,
    expectationFailed = 417,
    upgradeRequired = 426,
    internalServerError = 500,
    notImplemented = 501,
    badGateway = 502,
    serviceUnavailable = 503,
    gatewayTimeout = 504,
    httpVersionNotSupported = 505,
}
export enum enEmailStatus {
    unknown = 0,
    draft = 1,
    scheduled = 2,
    sending = 3,
    sent = 4,
    deleted = 5,
    archived = 6,
}
export enum enContactFilterFieldType {
    unknown = 0,
    string = 1,
    number = 2,
    boolean = 3,
    date = 4,
    category = 5,
}
//#endregion
