import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { IAppState } from "../../../store/store";
import { AuthService } from "../../../services/authservice";
import { EmarketingService } from "./../../emarketing.service";
import { LoaderService } from "./../../../core/loader/loader.service";
import { LoadNewEmailPage, CloseNewEmailPage, GetRecipientTypesOnNewEmailPage, GetEmailTemplatesOnNewEmailPage, AfterNewEmailCreationGotoEmailEditPage, SelectEmailTemplateOnNewEmailPage } from "./../../emarketing.action";
import "../../../../../../wwwroot/js/scripts.js";
import { IEmailContactList, IRecipientType, IEmailTemplate, IContactEmail, IEmail } from '../../emarketing';
import { DialogBodyComponent, enDialogRole } from "./../../../dialog-body/dialog-body.component";
import { EditEmailComponent } from './../emailsearchresult/editemail/editemail.component';
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'newemail',
  templateUrl: './newemail.component.html',
  styleUrls: ['./newemail.component.css']
})
export class NewEmailComponent implements OnInit, AfterViewInit {

  private model: FormGroup;
  private step: number;
  private dialogRef: any;
  private emailID: string = null;
  private loginUserEmail: string;

  @ViewChild(EditEmailComponent) childEditEmail: EditEmailComponent;

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    private _eMarketingService: EmarketingService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.model = new FormGroup({
      selectedRecipientSourceType: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.selectedRecipientSourceType, Validators.required),
      selectedRecipientType: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.selectedRecipientType, Validators.required),
      selectedContactList: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.selectedContactList, Validators.required),
      selectedTemplate: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.selectedTemplate, Validators.required),
      from: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.from, Validators.required),
      to: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.to, Validators.required),
    });
  }

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  @select(state => (state as IAppState).emarketing.email.addEditView.showNewPage) showNewPage$;
  @select(state => (state as IAppState).emarketing.email.addEditView.recipientSourceTypes) recipientSourceTypes$;
  @select(state => (state as IAppState).emarketing.email.addEditView.recipientTypes) recipientTypes$;
  @select(state => (state as IAppState).emarketing.email.addEditView.contactLists) contactLists$;
  @select(state => (state as IAppState).emarketing.email.addEditView.templates) templates$;

  private get selectedRecipientTypeName(): string {
    let recipientTypeName: string = "";
    let recipientTypes = this.ngRedux.getState().emarketing.email.addEditView.recipientTypes;
    if ((!!recipientTypes && !!recipientTypes.length) && (!!this.model.controls.selectedRecipientType && !!this.model.controls.selectedRecipientType.value)) {
      let rt = recipientTypes.filter(rt => rt.id == this.model.controls.selectedRecipientType.value);
      if (!!rt && !!rt.length) {
        recipientTypeName = rt[0].name;
      }
    }
    return recipientTypeName;
  }
  private partOfString(value: string, length: number = 20): string {
    let result: string = !!value && !!value.length ? value : "...";
    if (!!value && value.length > length) {
      result = value.substring(0, length - 1) + "...";
    }
    return result;
  }
  private templateImageUrlExceptSize(url: string): string {
    let result = !!url && !!url.length ? url : "";
    if (!!url && !!url.length) {
      let parts = url.split(";width=");
      if (!!parts && !!parts.length) {
        result = parts[0];
      }
    }
    return result;
  }

  ngOnInit() {
    this.step = 1;
    //this._loader.show();
    //this._service.GetRecipientSourceTypes()
    //.subscribe(rsts => {
    //  if (!!rsts && !!rsts.length) {
    //    this._service.GetRecipientTypes([rsts[0].id])
    //    .subscribe(rts => {
    //      this._service.GetContactLists()
    //      .map((contacts: IEmailContactList[]) => {
    //        return new LoadNewEmailPage({ recipientSourceTypes: rsts, recipientTypes: rts, contactLists: contacts, selectedRecipientSourceType: rsts[0].id });
    //      })
    //      .subscribe(action => {
    //        this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    //        this._loader.hide();
    //      });
    //    });
    //  }
    //});
    this._authService.GetSessionData().subscribe(email => {
      if (!!email) {
        this.loginUserEmail = email.email;
      }
    });
    let recipientTypes = this.ngRedux.getState().emarketing.email.addEditView.recipientTypes;
    if (!!recipientTypes && !!recipientTypes.length) {
      this.model.controls.selectedRecipientType.setValue(recipientTypes[0].id);
      this.onRecipientTypeChange(null);
    }
  }
  ngAfterViewInit() {
    LoadScript.init();
  }

  onRecipientSourceTypeChange(event: any) {
    if (!!this.model.controls.selectedRecipientSourceType && !!this.model.controls.selectedRecipientSourceType.value) {
      this._loader.show();
      this._eMarketingService.GetRecipientTypes([this.model.controls.selectedRecipientSourceType.value])
        .map((rts: IRecipientType[]) => {
          return new GetRecipientTypesOnNewEmailPage(rts);
        })
        .subscribe(action => {
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this._loader.hide();
        });
    }
  }
  onRecipientTypeChange(event: any) {
    if (!!this.model.controls.selectedRecipientType && !!this.model.controls.selectedRecipientType.value) {
      this._loader.show();
      this._eMarketingService.GetEmailTemplates(/*this.model.controls.selectedRecipientType.value,*/ "", 0)
        .map((templates: IEmailTemplate[]) => {
          return new GetEmailTemplatesOnNewEmailPage((!!templates && !!templates.length) ? templates : []);
        })
        .subscribe(action => {
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this._loader.hide();
        });
    }
  }
  onTemplateChange(template: IEmailTemplate, event: any) {
    this.model.controls.selectedTemplate.setValue(template.id);
    let action = new SelectEmailTemplateOnNewEmailPage(template.id);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    console.log(this.model.controls.selectedTemplate.value);
    if (!!event.target.closest(".templateThumbnail")) {
      document.querySelectorAll(".templateThumbnail").forEach(element => { element.classList.remove("active"); });
      event.target.closest(".templateThumbnail").classList.add("active");
    }
  }
  onNext() {
      switch (this.step) {
        case 1:
          if (this.isNew) {
            this.submit();            
          }
          else {            
          }
          break;
        case 2:
          if (!this.isNew) {
            if (this.childEditEmail.onNext()) {
              ++this.step;
            }
          }
          else {            
            ++this.step;
          }
          break;
        case 3:
          ++this.step;
          break;
        case 4:
          break;
      }

  }
  onPrevious() {

      switch (this.step) {
        case 1:
          break;
        case 2:
          --this.step;
          break;
        case 3:
          --this.step;
          break;
        case 4:
          --this.step;
          break;
      }
  }
  submit() {
    if (this.step === 1) {
      if (!(!!this.model.controls.selectedRecipientSourceType && !!this.model.controls.selectedRecipientSourceType.value)) {
        this.openDialog("Alert", "Please select Recipient Source Type!!", "alertdialog");
        return false;
      }
      if (!(!!this.model.controls.selectedRecipientType && !!this.model.controls.selectedRecipientType.value)) {
        this.openDialog("Alert", "Please select Recipient Type!!", "alertdialog");
        return false;
      }
      if (!(!!this.model.controls.selectedTemplate && !!this.model.controls.selectedTemplate.value)) {
        this.openDialog("Alert", "Please select Template!!", "alertdialog");
        return false;
      }
      if (!(!!this.model.controls.selectedContactList && !!this.model.controls.selectedContactList.value)) {
        this.openDialog("Alert", "Please select Contact List!!", "alertdialog");
        return false;
      }
    }
    else if (this.step === 2) {
      if (!(!!this.model.controls.from && !!this.model.controls.from.value)) {
        this.openDialog("Alert", "No valid from email found!!", "alertdialog");
        return false;
      }
    }
    this._loader.show();
    if (this.step === 1) {
      this._eMarketingService.CreateEmail
      (
        this.model.controls.selectedRecipientSourceType.value,
        this.model.controls.selectedRecipientType.value,
        this.model.controls.selectedTemplate.value,
        this.model.controls.selectedContactList.value
      )
      .subscribe((email: IEmail) => {
        this._eMarketingService.GetContactsForEmail(email.id, "", (!!email.emailMarker ? email.emailMarker: ""))
        .map((recipients : IContactEmail[]) => {
          return new AfterNewEmailCreationGotoEmailEditPage({email: email, recipients: recipients, from: this.loginUserEmail });
        })
        .subscribe((action) => {
          if (!!action.payload.email.id && action.payload.email.id.indexOf("000000000000") < 0) {
            this.emailID = action.payload.email.id;
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            this.openDialog("Alert", "Email created successfully", "alertdialog");
            ++this.step;            
            //this._router.navigate(["/emails/new/edit"]);
          }
          this._loader.hide();
        }, 
        (error) => {
          this.openDialog("Alert", error, "alertdialog");
          this._loader.hide();
        })
      },
      (error) => {
        this.openDialog("Alert", error, "alertdialog");
          this._loader.hide();
      });
      
    }
  }

  onClose() {
    let action = new CloseNewEmailPage(true);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    this._router.navigate(["/emails"]);
  }


public get isNew() : boolean {
  return !(!!this.emailID && this.emailID.indexOf("000000000000") < 0);
}


}
