import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { Sort, SortDirection } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { IAppState } from "../../../store/store";
import { AuthService } from "../../../services/authservice";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';

import { ReportsService } from "../../reports.service";
import { IEnquiryDto } from './../../reports';
import { CloseEnquiriersFollowupResultPage } from './../../reports.action';

@Component({
  selector: 'app-enquirersfollowupsearchresult',
  templateUrl: './enquirersfollowupsearchresult.component.html',
  styleUrls: ['./enquirersfollowupsearchresult.component.scss']
})
export class EnquirersFollowupSearchResultComponent implements OnInit, OnDestroy {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private _datePipe: DatePipe,
    private _authService: AuthService,
    private _service: ReportsService
  ) { }

  //#region Subscriptions
  private getSessionData_Subcription: Subscription;
  private getEnquiryAppointments_Subscription: Subscription;
  private getAppointmentContact_Subscription: Subscription;
  private modifyAppointmentDetails_Subscription: Subscription;
  //#endregion

  //#region Private Variables
  // private connectionStringName: string;
  // private appointmentID: string;
  // private footerText: string;
  // private modelAppointmentPopup: FormGroup;
  // private selectedAppContact: IAppointmentChildDetails = {} as IAppointmentChildDetails;
  //#endregion

  //#region Snackbar Config
  private snackConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //#endregion

  //#region Dialog
  private dialogRef: any;
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  //#endregion

  @select(state => (state as IAppState).reports.enquirersfollowup.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).reports.enquirersfollowup.search.enquiries) enquiries$;

  //#region Pagination
  private enquiries: IEnquiryDto[] = [];  
  private hasPagination: boolean = true;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: IEnquiryDto[];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.pageData = this.enquiries;
    if (this.hasPagination) {
      this.pageData = this.enquiries.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }
  }
  //#endegion

  //#region Grid Data Sort
  clearSortTimeout: any = null;
  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }
  sortData(sort: Sort) {
    this._loader.show();
    this.clearSortTimeout = setTimeout(() => { 
      this.sortAllData(sort);
    }, 10)
  }
  private sortAllData(sort: Sort) {
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.enquiries.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.enquiries.length; innerIndex++) {
        switch (sort.active) {
          case 'addressText': {
            if (isAsc) {
              if (this.enquiries[index].addressText > this.enquiries[innerIndex].addressText) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.enquiries[index].addressText < this.enquiries[innerIndex].addressText) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'contactName': {
            if (isAsc) {
              if (this.enquiries[index].contactName > this.enquiries[innerIndex].contactName) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.enquiries[index].contactName < this.enquiries[innerIndex].contactName) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'contactMobile': {
            if (isAsc) {
              if (this.enquiries[index].contactMobile > this.enquiries[innerIndex].contactMobile) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.enquiries[index].contactMobile < this.enquiries[innerIndex].contactMobile) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'contactEmail': {
            if (isAsc) {
              if (this.enquiries[index].contactEmail > this.enquiries[innerIndex].contactEmail) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.enquiries[index].contactEmail < this.enquiries[innerIndex].contactEmail) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'motivation': {
            if (isAsc) {
              if (this.enquiries[index].motivation > this.enquiries[innerIndex].motivation) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.enquiries[index].motivation < this.enquiries[innerIndex].motivation) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'followupRequired': {
            if (isAsc) {
              if (this.enquiries[index].followupRequired > this.enquiries[innerIndex].followupRequired) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.enquiries[index].followupRequired < this.enquiries[innerIndex].followupRequired) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'nextContactDate': {
            if (isAsc) {
              if (this.enquiries[index].nextContactDate > this.enquiries[innerIndex].nextContactDate) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.enquiries[index].nextContactDate < this.enquiries[innerIndex].nextContactDate) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            break;
          }          
          case 'staffName': {
            if (isAsc) {
              if (this.enquiries[index].staffName > this.enquiries[innerIndex].staffName) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.enquiries[index].staffName < this.enquiries[innerIndex].staffName) {
                let temp = this.enquiries[index];
                this.enquiries[index] = this.enquiries[innerIndex];
                this.enquiries[innerIndex] = temp;
              }
            }
            break;
          }
        }
      }
    }
    this.loadPageData() 
    this._loader.hide();
    clearTimeout(this.clearSortTimeout);
  }
  //#endregion

  //#region Grid Expand/Collapse
  onExpand(enq: IEnquiryDto) {
    if (!enq.showAppointment) {
      enq.showAppointment = false;
      enq.showAppointmentLoader = true;
      enq.details = [];
      this.getEnquiryAppointments_Subscription = 
      this._service.GetEnquiryAppointments(enq.listingID, enq.contactID)
        .subscribe(data => {
          if (!!data && !data.isError && !!data.response) {
            enq.details = data.response.map(item => [
              { fieldName: "id", value: item.id, showText: (!!item.id ? item.id : "-"), header: "ID", visible: false },
              { fieldName: "appointmentID", value: item.appointmentID, showText: (!!item.appointmentID ? item.appointmentID : "-"), header: "Appointment ID", visible: false },
              { fieldName: "categoryID", value: item.categoryID, showText: (!!item.categoryID ? item.id : "-"), header: "categoryID", visible: false },
              { fieldName: "listingID", value: item.listingID, showText: (!!item.listingID ? item.listingID : "-"), header: "Listing ID", visible: false },
              { fieldName: "contactID", value: item.contactID, showText: (!!item.contactID ? item.contactID : "-"), header: "Contact ID", visible: false },
              { fieldName: "appointmentType", value: item.appointmentType, showText: (!!item.appointmentType ? item.appointmentType : "-"), header: "Appointment Type", visible: true },
              { fieldName: "appointmentDate", value: this.getAppointmentDateRange(item.startDateTime, item.endDateTime), showText: this.getAppointmentDateRange(item.startDateTime, item.endDateTime), header: "Appointment Date", visible: true },
              { fieldName: "interestLevelID", value: item.interestLevelID, showText: (!!item.interestLevelID ? item.interestLevelID.toString() : "-"), header: "Interest Level ID", visible: false },
              { fieldName: "interestLevel", value: item.interestLevel, showText: (!!item.interestLevel ? item.interestLevel : "-"), header: "Interest Level", visible: true },
              { fieldName: "notes", value: item.notes, showText: (!!item.notes ? item.notes : "-"), header: "Notes", visible: true }
            ]);
            enq.showAppointment = true;
            enq.showAppointmentLoader = false;
          }
          else if (!!data && data.isError) {
            this.openDialog("Error", data.message, "alertdialog");
            Observable.throwError(data.message);
          }
          else {
            this.openDialog("Empty Data", "No data found!", "alertdialog");
            Observable.throwError("No data found!");
            enq.showAppointment = false;
            enq.showAppointmentLoader = false;
          }
        }),
        error => {
          debugger;
          this.openDialog("Alert", error, "alertdialog");
          enq.showAppointment = false;
          enq.showAppointmentLoader = false;
        };
    }
    else {
      enq.showAppointment = !enq.showAppointment;
    }

  }
  //#endregion

  //#region Events
  onClose() {
    let action = new CloseEnquiriersFollowupResultPage();
    this.ngRedux.dispatch({ type: action.type });
    this._router.navigate(["/enquirersfollowup"])
  }
  //#endregion

  //#region Functions
  sanitizeSMS(mobileNumber: string) {
    return this.sanitizer.bypassSecurityTrustUrl("sms:" + mobileNumber);
  }
  getAppointmentDateRange(startDateTime: Date, endDateTime: Date): string {
    return `${this._datePipe.transform(startDateTime, "dd/MM/yyyy")}  ${this._datePipe.transform(startDateTime, "hh:mm a")} - ${this._datePipe.transform(endDateTime, "hh:mm a")}`;
  }
  //#endregion

  ngOnInit() {
    this.enquiries = this.ngRedux.getState().reports.enquirersfollowup.search.enquiries;
    this.loadPageData();
  }
  ngOnDestroy() {
    if (!!this.getEnquiryAppointments_Subscription) this.getEnquiryAppointments_Subscription.unsubscribe();
    // if (!!this.getAppointmentContact_Subscription) this.getAppointmentContact_Subscription.unsubscribe();
    // if (!!this.modifyAppointmentDetails_Subscription) this.modifyAppointmentDetails_Subscription.unsubscribe();    
  }

}
