import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs';
import { DatePipe } from '@angular/common';

import { IAppState } from "../../store/store";
import { LoaderService } from "./../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";
import { IDNamePair, IDNameExtraPair, AuthService, SessionInfo, ServiceResponse } from "./../../services/authservice";
import { ReportsService } from './../reports.service';
import { GetAppStatusListingEnquiryStatusSearchPage, GetSaleStatusListingEnquiryStatusSearchPage, GetStaffsListingEnquiryStatusSearchPage, GetListingEnquiryStatusSearchPage } from './../reports.action';
import { IListingEnquiryStatusDto } from './../reports';

@Component({
  selector: 'app-listingenquirystatus',
  templateUrl: './listingenquirystatus.component.html',
  styleUrls: ['./listingenquirystatus.component.scss']
})
export class ListingEnquiryStatusComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _authService: AuthService,
    private _service: ReportsService
  ) {
    let now = new Date();
    let dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - (1 * 7));

    this.model = new FormGroup({
      selectedStaffIDs: new FormControl(this.ngRedux.getState().ofi.openprivateinspections.search.selectedStaffIDs),
      selectedEnquiryStatus: new FormControl(0),
      selectedAppointmentStatus: new FormControl(null),
      selectedSaleStatus: new FormControl(null),
      dateFrom: new FormControl(`${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString().padStart(2, "0")}-${dateFrom.getDate().toString().padStart(2, "0")}`, Validators.required),
      dateTo: new FormControl(`${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`, Validators.required),
      oldEnquiries: new FormControl(false, Validators.required),
    });
  }
  //#region Snackbar Config
  private snackbarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //#region
  //#region Multi-Select Dropdown Settings
  private dropdownSettings: any = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  //#endregion

  //#region Private Variables
  private model: FormGroup;
  private dialogRef: any;
  private staffIDs: IDNamePair<string>[];
  private appointmentStatuses: IDNamePair<number>[];
  private saleStatuses: IDNameExtraPair<number, string>[];
  //private isPropertyLoading: boolean = false;  
  //private selectedListing: IDNameExtraPair<string, string>;
  //#endregion

  //#region Subcription Variables
  private getListingEnquiryStatusSearch_Subcription: Subscription;
  private getAppointmentStatus_Subcription: Subscription;
  private getSaleStatus_Subcription: Subscription;
  //#endregion

  //#region State Variables
  @select(state => (state as IAppState).reports.visitors.search.staffIDs) staffIDs$;
  @select(state => (state as IAppState).reports.listingenquiries.search.appointmentStatuses) appointmentStatuses$;
  @select(state => (state as IAppState).reports.listingenquiries.search.saleStatuses) saleStatuses$;
  @select(state => (state as IAppState).reports.listingenquiries.search.showSearchResult) showSearchResult$;
  //#endregion

  //#region Functions
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  displayFn(item: IDNameExtraPair<string, string>): string {
    if (!!item && !!item.id) {
      return item.name;
    }
  }
  //#endregion

  ngOnInit() {
    this._loader.show();
    this.getAppointmentStatus_Subcription =
      this._service.GetAppointmentStatus()
        .map((data: ServiceResponse<IDNamePair<number>[]>) => {
          if (!data.isError) {
            return new GetAppStatusListingEnquiryStatusSearchPage(data.response);
          }
          else {
            Observable.throwError(data.message);
          }
        })
        .subscribe(action => {
          this.appointmentStatuses = action.payload;
          this.model.controls.selectedAppointmentStatus.setValue(this.appointmentStatuses.find(aps => aps.id == 0));
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this._loader.hide();
        }),
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      }

    this._loader.show();
    this.getSaleStatus_Subcription =
      this._service.GetSaleStatus()
        .map((data: ServiceResponse<IDNameExtraPair<number, string>[]>) => {
          if (!data.isError) {
            var ss = data.response.filter(res => [2, 4, 8, 256].indexOf(res.id) >= 0);
            ss.push({ id: 0, extra: null, name: 'All' });
            return new GetSaleStatusListingEnquiryStatusSearchPage(ss);
          }
          else {
            Observable.throwError(data.message);
          }
        })
        .subscribe(action => {
          this.saleStatuses = action.payload;
          this.model.controls.selectedSaleStatus.setValue(this.saleStatuses.find(aps => aps.id == 0));
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this._loader.hide();
        }),
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      }
  }
  ngAfterViewInit() {
    LoadScript.init();
  }
  ngOnDestroy() {
    if (!!this.getAppointmentStatus_Subcription) this.getAppointmentStatus_Subcription.unsubscribe();
    if (!!this.getSaleStatus_Subcription) this.getSaleStatus_Subcription.unsubscribe();
    if (!!this.getListingEnquiryStatusSearch_Subcription) this.getListingEnquiryStatusSearch_Subcription.unsubscribe();
  }
  onSubmit() {
    //Date From
    let dateFrom: Date | null = null;
    if (!!this.model.controls.dateFrom.value) {
      dateFrom = this.model.controls.dateFrom.value;
      console.log("dateFrom: ", dateFrom);
    }

    //Date To
    let dateTo: Date | null = null;
    if (!!this.model.controls.dateFrom.value) {
      dateTo = this.model.controls.dateTo.value;
      console.log("dateTo: ", dateTo);
    }

    //Enquiry Status
    let enquiryStatusID: number = null;
    if (!!this.model.controls.selectedEnquiryStatus) {
      enquiryStatusID = this.model.controls.selectedEnquiryStatus.value;
      console.log("enquiryStatusID: ", enquiryStatusID);
    }

    //Apointment Status
    let appointmentStatusID: number = null;
    if (!!this.model.controls.selectedAppointmentStatus.value) {
      appointmentStatusID = this.model.controls.selectedAppointmentStatus.value.id;
      console.log("appointmentStatusID: ", appointmentStatusID);
    }

    //Apointment Status
    let saleStatusID: number = null;
    if (!!this.model.controls.selectedSaleStatus.value && !!this.model.controls.selectedSaleStatus.value.id) {
      saleStatusID = this.model.controls.selectedSaleStatus.value.id;
      console.log("saleStatusID: ", saleStatusID);
    }

    this._loader.show();
    this.getListingEnquiryStatusSearch_Subcription =
      this._service
        .GetListingEnquiryStatusSearch
        (
          null,
          dateFrom,
          dateTo,
          enquiryStatusID,
          appointmentStatusID,
          saleStatusID
        )
        .map((data: ServiceResponse<IListingEnquiryStatusDto[]>) => {
          if (!data.isError) {
            return new GetListingEnquiryStatusSearchPage(data.response);
          }
          else {
            Observable.throwError(data.message);
          }
        })
        .subscribe(action => {
          if (!!action && !!action.payload && !!action.payload.length) {
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            console.log("action.payload: ", action.payload);
            this._router.navigate(["/listingenquirystatus/search"]);
          }
          else {
            this.snackBar.open("No record found", "Close", this.snackbarConfig);
          }
          this._loader.hide();
        }),
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      }

    //let action = new GetListingEnquiryStatusSearchPage([]);
    //this.ngRedux.dispatch({ type: action.type, payload: [] });
    //this._router.navigate(['listingenquirystatus/search']);
  }
}
