import { Subscription } from "rxjs/Subscription";

export function AutoUnsubscribe(blackList?: Array<string>) {

  return function (constructor) {
    const original = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function () {
      var keys = Object.keys(this).filter(key => key.toLowerCase().indexOf("_subscription") >= 0);
      for (let index in keys) {
        const property = this[keys[index]];
        if (!!blackList && !!blackList.length) {
          if (!blackList.includes(keys[index])) {
            if (property && (typeof property.unsubscribe === "function")) {
              property.unsubscribe();
            }
          }
        }
        else {
          if (property && (typeof property.unsubscribe === "function")) {
            property.unsubscribe();
          }
        }
      }
      original && typeof original === 'function' && original.apply(this, arguments);
    };

    //constructor.prototype.ngOnDestroy = function () {
    //  for (let prop in this) {
    //    const property = this[prop];
    //    if (!blackList.includes(prop)) {
    //      if (property && (typeof property.unsubscribe === "function")) {
    //        property.unsubscribe();
    //      }
    //    }
    //  }
    //  original && typeof original === 'function' && original.apply(this, arguments);
    //};
  }
}
export function emailValidation(mail): boolean {
  return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail));
}

export function format2(n, currency) {
  return currency + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
}

