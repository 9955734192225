import { GetRecipientTypes, GetEmailTemplates, LoadNewTemplatePage } from "./../emarketing.action";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { IAppState, INITIAL_STATE } from "../../store/store";
import { EmarketingService } from "./../emarketing.service";
import { LoaderService } from "./../../core/loader/loader.service";
import { IRecipientType, IEmailTemplate } from "./../emarketing";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IDNamePair } from "./../../services/authservice";
import "../../../../../wwwroot/js/scripts.js";
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: "templates",
  templateUrl: "./templates.component.html",
  styleUrls: ["./templates.component.css"]
})
export class TemplatesComponent implements OnInit, AfterViewInit {
  
  dropdownSettings: any = {};
  selectedItems = [];

  model: FormGroup;
  private recipientTypes: IRecipientType[] = [];
  private recipientTypeList: IRecipientType[] = [];
  
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private _service: EmarketingService,
    private _loader: LoaderService,
    private fb: FormBuilder
  ) {
    this.model = this.fb.group({
      selectedRecipientTypes: [this.selectedItems, Validators.required],
      templateName: ["", !Validators.required]
    });
  }

  @select(state => (state as IAppState).emarketing.template.search.recipientTypes) recipientTypes$;
  @select(state => (state as IAppState).emarketing.template.search.templates) templates$;
  @select(state => (state as IAppState).emarketing.template.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).emarketing.template.addEditView.showNewPage) showNewPage$;
  

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.recipientTypes$.subscribe(rt => {
        this.recipientTypeList = rt;
    });

    this._loader.show();
    let rSource: number[] = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384];
    this._service
      .GetRecipientTypes(rSource)
      .map((data: IRecipientType[]) => {
        return new GetRecipientTypes(data);
      })
      .subscribe(action => {
        this.recipientTypes = action.payload;
        this.ngRedux.dispatch({ type: action.type, payload: action.payload });
        this._loader.hide();
      });
  }

  ngAfterViewInit() {
    LoadScript.init();
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }

  onSubmit() {
    if (this.model.status == "VALID") {
      this._loader.show();
      let rtypes: number[] = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384];//[this.model.controls.selectedRecipientTypes.value]
      this._service
        .GetEmailTemplates(
          //this.model.controls.selectedRecipientTypes.value.map(rt => rt.id),
          this.model.controls.templateName.value,
          0
        )
        .map((data: IEmailTemplate[]) => {
          return new GetEmailTemplates({
            templateName: !!this.model.controls.templateName.value ? this.model.controls.templateName.value : "",
            selectedRecipientTypes: this.model.controls.selectedRecipientTypes.value,
            recipientTypes: this.recipientTypes,
            templates: data,
            showSearchResult: false
          });
        })
        .subscribe(action => {
          if (!!action.payload.templates && !!action.payload.templates.length) {
            action.payload.showSearchResult = true;
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            this._router.navigate(["/templates/templatesearchresult"]);
          }
          this._loader.hide();
        });
    }
  }
  onNew() {
    
    this._service
      .GetEmailAgencies()
      .subscribe((agencies: IDNamePair<string>[]) => {
        this._service
        .GetRecipientTypes()
        .map((data: IRecipientType[]) => {
          return new LoadNewTemplatePage({recipientTypes: data, agencies: agencies});
        })
        .subscribe(action => {
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this._loader.hide();
          this._router.navigate(["/templates/new"]);
        });

      });
    
    //this._router.navigate(["/templates/new"]);
  }
}
