import { Options } from 'ng5-slider';
import { async } from '@angular/core/testing';
import { Observable } from "rxjs/Observable";
import { Injectable, Inject, PLATFORM_ID, Injector } from "@angular/core";
import {
  Http,
  Headers,
  Response,
  RequestOptions,
  RequestMethod
} from "@angular/http";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";

import { IDNamePair, ServiceResponse, ContactDetails, IDNameExtraPair } from "./../services/authservice";
import { IManagementAgreement, ITenantContactSearchResult, IWorkOrderResult, IWorkOrderSave, 
  ITenantInvoiceSearchResult, ICommunicationStatement, ICommercialTenantContactSearchResult,
  IOutstandingInvoice, ICommercialRentReviewSchedule, IContactsSearchResults,ICommercialContactsSearchResults,
  ITenantInfo,
  IVacantPropertiesResult,
  IPersonContact,
  ILeadSource,
  ILeaseRenewalSearchResult,
  ILeaseRenewalLog,
  ILeaseRenewalPropertyAddress,
  INewLeaseCountSearchResult,
  ILeaseRenewalStatisticsSearchResult,
  ILeaseRenewal,
  IWorkOrderSearchByID
} from "./propertymanagement";
import { BaseService } from '../services/baseservice';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable()
export class PropertyManagementService extends BaseService {
  constructor(private _http: Http, private HTTP: HttpClient, @Inject(PLATFORM_ID) private platformID: Object) {
    super(HTTP, platformID);
  }


  //#region Management Agreements
  GetOwnerGroups(): Observable<IDNamePair<number>[]> {
    return this._http
      .get("api/PropertyManagement/GetOwnerGroups")
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  GetPropertyManagers(): Observable<IDNamePair<string>[]> {
    return this._http
      .get("api/PropertyManagement/GetPropertyManagers")
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || "Server Error"));
  }
  GetManagementAgreements(
    searchText: string,
    searchFields: number,
    managementTypeID: number | null,
    statusID: number,
    propertyManagerIDs: string[],
    ownerStatementGroupIDs: number | null,
    periodicLeases: number,
    alertNotes: boolean,
    isCommercialManagementSystem: boolean,
    vacant: boolean,
    callLogID: string | undefined | null = null,
    managementAgreementID: string | undefined | null = null
  ): Observable<ServiceResponse<IManagementAgreement[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetManagementAgreements", {
        searchText: searchText,
        searchFields: searchFields,
        managementTypeID: managementTypeID,
        statusID: statusID,
        propertyManagerIDs: propertyManagerIDs,
        ownerStatementGroupIDs: ownerStatementGroupIDs,
        periodicLeases: periodicLeases,
        alertNotes: alertNotes,
        isCommercialManagementSystem: isCommercialManagementSystem,
        vacant: vacant,
        callLogID: callLogID,
        managementAgreementID: managementAgreementID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  CommunicationDetails(
    id: string,moduleType: number,timezoneOffset: number): Observable<ICommunicationStatement[]> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetCommunicationDetails", {
        ID: id,
        moduleType: moduleType,
        timezoneOffset: timezoneOffset
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  //#endregion

  //#region Tenant Contact
  GetTenantContacts(
    searchText: string,
    searchFields: number,
    statusIDs: number,
    propertyManagerIDs: string[],
    hasOverdueInvoices: boolean,
    daysOverdue: number,
    includeJointTenants: boolean,
    excludePeriodLeases: boolean,
    nextInspectionScheduledDateFrom: Date | null,
    nextInspectionScheduledDateTo: Date | null,
    inspectionTypeID: number | null,
    sortBy: number
  ): Observable<ITenantContactSearchResult[]> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetTenantContacts", {
        searchText: searchText,
        searchFields: searchFields,
        statusIDs: statusIDs,
        propertyManagerIDs: propertyManagerIDs,
        hasOverdueInvoices: hasOverdueInvoices,
        daysOverdue: daysOverdue,
        includeJointTenants: includeJointTenants,
        excludePeriodLeases: excludePeriodLeases,
        nextInspectionScheduledDateFrom,
        nextInspectionScheduledDateTo,
        inspectionTypeID,
        sortBy
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }


  GetCommercialTenantContacts(
    searchText: string,
    searchFields: number,
    statusIDs: number,
    propertyManagerIDs: string[],
    hasOverdueInvoices: boolean,
    daysOverdue: number,
    includeJointTenants: boolean,
    excludePeriodLeases: boolean,
    nextInspectionScheduledDateFrom: Date | null,
    nextInspectionScheduledDateTo: Date | null,
    inspectionTypeID: number | null,
    sortBy: number
  ): Observable<ServiceResponse<ICommercialTenantContactSearchResult[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetCommercialTenantContacts", {
        searchText: searchText,
        searchFields: searchFields,
        statusIDs: statusIDs,
        propertyManagerIDs: propertyManagerIDs,
        hasOverdueInvoices: hasOverdueInvoices,
        daysOverdue: daysOverdue,
        includeJointTenants: includeJointTenants,
        excludePeriodLeases: excludePeriodLeases,
        nextInspectionScheduledDateFrom,
        nextInspectionScheduledDateTo,
        inspectionTypeID,
        sortBy
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  GetOutstandingInvoices(
    rentalTenantID: string
  ): Observable<ServiceResponse<IOutstandingInvoice[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetOutstandingInvoices", {
        rentalTenantID: rentalTenantID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }


  

  //#endregion 

  //#region Work Order  
  GetSupplierServiceTypes() {
    return this.GetAll<ServiceResponse<IDNamePair<number>[]>>("api/PropertyManagement/GetSupplierServiceType");
  }

  GetWorkOrder(
    searchText: string,
    statusIDs: number,
    serviceTypeIDs: number[],    
    dueDateFrom: Date | null,
    dueDateTo: Date | null,
    searchFields: number,
    no: number | null,
    propertyManagerIDs: string[],
    commercialServiceTypeIDs: string | null,
    isCommercialManagementSystem: boolean,
    managementAgreementID: string = null
  ): Observable<any/*IWorkOrderResult[]*/> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetWorkOrders", {
        searchText: searchText,
        statusIDs: statusIDs,
        serviceTypeIDs: serviceTypeIDs,
        dueDateFrom: dueDateFrom,
        dueDateTo: dueDateTo,
        searchFields: searchFields,
        no: no,
        propertyManagerIDs: propertyManagerIDs,
        commercialServiceTypeIDs: commercialServiceTypeIDs,
        isCommercialManagementSystem: isCommercialManagementSystem,
        managementAgreementID: managementAgreementID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  SaveWorkOrder
    (      
      managementAgreementID: string,
      propertyID: string,
      supplierContactID: string,
      costEstimate: number | null,
      amountCharged: number | null,
      gstAmount: number | null,
      gstPayable: boolean,
      gstInclusive: boolean,
      workDetail: string,
      agreedCompletionDate: Date | null,
      serviceTypeID: number | null,
      onSiteContactID: string,
      onSiteContactTypeID: number,
      completionNotes: string,
      clientDate: Date,
      statusID: number,
      photo1Name: string | null | undefined = null,
      photo1Base64: string | null | undefined = null,
      photo2Name: string | null | undefined = null,
      photo2Base64: string | null | undefined = null,
      photo3Name: string | null | undefined = null,
      photo3Base64: string | null | undefined = null,
      callLogID: string | undefined | null = null,
      id: string | undefined | null = null
  ): Observable<ServiceResponse<IWorkOrderSave>>
  {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/SaveWorkOrder", {
        id: id,
        managementAgreementID: managementAgreementID,
        propertyID: propertyID,
        supplierContactID: supplierContactID,
        costEstimate: costEstimate,
        amountCharged: amountCharged,
        gstAmount: gstAmount,
        gstPayable: gstPayable,
        gstInclusive: gstInclusive,
        workDetail: workDetail,
        agreedCompletionDate: agreedCompletionDate,
        serviceTypeID: serviceTypeID,
        onSiteContactID: onSiteContactID,
        onSiteContactTypeID: onSiteContactTypeID,
        completionNotes: completionNotes,
        clientDate: clientDate,
        statusID: statusID,
        photo1Name: photo1Name,
        photo1Base64: photo1Base64,
        photo2Name: photo2Name,
        photo2Base64: photo2Base64,
        photo3Name: photo3Name,
        photo3Base64: photo3Base64,
        callLogID: callLogID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetWorkOrderSearchByID(
      id: string
  ): Observable<ServiceResponse<IWorkOrderSearchByID>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetWorkOrderSearchByID", {
        id: id
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  RemoveWorkOrderImage(
    imageID: string
  ): Observable<ServiceResponse<boolean>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/RemoveWorkOrderImage", {
        imageID: imageID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  //#endregion

  //#region Tenant Invoice
  GetTenantInvoice(
    selectedPropertyID: string,
    tenantNames: string,
    statusIDs: number,
    dateFrom: Date | null,
    dateTo: Date | null,
    supplierServiceTypeID: bigint | null,
    number: number | null,
    propertyManagerIDs: string[],
    repeatingTypeID: number | null,
    recurringSeriesOnly: any,
    creditorTypeID: number | null,
    transactionReason: string,    
    tenantStatusIDs:  number | null,
    nonIssued: any | null ,
    overdueDaysFrom: number | null,
    overdueDaysTo: number | null,
    includeJointTenants: boolean,
    sortBy: number

  ): Observable<ServiceResponse<ITenantInvoiceSearchResult[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetTenantInvoices", {
        selectedPropertyID: selectedPropertyID,
        tenantNames: tenantNames,
        statusIDs: statusIDs,
        dateFrom: dateFrom,
        dateTo: dateTo,
        supplierServiceTypeID: supplierServiceTypeID,
        number: null,
        propertyManagerIDs: propertyManagerIDs,
        repeatingTypeID: repeatingTypeID,
        recurringSeriesOnly: recurringSeriesOnly,
        transactionReason: transactionReason,
        creditorTypeID: creditorTypeID,
        tenantStatusIDs: tenantStatusIDs,
        nonIssued: nonIssued,
        overdueDaysFrom: overdueDaysFrom,
        overdueDaysTo: overdueDaysTo,
        includeJointTenants: includeJointTenants,
        sortBy: sortBy
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  //endregion

  //#region Communication
  GetCommunicationReasons(targetTypeID: number): Observable<IDNamePair<number>[]> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetCommunicationReasons", {
        targetTypeID: targetTypeID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  SaveCommunicationLog(
    targetTypeID: number,
    staffID: string,
    mailMergeName: string,
    contactID: string,
    contactName: string,
    mobile: string,
    email: string,
    communicationMethodID: number,
    subject: string,
    reasonID: number,
    notes: string,
    sentDateTime: Date
  ): Observable<ServiceResponse<any>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/SaveCommunication", {
        staffID: staffID,
        mailMergeName: mailMergeName,
        contactID: contactID,
        contactName: contactName,
        mobile: mobile,
        email: email,
        communicationMethodID: communicationMethodID,
        subject: subject,
        reasonID: reasonID,
        notes: notes,
        sentDateTime: sentDateTime
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  //#endregion

  //#region Supplier
  GetSupplierDetails(
    textEntered: string,
    serviceTypeID: number | null
  ):Observable<ServiceResponse<IDNamePair<string>[]>>{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetSupplierDetails", {
        textEntered: textEntered,
        serviceTypeID: serviceTypeID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  //#endregion

  //#region Contact
  GetContactDetails(
    contactID: string,
  ):Observable<ServiceResponse<ContactDetails>>{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetContactDetails", {
        contactID: contactID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetOnSiteContactDetails(
    managementAgreementID: string,
    onSiteContactType: number
  ):Observable<ServiceResponse<ContactDetails>>{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetOnSiteContactDetails", {
        managementAgreementID: managementAgreementID,
        onSiteContactType: onSiteContactType
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetCompanyPersons(
    companyID: string
  ):Observable<ServiceResponse<ContactDetails[]>>{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetCompanyPersons", {
        companyID: companyID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }


  GetContactSearchDetails(
    name: string,
    mobile: string,
    email: string,
    currentStaffID: string,
    enquiryContactOnly: boolean = false
  ):Observable<ServiceResponse<IContactsSearchResults[]>>{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetContactSearchDetails", {
        name: name,
        mobilePhone: mobile,
        email: email,
        currentStaffID: currentStaffID,
        enquiryContactOnly: enquiryContactOnly
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  GetCommercialContactSearchDetails(
    name: string,
    mobile: string,
    email: string,
    currentStaffID: string,
    enquiryContactOnly: boolean = false
  ):Observable<ServiceResponse<ICommercialContactsSearchResults[]>>{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetContactSearchDetails", {
        name: name,
        mobilePhone: mobile,
        email: email,
        currentStaffID: currentStaffID,
        enquiryContactOnly: enquiryContactOnly
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetContact(
    mobile: string
  ): Observable<ServiceResponse<IPersonContact>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetContact", {
        mobile: mobile
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetLeadSources(): Observable<ServiceResponse<ILeadSource[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetLeadSources", options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  SavePersonContact(
    id: string,
    title: string,
    givenNames: string,
    surname: string,
    mobilePhone: string,
    email: string,    
    notes: string,
    leadSourceID: number,
    photoImageID: null
  ): Observable<ServiceResponse<string>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/SavePersonContact", {
        id: id,
        title: title,
        givenNames: givenNames,
        surname: surname,
        mobilePhone: mobilePhone,        
        email: email,
        notes: notes,
        leadSourceID: leadSourceID,
        photoImageID: photoImageID,
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  //#endregion

  //#region Get Property Address
  GetPropertyAddress(
    searchText: string
  ):Observable<ServiceResponse<IDNamePair<string>[]>>{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetPropertyAddress", {
        searchText: searchText
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  GetCommercialPropertyAddresses(
    searchText: string
  ):Observable<ServiceResponse<IDNamePair<string>[]>>{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetCommercialPropertyAddresses", {
        searchText: searchText
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  //#end region

  //#region Commercial Tenant Infor from Management Agreement
  GetTenantsFromCommercialManagementAgreement(
    complexID: string
  ): Observable<ServiceResponse<ITenantInfo[]>>{
    let headers = new Headers({'contacty-type' : 'application/json'});
    let Options = new RequestOptions({headers : headers});
    return this._http
        .post("api/PropertyManagement/GetTenantsFromCommercialManagementAgreement", {
          complexID: complexID
        }, Options)
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
  }
  //#endregion

  //#region Commercial Tenant Contact Rent Schedule Child Grid


  GetRentReviewScheduleInfo(
    rentalTenantID: string
  ): Observable<ServiceResponse<ICommercialRentReviewSchedule[]>>{
    let headers = new Headers({'contacty-type' : 'application/json'});
    let Options = new RequestOptions({headers : headers}); 

    return this._http
        .post("api/PropertyManagement/GetRentReviewScheduleInfos", {
          rentalTenantID: rentalTenantID
        }, Options)
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
  }
  //#endregion

  //#region  VacantProperty Less than 14
  GetVacantPropertiesLessThan14(
  ):Observable<ServiceResponse<IVacantPropertiesResult[]>>{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetVacantPropertiesLessThan14", {
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  //#endregion

  //#region  Vacant Property Greater than 14
  GetVacantPropertiesGreaterThan14(
    days: number
    ):Observable<ServiceResponse<IVacantPropertiesResult[]>>{
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
      return this._http
        .post("api/PropertyManagement/GetVacantPropertiesGreaterThan14", {
          days: days
        }, options)
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
    }
    //#endregion

  //#region Lease Renewal
  GetLeaseRenewalPropertyManagers(    
    database: string,
    sessionID: string
  ): Observable<ServiceResponse<IDNameExtraPair<string, boolean>[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetLeaseRenewalPropertyManagers", {        
        database: database,
        sessionID: sessionID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetLeaseRenewals(
    database: string,
    sessionID: string,
    departmentID: string,
    leaseEndDateFrom: Date,
    leaseEndDateTo: Date,
    processCompleted: boolean,
    bypass: boolean,
    propertyManagerIDs: string[],
    propertyID: string,
    convertPeriodicLeaseToNormalLease: boolean
  ): Observable<ServiceResponse<ILeaseRenewalSearchResult[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetLeaseRenewals", {
        database: database,
        sessionID: sessionID,
        departmentID: departmentID,
        leaseEndDateFrom: leaseEndDateFrom,
        leaseEndDateTo: leaseEndDateTo,
        processCompleted: processCompleted,
        bypass: bypass,
        propertyManagerIDs: propertyManagerIDs,
        propertyID: propertyID,
        convertPeriodicLeaseToNormalLease: convertPeriodicLeaseToNormalLease
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetLeaseRenewalLogs(
    database: string,
    sessionID: string,
    leaseRenewalID: string
  ): Observable<ServiceResponse<ILeaseRenewalLog[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetLeaseRenewalLogs", {
        database: database,
        sessionID: sessionID,
        leaseRenewalID: leaseRenewalID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetLeaseRenewal(
    database: string,
    sessionID: string,
    leaseRenewalID: string
  ): Observable<ServiceResponse<ILeaseRenewal>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetLeaseRenewal", {
        database: database,
        sessionID: sessionID,
        leaseRenewalID: leaseRenewalID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  SaveLeaseRenewalWeeklyRent(
    database: string,
    sessionID: string,
    leaseRenewalID: string,
    weeklyRent: number
  ): Observable<ServiceResponse<ILeaseRenewal>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/SaveLeaseRenewalWeeklyRent", {
        database: database,
        sessionID: sessionID,
        leaseRenewalID: leaseRenewalID,
        weeklyRent: weeklyRent
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  BypassLeaseRenewal(
    database: string,
    sessionID: string,
    leaseRenewalID: string,
    bypass: boolean
  ): Observable<ServiceResponse<boolean>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/BypassLeaseRenewal", {
        database: database,
        sessionID: sessionID,
        leaseRenewalID: leaseRenewalID,
        bypass: bypass
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  SetLeaseRenewalSequence(
    database: string,
    sessionID: string,
    leaseRenewalID: string,
    leaseRenewalSequenceID: number,
    renewalProcessCompleted: boolean
  ): Observable<ServiceResponse<boolean>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/SetLeaseRenewalSequence", {
        database: database,
        sessionID: sessionID,
        leaseRenewalID: leaseRenewalID,
        leaseRenewalSequenceID: leaseRenewalSequenceID,
        renewalProcessCompleted: renewalProcessCompleted
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetLeaseRenewalPropertyAddress(
    database: string,
    sessionID: string,
    departmentID: string,
    searchText: string
  ): Observable<ServiceResponse<ILeaseRenewalPropertyAddress[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetLeaseRenewalPropertyAddress", {
        database: database,
        sessionID: sessionID,
        departmentID: departmentID,
        searchText: searchText
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  RestartNegotiation(
    database: string,
    sessionID: string,
    leaseRenewalID: string
  ): Observable<ServiceResponse<boolean>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/RestartNegotiation", {
        database: database,
        sessionID: sessionID,
        leaseRenewalID: leaseRenewalID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetNewLeasesCount(
    database: string,
    sessionID: string,
    departmentID: string,
    completedDateFrom: Date,
    completedDateTo: Date,
    propertyManagerIDs: string[]
  ): Observable<ServiceResponse<INewLeaseCountSearchResult[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetNewLeasesCount", {
        database: database,
        sessionID: sessionID,
        departmentID: departmentID,
        completedDateFrom: completedDateFrom,
        completedDateTo: completedDateTo,
        propertyManagerIDs: propertyManagerIDs
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetLeaseRenewalStatistics(
    database: string,
    sessionID: string,
    departmentID: string,
    completedDateFrom: Date,
    completedDateTo: Date,
    propertyManagerIDs: string[]
  ): Observable<ServiceResponse<ILeaseRenewalStatisticsSearchResult[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/GetLeaseRenewalStatistics", {
        database: database,
        sessionID: sessionID,
        departmentID: departmentID,
        completedDateFrom: completedDateFrom,
        completedDateTo: completedDateTo,
        propertyManagerIDs: propertyManagerIDs
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  ChangeRent(
    database: string,
    sessionID: string,
    leaseRenewalID: string,
    newRentAmount: number
  ): Observable<ServiceResponse<ILeaseRenewal>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/PropertyManagement/ChangeRent", {
        database: database,
        sessionID: sessionID,
        leaseRenewalID: leaseRenewalID,
        newRentAmount: newRentAmount
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  //#endregion
}
