import { ISearchManagementAgreement } from './propertymanagement';
import { IPropertyManagementState, INITIAL_PROPERTYMANAGEMENT_STATE } from "./propertymanagement.state";
import { PropertyManagementAction, enActionTypes } from "./propertymanagement.action";
import { Reducer, Action } from "redux";

export const propertymanagementReducer: Reducer<IPropertyManagementState> = (state = INITIAL_PROPERTYMANAGEMENT_STATE(), action: PropertyManagementAction): IPropertyManagementState => {
    switch (action.type) {
        case enActionTypes.ShowContactSearchPageAction:{
            return {
                ...state,
                contacts: {
                    search: Object.assign({},state.contacts.search,{showSearchResult: false})
                }
            }
        }
        case enActionTypes.GetContactSearchAction: {
            return {
                ...state,
                contacts: {
                    search: Object.assign({},state.contacts.search,{contactSearchResults: action.payload,showSearchResult: true})
                }
            }
        }
        case enActionTypes.CloseContactSearchResultPageAction: {
            return {
                ...state,
                contacts: {
                    search: Object.assign({},state.contacts.search,{showSearchResult: false})
                }
            }
        }
        case enActionTypes.ShowManagementAgreementSearchPageAction: {
            return {
                ...state,
                managementagreement: {
                  search: Object.assign({}, state.managementagreement.search, { showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetOwnerGroupsInMAgreementSearchPageAction: {
            return {
                ...state,
                managementagreement: {
                  search: Object.assign({}, state.managementagreement.search, { ownerStatementGroupIDs: action.payload, showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetManagementAgreementsAction: {
            return {
                ...state,
                managementagreement: {
                  search: Object.assign({}, state.managementagreement.search, { managementagreements: action.payload, showSearchResult: true } )
                }
            };
        } 
        case enActionTypes.GetPropertyManagersInMAgreemantSearchPageAction: {
            return {
                ...state,
                managementagreement: {
                    search: Object.assign({}, state.managementagreement.search, { propertyManagerIDs: action.payload, showSearchResult: false } )
                }
            };
        }
        case enActionTypes.CloseManagementAgreementResultPageAction: {
            return {
                ...state,
                managementagreement: {
                    search: Object.assign({},state.managementagreement.search, { showSearchResult: false } )
                }
            }
        }
        case enActionTypes.ShowTenantContactsSearchPageAction: {
            return {
                ...state,
                tenantcontact: {
                    search: Object.assign({}, state.tenantcontact.search, { showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetTenantContactsAction: {
            return {
                ...state,
                tenantcontact: {
                    search: Object.assign({}, state.tenantcontact.search, { tenantContacts: action.payload, showSearchResult: true } )
                }
            };
        } 
        case enActionTypes.GetPropertyManagersInTanentContactSearchPageAction: {
            return {
                ...state,
                tenantcontact: {
                    search: Object.assign({}, state.tenantcontact.search, { propertyManagerIDs: action.payload, showSearchResult: false } )
                }
            };
        }
        case enActionTypes.CloseTanentContactResultPageAction: {
            return {
                ...state,
                tenantcontact: {
                    search: Object.assign({},state.tenantcontact.search, { showSearchResult: false } )
                }
            }
        }

        case enActionTypes.ShowWorkOrdersSearchPageAction: {
            return {
                ...state,
                workorder: {
                    search: Object.assign({}, state.workorder.search, { showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetWorkOrdersAction: {
            return {
                ...state,
                workorder: {
                    search: Object.assign({}, state.workorder.search, { workOrders: action.payload, showSearchResult: true } )
                }
            };
        } 
        case enActionTypes.GetPropertyManagersInWorkOrderSearchPageAction: {
            return {
                ...state,
                workorder: {
                    search: Object.assign({}, state.workorder.search, { propertyManagerIDs: action.payload, showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetServiceTypesInWorkOrderSearchPageAction: {
            return {
                ...state,
                workorder: {
                    search: Object.assign({}, state.workorder.search, { serviceTypeIDs: action.payload, showSearchResult: false } )
                }
            };
        }

        case enActionTypes.CloseWorkOrderResultPageAction: {
            return {
                ...state,
                workorder: {
                    search: Object.assign({}, state.workorder.search, { showSearchResult: false } )
                }
            };
        }

        case enActionTypes.ShowTenantInvoicesSearchPageAction: {
            return {
                ...state,
                tenantInvoice: {
                    search: Object.assign({}, state.tenantInvoice.search, { showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetTenantInvoicesAction: {
            return {
                ...state,
                tenantInvoice: {
                    search: Object.assign({}, state.tenantInvoice.search, { tenantInvoices: action.payload, showSearchResult: true } )
                }
            };
        } 

        case enActionTypes.GetPropertyManagersInTenantInvoiceSearchPageAction: {
            return {
                ... state,
                tenantInvoice: {
                    search: Object.assign({},state.tenantInvoice.search,{propertyManagerIDs: action.payload, showSearchResult: false})
                }
            };
        }

        case enActionTypes.CloseTenantInvoiceResultPageAction: {
            return {
                ... state,
                tenantInvoice: {
                    search: Object.assign({},state.tenantInvoice.search,{showSearchResult: false})
                }
            }
        }

        case enActionTypes.GetPropertyInfoInTenantInvoiceSearchPageAction: {
            return {
                ... state,
                tenantInvoice: {
                    search: Object.assign({},state.tenantInvoice.search,{selectedPropertyID: action.payload, showSearchResult: false})
                }
            };
        }

        case enActionTypes.GetVacancyPropertyLessThan14SearchAction: {
            return {
                ... state,
                vacantpropertieslessthan14: {
                    search: Object.assign({},state.vacantpropertieslessthan14.search, { vacantPropertySearchResult: action.payload, showSearchResult: true})
                }
            };
        }
        case enActionTypes.GetVacancyPropertyGreaterThan14SearchAction: {
            return {
                ... state,
                vacantpropertiesgreaterthan14: {
                    search: Object.assign({}, state.vacantpropertiesgreaterthan14.search, {vacantPropertySearchResult: action.payload, showSearchResult: true})
                }
            };
        }

        //#region Commercial

        //#region Commercial Contact
        case enActionTypes.ShowCommercialContactSearchPageAction:{
            return {
                ...state,
                commercialcontacts: {
                    search: Object.assign({},state.commercialcontacts.search,{showSearchResult: false})
                }
            }
        }
        case enActionTypes.GetCommercialContactSearchAction: {
            return {
                ...state,
                commercialcontacts: {
                  search: Object.assign({}, state.commercialcontacts.search, { contactSearchResults: action.payload, showSearchResult: true, showContactCreation: false})
                }
            }
        }
        case enActionTypes.CloseCommercialContactSearchResultPageAction: {
            return {
                ...state,
                commercialcontacts: {
                    search: Object.assign({},state.commercialcontacts.search,{showSearchResult: false, showContactCreation: false})
                }
            }
        }
        case enActionTypes.ShowCommercialContactCreationPageAction:{
            return {
                ...state,
                commercialcontacts: {
                    search: Object.assign({},state.commercialcontacts.search, {showSearchResult: false, showContactCreation: true })
                }
            }
        }
        case enActionTypes.GetLeadSourcesInCommercialContactCreationPageAction: {
            return {
                ...state,
              commercialcontacts: {
                search: Object.assign({}, state.commercialcontacts.search, { contactCreation: Object.assign({}, state.commercialcontacts.search.contactCreation, { leadSources: action.payload }) })
                }
            }
        }
        case enActionTypes.CloseCommercialContactCreationPageAction: {
            return {
                ...state,
                commercialcontacts: {
                    search: Object.assign({},state.commercialcontacts.search,{showSearchResult: false, showContactCreation: false})
                }
            }
        }
        //#endregion

        //#region Management Agreement
        case enActionTypes.ShowCommercialManagementAgreementSearchPageAction: {
            return {
                ...state,
                commercialmanagementagreement: {
                  search: Object.assign({}, state.commercialmanagementagreement.search, { showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetCommercialManagementAgreementsAction: {
            return {
                ...state,
                commercialmanagementagreement: {
                  search: Object.assign({}, state.commercialmanagementagreement.search, { managementagreements: action.payload, showSearchResult: true } )
                }
            };
        } 
        case enActionTypes.GetCommercialPropertyManagersInMAgreemantSearchPageAction: {
            return {
                ...state,
                commercialmanagementagreement: {
                    search: Object.assign({}, state.commercialmanagementagreement.search, { propertyManagerIDs: action.payload, showSearchResult: false } )
                }
            };
        }
        case enActionTypes.CloseCommercialManagementAgreementResultPageAction: {
            return {
                ...state,
                commercialmanagementagreement: {
                    search: Object.assign({},state.commercialmanagementagreement.search, { showSearchResult: false } )
                }
            }
        }
        //#endregion

        //#region Tenant Contacts
        case enActionTypes.ShowCommercialTenantContactsSearchPageAction: {
            return {
                ...state,
                commercialtenantcontact: {
                    search: Object.assign({}, state.commercialtenantcontact.search, { showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetCommercialTenantContactsAction: {
            return {
                ...state,
                commercialtenantcontact: {
                    search: Object.assign({}, state.commercialtenantcontact.search, { tenantContacts: action.payload, showSearchResult: true } )
                }
            };
        }
        case enActionTypes.CloseCommercialTanentContactResultPageAction: {
            return {
                ...state,
                commercialtenantcontact: {
                    search: Object.assign({},state.commercialtenantcontact.search, { showSearchResult: false } )
                }
            }
        }
        case enActionTypes.GetPropertyManagersInCommercialTanentContactSearchPageAction: {
            return {
                ...state,
                commercialtenantcontact: {
                    search: Object.assign({}, state.commercialtenantcontact.search, { propertyManagerIDs: action.payload, showSearchResult: false } )
                }
            };
        }
        //#region

        //#region Commercial Work Order
        case enActionTypes.ShowCommercialWorkOrdersSearchPageAction: {
            return {
                ...state,
                commercialworkorder: {
                    search: Object.assign({}, state.commercialworkorder.search, { showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetCommercialWorkOrdersAction: {
            return {
                ...state,
                commercialworkorder: {
                    search: Object.assign({}, state.commercialworkorder.search, { workOrders: action.payload, showSearchResult: true } )
                }
            };
        } 
        case enActionTypes.GetCommercialPropertyManagersInWorkOrderSearchPageAction: {
            return {
                ...state,
                commercialworkorder: {
                    search: Object.assign({}, state.commercialworkorder.search, { propertyManagerIDs: action.payload, showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetCommercialServiceTypesInWorkOrderSearchPageAction: {
            return {
                ...state,
                commercialworkorder: {
                    search: Object.assign({}, state.commercialworkorder.search, { serviceTypeIDs: action.payload, showSearchResult: false } )
                }
            };
        }

        case enActionTypes.CloseCommercialWorkOrderResultPageAction: {
            return {
                ...state,
                commercialworkorder: {
                    search: Object.assign({}, state.commercialworkorder.search, { showSearchResult: false } )
                }
            };
        }
        //#endregion

        //#endregion

        //#region Commercial Tenant Invoice
        case enActionTypes.ShowCommercialTenantInvoicesSearchPageAction: {
            return {
                ...state,
                commercialTenantInvoice: {
                    search: Object.assign({}, state.commercialTenantInvoice.search, { showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetCommercialTenantInvoicesAction: {
            return {
                ...state,
                commercialTenantInvoice: {
                    search: Object.assign({}, state.commercialTenantInvoice.search, { tenantInvoices: action.payload, showSearchResult: true } )
                }
            };
        } 

        case enActionTypes.GetCommercialPropertyManagersInTenantInvoiceSearchPageAction: {
            return {
                ... state,
                commercialTenantInvoice: {
                    search: Object.assign({},state.commercialTenantInvoice.search,{propertyManagerIDs: action.payload, showSearchResult: false})
                }
            };
        }

        case enActionTypes.CloseCommercialTenantInvoiceResultPageAction: {
            return {
                ... state,
                commercialTenantInvoice: {
                    search: Object.assign({},state.commercialTenantInvoice.search,{showSearchResult: false})
                }
            }
        }

        case enActionTypes.GetCommercialPropertyInfoInTenantInvoiceSearchPageAction: {
            return {
                ... state,
                commercialTenantInvoice: {
                    search: Object.assign({},state.commercialTenantInvoice.search,{selectedPropertyID: action.payload, showSearchResult: false})
                }
            };
        }
        //#endregion
        
        //#region Lease Renewal
        case enActionTypes.ShowLeaseRenewalSearchAction: {
            return {
                ...state,
                leaseRenewal: {
                    search: Object.assign({}, state.leaseRenewal.search, { showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetLeaseRenewalSearchAction: {
            return {
                ...state,
                leaseRenewal: {
                    search: Object.assign({}, state.leaseRenewal.search, { leaseRenewals: action.payload, showSearchResult: true } )
                }
            };
        }
        case enActionTypes.CloseLeaseRenewalResultPageAction: {
            return {
                ...state,
                leaseRenewal: {
                    search: Object.assign({}, state.leaseRenewal.search, { showSearchResult: false } )
                }
            }
        }
        //#endregion

        //#region New Leases Count
        case enActionTypes.ShowNewLeasesCountSearchAction: {
            return {
                ...state,
                newLeasesCount: {
                    search: Object.assign({}, state.newLeasesCount.search, { showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetNewLeasesCountSearchAction: {
            return {
                ...state,
                newLeasesCount: {
                    search: Object.assign({}, state.newLeasesCount.search, { newLeasesCounts: action.payload, showSearchResult: true } )
                }
            };
        }
        case enActionTypes.CloseNewLeasesCountResultPageAction: {
            return {
                ...state,
                newLeasesCount: {
                    search: Object.assign({}, state.newLeasesCount.search, { showSearchResult: false } )
                }
            }
        }
        //#endregion

        //#region Lease Renewal Statistics
        case enActionTypes.ShowLeaseRenewalStatisticsSearchAction: {
            return {
                ...state,
                leaseRenewalStatistics: {
                    search: Object.assign({}, state.leaseRenewalStatistics.search, { showSearchResult: false } )
                }
            };
        }
        case enActionTypes.GetLeaseRenewalStatisticsSearchAction: {
            return {
                ...state,
                leaseRenewalStatistics: {
                    search: Object.assign({}, state.leaseRenewalStatistics.search, { statistics: action.payload, showSearchResult: true } )
                }
            };
        }
        case enActionTypes.CloseLeaseRenewalStatisticsResultPageAction: {
            return {
                ...state,
                leaseRenewalStatistics: {
                    search: Object.assign({}, state.leaseRenewalStatistics.search, { showSearchResult: false } )
                }
            }
        }
        //#endregion

        default:
          return state;
    }
}
