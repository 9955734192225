import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs';
import { DatePipe } from '@angular/common';

import { IAppState } from "../../store/store";
import { LoaderService } from "./../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";
import { IDNamePair, IDNameExtraPair, AuthService, SessionInfo, ServiceResponse } from "./../../services/authservice";
import { ReportsService } from './../reports.service';
import { ShowVisitorsSearchPage, GetCurrentStaffsVisitorsSearchPage } from './../reports.action';


import "../../../../../wwwroot/js/scripts.js";
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.css']
})
export class VisitorsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _authService: AuthService,
    private _service: ReportsService
  ) {
    this.model = new FormGroup({
      selectedStaffIDs: new FormControl(this.ngRedux.getState().ofi.openprivateinspections.search.selectedStaffIDs),
      privatePropertyInspection: new FormControl(false, Validators.required),
      openHomeAttendees: new FormControl(false, Validators.required),
      specificPropertyEnquiries: new FormControl(false, Validators.required),
      contractSent: new FormControl(-1, Validators.required),
      imSent: new FormControl(1, Validators.required),
      followUp: new FormControl(1, Validators.required),
      selectedPropertyText: new FormControl(null),
      dateFrom: new FormControl(null, Validators.required),
      dateTo: new FormControl(null, Validators.required)
    });
   }

  //#region Snackbar Config
  private snackbarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //#region 

  //#region Multi-Select Dropdown Settings
  private dropdownSettings: any = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  //#endregion

  //#region Subcription Variables
  private subscription_GetCurrentStaffs: Subscription;
  private getListingAddress_Subcription: Subscription;
  //#endregion

  //#region Private Variables
  private model: FormGroup;
  private dialogRef: any;
  private staffIDs: IDNamePair<string>[];
  private isPropertyLoading: boolean = false;
  private listingAddress: Observable<IDNameExtraPair<string, string>[]>;
  private selectedListing: IDNameExtraPair<string, string>;
  //#endregion

  //#region State Variables
  @select(state => (state as IAppState).reports.visitors.search.staffIDs) staffIDs$;
  @select(state => (state as IAppState).reports.visitors.search.showSearchResult) showSearchResult$;
  //#endregion

  ngOnInit() {
    this._loader.show();
    this._authService.GetSessionData()
    .subscribe((session: SessionInfo) => {
      this.subscription_GetCurrentStaffs =
        this._service.GetCurrentStaffs()
        .map((data: ServiceResponse<IDNamePair<string>[]>) => {
          if (!data.isError) {
            return new GetCurrentStaffsVisitorsSearchPage(data.response);
          }
          else {
            Observable.throwError(data.message);
          }
        })
        .subscribe(action => {
          this.staffIDs = (!!action.payload.filter(staff => staff.id == session.staffID).length ? action.payload.filter(staff => staff.id == session.staffID) : null) || action.payload;
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this._loader.hide();
        }),
        error => {
          this._loader.hide();
          this.openDialog("Alert", error, "alertdialog");
        }
    });
  }
  ngAfterViewInit() {
    LoadScript.init();
  }
  ngOnDestroy() {
    if (!!this.subscription_GetCurrentStaffs) this.subscription_GetCurrentStaffs.unsubscribe();
  }
  //#region Events
  onKeyUp(event: any) {
    if (!!event && event.keyCode >= 37 && event.keyCode <= 40) return;
    if (!!this.model.controls.selectedPropertyText.value && this.model.controls.selectedPropertyText.value.length >= 3) {
      this.isPropertyLoading = true;
      this.getListingAddress_Subcription =
      this._service.GetListingAddress(
        this.model.controls.selectedPropertyText.value
      )
      .subscribe(data => {
        if (!!data && !data.isError && !!data.response) {
          this.listingAddress = of(data.response);
          console.log("data.response: ", data.response);
        }
        else if (data.isError) {
          this.openDialog("Alert", data.message, "alertdialog");
        }
        this.isPropertyLoading = false;
      });
    }
  }
  onClearSelectedListing() {
    this.model.controls.selectedPropertyText.setValue(null)
    this.selectedListing = null;
  }
  onListingOptionSelected(selectedValue: IDNameExtraPair<string, string>) {
    this.selectedListing = selectedValue;
    console.log("selectedListing: ", this.selectedListing);
  }
  //#endregion

  //#region Functions
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  displayFn(item: IDNameExtraPair<string, string>): string {
    if (!!item && !!item.id) {
      return item.name;
    }
  }
  //#endregion

}
