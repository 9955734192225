import { ISearchManagementAgreement
    , IContactsSearchResults
    , IManagementAgreement
    , ITenantContactSearch
    , ITenantContactSearchResult
    , IWorkOrderResult
    , ITenantInvoiceSearchResult
    , ICommunicationStatement
    , ICommercialTenantContactSearchResult
    , ICommercialWorkOrderSearch
    , ICommercialWorkOrderResult
    , ICommercialManagementAgreement
    , ICommercialTenantInvoiceSearch
    , ICommercialTenantInvoiceSearchResult
    , ICommercialContactsSearchResults
    , IVacantPropertiesResult
    , ISearchVacantProperty
    , ILeadSource
    , ILeaseRenewalSearchResult
    , INewLeaseCountSearchResult
    , ILeaseRenewalStatisticsSearchResult
    } from './propertymanagement';
import { Action } from 'redux';
import { IDNamePair } from "../services/authservice";

export enum enActionTypes {
    ShowContactSearchPageAction = "[CONTACT] Shows Contact Search Page Action",
    GetContactSearchAction = "[CONTACT] Searches Contacts And Returns The Result Action",
    CloseContactSearchResultPageAction = "[CONTACT] Closes Contact Search Result Page And Moves To Contact Search Page",
    ShowManagementAgreementSearchPageAction = "[MANAGEMENT AGREEMENTS] Shows Management Agreements Search Page Action",
    GetManagementAgreementsAction = "[MANAGEMENT AGREEMENTS] Searches Management Agreements And Returns The Result Action",
    GetOwnerGroupsInMAgreementSearchPageAction = "[MANAGEMENT AGREEMENTS] Fetches The Owner Groups For Filling Dropdown In Management Agrrements Search Page Action",
    GetPropertyManagersInMAgreemantSearchPageAction = "[MANAGEMENT AGREEMENTS] Fetches The Property Managers For Filling Dropdown In Management Agrrements Search Page Action",
    CloseManagementAgreementResultPageAction = "[MANAGEMENT AGREEMENTS] Closes Management Agreement Search Result Page And Moves To Management Agreement Search Page",
    ShowCommercialContactSearchPageAction = "[COMMERCIAL CONTACT] Shows Commercial Contact Search Page Action",
    GetCommercialContactSearchAction = "[COMMERCIAL CONTACT] Searches Commercial Contacts And Returns The Result Action",
    CloseCommercialContactSearchResultPageAction = "[COMMERCIAL CONTACT] Closes Commercial Contact Search Result Page And Moves To Contact Search Page",
    ShowCommercialContactCreationPageAction = "[COMMERCIAL CONTACT] Shows Commercial Contact Creation Page Action",
    GetLeadSourcesInCommercialContactCreationPageAction = "[COMMERCIAL CONTACT] Fetches Lead Sources For Filling Dropdown In Commercial Contact Creation Page Action",
    CloseCommercialContactCreationPageAction = "[COMMERCIAL CONTACT] Closes Commercial Contact Creation Page And Moves To Commercial Contact Search Page",

    ShowCommercialManagementAgreementSearchPageAction = "[COMMERCIAL MANAGEMENT AGREEMENTS] Shows Commercial Management Agreements Search Page Action",
    GetCommercialManagementAgreementsAction = "[COMMERCIAL MANAGEMENT AGREEMENTS] Searches Management Agreements And Returns The Result Action",
    GetCommercialOwnerGroupsInMAgreementSearchPageAction = "[COMMERCIAL MANAGEMENT AGREEMENTS] Fetches The Owner Groups For Filling Dropdown In Management Agrrements Search Page Action",
    GetCommercialPropertyManagersInMAgreemantSearchPageAction = "[COMMERCIAL MANAGEMENT AGREEMENTS] Fetches The Property Managers For Filling Dropdown In Management Agrrements Search Page Action",
    CloseCommercialManagementAgreementResultPageAction = "[COMMERCIAL MANAGEMENT AGREEMENTS] Closes Management Agreement Search Result Page And Moves To Management Agreement Search Page",
    ShowCommercialTenantContactsSearchPageAction = "[COMMERCIAL TANENT CONTACT] Shows Commercial Tanent Contacts Search Page Action",
    GetCommercialTenantContactsAction = "[COMMERCIAL TANENT CONTACT] Searches Commercial Tanent Contacts And Returns The Reault Action",
    CloseCommercialTanentContactResultPageAction = "[COMMERCIAL TANENT CONTACT] Closes Commercial Tenant Contacts Search Result Page And Moves To Tanent Contacts Search Page",
    GetPropertyManagersInCommercialTanentContactSearchPageAction = "[COMMERCIAL TANENT CONTACT] Fetches The Property Managers For Filling Dropdown In Commercial Tenant Contacts Search Page Action",
    ShowTenantContactsSearchPageAction = "[TANENT CONTACT] Shows Tanent Contacts Search Page Action",
    GetTenantContactsAction = "[TANENT CONTACT] Searches Tanent Contacts And Returns The Result Action",
    CloseTanentContactResultPageAction = "[TANENT CONTACT] Closes Tenant Contacts Search Result Page And Moves To Tenant Contacts Search Page",
    GetPropertyManagersInTanentContactSearchPageAction = "[TANENT CONTACT] Fetches The Property Managers For Filling Dropdown In Tenant Contacts Search Page Action",
    ShowWorkOrdersSearchPageAction = "[WORK ORDER] Shows Work Orders Search Page Action",
    GetWorkOrdersAction = "[WORK ORDER] Searches Work Order And Returns The Result Action",
    CloseWorkOrderResultPageAction = "[WORK ORDER] Closes Work Order Search Result Page And Moves To Work Order Search Page",
    GetPropertyManagersInWorkOrderSearchPageAction = "[WORK ORDER] Fetches The Property Managers For Filling Dropdown In Tanent Contacts Search Page Action",
    GetServiceTypesInWorkOrderSearchPageAction = "[WORK ORDER] Fetches The Service Types For Filling Dropdown In Work Order Search Page Action",
    ShowCommercialWorkOrdersSearchPageAction = "[COMMERCIAL WORK ORDER] Shows Work Orders Search Page Action",
    GetCommercialWorkOrdersAction = "[COMMERCIAL WORK ORDER] Searches Work Order And Returns The Result Action",
    CloseCommercialWorkOrderResultPageAction = "[COMMERCIAL WORK ORDER] Closes Work Order Search Result Page And Moves To Work Order Search Page",
    GetCommercialPropertyManagersInWorkOrderSearchPageAction = "[COMMERCIAL WORK ORDER] Fetches The Property Managers For Filling Dropdown In Tanent Contacts Search Page Action",
    GetCommercialServiceTypesInWorkOrderSearchPageAction = "COMMERCIAL [WORK ORDER] Fetches The Service Types For Filling Dropdown In Work Order Search Page Action",
    ShowTenantInvoicesSearchPageAction = "[TENANT INVOICE] Shows Tenant Invoices Search Page Action",
    GetTenantInvoicesAction = "[TENANT INVOICE] Searches Tenant Invoice And Returns The Result Action",
    CloseTenantInvoiceResultPageAction = "[TENANT INVOICE] Closes Tenant Invoice Search Result Page And Moves To Tenant Invoice Search Page",
    GetPropertyManagersInTenantInvoiceSearchPageAction = "[TENANT INVOICE] Fetches The Property Managers For Filling Dropdown In Tenant Invoice Search Page Action",
    GetPropertyInfoInTenantInvoiceSearchPageAction = "[TENANT INVOICE] Fetches The Property Info For Filling Property Text Box In Tenant Invoice Search Page Action",
    ShowCommercialTenantInvoicesSearchPageAction = "[COMMERCIAL TENANT INVOICE] Shows Tenant Invoices Search Page Action",
    GetCommercialTenantInvoicesAction = "[COMMERCIAL TENANT INVOICE] Searches Tenant Invoice And Returns The Result Action",
    CloseCommercialTenantInvoiceResultPageAction = "[COMMERCIAL TENANT INVOICE] Closes Tenant Invoice Search Result Page And Moves To Tenant Invoice Search Page",
    GetCommercialPropertyManagersInTenantInvoiceSearchPageAction = "[COMMERCIAL TENANT INVOICE] Fetches The Property Managers For Filling Dropdown In Tenant Invoice Search Page Action",
    GetCommercialPropertyInfoInTenantInvoiceSearchPageAction = "[COMMERCIAL TENANT INVOICE] Fetches The Property Info For Filling Property Text Box In Tenant Invoice Search Page Action",
    GetVacancyPropertyLessThan14SearchAction = "[VACANT PROPERTY] Searches Vacant Property Less Than 14 And Returns The Result Action",
    GetVacancyPropertyGreaterThan14SearchAction = "[VACANT PROPERTY] Searches Vacant Property Greater Than 14 And Returns The Result Action",

    ShowLeaseRenewalSearchAction = "[LEASE RENEWAL] Shows Lease Renewal Search Page Action",
    GetLeaseRenewalSearchAction = "[LEASE RENEWAL] Searches Lease Renewal And Returns The Result Action",
    CloseLeaseRenewalResultPageAction = "[LEASE RENEWAL] Closes Lease Renewal Search Result Page And Moves To Lease Renewal Search Page",

    ShowNewLeasesCountSearchAction = "[NEW LEASES COUNT] Shows New Leases Count Search Page Action",
    GetNewLeasesCountSearchAction = "[NEW LEASES COUNT] Searches New Leases Count And Returns The Result Action",
    CloseNewLeasesCountResultPageAction = "[NEW LEASES COUNT] Closes New Leases Count Search Result Page And Moves To New Leases Count Search Page",

    ShowLeaseRenewalStatisticsSearchAction = "[NEW LEASES COUNT] Shows Lease Renewal Statistics Search Page Action",
    GetLeaseRenewalStatisticsSearchAction = "[NEW LEASES COUNT] Searches Lease Renewal Statistics And Returns The Result Action",
    CloseLeaseRenewalStatisticsResultPageAction = "[NEW LEASES COUNT] Closes Lease Renewal Statistics Search Result Page And Moves To Lease Renewal Statistics Search Page",
}

export class ShowContactSearchPage implements Action {    
    readonly type = enActionTypes.ShowContactSearchPageAction;
    constructor() {}
}
export class GetContactSearch implements Action {    
    readonly type = enActionTypes.GetContactSearchAction;
    constructor(public readonly payload: IContactsSearchResults[]) {}
}
export class CloseContactSearchResultPage implements Action{
    readonly type = enActionTypes.CloseContactSearchResultPageAction;
    constructor(public readonly payload:boolean){}
}


export class ShowManagementAgreementSearchPage implements Action {    
    readonly type = enActionTypes.ShowManagementAgreementSearchPageAction;
    constructor() {}
}
export class GetManagementAgreements implements Action {    
    readonly type = enActionTypes.GetManagementAgreementsAction;
    constructor(public readonly payload: IManagementAgreement[]) {}
}
export class GetOwnerGroupsInMAgreementSearchPage implements Action {    
    readonly type = enActionTypes.GetOwnerGroupsInMAgreementSearchPageAction;
    constructor(public readonly payload: IDNamePair<number>[]) {}
}

export class GetPropertyManagersInMAgreementSearchPage implements Action{
    readonly type = enActionTypes.GetPropertyManagersInMAgreemantSearchPageAction;
    constructor(public readonly payload:IDNamePair<string>[]){}
}
export class CloseManagementAgreementResultPage implements Action{
    readonly type = enActionTypes.CloseManagementAgreementResultPageAction;
    constructor(public readonly payload:boolean){}
}

export class ShowTenantContactsSearchPage implements Action {    
    readonly type = enActionTypes.ShowTenantContactsSearchPageAction;
    constructor() {}
}
export class GetTanentContacts implements Action {
    readonly type = enActionTypes.GetTenantContactsAction;
    constructor(public readonly payload:ITenantContactSearchResult[]){}
}
export class GetPropertyManagerTContactSearchPage implements Action {
    readonly type = enActionTypes.GetPropertyManagersInTanentContactSearchPageAction;
    constructor(public readonly payload:IDNamePair<string>[]){}
}
export class CloseTenantContactsResultPage implements Action{
    readonly type = enActionTypes.CloseTanentContactResultPageAction;
    constructor(public readonly payload:boolean){}
}

export class ShowWorkOrdersSearchPage implements Action {    
    readonly type = enActionTypes.ShowWorkOrdersSearchPageAction;
    constructor() {}
}
export class GetWorkOrder implements Action {
    readonly type = enActionTypes.GetWorkOrdersAction;
    constructor(public readonly payload:IWorkOrderResult[]){}
}
export class CloseWorkOrderResultPage implements Action{
    readonly type = enActionTypes.CloseWorkOrderResultPageAction;
    constructor(public readonly payload:boolean){}
}
export class GetPropertyManagerWorkOrderSearchPage implements Action {
    readonly type = enActionTypes.GetPropertyManagersInWorkOrderSearchPageAction;
    constructor(public readonly payload:IDNamePair<string>[]){}
}
export class GetServiceTypesInWorkOrderSearchPage implements Action {
    readonly type = enActionTypes.GetServiceTypesInWorkOrderSearchPageAction;
    constructor(public readonly payload:IDNamePair<number>[]){}
}

export class ShowTenantInvoicesSearchPage implements Action {    
    readonly type = enActionTypes.ShowTenantInvoicesSearchPageAction;
    constructor() {}
}
export class GetTenantInvoices implements Action {
    readonly type = enActionTypes.GetTenantInvoicesAction;
    constructor(public readonly payload:ITenantInvoiceSearchResult[]){}
}
export class CloseTenantInvoiceResultPage implements Action {
    readonly type = enActionTypes.CloseTenantInvoiceResultPageAction;
    constructor(public readonly payload:boolean){}
}
export class GetPropertyManagersInTenantInvoiceSearchPage implements Action {
    readonly type = enActionTypes.GetPropertyManagersInTenantInvoiceSearchPageAction;
    constructor(public readonly payload: IDNamePair<string>[]){}
}
export class GetPropertyInfoInTenantInvoiceSearchPage implements Action {
    readonly type = enActionTypes.GetPropertyInfoInTenantInvoiceSearchPageAction
    constructor(public readonly payload: any){}
}

export class GetVacantPropertyLessThan14Page implements Action {    
    readonly type = enActionTypes.GetVacancyPropertyLessThan14SearchAction;
    constructor(public readonly payload: IVacantPropertiesResult[]) {}
}

export class GetVacantPropertyGreaterThan14Page implements Action {    
    readonly type = enActionTypes.GetVacancyPropertyGreaterThan14SearchAction;
    constructor(public readonly payload: IVacantPropertiesResult[]) {}
}
//#region Commercial

//#region Commercial Contacts
export class ShowCommercialContactSearchPage implements Action {    
    readonly type = enActionTypes.ShowCommercialContactSearchPageAction;
    constructor() {}
}
export class GetCommercialContactSearch implements Action {    
    readonly type = enActionTypes.GetCommercialContactSearchAction;
    constructor(public readonly payload: ICommercialContactsSearchResults[]) {}
}
export class CloseCommercialContactSearchResultPage implements Action{
    readonly type = enActionTypes.CloseCommercialContactSearchResultPageAction;
    constructor(public readonly payload:boolean){}
}
export class ShowCommercialContactCreationPage implements Action {
  readonly type = enActionTypes.ShowCommercialContactCreationPageAction;
  constructor(public readonly payload: boolean) { }
}
export class GetLeadSourcesInCommercialContactCreationPage implements Action {
  readonly type = enActionTypes.GetLeadSourcesInCommercialContactCreationPageAction;
  constructor(public readonly payload: ILeadSource[]) { }
}
export class CloseCommercialContactCreationPage implements Action {
  readonly type = enActionTypes.CloseCommercialContactCreationPageAction;
  constructor() { }
}
//#endregion

//#region Management Agreement
export class ShowCommercialManagementAgreementSearchPage implements Action {    
    readonly type = enActionTypes.ShowCommercialManagementAgreementSearchPageAction;
    constructor() {}
}
export class GetCommercialManagementAgreements implements Action {    
    readonly type = enActionTypes.GetCommercialManagementAgreementsAction;
    constructor(public readonly payload: ICommercialManagementAgreement[]) {}
}
export class GetCommercialOwnerGroupsInMAgreementSearchPage implements Action {    
    readonly type = enActionTypes.GetCommercialOwnerGroupsInMAgreementSearchPageAction;
    constructor(public readonly payload: IDNamePair<number>[]) {}
}
export class GetCommercialPropertyManagersInMAgreementSearchPage implements Action{
    readonly type = enActionTypes.GetCommercialPropertyManagersInMAgreemantSearchPageAction;
    constructor(public readonly payload:IDNamePair<string>[]){}
}
export class CloseCommercialManagementAgreementResultPage implements Action{
    readonly type = enActionTypes.CloseCommercialManagementAgreementResultPageAction;
    constructor(public readonly payload:boolean){}
}
//#endregion

//#region Tenant Contacts
export class ShowCommercialTenantContactsSearchPage implements Action {    
    readonly type = enActionTypes.ShowCommercialTenantContactsSearchPageAction;
    constructor() {}
}
export class GetCommercialTanentContacts implements Action {
    readonly type = enActionTypes.GetCommercialTenantContactsAction;
    constructor(public readonly payload:ICommercialTenantContactSearchResult[]){}
}
export class CloseCommercialTenantContactsResultPage implements Action{
    readonly type = enActionTypes.CloseCommercialTanentContactResultPageAction;
    constructor(public readonly payload:boolean){}
}
export class GetPropertyManagersInCommercialTanentContactSearchPage implements Action {
    readonly type = enActionTypes.GetPropertyManagersInCommercialTanentContactSearchPageAction;
    constructor(public readonly payload:IDNamePair<string>[]){}
}
//#region

//#region Commercial Work Order
export class ShowCommercialWorkOrdersSearchPage implements Action {    
    readonly type = enActionTypes.ShowCommercialWorkOrdersSearchPageAction;
    constructor() {}
}
export class GetCommercialWorkOrder implements Action {
    readonly type = enActionTypes.GetCommercialWorkOrdersAction;
    constructor(public readonly payload:ICommercialWorkOrderResult[]){}
}
export class CloseCommercialWorkOrderResultPage implements Action{
    readonly type = enActionTypes.CloseCommercialWorkOrderResultPageAction;
    constructor(public readonly payload:boolean){}
}
export class GetCommercialPropertyManagerWorkOrderSearchPage implements Action {
    readonly type = enActionTypes.GetCommercialPropertyManagersInWorkOrderSearchPageAction;
    constructor(public readonly payload:IDNamePair<string>[]){}
}
export class GetCommercialServiceTypesInWorkOrderSearchPage implements Action {
    readonly type = enActionTypes.GetCommercialServiceTypesInWorkOrderSearchPageAction;
    constructor(public readonly payload:IDNamePair<number>[]){}
}
//#endregion

//#region Commercial Tenant Invoice 
export class ShowCommercialTenantInvoicesSearchPage implements Action {    
    readonly type = enActionTypes.ShowCommercialTenantInvoicesSearchPageAction;
    constructor() {}
}
export class GetCommercialTenantInvoices implements Action {
    readonly type = enActionTypes.GetCommercialTenantInvoicesAction;
    constructor(public readonly payload:ICommercialTenantInvoiceSearchResult[]){}
}
export class CloseCommercialTenantInvoiceResultPage implements Action {
    readonly type = enActionTypes.CloseCommercialTenantInvoiceResultPageAction;
    constructor(public readonly payload:boolean){}
}
export class GetCommercialPropertyManagersInTenantInvoiceSearchPage implements Action {
    readonly type = enActionTypes.GetCommercialPropertyManagersInTenantInvoiceSearchPageAction;
    constructor(public readonly payload: IDNamePair<string>[]){}
}
export class GetCommercialPropertyInfoInTenantInvoiceSearchPage implements Action {
    readonly type = enActionTypes.GetCommercialPropertyInfoInTenantInvoiceSearchPageAction
    constructor(public readonly payload: any){}
}
//#endregion

//#region Lease Renewal
export class ShowLeaseRenewalSearch implements Action {
    readonly type = enActionTypes.ShowLeaseRenewalSearchAction;
    constructor() {}
}
export class GetLeaseRenewalSearch implements Action {
  readonly type = enActionTypes.GetLeaseRenewalSearchAction;
  constructor(public readonly payload: ILeaseRenewalSearchResult[]) { }
}
export class CloseLeaseRenewalResultPage implements Action {
  readonly type = enActionTypes.CloseLeaseRenewalResultPageAction;
  constructor(public readonly payload: boolean) { }
}
//#endregion

//#region New Leases Count
export class ShowNewLeasesCountSearch implements Action {
  readonly type = enActionTypes.ShowNewLeasesCountSearchAction;
  constructor() { }
}
export class GetNewLeasesCountSearch implements Action {
  readonly type = enActionTypes.GetNewLeasesCountSearchAction;
  constructor(public readonly payload: INewLeaseCountSearchResult[]) { }
}
export class CloseNewLeasesCountResultPage implements Action {
  readonly type = enActionTypes.CloseNewLeasesCountResultPageAction;
  constructor(public readonly payload: boolean) { }
}
//#endregion

//#region Lease Renewal Statistics
export class ShowLeaseRenewalStatisticsSearch implements Action {
  readonly type = enActionTypes.ShowLeaseRenewalStatisticsSearchAction;
  constructor() { }
}
export class GetLeaseRenewalStatisticsSearch implements Action {
  readonly type = enActionTypes.GetLeaseRenewalStatisticsSearchAction;
  constructor(public readonly payload: ILeaseRenewalStatisticsSearchResult[]) { }
}
export class CloseLeaseRenewalStatisticsResultPage implements Action {
  readonly type = enActionTypes.CloseLeaseRenewalStatisticsResultPageAction;
  constructor(public readonly payload: boolean) { }
}
//#endregion

//#endregion


export type PropertyManagementAction =
| ShowContactSearchPage
| GetContactSearch
| CloseContactSearchResultPage
| ShowManagementAgreementSearchPage
| GetOwnerGroupsInMAgreementSearchPage
| GetManagementAgreements
| GetPropertyManagersInMAgreementSearchPage
| CloseManagementAgreementResultPage
| ShowTenantContactsSearchPage
| GetTanentContacts
| GetPropertyManagerTContactSearchPage
| CloseTenantContactsResultPage
| ShowWorkOrdersSearchPage
| GetWorkOrder
| CloseWorkOrderResultPage
| GetPropertyManagerWorkOrderSearchPage
| GetServiceTypesInWorkOrderSearchPage
| ShowTenantInvoicesSearchPage
| GetTenantInvoices
| CloseTenantInvoiceResultPage
| GetPropertyManagersInTenantInvoiceSearchPage
| GetPropertyInfoInTenantInvoiceSearchPage
| ShowCommercialContactSearchPage
| GetCommercialContactSearch
| CloseCommercialContactSearchResultPage
| ShowCommercialContactCreationPage
| GetLeadSourcesInCommercialContactCreationPage
| CloseCommercialContactCreationPage
| ShowCommercialManagementAgreementSearchPage
| ShowCommercialTenantContactsSearchPage
| GetCommercialTanentContacts
| CloseCommercialTenantContactsResultPage
| GetPropertyManagersInCommercialTanentContactSearchPage
| ShowCommercialTenantContactsSearchPage
| GetCommercialOwnerGroupsInMAgreementSearchPage
| GetCommercialManagementAgreements
| GetCommercialPropertyManagersInMAgreementSearchPage
| CloseCommercialManagementAgreementResultPage
| ShowCommercialWorkOrdersSearchPage
| GetCommercialWorkOrder
| CloseCommercialWorkOrderResultPage
| GetCommercialPropertyManagerWorkOrderSearchPage
| GetCommercialServiceTypesInWorkOrderSearchPage
| ShowCommercialTenantInvoicesSearchPage
| GetCommercialTenantInvoices
| CloseCommercialTenantInvoiceResultPage
| GetCommercialPropertyManagersInTenantInvoiceSearchPage
| GetCommercialPropertyInfoInTenantInvoiceSearchPage
| GetVacantPropertyLessThan14Page
| GetVacantPropertyGreaterThan14Page
| ShowLeaseRenewalSearch
| GetLeaseRenewalSearch
| CloseLeaseRenewalResultPage
| ShowNewLeasesCountSearch
| GetNewLeasesCountSearch
| CloseNewLeasesCountResultPage
| ShowLeaseRenewalStatisticsSearch
| GetLeaseRenewalStatisticsSearch
| CloseLeaseRenewalStatisticsResultPage
