import { Observable, of, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable()
export class EncryptionService {
    private key: string = "multiarrayFree2015!";

    /**
     * Encrypts a string
     * @param value is the string to encrypt
     */
    public encrypt(value: string): string { 
        return CryptoJS.AES.encrypt(value, this.key).toString();
    }

    /**
     * Decrypts an ecrypted string
     * @param value is the string to decrypt
     */
    public decrypt(value: string): string {
        let data: string = "";
        try {  
            data = CryptoJS.AES.decrypt(value, this.key).toString(CryptoJS.enc.Utf8);
        }
        catch(error) {  
            // handel error here            
            console.error(error);
            return error;
        }
        return data;
    }
}
