import { Http } from "@angular/http";
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { state } from "@angular/animations";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs/Observable";
import { AuthService, SessionInfo } from "./services/authservice";
import { isPlatformBrowser, isPlatformServer, Location } from "@angular/common";

import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from './store/store';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _http: Http,
    private _service: AuthService,
    private _router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
    

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (isPlatformBrowser(this.platformId)) {
      return this._service.GetSessionData().map((data: SessionInfo) => {
        if (data.isLoggedIn) {
          this._router.navigate(['/dashboard']);
        }
        return !data.isLoggedIn;
      }).catch(() => {
        //this._router.navigate(['/login']);
        return Observable.of(true);
      });      
    }
    else {
      return Observable.of(true);
    }

  }

}
