import { Observable } from "rxjs/Observable";

import { Injectable, Inject, PLATFORM_ID, Injector } from "@angular/core";
import {
  Http,
  Headers,
  Response,
  RequestOptions,
  RequestMethod
} from "@angular/http";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
//import "rxjs/add/Observable/of";
//import "rxjs/add/operator/delay";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";

@Injectable()
export class AuthService {
  constructor(
    private _http: Http,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  Authenticate(
    connectionStringName: string,
    username: string,
    password: string,
    authenticationCode: string = null
  ): Observable<ServiceResponse<SessionInfo>> {
    return this._http
      .get("api/Login/Authenticate", {
        params: {
          connectionStringName: connectionStringName,
          username: username,
          password: password,
          authenticationCode: authenticationCode
        }
      })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  GetSessionData(): Observable<SessionInfo> {
    if (isPlatformBrowser(this.platformId)) {
      return this._http
        .get("api/Login/GetSessionData")
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
    } else {
      let session: SessionInfo = {
        id: "",
        staffID: "",
        agencyID: "",
        departmentID: "",
        firstName: "",
        lastName: "",
        userName: "",
        mailMergeName: "",
        email: "",
        mobile: "",
        isLoggedIn: false,
        connectionStringName: "",
        tokenEmailService: "",
        contactAccessible: false,
        trisetSystemID: 0,
        categoryID: 0,
        isCommercial: false,
        isSessionApiCreated: false,
        showQRCode: false,
        showLogin: true,
        showAuthCode: false,
        qrCodeUrl: null,
        textCode: null,
        userRoles: []
      };
      return Observable.of(session);
    }
  }
  GetStaffRoles(
    database: string,
    staffID: string
  ): Observable<ServiceResponse<StaffRole[]>> {
    return this._http
      .post("api/Login/GetStaffRoles", {
        database: database,
        staffID: staffID
      })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetAgencyBySessionID(
    database: string,
    sessionID: string
  ): Observable<ServiceResponse<Agency>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Login/GetAgencyBySessionID", {
        database: database,
        sessionID: sessionID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetStaffBySessionID(
    database: string,
    sessionID: string
  ): Observable<ServiceResponse<StaffView>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Login/GetStaffBySessionID", {
        database: database,
        sessionID: sessionID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetDepartment(
    database: string,
    sessionID: string,
    departmentID: string
  ): Observable<ServiceResponse<Department>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Login/GetDepartment", {
        database: database,
        sessionID: sessionID,
        departmentID: departmentID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  SignOut(): Observable<ServiceResponse<boolean>> {
    return this._http
      .get("api/Login/SignOut")
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  GetDepartments(): Observable<Department[]> {
    return this._http
      .get("api/Login/GetDepartments")
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  ChangeDepartment(departmentID: string): Observable<SessionInfo> {
    return this._http
      .get("api/Login/ChangeDepartment", { params: { departmentID: departmentID } })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }

  //#region Contacts

  //#region Rental Tenant
  GetRentalTenantSortOrders(): Observable<IDNamePair<number>[]> {
    return this._http
      .get("api/Contacts/GetRentalTenantSortOrders")
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetRentalTenantSearchResult(address: string, sortOrder: number | null): Observable<RentalTenantSearchResult[]> {
    return this._http
      .get("api/Contacts/GetRentalTenantSearchResult", { params: { address: address, sortOrder: sortOrder } })
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  //#endregion

  //#endregion

  GetRMSetting(
    database: string,
    departmentID: string
  ): Observable<ServiceResponse<RMSetting>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Login/GetRMSetting", {
        database: database,
        departmentID: departmentID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
}
export class StaffRole {
  constructor(
    public id: string,
    public staffID: string,
    public departmentID: string,
    public roleID: string,
    public agencyID: string,
    public name: string,
    public forSaleSystem: boolean,
    public forPropertyManagementSystem: boolean,
    public forStrataSystem: boolean
  ) { }
}
export class SessionInfo {
  constructor(
    public id: string,
    public staffID: string,
    public agencyID: string,
    public departmentID: string,
    public trisetSystemID: number,
    public categoryID: number,
    public firstName: string,
    public lastName: string,
    public userName: string,
    public mailMergeName: string,
    public email: string,
    public mobile: string,
    public isLoggedIn: boolean,
    public connectionStringName: string,
    public tokenEmailService: string,
    public contactAccessible: boolean,
    public isCommercial: boolean,
    public isSessionApiCreated: boolean,
    public showQRCode: boolean,
    public showLogin: boolean,
    public showAuthCode: boolean,
    public qrCodeUrl: string,
    public textCode: string,
    public userRoles: StaffRole[]
  ) { }
}
export enum enImageSize {
  cp,
  crop,
  padw,
  padb
}
export class IDNamePair<T> {
  constructor(public id: T, public name: string) { }
}
export class IDNameExtraPair<S, T> {
  constructor(public id: S, public extra: T, public name: string) { }
}
export class RentalTenantSearchResult {
  constructor(
    public propertyAddress: string,
    public staffCode: string,
    public leaseStart: Date | null,
    public leaseEnd: Date | null,
    public rentReview: Date | null,
    public weeklyRent: number | null,
    public paidToDate: Date | null,
    public nextInspection: Date | null,
    public tenantName: string,
    public mobile: string,
    public homePhone: string,
    public email: string,
    public keyNo: number | null
  ) { }
}
export class LoginUserDetails {
  constructor(
    public id: string,
    public agencyID: string,
    public statusID: number,
    public name: string,
    public attention: string,
    public addressID: string | null,
    public postalAddressID: string | null,
    public companyID: string | null,
    public leadSourceID: number,
    public categoryIDs: number,
    public workPhone: string,
    public fax: string,
    public email: string,
    public webPageAddress: string,
    public acceptsEmail: boolean,
    public acceptsSMS: boolean,
    public acceptsMail: boolean,
    public typeID: number,
    public addressText: string,
    public title: string,
    public givenNames: string,
    public surname: string,
    public letterName: string,
    public dateOfBirth: Date | null,
    public jobTitle: string,
    public occupationID: number | null,
    public homePhone: string,
    public mobilePhone: string,
    public workPhoneExt: string,
    public photoImageID: string | null,
    public code: string,
    public userName: string,
    public dateStarted: Date | null,
    public terminationDate: Date | null,
    public terminationNotes: string,
    public notes: string,
    public hideMobileOnWeb: boolean,
    public hideFromStaffWebList: boolean,
    public webProfileNotes: string,
    public webDisplayOrder: number | null,
    public mailMergeName: string,
    public departmentID: string | null,
    public password: string,
    public personalAssitantStaffID: string | null,
    public salesCoordinatorStaffID: string | null,
    public supportAssitantStaffID: string | null,
    public includeInStatistics: boolean,
    public commissionOpeningBalance: number,
    public revisionID: string,
    public salesStaff: boolean,
    public disableMainGridToolTip: boolean | null,
    public reiID: string,
    public mobileDeviceSync: boolean,
    public gmailUsername: string,
    public gmailPassword: string,
    public passwordHash: string,
    public altSQLUser: string,
    public altSQLUserPassword: string,
    public email2: string,
    public mobilePhone2: string,
    public metaDescription: string,
    public metaKeyWords: string,
    public isPropertyManager: boolean,
    public authKeyEncrypted: Int8Array
  ) { }
}
export class LoginUserAddressDetails {
  constructor(
    public id: string,
    public unit: string,
    public level: Date | null,
    public siteName: Date | null,
    public streetNumber: Date | null,
    public streetName: number | null,
    public streetTypeID: number,
    public suburbID: number,
    public text: string,
    public latitude: number | null,
    public longitude: number | null,
    public geoCodeAccuracyLevel: number | null
  ) { }
}
export class LoginInfoDetails {
  constructor(
    public user: LoginUserDetails,
    public address: LoginUserAddressDetails,
    public isLoggedIn: boolean
  ) { }
}
export class Department {
  constructor(
    public id: string,
    public name: string,
    public agencyID: string,
    public trisetSystemID: number,
    public categoryID: number
  ) { }
}

export class ContactDetails {
  constructor(
    public id: string,
    public name: string,
    public email: string,
    public mobilePhone: string,
    public homePhone: string,
    public workPhone: string,
    public type: number
  ) { }
}
export class ServiceResponse<T> {
  constructor(
    public isError: boolean,
    public message: string,
    public response: T
  ) { }
}
export class Agency {
  id: string;
  marketingGroupID: number;
  name: string;
  tradingAs: string;
}
export class StaffView {
  id: string;
  agencyID: string;
  code: string;
  userName: string;
  statusID: number;
  name: string;
  title: string;
  givenNames: string;
  surname: string;
  letterName: string;
  attention: string;
  dateOfBirth: Date | null;
  jobTitle: string;
  addressID: string;
  postalAddressID: string;
  homePhone: string;
  mobilePhone: string;
  workPhoneExt: string;
  email: string;
  photoImageID: string;
}
export class RMSetting {
  id: string;
  agencyID: string;
  departmentID: string;
  leaseRenewalHomePageRefreshInMinutes: number | null;
  rmsettingPeriodInWeeksShownOnDesktop: number | null;
}
