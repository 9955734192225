import { ISearchTemplate, ISearchEmail } from './emarketing';
import { IEmarketingState, INITIAL_EMARKETING_STATE } from "./emarketing.state";
import { EmarketingAction, enActionTypes } from "./emarketing.action";
import { Reducer, Action } from "redux";

export const emarketingReducer: Reducer<IEmarketingState> = (state = INITIAL_EMARKETING_STATE(), action: EmarketingAction): IEmarketingState => {
  switch (action.type) {
    case enActionTypes.GetRecipientTypesAction: {
      return {
        ...state,
        template: {
          search: {
            recipientTypes: action.payload
          } as ISearchTemplate,
          addEditView: state.template.addEditView
        }
      };
    }
    case enActionTypes.GetEmailTemplatesAction: {
      return {
        ...state,
        template: {
          search: {
            templateName: action.payload.templateName,
            selectedRecipientTypes: action.payload.selectedRecipientTypes,
            recipientTypes: state.template.search.recipientTypes,
            templates: action.payload.templates,
            showSearchResult: action.payload.showSearchResult
          } as ISearchTemplate,
          addEditView: state.template.addEditView
        }
      };
    }
    case enActionTypes.CloseEmailTemplatesSearchResultAction: {
      return {
        ...state,
        template: {
          search: {
            templateName: state.template.search.templateName,
            selectedRecipientTypes: state.template.search.selectedRecipientTypes,
            recipientTypes: state.template.search.recipientTypes,
            templates: state.template.search.templates,
            showSearchResult: !action.payload
          } as ISearchTemplate,
          addEditView: state.template.addEditView
        }
      };
    }
    case enActionTypes.EditTempateAction: {
      return {
        ...state,
        template: {
          search: state.template.search,
          addEditView: action.payload
        }
      }
    }
    case enActionTypes.EditTempateCloseAction: {
      return {
        ...state,
        template: Object.assign({}, state.template, { addEditView: Object.assign({}, state.template.addEditView, { showEditPage: !action.payload }) })
      };
    }
    case enActionTypes.AddWidgetToTemplateAction: {
      return {
        ...state,
        template: Object.assign
          (
            {}, state.template,
            {
              addEditView:
                Object.assign
                  (
                    {}, state.template.addEditView,
                    {
                      template: Object.assign
                        (
                          {}, state.template.addEditView.template,
                          {
                            htmlBody: action.payload.htmlBody,
                            widgets: [
                              ...state.template.addEditView.template.widgets,
                              { id: action.payload.widget.id, settings: action.payload.widget.settings, widgetType: action.payload.widget.widgetType, templateHTML: action.payload.widget.templateHTML }
                            ]
                          }
                        )
                    }
                  )
            }
          )
      }
    }
    case enActionTypes.RemoveWidgetFromTemplateAction: {
      return {
        ...state,
        template: Object.assign
          (
            {}, state.template,
            {
              addEditView:
                Object.assign
                  (
                    {}, state.template.addEditView,
                    {
                      template: Object.assign
                        (
                          {}, state.template.addEditView.template,
                          {
                            htmlBody: state.template.addEditView.template.htmlBody,
                            widgets: state.template.addEditView.template.widgets.filter(widg => widg.id != action.payload)
                          }
                        )
                    }
                  )
            }
          )
      };
    }
    case enActionTypes.GetTemplateImageTypesAction: {
      return {
        ...state,
        template: Object.assign
          (
            {}, state.template,
            {
              addEditView:
                Object.assign({}, state.template.addEditView, { templateImageTypes: action.payload })
            }
          )
      };
    }
    case enActionTypes.GetTemplateEmailImagesAction: {
      return {
        ...state,
        template: Object.assign
          (
            {}, state.template,
            {
              addEditView:
                Object.assign({}, state.template.addEditView, { templateEmailImages: action.payload })
            }
          )
      };
    }
    case enActionTypes.LoadNewTemplatePageAction: {
      return {
        ...state,
        template: Object.assign
          (
            {}, state.template,
            {
              search: Object.assign({}, state.template.search, { showSearchResult: false })
            },
            {
              addEditView:
                Object.assign({}, state.template.addEditView, { showNewPage: true, recipientTypes: action.payload.recipientTypes, agencies: action.payload.agencies, availableFor: "Agency" })
            }
          )
      };
    }
    case enActionTypes.GetTemplateDepartmentsAndStaffsAction: {
      return {
        ...state,
        template: Object.assign
          (
            {}, state.template,
            {
              addEditView:
                Object.assign({}, state.template.addEditView, { departments: action.payload.departments, staffs: action.payload.staffs })
            }
          )
      };
    }
    case enActionTypes.CloseNewTemplatePageAction: {
      return {
        ...state,
        template: Object.assign
          (
            {}, state.template,
            {
              search: Object.assign({}, state.template.search, { showSearchResult: false })
            },
            {
              addEditView:
                Object.assign({}, state.template.addEditView, { showNewPage: !action.payload })
            }
          )
      };
    }
    case enActionTypes.LoadStaffsAndStatusesOfEmailSearchPageAction: {
      return {
        ...state,
        email: Object.assign
          (
            {}, state.email,
            {
              search: Object.assign({}, state.email.search, { showSearchResult: false, staffs: action.payload.staffs, statuses: action.payload.statuses })
            },
            {
              addEditView: Object.assign({}, state.email.addEditView, { showNewPage: false, showEditPage: false, showPreviewPage: false })
            }
          )
      };
    }
    case enActionTypes.LoadNewEmailPageAction: {
      return {
        ...state,
        email: Object.assign
          (
            {}, state.email,
            {
              search: Object.assign({}, state.email.search, { showSearchResult: false })
            },
            {
              addEditView:
                Object.assign({}, state.email.addEditView,
                  {
                    showEditPage: false,
                    showPreviewPage: false,
                    showNewPage: true,
                    selectedRecipientSourceType: action.payload.selectedRecipientSourceType,
                    recipientSourceTypes: [...action.payload.recipientSourceTypes],
                    recipientTypes: [...action.payload.recipientTypes],
                    contactLists: [...action.payload.contactLists]
                  })
            }
          )
      };
    }
    case enActionTypes.CloseNewEmailPageAction: {
      return {
        ...state,
        email: Object.assign
          (
            {}, state.email,
            {
              search: Object.assign({}, state.email.search, { showSearchResult: false })
            },
            {
              addEditView:
                Object.assign({}, INITIAL_EMARKETING_STATE().email.addEditView, { showNewPage: !action.payload, showEditPage: !action.payload })
            }
          )
      };
    }
    case enActionTypes.GetRecipientTypesOnNewEmailPageAction: {
      return {
        ...state,
        email: Object.assign
          (
            {}, state.email,
            {
              addEditView: Object.assign({}, state.email.addEditView, { recipientTypes: action.payload })
            }
          )
      };
    }
    case enActionTypes.GetEmailTemplatesOnNewEmailPageAction: {
      return {
        ...state,
        email: Object.assign
          (
            {}, state.email,
            {
              addEditView: Object.assign({}, state.email.addEditView, { templates: [...action.payload] })
            }
          )
      };
    }
    case enActionTypes.AfterNewEmailCreationGotoEmailEditPageAction: {
      return {
        ...state,
        email: Object.assign
          (
            {}, state.email,
            {
              search: Object.assign({}, state.email.search, { showSearchResult: false })
            },
            {
              addEditView: Object.assign({}, state.email.addEditView, 
                { 
                  id: action.payload.email.id,
                  //isNew: !(!!action.payload.email.id && action.payload.email.id.indexOf("000000000000") < 0),
                  from: action.payload.from,
                  subject: action.payload.email.subject,
                  textBody: action.payload.email.textBody,
                  htmlBody: action.payload.email.htmlBody,
                  recipientList: [...action.payload.recipients],
                  showNewPage: true,
                  showEditPage: true 
                })
            }
          )
      };
    }
    case enActionTypes.SelectEmailTemplateOnNewEmailPageAction: {
      return {
        ...state,
        email: Object.assign
          (
            {}, state.email,            
            {
              addEditView: Object.assign({}, state.email.addEditView, { selectedTemplate: action.payload })
            }
          )
      };
    }
    case enActionTypes.GetEmailsSearchAction: {
      return {
        ...state,
        email: {
          search: Object.assign({}, state.email.search, {
            selectedStatuses: action.payload.selectedStatuses,
            selectedCreatedBys: action.payload.selectedCreatedBys,
            selectedSentBys: action.payload.selectedSentBys,
            maxResultsToReturn: action.payload.maxResultsToReturn,
            emailMarker: action.payload.emailMarker,            
            emails: action.payload.emails,
            showSearchResult: action.payload.showSearchResult
          }),
          addEditView: Object.assign({}, state.email.addEditView, { showNewPage: false, showEditPage: false, showPreviewPage: false })
        }
      };
    }
    case enActionTypes.EditEmailFromSearchResultAction: {
      return {
        ...state,
        email: Object.assign({}, state.email, {
          search: Object.assign({}, state.email.search, { showSearchResult: false }),
          addEditView: Object.assign({}, INITIAL_EMARKETING_STATE().email.addEditView, action.payload)
        })
      };
    }
    case enActionTypes.ShowHidePreviewEmailFromSearchResultEditPageAction: {
      return {
        ...state,
        email: Object.assign({}, state.email, {
          search: Object.assign({}, state.email.search, { showSearchResult: false }),
          addEditView: Object.assign({}, state.email.addEditView, { showNewPage: false, showEditPage: !action.payload, showPreviewPage: action.payload })
        })
      };
    }
    case enActionTypes.ModifyEmailAction: {
      return {
        ...state,
        email: Object.assign
          (
            {}, state.email,
            {
              addEditView: Object.assign({}, state.email.addEditView, 
                {                  
                  subject: action.payload.subject,
                  textBody: action.payload.textBody,
                  htmlBody: action.payload.htmlBody,
                  widgets: action.payload.widgets,
                  attachments: action.payload.attachments
                })
            }
          )
      };
    }
    case enActionTypes.RemoveEmailAction: {
      let index: number = state.email.search.emails.findIndex(email => email.id === action.payload.id);
      if (index !== -1) {
        state.email.search.emails.splice(index, 1);
      }
      return state;
    }
    case enActionTypes.LoadContactListsSearchPageAction: {
      return {
        ...state,
        contactlists: {
          search: Object.assign({}, state.contactlists.search, { statuses: action.payload }),
          addEditView: state.contactlists.addEditView
        }
      };
    }
    case enActionTypes.GetContactListsSearchAction: {
      return {
        ...state,
        contactlists: {
          search: Object.assign({}, state.contactlists.search, { contactLists: action.payload, showSearchResult: true }),
          addEditView: state.contactlists.addEditView
        }
      };
    }
    case enActionTypes.LoadNewContactListsPageAction: {
      return {
        ...state,
        contactlists: {
          search: Object.assign({}, state.contactlists.search, { showSearchResult: false }),
          addEditView: Object.assign({}, INITIAL_EMARKETING_STATE().contactlists.addEditView, { statuses: action.payload, showAddEditPage: true }),
        }
      };
    }
    case enActionTypes.EditContactListAction: {
      return {
        ...state,
        contactlists: {
          search: Object.assign({}, state.contactlists.search, { showSearchResult: false }),
          addEditView: Object.assign({}, INITIAL_EMARKETING_STATE().contactlists.addEditView, 
          { 
            contactListID: action.payload.contactList.id,
            name: action.payload.contactList.name,
            description: action.payload.contactList.description,
            autoSubscribeNewContacts: action.payload.contactList.autoSubscribeNewContacts,
            status: action.payload.contactList.status,
            filter: action.payload.contactList.filter,
            statuses: action.payload.statuses,
            showAddEditPage: true 
          }),
        }
      };
    }
    case enActionTypes.CloseContactListAddEditPageAction: {
      return {
        ...state,
        contactlists: {
          search: Object.assign({}, state.contactlists.search, { showSearchResult: true }),
          addEditView: Object.assign({}, INITIAL_EMARKETING_STATE().contactlists.addEditView, { showAddEditPage: false }),
        }
      };
    }
    default:
      return state;
  }
}
