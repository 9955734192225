import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";

import { IAppState } from "../../../store/store";
import { PropertyManagementService } from "./../../propertymanagement.service";
import { LoaderService } from "./../../../core/loader/loader.service";
import { IDNamePair, AuthService, ServiceResponse } from "./../../../services/authservice";
import { DialogBodyComponent, enDialogRole } from "./../../../dialog-body/dialog-body.component";
import { IPersonContact, ILeadSource } from '../../propertymanagement';
import { GetLeadSourcesInCommercialContactCreationPage } from '../../propertymanagement.action';

@Component({
  selector: 'app-addeditcontact',
  templateUrl: './addeditcontact.component.html',
  styleUrls: ['./addeditcontact.component.scss']
})
export class AddEditContactComponent implements OnInit, OnDestroy {
    
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: PropertyManagementService,
    private _authSercice: AuthService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) {
    this.model = new FormGroup({
      id: new FormControl(null),
      mobile: new FormControl(null, Validators.required),
      givenName: new FormControl(null, Validators.required),
      surname: new FormControl(null),
      email: new FormControl(null),
      notes: new FormControl(null),
      selectedLeadSourceID: new FormControl(null, Validators.required),
    });
  }

  //@Output() save: EventEmitter<boolean> = new EventEmitter<boolean>();

  private model: FormGroup;
  private dialogRef: any;

  //#region Subscriptions
  private GetContact_Subscription: Subscription;
  private GetLeadSources_Subscription: Subscription;
  private SavePersonContact_Subscription: Subscription;
  //#endregion

  @select(state => (state as IAppState).propertymanagement.commercialcontacts.search.contactCreation.leadSources) leadSources$

  //#region Settings
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //#endregion

  ngOnInit() {
    this._loader.show()
    this.GetLeadSources_Subscription =
      this._service.GetLeadSources()
        .subscribe((data: ServiceResponse<ILeadSource[]>) => {
          this._loader.hide();
          if (data.isError) {
            this.openDialog("Error", data.message, "alertdialog");            
          }
          else {
            let action = new GetLeadSourcesInCommercialContactCreationPage(data.response)
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            if (!!data.response && !!data.response.length) {
              this.model.controls.selectedLeadSourceID.setValue(data.response.find(r => r.name.toLowerCase() == 'unknown'));
            }
            else {
              this.model.controls.selectedLeadSourceID.setValue(null);
            }
          }
        },
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          });
  }
  ngOnDestroy() {
    if (!!this.GetContact_Subscription) this.GetContact_Subscription.unsubscribe();
    if (!!this.GetLeadSources_Subscription) this.GetLeadSources_Subscription.unsubscribe();
    if (!!this.SavePersonContact_Subscription) this.SavePersonContact_Subscription.unsubscribe();
  }
  //#region Events
  onMobileChange() {
    if (!this.model.controls.mobile.value) {
      this.model.controls.id.setValue(null);
      this.model.controls.givenName.setValue(null);
      this.model.controls.surname.setValue(null);
      this.model.controls.email.setValue(null);
      this.model.controls.notes.setValue(null);
    }
    else {
      this._loader.show()
      this.GetContact_Subscription =
        this._service.GetContact
          (
            this.model.controls.mobile.value
          )
          .subscribe((data: ServiceResponse<IPersonContact>) => {
            this._loader.hide();
            if (data.isError) {
              this.openDialog("Error", data.message, "alertdialog");

              this.model.controls.id.setValue(null);
              this.model.controls.givenName.setValue(null);
              this.model.controls.surname.setValue(null);
              this.model.controls.email.setValue(null);
              this.model.controls.notes.setValue(null);
              this.model.controls.selectedLeadSourceID.setValue(null);
            }
            else {
              //let action = new GetCommercialContactSearch(data.response)
              //this.ngRedux.dispatch({ type: action.type, payload: action.payload });
              //this._router.navigate(["/commercialcontacts/search"]);
              if (!!data.response) {
                this.model.controls.id.setValue(data.response.id);
                this.model.controls.givenName.setValue(data.response.givenNames);
                this.model.controls.surname.setValue(data.response.surname);
                this.model.controls.email.setValue(data.response.email);
                this.model.controls.notes.setValue(data.response.notes);
                this.model.controls.selectedLeadSourceID.setValue(data.response.leadSourceID);
              }
              else {
                this.model.controls.id.setValue(null);
                this.model.controls.givenName.setValue(null);
                this.model.controls.surname.setValue(null);
                this.model.controls.email.setValue(null);
                this.model.controls.notes.setValue(null);
                this.model.controls.selectedLeadSourceID.setValue(null);
              }
            }
          },
            error => {
              this._loader.hide();
              this.openDialog("Alert", error, "alertdialog");
            });
    }
  }
  onSubmit() {    
    let id: string = null;
    if (!!this.model.controls.id.value && this.model.controls.id.value.indexOf("00000000-") < 0) {
      id = this.model.controls.id.value;
    }
    if (!this.model.controls.mobile.value) {
      this.snackBar.open("Invalid Mobile Number", "Close", this.config);
      return;
    }
    if (!this.model.controls.givenName.value) {
      this.snackBar.open("Invalid Given Name", "Close", this.config);
      return;
    }
    if (!this.model.controls.surname.value) {
      this.snackBar.open("Invalid Surname", "Close", this.config);
      return;
    }
    if (!this.model.controls.email.value) {
      this.snackBar.open("Invalid Email", "Close", this.config);
      return;
    }
    if (!this.model.controls.selectedLeadSourceID.value) {
      this.snackBar.open("Invalid Lead Source Selected", "Close", this.config);
      return;
    }

    this._loader.show()
    this.SavePersonContact_Subscription =
      this._service.SavePersonContact
        (
          id,
          null,
          this.model.controls.givenName.value,
          this.model.controls.surname.value,
          this.model.controls.mobile.value,
          this.model.controls.email.value,
          this.model.controls.notes.value,
          this.model.controls.selectedLeadSourceID.value.id,
          null
        )
        .subscribe((data: ServiceResponse<string>) => {
          this._loader.hide();
          if (data.isError) {
            this.openDialog("Error", data.message, "alertdialog");
          }
          else {
            //let action = new GetCommercialContactSearch(data.response)
            //this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            //this._router.navigate(["/commercialcontacts/search"]);
            if (!!data.response) {
              this.model.controls.id.setValue(data);
              this.snackBar.open("Contact Saved Successfully", "Close", this.config);
            }
          }
        },
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          });

  }
  //#endregion
}
