import { AuthService, enImageSize, SessionInfo, IDNamePair, RentalTenantSearchResult } from "./../services/authservice";
import { RentalTenantSearchResultComponent } from './../contacts/rentaltenantsearchresult/rentaltenantsearchresult.component';
import {
  CONTACT_GET_RENTALTENANT_SORTORDERS,
  CONTACT_RENTALTENANT_SEARCH_REFRESH,
  CONTACT_RENTALTENANT_SEARCH,
  CONTACT_RENTALTENANT_SORTORDER_CHANGE,
  CONTACT_SEARCH_TYPE_CHANGE,
  CONTACT_RENTALTENANT_SEARCH_ACTION,
  CONTACT_RENTALTENANT_SEARCH_RESULT_SHOW,
  CONTACT_RENTALTENANT_SEARCH_RESULT_CLOSE
} from "./actions";
import { Reducer, Action } from "redux";

export interface IContactState {
  searchType: string;
  allSearch: IAllSearch;
  vendorSearch: IVendorSearch;
  propertyOwnerSearch: IPropertyOwnerSearch;
  rentalTenantSearch: IRentalTenantSearch;
  propertyManagementSupplierSearch: IPropertyManagementSupplierSearch;
  showSearchResult: boolean;
}
interface IRentalTenantSearch {
  show: boolean;
  address: string;
  rentalTenantSortOrderID: string;
  rentalTenantSortOrders: IDNamePair<number>[];
  searchResult: RentalTenantSearchResultComponent[];
}
interface IAllSearch {
  show: boolean;
}
interface IVendorSearch {
  show: boolean;
}
interface IPropertyOwnerSearch {
  show: boolean;
}
interface IPropertyManagementSupplierSearch {
  show: boolean;
}


export const INITIAL_CONTACT_STATE: IContactState = {
  searchType: "RentalTenant",
  allSearch: { show: false },
  vendorSearch: { show: false },
  propertyOwnerSearch: { show: false },
  rentalTenantSearch: {
    show: true,
    address: "",
    rentalTenantSortOrderID: "",
    rentalTenantSortOrders: [],
    searchResult: []
  },
  propertyManagementSupplierSearch: { show: false },
  showSearchResult: false
}

export const contactReducer: Reducer<IContactState> = (state = INITIAL_CONTACT_STATE, action: any): IContactState => {
  switch (action.type) {
    case CONTACT_RENTALTENANT_SEARCH_REFRESH:
      return Object.assign({}, INITIAL_CONTACT_STATE);
    case CONTACT_RENTALTENANT_SEARCH:
      let contacts = {
        searchType: state.searchType,
        allSearch: { show: state.allSearch.show },
        vendorSearch: { show: state.vendorSearch.show },
        propertyOwnerSearch: { show: state.propertyOwnerSearch.show },
        rentalTenantSearch: {
          show: state.rentalTenantSearch.show,
          address: action.contacts.rentalTenantSearch.address,
          rentalTenantSortOrderID: action.contacts.rentalTenantSearch.rentalTenantSortOrderID,
          rentalTenantSortOrders: action.contacts.rentalTenantSearch.rentalTenantSortOrders,
          searchResult: []
        },
        propertyManagementSupplierSearch: { show: state.propertyManagementSupplierSearch.show },
        showSearchResult: state.showSearchResult
      };
      return Object.assign({}, state, contacts);
    case CONTACT_GET_RENTALTENANT_SORTORDERS:
      state.rentalTenantSearch.rentalTenantSortOrders = Object.assign({}, action.contacts.rentalTenantSearch.rentalTenantSortOrders);
      return Object.assign({}, state);
    case CONTACT_RENTALTENANT_SORTORDER_CHANGE:
      return Object.assign({}, state,
        {
          searchType: state.searchType,
          allSearch: { show: state.allSearch.show },
          vendorSearch: { show: state.vendorSearch.show },
          propertyOwnerSearch: { show: state.propertyOwnerSearch.show },
          rentalTenantSearch: {
            show: state.rentalTenantSearch.show,
            address: state.rentalTenantSearch.address,
            rentalTenantSortOrderID: action.rentalTenantSortOrderID,
            rentalTenantSortOrders: state.rentalTenantSearch.rentalTenantSortOrders,
            searchResult: []
          },
          propertyManagementSupplierSearch: { show: state.propertyManagementSupplierSearch.show },
          showSearchResult: state.showSearchResult
        }
      );
    case CONTACT_SEARCH_TYPE_CHANGE:
      return Object.assign({}, state,
        {
          searchType: action.contacts.searchType,
          allSearch: { show: (action.contacts.searchType + "").toLowerCase() == "normalsearch" },
          vendorSearch: { show: (action.contacts.searchType + "").toLowerCase() == "entitysearch" },
          propertyOwnerSearch: { show: (action.contacts.searchType + "").toLowerCase() == "propertyowner" },
          rentalTenantSearch: {
            show: (action.contacts.searchType + "").toLowerCase() == "rentaltenant",
            address: state.rentalTenantSearch.address,
            rentalTenantSortOrderID: action.rentalTenantSortOrderID,
            rentalTenantSortOrders: state.rentalTenantSearch.rentalTenantSortOrders,
            searchResult: []
          },
          propertyManagementSupplierSearch: { show: (action.contacts.searchType + "").toLowerCase() == "propertymanagementsupplier" },
          showSearchResult: state.showSearchResult
        }
      );
    case CONTACT_RENTALTENANT_SEARCH_ACTION:
      return Object.assign({}, state,
        {
          searchType: state.searchType,
          allSearch: { show: state.allSearch.show },
          vendorSearch: { show: state.vendorSearch.show },
          propertyOwnerSearch: { show: state.propertyOwnerSearch.show },
          rentalTenantSearch: {
            show: state.rentalTenantSearch.show,
            address: action.address,
            rentalTenantSortOrderID: action.sortOrder,
            rentalTenantSortOrders: state.rentalTenantSearch.rentalTenantSortOrders,
            searchResult: []
          },
          propertyManagementSupplierSearch: { show: state.propertyManagementSupplierSearch.show },
          showSearchResult: action.showSearchResult
        }
      );
    case CONTACT_RENTALTENANT_SEARCH_RESULT_SHOW:
      return Object.assign({}, state,
        {
          searchType: state.searchType,
          allSearch: { show: state.allSearch.show },
          vendorSearch: { show: state.vendorSearch.show },
          propertyOwnerSearch: { show: state.propertyOwnerSearch.show },
          rentalTenantSearch: {
            show: state.rentalTenantSearch.show,
            address: action.contacts.rentalTenantSearch.address,
            rentalTenantSortOrderID: action.contacts.rentalTenantSearch.rentalTenantSortOrderID,
            rentalTenantSortOrders: state.rentalTenantSearch.rentalTenantSortOrders,
            searchResult: action.contacts.rentalTenantSearch.searchResult//[/*...state.contacts.rentalTenantSearch.searchResult,*/ ...action.contacts.rentalTenantSearch.searchResult]
          },
          propertyManagementSupplierSearch: { show: state.propertyManagementSupplierSearch.show },
          showSearchResult: action.contacts.showSearchResult,//state.showSearchResult,
        }
      );
    case CONTACT_RENTALTENANT_SEARCH_RESULT_CLOSE:
      return Object.assign({}, state,
        {
          searchType: state.searchType,
          allSearch: { show: state.allSearch.show },
          vendorSearch: { show: state.vendorSearch.show },
          propertyOwnerSearch: { show: state.propertyOwnerSearch.show },
          rentalTenantSearch: {
            show: state.rentalTenantSearch.show,
            address: state.rentalTenantSearch.address,
            rentalTenantSortOrderID: state.rentalTenantSearch.rentalTenantSortOrderID,
            rentalTenantSortOrders: state.rentalTenantSearch.rentalTenantSortOrders,
            searchResult: action.searchResult//[...action.searchResult]
          },
          propertyManagementSupplierSearch: { show: state.propertyManagementSupplierSearch.show },
          showSearchResult: action.showSearchResult,
        }
      );
    default:
      break;
  }
  return state;
}
