import { Department } from './../services/authservice';
import { LOGIN, LOGOUT, IS_LOGGED_IN, CHANGE_DEPARTMENT } from "./actions";
import { Reducer } from "redux";

export interface ILogonState {
  connectionStringName: string;
  loginID: string;
  password: string;
  rememberMe: boolean;
  rememberValue: string;
  userName: string;
  requiredForgotPassword: boolean;
  isLoggedIn: boolean;
  contactAccessible: boolean;
  trisetSystemID: number;
  categoryID: number;
  departmentID: string;
  isCommercial: boolean;
  departments: Department[];
}

export const INITIAL_LOGON_STATE: ILogonState = {
  connectionStringName: "",
  loginID: "",
  password: "",
  rememberMe: false,
  rememberValue: "",
  userName: "",
  requiredForgotPassword: false,
  isLoggedIn: false,
  contactAccessible: false,
  trisetSystemID: 0,
  categoryID: 0,
  departmentID: "",
  isCommercial: false,
  departments: []
};

export const loginReducer: Reducer<ILogonState> = (state = INITIAL_LOGON_STATE, action: any): ILogonState => {
  switch (action.type) {
    case LOGIN:
      return Object.assign({}, state, {
        isLoggedIn: action.isLoggedIn,
        userName: action.userName,
        contactAccessible: action.contactAccessible,
        trisetSystemID: action.trisetSystemID,
        categoryID: action.categoryID,
        departmentID: action.departmentID,
        isCommercial: action.isCommercial,
        departments: action.departments
      });
    case LOGOUT:
      let curState = INITIAL_LOGON_STATE;
      curState.isLoggedIn = action.isLoggedIn;
      return Object.assign({}, INITIAL_LOGON_STATE, curState);
    case IS_LOGGED_IN:
      return Object.assign({}, state, {
        isLoggedIn: action.isLoggedIn,
        contactAccessible: action.contactAccessible,
        isCommercial: action.isCommercial
      });
    case CHANGE_DEPARTMENT:
        return Object.assign({}, state, { contactAccessible: action.contactAccessible, isCommercial: action.isCommercial, trisetSystemID: action.trisetSystemID, categoryID: action.categoryID, departmentID: action.departmentID });
    default:
      break;
  }
  return state;
}
