import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from "@angular/router";
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../store/store';
import { LOGIN, LOGOUT, IS_LOGGED_IN, CHANGE_DEPARTMENT } from '../store/actions';
import { ILogonState } from '../store/logon';
import { AuthService, enImageSize, SessionInfo, Department, ServiceResponse } from "./../services/authservice";
import { HomeService } from '../home/home.service';
import { Subscription } from "rxjs/Subscription";
import {
  GetPropertiesUnderManagement,
  GetPropertiesGained,
  GetPropertiesLost,
  GetPropertiesAgreementRenewalDue,
  GetPropertiesVacantLessThan14,
  GetPropertiesVacantGraterThan14,
  GetPropertiesPeriodicLeases,
  GetPropertiesAgreementExpired,
  GetPropertiesAverageWeeklyRent,
  GetPropertiesOutstandingWorkOrder3To14Days,
  GetPropertiesOutstandingWorkOrderGraterThan14Days,
  GetPropertiesLeaseRenewedDays,
  GetPropertiesLeasedDays,
  GetPropertiesTenantInvoicesOverdueWithin2To14Days,
  GetPropertiesTenantInvoicesOverdueGreaterThan14Days,
  GetPropertiesLeaseExpiryOverdue,
  GetPropertiesLeaseExpiryDueWithinDays,
  GetPropertiesTenantVacatingWithinDays,
  GetPropertiesInspectionOverdue,
  GetPropertiesInspectionDueWithin7Days,
  GetPropertiesInspectionDueWithin30Days,
  GetPropertiesTenantsInArrearsGraterThan3,
  GetPropertiesTenantsInArrearsGraterThan10,
  GetPropertiesAverageCommissionPercent
} from '../home/home.action';
import { CONTACT_RENTALTENANT_SEARCH_REFRESH } from './../store/actions';
import { IDashboardItem } from '../home/home';
import { 
  ShowCommercialContactSearchPage,
  ShowContactSearchPage,
  ShowManagementAgreementSearchPage,
  ShowTenantContactsSearchPage, 
  ShowWorkOrdersSearchPage, 
  ShowTenantInvoicesSearchPage, 
  ShowCommercialManagementAgreementSearchPage, 
  ShowCommercialTenantContactsSearchPage,
  ShowCommercialWorkOrdersSearchPage,
  ShowCommercialTenantInvoicesSearchPage,
  GetVacantPropertyLessThan14Page,
  ShowLeaseRenewalSearch
  } from './../propertymanagement/propertymanagement.action';
import { ShowOpenPrivateInspectionSearchPage } from './../ofi/ofi.action';
import { ShowVisitorsSearchPage, ShowEnquirersFollowUpSearchPage, ShowListingEnquiryStatusSearchPage } from './../reports/reports.action';
import {IVacantPropertiesResult} from '../propertymanagement/propertymanagement'
import { ShowMaintenanceSearchPage } from '../maintenance/maintenance.action';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit, OnDestroy {
  @Input() showNavMenu: boolean = true;
  @Input() showHeaderDepartment: boolean = true;
  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }


  @select(state => state.login.isLoggedIn) isLoggedIn;
  @select(state => state.login.userName) userName$;
  @select(state => state.login.contactAccessible) contactAccessible$;
  @select(state => state.login.departments) departments$;
  @select(state => (state as ILogonState).categoryID) categoryID$;
  @select(state => (state as ILogonState).trisetSystemID) trisetSystemID$;
  @select(state => (state as ILogonState).departmentID) departmentID$;

  private contactAccessible: boolean;
  private isCommercial: boolean;
  private userName: string;
  private departments: Department[] = [];
  private trisetSystemID: number;
  private categoryID: number;
  private departmentID: string;


  private getSessionData_Subcription: Subscription;
  private getDepartments_Subcription: Subscription;
  private changeDepartment_Subcription: Subscription;
  private currentYear : number;

  model: ILogonState = {
    connectionStringName: null,
    loginID: null,
    password: null,
    rememberMe: null,
    rememberValue: null,
    userName: null,
    requiredForgotPassword: null,
    isLoggedIn: false,
    contactAccessible: false,
    trisetSystemID: 0,
    categoryID: 0,
    departmentID: "",
    isCommercial: false,
    departments: []
  };

  //#region Properties
  get isResidentialPropertyManagement(): boolean {
    return (this.trisetSystemID == 8 && this.categoryID == 1);
  }
  //#endregion

  constructor(private ngRedux: NgRedux<IAppState>, private _router: Router, private _service: AuthService, private _homeService: HomeService) {
    this.getSessionData_Subcription = this._service.GetSessionData().map((data: SessionInfo) => {
      return data;
    })
      .subscribe(result => {
        this.contactAccessible = result.contactAccessible;
        this.isCommercial = result.isCommercial;
        this.userName = result.firstName;
        this.trisetSystemID = result.trisetSystemID;
        this.categoryID = result.categoryID;
        this.departmentID = result.departmentID;
        this.ngRedux.dispatch({ type: CHANGE_DEPARTMENT, contactAccessible: result.contactAccessible, isCommercial: result.isCommercial, trisetSystemID: result.trisetSystemID, categoryID: result.categoryID, departmentID: result.departmentID });

        this.getDepartments_Subcription = this._service.GetDepartments()
          .subscribe(deps => {
            /*if (!!deps) {
              this.departments = deps.filter(f => f.categoryID == result.categoryID && f.trisetSystemID == result.trisetSystemID);
            }
            else {
              this.departments = deps;
            }*/
            this.departments = deps;
          });

      });
  }

  ngOnInit() {
    console.log("showNavMenu: ", this.showNavMenu);
    this.currentYear = new Date().getFullYear();
  }
  ngOnDestroy() {
    if (!!this.getSessionData_Subcription) { this.getSessionData_Subcription.unsubscribe(); }
    if (!!this.getDepartments_Subcription) { this.getDepartments_Subcription.unsubscribe(); }
    if (!!this.changeDepartment_Subcription) { this.changeDepartment_Subcription.unsubscribe(); }
  }

  onDepartmentChange(event: any) {
    if (event.target.checked) {
      if (!!event.target.value) {
        this.resetDashboardData();
        this.changeDepartment_Subcription = this._service.ChangeDepartment(event.target.value).map((data: SessionInfo) => {
          return data;
        })
          .subscribe(result => {
            this._homeService.ReloadDashboard();
            this.contactAccessible = result.contactAccessible;
            this.isCommercial = result.isCommercial;
            this.ngRedux.dispatch({ type: CHANGE_DEPARTMENT, contactAccessible: result.contactAccessible, isCommercial: result.isCommercial, trisetSystemID: result.trisetSystemID, categoryID: result.categoryID, departmentID: result.departmentID });
          });
      }
    }
  }

  logOut() {
    this._service
      .SignOut()
      .map((data: ServiceResponse<boolean>) => {
        //return { type: LOGOUT, isLoggedIn: !data };
        return data;
      }).subscribe((data) => {
        if (!data.isError) {
          let action = { type: LOGOUT, isLoggedIn: !data.response };
          this.ngRedux.dispatch(action);
          this._router.navigate(['/login']);
        }
      });
  }

  resetDashboardData() {
    //#region Reset dashboard data
    let action: any;
    action = new GetPropertiesUnderManagement({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesGained({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesLost({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesAgreementRenewalDue({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesVacantLessThan14({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesVacantGraterThan14({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesPeriodicLeases({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload })

    action = new GetPropertiesAgreementExpired({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesAverageWeeklyRent({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesOutstandingWorkOrder3To14Days({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesOutstandingWorkOrderGraterThan14Days({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesLeaseRenewedDays({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesLeasedDays({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesTenantInvoicesOverdueWithin2To14Days({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesTenantInvoicesOverdueGreaterThan14Days({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesLeaseExpiryOverdue({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesLeaseExpiryDueWithinDays({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesTenantVacatingWithinDays({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesInspectionOverdue({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesInspectionDueWithin7Days({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesInspectionDueWithin30Days({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesTenantsInArrearsGraterThan3({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesTenantsInArrearsGraterThan10({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });

    action = new GetPropertiesAverageCommissionPercent({} as IDashboardItem);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    //#endregion
  }

  onContactsClick(){
    let action = new ShowContactSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }

  onManagementAgreementsClick() {
    let action = new ShowManagementAgreementSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onTenantContactsClick() {
    let action = new ShowTenantContactsSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onSearchMaintenanceClick() {
    let action = new ShowMaintenanceSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onNewMaintenanceClick() {
    //let action = new ShowWorkOrdersSearchPage();
    //this.ngRedux.dispatch({ type: action.type });
  }
  onWorkOrdersClick() {
    let action = new ShowWorkOrdersSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onTenantInvoicesClick() {
    let action = new ShowTenantInvoicesSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onVacantPropertiesLessThan14() {
    let action = new GetVacantPropertyLessThan14Page([]);
    this.ngRedux.dispatch({ type: action.type });
  }
  // onContactsClick() {
  //   this.ngRedux.dispatch({ type: CONTACT_RENTALTENANT_SEARCH_REFRESH });
  //   this._router.navigate(["/contacts"]);
  // }

  //#region Commercial

  onCommercialContactsClick(){
    let action = new ShowCommercialContactSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onCommercialManagementAgreementsClick() {
    let action = new ShowCommercialManagementAgreementSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onCommercialTenantContactsClick() {
    let action = new ShowCommercialTenantContactsSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onCommercialWorkOrdersClick() {
    let action = new ShowCommercialWorkOrdersSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onCommercialTenantInvoicesClick() {
    let action = new ShowCommercialTenantInvoicesSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  //#endregion
  onOfiClick() {
    let action = new ShowOpenPrivateInspectionSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onEnquirersFollowUpClick() {
    let action = new ShowEnquirersFollowUpSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onVisitorsClick() {
    let action = new ShowVisitorsSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onListingEnquiryStatusClick() {
    let action = new ShowListingEnquiryStatusSearchPage();
    this.ngRedux.dispatch({ type: action.type });
  }
  onLeaseRenewalClick() {
    let action = new ShowLeaseRenewalSearch();
    this.ngRedux.dispatch({ type: action.type });
  }
}
