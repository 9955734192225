import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy, Pipe, PipeTransform, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
//import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
//import { CKEditorComponent } from 'ckeditor4-angular';
import { TreeNode, ITreeOptions } from "angular-tree-component";
import { ISubscription } from "rxjs/Subscription";
import { Options } from 'ng5-slider';
import { DomSanitizer } from "@angular/platform-browser";

import { ShowHidePreviewEmailFromSearchResultEditPage, ModifyEmail } from "./../../../emarketing.action";
import { IAppState } from "../../../../store/store";
import { EmarketingService } from "./../../../emarketing.service";
import { IAddEditEmail, IEmailAttachment, IEmail, IWidget } from "./../../../emarketing";
import { LoaderService } from "./../../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../../dialog-body/dialog-body.component';
declare var $: JQueryStatic;
declare var LoadScript: any;
declare var CKEDITOR: any;

@Component({
  selector: 'editemail',
  templateUrl: './editemail.component.html',
  styleUrls: ['./editemail.component.css']
})
export class EditEmailComponent implements OnInit, AfterViewInit, OnDestroy {

  private model: FormGroup;
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  private dialogRef: any;
  private editorConfig = {
    allowedContent: true,
    removeButtons: 'Image',
  };
  private showRecipientListDialog: boolean = false;
  private showLinePropertyDialog: boolean = false;
  private showAttachmentDialog: boolean = false;
  private showLoader: boolean = false;
  private dropAreaExists: boolean = false;
  private htmlBody: string;
  
  private subscription_htmlBody: ISubscription;
  private subscription_RemoveWidgetFromEmail: ISubscription;

  private editor: any;
  private nodes = [];

  @Input() showPreviewButton: boolean = false;

  //@ViewChild(CKEditorComponent) ckeditor: CKEditorComponent;
  @ViewChild("dvPreview") dvPreview: ElementRef;


  constructor(private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: EmarketingService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef) {
    this.model = new FormGroup({
      from: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.from, Validators.required),
      to: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.to, Validators.required),
      subject: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.subject),
      selectedRecipientList: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.selectedRecipientList),
      selectedWidgetType: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.selectedWidgetType),
      htmlBody: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.htmlBody, Validators.required),
      attachments: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.attachments)
    });
  }

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }

  @select(state => (state as IAppState).emarketing.email.addEditView.recipientList) recipientList$;
  @select(state => (state as IAppState).emarketing.email.addEditView.htmlBody) htmlBody$;
  @select(state => (state as IAppState).emarketing.email.addEditView) addEditView$;
  @select(state => (state as IAppState).emarketing.email.addEditView.widgetTypes) widgetTypes$;

  ngOnInit() {
    this._loader.show();
    this.subscription_htmlBody = this.htmlBody$.subscribe(html => { this.htmlBody = html; })

    let emailID = this.ngRedux.getState().emarketing.email.addEditView.id;
    if (!!emailID) {
      this._service.GetEmailChildFields(emailID, "").then(childFields => {
        this.nodes = [];
        childFields.forEach(cf => {

          this.nodes.push(
            {
              id: parseInt(cf.id),
              name: cf.name,
              description: cf.description,
              fieldName: cf.fieldName,
              fieldTypeID: cf.fieldTypeID,
              parentTemplateFieldID: "",
              isField: cf.isField,
              hasChildren: cf.hasChildren
            });
        });
        this._loader.hide();
      });
    }
  }
  ngAfterViewInit() {
    try { CKEDITOR.replace('taHTMLBody'); }
    catch
    {
      window.location.reload();
    }

    //this.editor = this.ckeditor;
    //LoadScript.ckEditor();
    //$.getScript('https://cdn.ckeditor.com/4.5.11/full/ckeditor.js', function () {
    // script is now loaded and executed.
    // put your dependent JS here.
    //});

    //#region JQuery Blocked
    // $("div[class='MultiArrayWidgetText']").hover(function() {
    //   $(this).css({ "border-color": "red", "border-style": "dotted" });
    //   $(this).prop("contenteditable", true);
    //   console.log("Mouse Over...");
    // },
    // function() {
    //   $(this).css({ "border-color": "none", "border-style": "none" });
    //   console.log("Mouse Out...");
    // });
    // $("div[class='MultiArrayWidgetText']").focusout(function() {
    //   $(this).removeAttr("contenteditable");
    //   console.log("Focus Out...");
    // });
    //#endregion    

    let multiArrayWidgetTexts = this.elementRef.nativeElement.querySelectorAll("div[class*='MultiArrayWidgetText']");
    if (!!multiArrayWidgetTexts && !!multiArrayWidgetTexts.length) {
      multiArrayWidgetTexts.forEach((multiArrayWidgetText, index) => {

        multiArrayWidgetText.addEventListener('mouseover', this.onMouseOver_MultiArrayWidgetText.bind(this, multiArrayWidgetText));
        multiArrayWidgetText.addEventListener('mouseout', this.onMouseOut_MultiArrayWidgetText.bind(this, multiArrayWidgetText));
        multiArrayWidgetText.addEventListener('focus', this.onFocusIn_MultiArrayWidgetText.bind(this, multiArrayWidgetText));
        multiArrayWidgetText.addEventListener('focusout', this.onFocusOut_MultiArrayWidgetText.bind(this, multiArrayWidgetText));
        multiArrayWidgetText.setAttribute("contenteditable", true);
        if (!multiArrayWidgetText.id) {
          multiArrayWidgetText.id = "dv" + (index + 1);
        }

        setTimeout(() => {
          try {
            this.initializeCkeditor(multiArrayWidgetText.id);
            setTimeout(() => { 
              this.createAnchor(multiArrayWidgetText, enAchorButtonType.moveUp); 
              this.createAnchor(multiArrayWidgetText, enAchorButtonType.moveDown);
              this.createAnchor(multiArrayWidgetText, enAchorButtonType.remove);
            }, 5000);
          }
          catch { window.location.reload(); }
          this._loader.hide();
        }, 5000);
      });
    }

    let multiArrayWidgetLines = this.elementRef.nativeElement.querySelectorAll("div[class*='MultiArrayWidgetLine']");
    if (!!multiArrayWidgetLines && !!multiArrayWidgetLines.length) {
      multiArrayWidgetLines.forEach((multiArrayWidgetLine, index) => {
        multiArrayWidgetLine.classList.remove("hrbutton");
        multiArrayWidgetLine.addEventListener('mouseover', this.onMouseOver_MultiArrayWidgetLine.bind(this, multiArrayWidgetLine));
        multiArrayWidgetLine.addEventListener('mouseout', this.onMouseOut_MultiArrayWidgetLine.bind(this, multiArrayWidgetLine));
        multiArrayWidgetLine.classList.add("hrbutton");
        this.createAnchor(multiArrayWidgetLine, enAchorButtonType.edit);
        this.createAnchor(multiArrayWidgetLine, enAchorButtonType.moveUp);
        this.createAnchor(multiArrayWidgetLine, enAchorButtonType.moveDown);
        this.createAnchor(multiArrayWidgetLine, enAchorButtonType.remove);
      });
    }

    let multiArrayWidgetDropAreas = this.elementRef.nativeElement.querySelectorAll("div[class*='MultiArrayWidgetDropArea']");
    if (!!multiArrayWidgetDropAreas && !!multiArrayWidgetDropAreas.length) {
      multiArrayWidgetDropAreas.forEach((multiArrayWidgetDropArea, index) => {
        if (!!multiArrayWidgetDropArea) {
          if (!multiArrayWidgetDropArea.id) {
            multiArrayWidgetDropArea.id = "dvDropArea" + (index + 1);
          }
          //multiArrayWidgetDropArea.setAttribute("contenteditable", true);
          multiArrayWidgetDropArea.setAttribute("droppable", "");
          multiArrayWidgetDropArea.addEventListener('mouseover', this.onMouseOver_MultiArrayWidgetDropArea.bind(this, multiArrayWidgetDropArea));
          multiArrayWidgetDropArea.addEventListener('mouseout', this.onMouseOut_MultiArrayWidgetDropArea.bind(this, multiArrayWidgetDropArea));
          multiArrayWidgetDropArea.addEventListener('dragover', this.onDragOver_MultiArrayWidgetDropArea.bind(this, multiArrayWidgetDropArea), false);
          multiArrayWidgetDropArea.addEventListener('dragenter', this.onDragEnter_MultiArrayWidgetDropArea.bind(this, multiArrayWidgetDropArea), false);
          multiArrayWidgetDropArea.addEventListener('dragleave', this.onDragLeave_MultiArrayWidgetDropArea.bind(this, multiArrayWidgetDropArea), false);
          multiArrayWidgetDropArea.addEventListener('drop', this.onDragDrop_MultiArrayWidgetDropArea.bind(this, multiArrayWidgetDropArea), false);
        }
      });
    }

    setTimeout(() => {
      let dropAreas = $(CKEDITOR.instances['taHTMLBody'].editable().$).find("div[class*='MultiArrayWidgetDropArea']");
      if (!!dropAreas && !!dropAreas.length) {
        dropAreas.toArray().forEach((dropArea, index) => {
          if (!!dropArea) {
            if (!dropArea.id) {
              dropArea.id = "dvDropArea" + (index + 1);
            }
          }
        });
      }
    }, 5000);

    // let widgetTypes = this.elementRef.nativeElement.querySelectorAll("button[class^='widgetType']");
    // if (!!widgetTypes && !!widgetTypes.length) {
    //   widgetTypes.forEach((widgetType, index) => {
    //     widgetType.addEventListener('dragstart', this.onDragStart_WidgetType.bind(this, widgetType), false);
    //     widgetType.addEventListener('dragend', this.onDragEnd_WidgetType.bind(this, widgetType), false);
    //   });
    // }

    setTimeout(() => {
      this.setDropAreaExistence();
    }, 5000);

  }
  ngOnDestroy() {
    if (!!this.subscription_htmlBody) { this.subscription_htmlBody.unsubscribe(); }
    if (!!this.subscription_RemoveWidgetFromEmail) { this.subscription_RemoveWidgetFromEmail.unsubscribe(); }

  }
  //#region Attachments
  private selectedAttachments: IEmailAttachment[] = [];
  readAttachments(event: any) {
    let selectedAttachments = [];
    let files = event.target.files as FileList; //FileList object
    for (var index = 0; index < files.length; index++) {
      (function (file, model) {
        var fileReader = new FileReader();
        fileReader.onload = function (e) {
          selectedAttachments.push({ id: null, name: file.name, size: file.size, url: /*e.target.result*/fileReader.result, file: file, isImage: (file.type.indexOf('image') >= 0) });
          model.controls.attachments.setValue(selectedAttachments);
        };
        fileReader.readAsDataURL(file);
      })(files[index], this.model);
    }
    this.showAttachmentDialog = true;
  }
  private get isVisible(): boolean {
    return !!this.model.controls.attachments && !!this.model.controls.attachments.value && this.model.controls.attachments.value.filter(att => !att.id || (!!att.id && att.id.toString().indexOf('00000000') >= 0)).length;
  }
  onAddAttachments() {
    if (!this.isVisible) {
      this.openDialog("Alert", "No new attachment found!!", "alertdialog");
      return false;
    }
    this.showLoader = true;
    this._service.AddAttachmentsToEmail
      (
        this.ngRedux.getState().emarketing.email.addEditView.id,
        this.model.controls.attachments.value
      )
      .subscribe(attachments => {
        if (!!attachments) {
          this.selectedAttachments = attachments;
        }
        this.showLoader = false;
        console.log(attachments);
      });
  }
  //#endregion Attachments

  //#region MultiArrayWidgetText Events
  activeMultiArrayWidgetText: any;
  onMouseOver_MultiArrayWidgetText(element, event) {
    //let element = event.target;
    if (element.classList.contains("MultiArrayWidgetText")) {
      element.setAttribute("style", "border-color: red; border-style: dotted; position: relative;");
      this.setAnchorVisibility(element, true);
    }
  }
  onMouseOut_MultiArrayWidgetText(element, event) {
    //let element = event.target;
    if (element.classList.contains("MultiArrayWidgetText")) {
      element.setAttribute("style", "border-color: none; border-style: none; position: relative;");
      this.setAnchorVisibility(element, false);
    }
  }
  onFocusIn_MultiArrayWidgetText(element, event) {
    //let element = event.target;
    if (element.classList.contains("MultiArrayWidgetText")) {
      this.activeMultiArrayWidgetText = element;
      element.removeAttribute("style");
      element.setAttribute("style", "border-color: red; border-style: dotted; position: relative;");
    }
  }
  onFocusOut_MultiArrayWidgetText(element, event) {
    //let element = event.target;
    if (element.classList.contains("MultiArrayWidgetText")) {
    }
  }
  //#endregion 

  //#region MultiArrayWidgetLine Events
  activeMultiArrayWidgetLine: any;
  onMouseOver_MultiArrayWidgetLine(multiArrayWidgetLine, event) {
    let element = multiArrayWidgetLine;//event.target;
    if (element.classList.contains("MultiArrayWidgetLine")) {
      element.setAttribute("style", "border-color: red; border-style: dotted; position: relative;");
      this.setAnchorVisibility(multiArrayWidgetLine, true);
    }
  }
  onMouseOut_MultiArrayWidgetLine(multiArrayWidgetLine, event) {
    var e = event.toElement || event.relatedTarget;
    if (!!e && (e.parentNode == multiArrayWidgetLine || e == multiArrayWidgetLine || (!!e.parentNode && e.parentNode.parentNode == multiArrayWidgetLine))) {
      return;
    }
    let element = multiArrayWidgetLine;//event.target;
    if (element.classList.contains("MultiArrayWidgetLine")) {
      element.removeAttribute("style");
      this.setAnchorVisibility(multiArrayWidgetLine, false);
    }
  }
  //#region HR Anchor
  onClick_MultiArrayWidgetLineAnchor(anchor) {
    let element = anchor;
    this.showLinePropertyDialog = true;
    this.activeMultiArrayWidgetLine = element.closest("div[class*='MultiArrayWidgetLine']");
    console.log("onClick_MultiArrayWidgetLineAnchor => ", element);
  }
  /*createLineEditAnchor(multiArrayWidgetLine: HTMLDivElement) {
    this.removeAnchor(multiArrayWidgetLine);
    if (!!multiArrayWidgetLine.children && !!multiArrayWidgetLine.children.length) {
      Array.from(multiArrayWidgetLine.children).forEach((item: HTMLElement) => {
        if (!!item.tagName && item.tagName.toLowerCase() === "hr") {
          let anchor = document.createElement("a");
          anchor.setAttribute("data-widgetid", multiArrayWidgetLine.id);
          anchor.setAttribute("style", "right: 24px; top: 20px; display: none;");
          anchor.classList.add("mybtn", "btn", "btn-info", "btn-circle", "btn-sm", "viewresult");
          let i = document.createElement("i");
          i.classList.add("fa", "fa-edit");
          anchor.appendChild(i);
          anchor.addEventListener("click", this.onClick_MultiArrayWidgetLineAnchor.bind(this, anchor));
          multiArrayWidgetLine.append(anchor)
          console.log(item);
        }
      });
    }
  }
  removeAnchor(multiArrayWidgetLine: HTMLDivElement) {
    if (!!multiArrayWidgetLine.children && !!multiArrayWidgetLine.children.length) {
      Array.from(multiArrayWidgetLine.children).forEach((item: HTMLElement) => {

        if (!!item && !!item.tagName && item.tagName.toLowerCase() === "a") {
          console.log(item);
          item.remove();
        }

      });
    }
  }*/
  setAnchorVisibility(element: HTMLDivElement, isVisible) {
    if (!!element.children && !!element.children.length) {
      Array.from(element.children).forEach((item: HTMLElement) => {
        if (!!item && !!item.tagName && item.tagName.toLowerCase() === "a") {
          // let existingStyle = "";
          // if (!!item.attributes && !!item.attributes.getNamedItem("style") && !!item.attributes.getNamedItem("style").value) {
          //   existingStyle = item.attributes.getNamedItem("style").value + ";";
          // }
          // item.setAttribute("style", isVisible ? existingStyle + "display: block;" : existingStyle + "display: none;");
          item.style.display = isVisible ? "block" : "none";
        }
      });
    }
  }
  //#endregion

  //#region Slider
  private sliderValue: number = 100;
  private sliderOptions: Options = {
    floor: 0,
    ceil: 100
  };
  private lineThicknessPixel: number;
  private lineColorHbc: string;
  onLinePropertyChange() {
    if (!this.activeMultiArrayWidgetLine) return;
    let style = "";
    if (!!this.sliderValue) {
      style = "width: " + this.sliderValue + "%;";
    }
    if (!!this.lineThicknessPixel) {
      style += "height: " + this.lineThicknessPixel + "px;";
    }
    if (!!this.lineColorHbc) {
      style += "background-color: " + this.lineColorHbc + ";";
    }
    this.activeMultiArrayWidgetLine.childNodes.forEach(node => {
      if (!!node && !!node.tagName && node.tagName.toLowerCase() === "hr") {
        node.setAttribute("style", style);
        var hrElement = $(CKEDITOR.instances['taHTMLBody'].editable().$).find("div[id='" + $(node).closest("div[class*='MultiArrayWidgetLine']").attr("id") + "'] > hr");
        console.log(hrElement);
        $(hrElement).attr("style", style);
        console.log("node: ", node);
        this.snackBar.open("Line properties changed!!", "Close", this.config);
      }
    });
  }
  //#endregion

  //#endregion

  //#region MultiArrayWidgetDropArea Events
  activeMultiArrayWidgetDropArea: HTMLDivElement;
  onMouseOver_MultiArrayWidgetDropArea(multiArrayWidgetDropArea, event) {
    if (multiArrayWidgetDropArea.classList.contains("MultiArrayWidgetDropArea")) {
      multiArrayWidgetDropArea.setAttribute("style", "border-color: blue; border-style: dashed; position: relative;");
      this.setAnchorVisibility(multiArrayWidgetDropArea, true);
    }
  }
  onMouseOut_MultiArrayWidgetDropArea(multiArrayWidgetDropArea, event) {
    var e = event.toElement || event.relatedTarget;
    if (!!e && (e.parentNode == multiArrayWidgetDropArea || e == multiArrayWidgetDropArea || (!!e.parentNode && e.parentNode.parentNode == multiArrayWidgetDropArea))) {
      return;
    }
    if (multiArrayWidgetDropArea.classList.contains("MultiArrayWidgetDropArea")) {
      multiArrayWidgetDropArea.removeAttribute("style");
      this.setAnchorVisibility(multiArrayWidgetDropArea, false);
    }
  }
  onDragOver_MultiArrayWidgetDropArea(multiArrayWidgetDropArea, event) {
    if (multiArrayWidgetDropArea.classList.contains("MultiArrayWidgetDropArea")) {
      event.dataTransfer.dropEffect = 'move';
      // allows us to drop
      if (event.preventDefault) event.preventDefault();
      multiArrayWidgetDropArea.classList.add('over');
    }
    return false;
  }
  onDragEnter_MultiArrayWidgetDropArea(multiArrayWidgetDropArea, event) {
    if (multiArrayWidgetDropArea.classList.contains("MultiArrayWidgetDropArea")) {
      multiArrayWidgetDropArea.classList.add('over');
    }
    return false;
  }
  onDragLeave_MultiArrayWidgetDropArea(multiArrayWidgetDropArea, event) {
    if (multiArrayWidgetDropArea.classList.contains("MultiArrayWidgetDropArea")) {
      multiArrayWidgetDropArea.classList.remove('over');
    }
    return false;
  }
  onDragDrop_MultiArrayWidgetDropArea(multiArrayWidgetDropArea, event) {
    if (multiArrayWidgetDropArea.classList.contains("MultiArrayWidgetDropArea")) {
      if (event.stopPropagation) event.stopPropagation();
      event.preventDefault();
      multiArrayWidgetDropArea.classList.remove('over');
      if (!!event.dataTransfer.getData('Text') && !!parseInt(event.dataTransfer.getData('Text'))) {
        if (!!multiArrayWidgetDropArea && !!multiArrayWidgetDropArea.id && multiArrayWidgetDropArea.id.indexOf("00000000") < 0) {
          if (parseInt(event.dataTransfer.getData('Text')) > 0) {
            let emailID = this.ngRedux.getState().emarketing.email.addEditView.id;
            if (!!emailID) {
              this._service.AddWidgetToEmail(emailID, parseInt(event.dataTransfer.getData('Text')))
                .subscribe((widget: IWidget) => {
                  //var htmlElements = $(widget.templateHTML);
                  //$(htmlElements).attr("draggable", "");                
                  multiArrayWidgetDropArea.insertAdjacentHTML("beforeend", widget.templateHTML);
                  //let existingHtml = CKEDITOR.instances['taHTMLBody'].getData();
                  //CKEDITOR.instances['taHTMLBody'].setData(existingHtml + widget.templateHTML);
                  $(CKEDITOR.instances['taHTMLBody'].editable().$).find("div[id='" + multiArrayWidgetDropArea.id + "']").append(widget.templateHTML);

                  setTimeout(() => {
                    
                    //let multiArrayWidgetTexts = this.elementRef.nativeElement.querySelectorAll("div[id='" + multiArrayWidgetDropArea.id + "'] > div[class*='MultiArrayWidgetText']");                    
                    let multiArrayWidgetTexts = multiArrayWidgetDropArea.querySelectorAll("div[class*='MultiArrayWidgetText']");
                    if (!!multiArrayWidgetTexts && !!multiArrayWidgetTexts.length) {
                      multiArrayWidgetTexts.forEach((multiArrayWidgetText: any, index) => {
                        multiArrayWidgetText.removeEventListener("mouseover", this.onMouseOver_MultiArrayWidgetText.bind(this, multiArrayWidgetText));
                        multiArrayWidgetText.removeEventListener("mouseout", this.onMouseOut_MultiArrayWidgetText.bind(this, multiArrayWidgetText));
                        multiArrayWidgetText.removeEventListener("focus", this.onFocusIn_MultiArrayWidgetText.bind(this, multiArrayWidgetText));
                        multiArrayWidgetText.removeEventListener('focusout', this.onFocusOut_MultiArrayWidgetText.bind(this, multiArrayWidgetText));

                        multiArrayWidgetText.addEventListener('mouseover', this.onMouseOver_MultiArrayWidgetText.bind(this, multiArrayWidgetText));
                        multiArrayWidgetText.addEventListener('mouseout', this.onMouseOut_MultiArrayWidgetText.bind(this, multiArrayWidgetText));
                        multiArrayWidgetText.addEventListener('focus', this.onFocusIn_MultiArrayWidgetText.bind(this, multiArrayWidgetText));
                        multiArrayWidgetText.addEventListener('focusout', this.onFocusOut_MultiArrayWidgetText.bind(this, multiArrayWidgetText));                        
                        if (!multiArrayWidgetText.id) {
                          multiArrayWidgetText.id = "dv" + (index + 1);
                        }
                        
                        multiArrayWidgetText.setAttribute("contenteditable", true);
                        //this.createAnchor(multiArrayWidgetText, enAchorButtonType.moveUp);

                        setTimeout(() => {
                          try {
                            this.initializeCkeditor(multiArrayWidgetText.id);
                            setTimeout(() => {
                              this.createAnchor(multiArrayWidgetText, enAchorButtonType.moveUp);
                              this.createAnchor(multiArrayWidgetText, enAchorButtonType.moveDown);
                              this.createAnchor(multiArrayWidgetText, enAchorButtonType.remove);
                            }, 5000);
                          }
                          catch { window.location.reload(); }
                          this._loader.hide();
                        }, 5000);
                      });
                    }
                    
                    
                    //let multiArrayWidgetLines = this.elementRef.nativeElement.querySelectorAll("div[class*='MultiArrayWidgetLine']");
                    let multiArrayWidgetLines = multiArrayWidgetDropArea.querySelectorAll("div[class*='MultiArrayWidgetLine']");
                    if (!!multiArrayWidgetLines && !!multiArrayWidgetLines.length) {
                      multiArrayWidgetLines.forEach((multiArrayWidgetLine: any, index) => {

                        multiArrayWidgetLine.removeEventListener('mouseover', this.onMouseOver_MultiArrayWidgetLine.bind(this, multiArrayWidgetLine));
                        multiArrayWidgetLine.removeEventListener('mouseout', this.onMouseOut_MultiArrayWidgetLine.bind(this, multiArrayWidgetLine));

                        multiArrayWidgetLine.classList.remove("hrbutton");
                        multiArrayWidgetLine.addEventListener('mouseover', this.onMouseOver_MultiArrayWidgetLine.bind(this, multiArrayWidgetLine));
                        multiArrayWidgetLine.addEventListener('mouseout', this.onMouseOut_MultiArrayWidgetLine.bind(this, multiArrayWidgetLine));
                        multiArrayWidgetLine.classList.add("hrbutton");
                        this.createAnchor(multiArrayWidgetLine, enAchorButtonType.edit);
                        this.createAnchor(multiArrayWidgetLine, enAchorButtonType.moveUp);
                        this.createAnchor(multiArrayWidgetLine, enAchorButtonType.moveDown);
                        this.createAnchor(multiArrayWidgetLine, enAchorButtonType.remove);
                      });
                    }

                  }, 5000);

                });
            }
          }
        }
      }
    }
    return false;
  }
  //#endregion

  //#region WidgetType
  onDragStart_WidgetType(widgetType, event) {
    if (!!widgetType && !!widgetType.id && widgetType.id.indexOf("00000000") < 0) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('Text', widgetType.id);
      widgetType.classList.add('drag');
    }
    return false
  }
  onDragEnd_WidgetType(widgetType, event) {
    if (!!widgetType && !!widgetType.id && widgetType.id.indexOf("00000000") < 0) {
      widgetType.classList.remove('drag');
    }
    return false;
  }
  //#endregion

  //#region Ckeditor
  editorOptions = {
    //language: 'ru',
    //uiColor: '#000000'
  };
  initializeCkeditor(editorID) {


    let config: any = {} as any;
    config.toolbar = 'MyToolbar';
    config.toolbar_MyToolbar =
      [
        ['Preview'],
        ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'Undo', 'Redo', '-', 'Find', 'Replace', '-', 'SelectAll', 'RemoveFormat'],
        ['TextColor', 'BGColor'],
        //['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Scayt', 'Image'],
      ];
    config.autoParagraph = false;
    config.enterMode = CKEDITOR.ENTER_BR;
    config.shiftEnterMode = CKEDITOR.ENTER_P;
    //config.startupFocus = true;
    CKEDITOR.disableAutoInline = true;
    if (!!CKEDITOR.instances[editorID]) {
      CKEDITOR.instances[editorID].destroy();
    }
    CKEDITOR.inline(editorID, config);

    CKEDITOR.instances[editorID].on('change', function (e) {
      var modifiedHtml = e.editor.getData();
      $(CKEDITOR.instances['taHTMLBody'].editable().$).find("div[id='" + editorID + "']").html(modifiedHtml);

      console.log("TEST");
      console.log(e);
    });


  }
  //#endregion

  //#region Tree
  private selectedTreeNode: TreeNode;
  options: ITreeOptions = {
    getChildren: this.getChildren.bind(this),
    useCheckbox: false
  };
  getChildren(node: any) {
    let emailID = this.ngRedux.getState().emarketing.email.addEditView.id;

    const newNodes = this._service.GetEmailChildFields(emailID, node.id);
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(newNodes), 1000);
    });
  }
  onDoubleclick(event: any) {
    if (!!this.selectedTreeNode) {
      //this.editor.instance.insertText(this.selectedTreeNode.data.fieldName);
      //this.activeMultiArrayWidgetText.innerHTML += this.selectedTreeNode.data.fieldName;
      //this.activeMultiArrayWidgetText.insertAdjacentText("beforeend", this.selectedTreeNode.data.fieldName);
      CKEDITOR.instances[this.activeMultiArrayWidgetText.id].insertText(this.selectedTreeNode.data.fieldName);
    }
  }
  onEvent(event: any) {
    if (!!event && !!event.node) {
      this.selectedTreeNode = event.node;
    }
  }
  //#endregion

  async onNext() {
    this.updateHtml();
    if (!this.model.controls.from.value) {
      this.openDialog("Alert", "Invalid sender email!!", "alertdialog");
      return false;
    }
    if (!(!!this.ngRedux.getState().emarketing.email.addEditView.id && this.ngRedux.getState().emarketing.email.addEditView.id.indexOf('00000000') < 0)) {
      this.openDialog("Alert", "Invalid email!!", "alertdialog");
      return false;
    }
    // if (!this.dvPreview.nativeElement.innerHTML) {
    //   this.openDialog("Alert", "No html found!!", "alertdialog");
    //   return false;
    // }
    if (!this.model.controls.htmlBody.value) {
      this.openDialog("Alert", "No html found!!", "alertdialog");
      return false;
    }
    //let success: boolean = false;
    this._loader.show();
    let success: boolean = false;
    //let modifiedHtml = CKEDITOR.instances['taHTMLBody'].getData();

    await this._service.SaveEmail
      (
        this.ngRedux.getState().emarketing.email.addEditView.id,
        this.model.controls.subject.value,
        null,
        this.model.controls.htmlBody.value,//modifiedHtml,//this.dvPreview.nativeElement.innerHTML,
        this.selectedAttachments
      )
      .then(result => {

        this._service.GetEmail(this.ngRedux.getState().emarketing.email.addEditView.id)
          .map((email: IEmail) => {
            return new ModifyEmail(email);
          })
          .subscribe(action => {

            success = result;
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            this._loader.hide();
            this.snackBar.open("Email modified successfully!!", "Close", this.config);

          });

      })
      .catch((error: any) => {
        this._loader.hide();
        this.openDialog("Error", (error.json().error || "Server Error"), "alertdialog");
      });
    // this._loader.hide();
    // this.snackBar.open("Email modified successfully!!", "Close", this.config);
    return success;
  }
  onSubmit() {
    this.updateHtml();
    let emailID = this.ngRedux.getState().emarketing.email.addEditView.id;
    if (!this.model.controls.from.value) {
      this.openDialog("Alert", "Invalid sender email!!", "alertdialog");
      return false;
    }
    if (!(!!emailID && emailID.indexOf('00000000') < 0)) {
      this.openDialog("Alert", "Invalid email!!", "alertdialog");
      return false;
    }
    if (!this.model.controls.htmlBody.value) {
      this.openDialog("Alert", "No html found!!", "alertdialog");
      return false;
    }
    this._loader.show();
    this._service.SaveEmail
      (
        emailID,
        this.model.controls.subject.value,
        null,
        this.model.controls.htmlBody.value,
        this.selectedAttachments
      )
      .then(result => {

        this._service.GetEmail(emailID)
          .map((email: IEmail) => {
            return new ModifyEmail(email);
          })
          .subscribe(action => {

            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            this._loader.hide();
            this.snackBar.open("Email modified successfully!!", "Close", this.config);

          });

      })
      .catch((error: any) => {
        this._loader.hide();
        this.openDialog("Error", (error.json().error || "Server Error"), "alertdialog");
      });
  }


  //#region Preview
  onPreview() {
    let action = new ShowHidePreviewEmailFromSearchResultEditPage(true);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
  }
  //#endregion

  onClose() {
    //let action = new EditTempateClose(true);
    //this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    //this._router.navigate(["/emails/emailsearchresult"]);
    console.log(this.route);
  }

  //#region Functions
  updateHtml() {
    this.model.controls.htmlBody.setValue(CKEDITOR.instances['taHTMLBody'].getData());
  }
  private setDropAreaExistence() {
    let multiArrayWidgetDropAreas = this.elementRef.nativeElement.querySelectorAll("div[class*='MultiArrayWidgetDropArea']");
    this.dropAreaExists = !!multiArrayWidgetDropAreas && !!multiArrayWidgetDropAreas.length;

    setTimeout(() => {
      let widgetTypes = this.elementRef.nativeElement.querySelectorAll("button[class*='widgetType']");
      if (!!widgetTypes && !!widgetTypes.length) {
        widgetTypes.forEach((widgetType, index) => {
          widgetType.addEventListener('dragstart', this.onDragStart_WidgetType.bind(this, widgetType), false);
          widgetType.addEventListener('dragend', this.onDragEnd_WidgetType.bind(this, widgetType), false);
        });
      }
    }, 1000);
  }

  //#region Various Anchor button
  createAnchor(container: HTMLDivElement, buttonType: enAchorButtonType) {
    switch (buttonType) {
      case enAchorButtonType.edit: {
        this.removeAnchor(container, buttonType);
        if (!!container.children && !!container.children.length) {
          Array.from(container.children).forEach((item: HTMLElement) => {
            if (!!item.tagName && item.tagName.toLowerCase() === "hr") {
              let anchor = document.createElement("a");
              anchor.setAttribute("data-widgetid", container.id);
              anchor.setAttribute("style", "right: 24px !important; top: -24px; display: none;");
              anchor.classList.add("mybtn", "btn", "btn-info", "btn-circle", "btn-sm", "viewresult");
              let i = document.createElement("i");
              //anchor.setAttribute("style", "font-size: 22px; margin-top: -1px; display:inline-block");
              i.classList.add("fa", "fa-edit");
              anchor.appendChild(i);
              anchor.addEventListener("click", this.onClick_MultiArrayWidgetLineAnchor.bind(this, anchor));
              container.append(anchor)
              console.log(item);
            }
          });
        }
        break;
      }
      case enAchorButtonType.moveUp: {
        this.removeAnchor(container, buttonType);
        //if (!!container.children && !!container.children.length) {
        //Array.from(container.children).forEach((item: HTMLElement) => {
        let anchor = document.createElement("a");
        anchor.setAttribute("data-widgetid", container.id);
        anchor.setAttribute("style", "right: 48px !important; top: -24px; display: none; position: absolute;");
        anchor.classList.add("mybtn", "btn", "btn-info", "btn-circle", "btn-sm", "moveup");
        let i = document.createElement("i");
        i.setAttribute("style", "font-size: 22px; margin-top: -1px; display:inline-block");
        i.classList.add("fa", "fa-caret-up");
        anchor.appendChild(i);
        anchor.addEventListener("click", this.onClick_WidgetMoveUpAnchor.bind(this, anchor));
        container.append(anchor)
        //});
        //}

        break;
      }
      case enAchorButtonType.moveDown: {
        this.removeAnchor(container, buttonType);
        let anchor = document.createElement("a");
        anchor.setAttribute("data-widgetid", container.id);
        anchor.setAttribute("style", "right: 72px !important; top: -24px; display: none; position: absolute;");
        anchor.classList.add("mybtn", "btn", "btn-info", "btn-circle", "btn-sm", "movedown");
        let i = document.createElement("i");
        i.setAttribute("style", "font-size: 22px; margin-top: 2px; display:inline-block");
        i.classList.add("fa", "fa-caret-down");
        anchor.appendChild(i);
        anchor.addEventListener("click", this.onClick_WidgetMoveDownAnchor.bind(this, anchor));
        container.append(anchor)

        break;
      }
      case enAchorButtonType.remove: {
        this.removeAnchor(container, buttonType);
        let anchor = document.createElement("a");
        anchor.setAttribute("data-widgetid", container.id);
        anchor.setAttribute("style", "right: 0px !important; top: -24px; display: none; position: absolute;");
        anchor.classList.add("mybtn", "btn", "btn-info", "btn-circle", "btn-sm", "removewidget");
        let i = document.createElement("i");
        i.setAttribute("style", "font-size: 14px; margin-top: 5px; display:inline-block");
        i.classList.add("fa", "fa-trash-o");
        anchor.appendChild(i);
        anchor.addEventListener("click", this.onClick_WidgetRemoveAnchor.bind(this, anchor));
        container.append(anchor)
        break;
      }
    }
  }
  removeAnchor(multiArrayWidgetLine: HTMLDivElement, buttonType: enAchorButtonType) {
    if (!!multiArrayWidgetLine.children && !!multiArrayWidgetLine.children.length) {
      Array.from(multiArrayWidgetLine.children).forEach((item: HTMLElement) => {

        if (!!item && !!item.tagName && item.tagName.toLowerCase() === "a") {
          switch (buttonType) {
            case enAchorButtonType.edit:
              if (item.classList.contains("viewresult")) {
                console.log(item);
                item.remove();
              }
              break;
            case enAchorButtonType.moveUp:
              if (item.classList.contains("moveup")) {
                console.log(item);
                item.remove();
              }
              break;
            case enAchorButtonType.moveDown:
              if (item.classList.contains("movedown")) {
                console.log(item);
                item.remove();
              }
              break;
          }

        }

      });
    }
  }
  onClick_WidgetMoveUpAnchor(element, event) {
    if (!!element && !!element.tagName && element.tagName.toLowerCase() === "a") {
      if (!!element.parentElement && (element.parentElement.classList.contains("MultiArrayWidgetText") || element.parentElement.classList.contains("MultiArrayWidgetLine"))) {
        let prevElement = element.parentElement.previousElementSibling;

        if (!!prevElement && (prevElement.classList.contains("MultiArrayWidgetText") || prevElement.classList.contains("MultiArrayWidgetLine"))) {
          prevElement.before(element.parentElement);

          var multiArrayWidget = $(CKEDITOR.instances['taHTMLBody'].editable().$).find("div[id='" + element.parentElement.id + "']");
          if (!!multiArrayWidget && !!multiArrayWidget.length) {
            var prevMultiArrayWidget = multiArrayWidget.prev();
            prevMultiArrayWidget.before(multiArrayWidget);
          }

        }
      }
    }
  }
  onClick_WidgetMoveDownAnchor(element, event) {
    if (!!element && !!element.tagName && element.tagName.toLowerCase() === "a") {
      if (!!element.parentElement && (element.parentElement.classList.contains("MultiArrayWidgetText") || element.parentElement.classList.contains("MultiArrayWidgetLine"))) {
        let afterElement = element.parentElement.nextElementSibling;

        if (!!afterElement && (afterElement.classList.contains("MultiArrayWidgetText") || afterElement.classList.contains("MultiArrayWidgetLine"))) {
          afterElement.after(element.parentElement);

          var multiArrayWidget = $(CKEDITOR.instances['taHTMLBody'].editable().$).find("div[id='" + element.parentElement.id + "']");
          if (!!multiArrayWidget && !!multiArrayWidget.length) {
            var afterMultiArrayWidget = multiArrayWidget.next();
            afterMultiArrayWidget.after(multiArrayWidget);
          }

        }

      }
    }
  }
  onClick_WidgetRemoveAnchor(element, event) {
    if (!!element && !!element.tagName && element.tagName.toLowerCase() === "a") {
      if (!!element.parentElement && (element.parentElement.classList.contains("MultiArrayWidgetText") || element.parentElement.classList.contains("MultiArrayWidgetLine"))) {
        var parentElementID = element.parentElement.id;

        let emailID = this.ngRedux.getState().emarketing.email.addEditView.id;
        if ((!!emailID && emailID.indexOf("00000000") < 0) && (!!parentElementID && parentElementID.indexOf("00000000") < 0)) {

          this.openDialog("Confirmation", "Are you sure you want to delete the widget?", "dialog");
          this.dialogRef.afterClosed().subscribe(dResult => {
            if (dResult) {
              this._loader.show();
              this.subscription_RemoveWidgetFromEmail = this._service.RemoveWidgetFromEmail(emailID, parentElementID)
              .subscribe(success => {
                if (success) {

                  element.parentElement.remove();
                  var multiArrayWidget = $(CKEDITOR.instances['taHTMLBody'].editable().$).find("div[id='" + parentElementID + "']");
                  if (!!multiArrayWidget && !!multiArrayWidget.length) {
                    multiArrayWidget.remove();
                  }
                  this.snackBar.open("Widget deleted successfully!!", "Close", this.config);

                }
                this._loader.hide();
              },
              (error) => {
                this._loader.hide();
                this.openDialog("Error", (error.json().error || "Server Error"), "alertdialog");
              });
            }
          });

          
        }

        

      }
    }
  }
  //#endregion

  //#endregion

}
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
//#region Various Anchor button
enum enAchorButtonType {
  edit,
  moveUp,
  moveDown,
  remove
};
//#endregion
