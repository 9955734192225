import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig, SortDirection, Sort } from "@angular/material";
import { Subscription } from "rxjs/Subscription";

import { IAppState } from "../../store/store";
import { PropertyManagementService } from "../propertymanagement.service";
import { LoaderService } from "../../core/loader/loader.service";
import { IDNamePair, AuthService, ServiceResponse } from "../../services/authservice";
import { DialogBodyComponent, enDialogRole } from "../../dialog-body/dialog-body.component";
import { GetVacantPropertyLessThan14Page, GetVacantPropertyGreaterThan14Page} from "../propertymanagement.action";
import "../../../../../wwwroot/js/scripts.js";
import { IVacantPropertiesResult } from '../propertymanagement';

declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'app-vacantpropertiesgreaterthan14',
  templateUrl: './vacantpropertiesgreaterthan14.component.html',
  styleUrls: ['./vacantpropertiesgreaterthan14.component.css']
})
export class VacantPropertiesGreaterThan14Component implements OnInit,AfterViewInit, OnDestroy {

  private dialogRef: any;
  private GetVacantProperyGreaterThan14_Subscription: Subscription;
  private VacantPropertyGreaterThan14Result: IVacantPropertiesResult[];
  private isResponse:boolean = false;
  constructor(
      private ngRedux: NgRedux<IAppState>,
      private _router: Router,
      private route: ActivatedRoute,
      private _service: PropertyManagementService,
      private _authSercice: AuthService,
      private _loader: LoaderService,
      private snackBar: MatSnackBar,
      private dialog: MatDialog
  ) { }

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  ngOnInit() {
    this.isResponse = false;
    this._loader.show()
    this.GetVacantProperyGreaterThan14_Subscription =
        this._service.GetVacantPropertiesGreaterThan14(14)
      .subscribe((data: ServiceResponse<IVacantPropertiesResult[]>) =>{
        this._loader.hide()
        if(data.isError){
          this.openDialog("Error", data.message, "alertdialog");
        }
        else{
         // let action = new GetVacantPropertiesGreaterThan14(data.response)
         // this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this.VacantPropertyGreaterThan14Result = data.response;
         this.loadPageData();
         this.isResponse = true;
         //this._router.navigate(["/VacantPropertiesLessThen14"]);
        }
      },
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      });
  }


  ngOnDestroy(){
    if (!!this.GetVacantProperyGreaterThan14_Subscription) { this.GetVacantProperyGreaterThan14_Subscription.unsubscribe();}
  }

  ngAfterViewInit(){
    LoadScript.init();
  }

  //region FOR Mobile VIEW SORT
  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }

  //end region


  sortData(sort: Sort) {
    this._loader.show();
    setTimeout(() => { 
      this.sortAllData(sort);
    }, 10)
  }

  private sortAllData(sort: Sort) {    
    const isAsc = sort.direction === 'asc';

    for (var index = 0; index < this.VacantPropertyGreaterThan14Result.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.VacantPropertyGreaterThan14Result.length; innerIndex++) {
        switch (sort.active) {
          case "Property Address": {
            if (isAsc) {
              if (this.VacantPropertyGreaterThan14Result[index].propertyAddress > this.VacantPropertyGreaterThan14Result[innerIndex].propertyAddress) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            else {
              if (this.VacantPropertyGreaterThan14Result[index].propertyAddress < this.VacantPropertyGreaterThan14Result[innerIndex].propertyAddress) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            break;
          }
          case "Suburb": {
            if (isAsc) {
              if (this.VacantPropertyGreaterThan14Result[index].suburb > this.VacantPropertyGreaterThan14Result[innerIndex].suburb) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            else {
              if (this.VacantPropertyGreaterThan14Result[index].suburb < this.VacantPropertyGreaterThan14Result[innerIndex].suburb) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            break;
          }
          case "Property Manager": {
            if (isAsc) {
              if (this.VacantPropertyGreaterThan14Result[index].propertyManager > this.VacantPropertyGreaterThan14Result[innerIndex].propertyManager) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            else {
              if (this.VacantPropertyGreaterThan14Result[index].propertyManager < this.VacantPropertyGreaterThan14Result[innerIndex].propertyManager) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            break;
          }
          case "Management Agreement Start Date": {
            if (isAsc) {
              if (this.VacantPropertyGreaterThan14Result[index].agreementSignedOn > this.VacantPropertyGreaterThan14Result[innerIndex].agreementSignedOn) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            else {
              if (this.VacantPropertyGreaterThan14Result[index].agreementSignedOn < this.VacantPropertyGreaterThan14Result[innerIndex].agreementSignedOn) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            break;
          }
          case "Vacant Since": {
            if (isAsc) {
              if (this.VacantPropertyGreaterThan14Result[index].vacancyDate > this.VacantPropertyGreaterThan14Result[innerIndex].vacancyDate) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            else {
              if (this.VacantPropertyGreaterThan14Result[index].vacancyDate < this.VacantPropertyGreaterThan14Result[innerIndex].vacancyDate) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            break;
          }
          case "Days Vacant": {
            if (isAsc) {
              if (this.VacantPropertyGreaterThan14Result[index].daysVacant > this.VacantPropertyGreaterThan14Result[innerIndex].daysVacant) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            else {
              if (this.VacantPropertyGreaterThan14Result[index].daysVacant < this.VacantPropertyGreaterThan14Result[innerIndex].daysVacant) {
                let temp = this.VacantPropertyGreaterThan14Result[index];
                this.VacantPropertyGreaterThan14Result[index] = this.VacantPropertyGreaterThan14Result[innerIndex];
                this.VacantPropertyGreaterThan14Result[innerIndex] = temp;
              }
            }
            break;
          }
        }
      }  
    }
     this.loadPageData();
     this._loader.hide();

  }


  //#region Pagination  
  private hasPagination: boolean = true;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: IVacantPropertiesResult[];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.pageData = this.VacantPropertyGreaterThan14Result;
    if (this.hasPagination) {
      this.pageData = this.VacantPropertyGreaterThan14Result.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }    
  }
  //#endegion

}
