import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { IAppState } from "../../store/store";
import { EmarketingService } from "./../emarketing.service";
import { LoaderService } from "./../../core/loader/loader.service";
import { IRecipientType, IEmailTemplate, IEmailContactList, IEmail } from "./../emarketing";
import { LoadStaffsAndStatusesOfEmailSearchPage, LoadNewEmailPage, GetEmailsSearch } from "./../emarketing.action";
import "../../../../../wwwroot/js/scripts.js";
import { IDNamePair } from "./../../services/authservice";
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.css']
})
export class EmailsComponent implements OnInit, AfterViewInit {

  private model: FormGroup;
  private dropdownSettings: any = {};
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: EmarketingService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) {
    this.model = new FormGroup({
      subject: new FormControl(this.ngRedux.getState().emarketing.email.search.subject),
      selectedStatuses: new FormControl(this.ngRedux.getState().emarketing.email.search.selectedStatuses, Validators.required),
      selectedCreatedBys: new FormControl(this.ngRedux.getState().emarketing.email.search.selectedCreatedBys, Validators.required),
      selectedSentBys: new FormControl(this.ngRedux.getState().emarketing.email.search.selectedSentBys, Validators.required),
      maxResultsToReturn: new FormControl(this.ngRedux.getState().emarketing.email.search.maxResultsToReturn),
      emailMarker: new FormControl(this.ngRedux.getState().emarketing.email.search.emailMarker)
    });
  }

  @select(state => (state as IAppState).emarketing.email.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).emarketing.email.search.staffs) staffs$;
  @select(state => (state as IAppState).emarketing.email.search.statuses) statuses$;
  @select(state => (state as IAppState).emarketing.email.search.emails) emails$;
  @select(state => (state as IAppState).emarketing.email.addEditView.showEditPage) showEditPage$;
  @select(state => (state as IAppState).emarketing.email.addEditView.showPreviewPage) showPreviewPage$;
  @select(state => (state as IAppState).emarketing.email.addEditView.showNewPage) showNewPage$;

  ngOnInit() {
    //console.log("ngOnInit");
    this.showNewPage$.subscribe(showNewPage => { console.log("ngOnInit => showNewPage: ", showNewPage); });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this._loader.show();
    this._service.GetStaffs(null)
      .subscribe(staffs => {
        this._service.GetEmailStatuses()
          .map((data: IDNamePair<number>[]) => {
            return new LoadStaffsAndStatusesOfEmailSearchPage({ staffs: staffs, statuses: data });
          })
          .subscribe(action => {
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            this._loader.hide();
          });
      });
  }
  ngAfterViewInit() {
    LoadScript.init();
  }

  onNew() {
    this._loader.show();
    this._service.GetRecipientSourceTypes()
      .subscribe(rsts => {
        if (!!rsts && !!rsts.length) {
          this._service.GetRecipientTypes([rsts[0].id])
            .subscribe(rts => {
              this._service.GetContactLists()
                .map((contacts: IEmailContactList[]) => {
                  return new LoadNewEmailPage({ recipientSourceTypes: rsts, recipientTypes: rts, contactLists: contacts, selectedRecipientSourceType: rsts[0].id });
                })
                .subscribe(action => {
                  setTimeout(() => {
                    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                    this._loader.hide();
                    this._router.navigate(["/emails/new"]);
                  }, 2000);

                });
            });
        }
      });
  }
  onSubmit() {
    if (this.model.status == "VALID") {
      let selectedCreatedBys: string[] = [];
      let selectedSentBys: string[] = [];
      let selectedStatuses: number[] = [];
      if (!!this.model.controls.selectedCreatedBys && !!this.model.controls.selectedCreatedBys.value && !!this.model.controls.selectedCreatedBys.value.length) {
        selectedCreatedBys = this.model.controls.selectedCreatedBys.value.map(createdBy => { return createdBy.id });
      }
      if (!!this.model.controls.selectedSentBys && !!this.model.controls.selectedSentBys.value && !!this.model.controls.selectedSentBys.value.length) {
        selectedSentBys = this.model.controls.selectedSentBys.value.map(sentBy => { return sentBy.id });
      }
      if (!!this.model.controls.selectedStatuses && !!this.model.controls.selectedStatuses.value && !!this.model.controls.selectedStatuses.value.length) {
        selectedStatuses = this.model.controls.selectedStatuses.value.map(status => { return status.id });
      }
      this._loader.show();
      this._service.GetEmails
        (
        this.model.controls.subject.value,
        this.model.controls.maxResultsToReturn.value,
        this.model.controls.emailMarker.value,
        selectedCreatedBys,
        selectedSentBys,
        selectedStatuses
        )
        .map((emails: IEmail[]) => {
          return new GetEmailsSearch({
            subject: this.model.controls.subject.value,
            selectedStatuses: selectedStatuses,
            selectedCreatedBys: selectedCreatedBys,
            selectedSentBys: selectedSentBys,
            maxResultsToReturn: this.model.controls.maxResultsToReturn.value,
            emailMarker: this.model.controls.emailMarker.value,
            staffs: this.ngRedux.getState().emarketing.email.search.staffs,
            statuses: this.ngRedux.getState().emarketing.email.search.statuses,
            emails: emails,
            showSearchResult: true
          });
        })
        .subscribe(action => {
          if (!!action.payload.emails && !!action.payload.emails.length) {
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            this._router.navigate(["/emails/emailsearchresult"]);
          }
          this._loader.hide();
        },
        (error) => {
          console.log(error);
          this._loader.hide();
        });
    }
  }

}
