import { ISearchTemplate, IAddEditTemplate, ISearchEmail, IAddEditEmail, enRecipientSource, ISearchContactLists, IAddEditContactLists, IContactFilterRuleSet } from './emarketing';
export interface IEmarketingState {
  email: {
    search: ISearchEmail;
    addEditView: IAddEditEmail;
  },
  template: {
    search: ISearchTemplate;
    addEditView: IAddEditTemplate;
  },
  contactlists: {
    search: ISearchContactLists;
    addEditView: IAddEditContactLists;
  }
}
export const INITIAL_EMARKETING_STATE = (): IEmarketingState => ({
  email: {
    search: {
      subject: "",
      selectedStatuses: [],
      selectedCreatedBys: [],
      selectedSentBys: [],
      maxResultsToReturn: 1000,
      emailMarker: "",
      staffs: [],
      statuses: [],
      emails: [],
      showSearchResult: false
    } as ISearchEmail,
    addEditView: {
      id: "",
      subject: "",
      textBody: "",
      htmlBody: "",
      widgets: [],
      selectedRecipientSourceType: 0,
      selectedRecipientType: 0,
      selectedTemplate: "",
      selectedWidgetType: 0,
      selectedWidgetID: "",
      selectedContactList: "",
      selectedRecipientList: [],
      recipientSourceTypes: [],
      recipientTypes: [],
      templates: [],
      contactLists: [],
      recipientList: [],
      //isNew: false,
      from: "",
      to: [],
      attachments: [],
      widgetTypes: [],
      //step: 1,
      previewToEmails: "",
      usingRecipientContactID: "",
      scheduledDatetime: null,
      showEditPage: false,
      showPreviewPage: false,
      showNewPage: false
    } as IAddEditEmail
  },
  template: {
    search: {
      templateName: "",
      selectedRecipientTypes: [],
      recipientTypes: [],
      templates: [],
      showSearchResult: false
    },
    addEditView: {
      name: "",
      selectedRecipientType: { id: 0, name: "", communicationReasons: [] },
      selectedWidgetType: { id: "", name: "", description: "", iconImageID: null, iconImageURL: "" },
      selectedWidgetID: "",
      //selectedReason: 0,
      selectedAgency: null,
      selectedDepartment: null,
      selectedStaff: null,
      recipientTypes: [],
      agencies: [],
      departments: [],
      staffs: [],
      availableFor: "",
      template: {
        id: "",
        agencyID: "",
        name: "",
        description: "",
        subject: "",
        ownerType: 0,
        ownerName: "",
        ownerID: null,
        recipientSources: enRecipientSource.unknown,
        /*communicationReasonID: 0,
        communicationReasonName: "",
        communicationReasonIsTransactional: false,
        communicationReasonRecipientTypeID: 0,
        communicationReasonRecipientTypeName: "",*/
        thumbnailImageID: null,
        thumbnailImageURL: "",
        htmlBody: "",
        canCopy: false,
        canEdit: false,
        canDelete: false,
        saveTemplateStatus: false,
        widgetTypes: [],
        widgets: []
      },
      selectedTemplateField: "",
      selectedGetImagesFor: 0,
      templateImages: [],
      selectedTemplateImage: { id: "", imageID: "", templateID: "", name: "", caption: "", width: 0, height: 0, url: "", imageUrl: "" },
      showEditPage: false,
      showNewPage: false,
      templateImageTypes: [],
      templateEmailImages: []
    }
  },
  contactlists: {
    search: {
      statuses: [],
      contactLists: [],
      showSearchResult: true
    },
    addEditView: {
      contactListID: "",
      name: "",
      description: "",
      autoSubscribeNewContacts: false,
      status: 1,
      contactListCreated: false,
      contactListUpdated: false,
      filter: {} as IContactFilterRuleSet,
      statuses: [],
      contactLists: [],
      showAddEditPage: false
    }
  }
});
