import { Component,ViewChild , OnInit, AfterViewInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTableDataSource, MatSort, MatPaginator, Sort, SortDirection } from '@angular/material';
import { LoaderService } from "./../../../core/loader/loader.service";
import { emailValidation } from "./../../../utility/autounsubcribe";


@Component({
  selector: 'app-gridview',
  templateUrl: './gridview.component.html',
  styleUrls: ['./gridview.component.css']
})
export class GridViewComponent implements OnInit {
  @Input() headerNames: string[];
  @Input() dataSource: GridViewDataTable[][];
  @Input() columns: string[];
  @Input() hasSerial: boolean;
  @Input() showEdit: boolean;
  @Input() showDelete: boolean;
  @Input() showView: boolean;
  @Input() isFullRowSelect: boolean;
  @Input() isMultSelect: boolean;
  @Input() hasPagination: boolean;
  @Input() editIcon: string;
  @Input() deleteIcon: string;
  @Input() viewIcon: string;

  //@ViewChild(MatSort) sort: MatSort;

  @Output() select = new EventEmitter<any>();
  @Output() clickById = new EventEmitter();
  @Output() openqrcode = new EventEmitter<any>();

  private headers: GridViewDataTable[];
  //private selectedRow: number;
  private selectedRows: number[] = [];
  private hoverRow: number;
  private rows: GridViewDataTable[][] = [];
  private slNo: string = "Sl. No.";
  private action: string = "Action"

  private showCommunication: boolean;
  private actionCommunication: "tel" | "sms" | "mailto";
  private contactForCommunication: string;
  private selectedCommunicationRow: GridViewDataTable[] = [];

  constructor(private sanitizer: DomSanitizer,private _loader: LoaderService,) { }
  ngOnInit() {
    if (!!this.dataSource && !!this.dataSource.length) {
      this.headers = this.dataSource[0];

      
      //this.dataSource.sort[0] = this.sort ;      
      //this.pageData = this.hasPagination ? this.dataSource.slice(this.pageIndex, this.pageSize) : this.dataSource;
      this.loadPageData();
    }
  }
  onEdit(id: any) {
    this.clickById.emit(id);
  }

  onSelectRow(event: MouseEvent, index) {
    if (this.rows.findIndex(row => row === this.dataSource[index]) < 0) {
      if (this.isMultSelect && !!this.dataSource.length) {
        if (!!event.ctrlKey) {
          this.selectedRows.push(index);
          this.rows.push(this.dataSource[index]);
        }
        else {
          this.selectedRows = [];
          this.rows = [];
          this.selectedRows.push(index);
          this.rows.push(this.dataSource[index]);
        }
      }
      else if (!this.isMultSelect) {
        this.selectedRows = [];
        this.rows = [];
        this.selectedRows.push(index);
        this.rows.push(this.dataSource[index]);
      }
    }
    else if (this.isMultSelect) {
      if (!!event.ctrlKey) {
        this.onDeselectRow(index);
      }
      else {
        this.selectedRows = [];
        this.rows = [];
        this.selectedRows.push(index);
        this.rows.push(this.dataSource[index]);
      }
    }
    this.hoverRow = -1;
    this.select.emit(this.rows);
  }

  onDeselectRow(index) {
    this.selectedRows = this.selectedRows.filter(f => f != index);
    if (index < this.dataSource.length) {
      var newIndex = this.rows.findIndex(row => row === this.dataSource[index]);
      if (newIndex >= 0) {
        this.rows = this.rows.filter(row => row != this.rows[newIndex]);
      }
    }
    this.select.emit(this.rows);

  }

  isSelected(index) {
    if (this.isMultSelect)
      return this.selectedRows.indexOf(index) != -1
    else
      return this.selectedRows[0] == index;
  }
  sanitizeSMS(mobileNumber: string) {
    return this.sanitizer.bypassSecurityTrustUrl("sms:" + mobileNumber);
  }
  onCommunicationClick(action: "tel" | "sms" | "mailto", fieldName: string, contact: string, row: GridViewDataTable[]) {
    if (!!row && !!row.length) {
      this.selectedCommunicationRow = row;
    }
    this.actionCommunication = action;
    this.contactForCommunication = contact;
    this.showCommunication = !this.showCommunication;
    this.selectedCommunicationFieldName = "";
    if (action == "tel" || action == "sms") {
      this.selectedCommunicationFieldName = fieldName;
    }
  }
  onSave(event: any) {
    if (!!event && !event.isError) {
      this.showCommunication = false;
    }
  }

  private get contactID(): string {
    let id: string;
    let rows = this.selectedCommunicationRow.filter(dsColumn => dsColumn.fieldName == "contactID");
    if (!!rows.length) {
      id = !!rows[0].value ? rows[0].value.toString() : ""
    }
    return id;
  }
  private get contactName(): string {
    let id: string;
    let rows = this.selectedCommunicationRow.filter(dsColumn => dsColumn.fieldName == "contactName");
    if (!!rows.length) {
      id = !!rows[0].value ? rows[0].value.toString() : ""
    }
    return id;
  }
  private selectedCommunicationFieldName: string;
  private get mobile(): string {
    /*let mobilePhone: string;
    let rows = this.selectedCommunicationRow.filter(dsColumn => dsColumn.fieldName == "mobile");
    if (!!rows.length) {
      mobilePhone = !!rows[0].value ? rows[0].value.toString() : ""
    }
    return mobilePhone;*/
    let mobilePhone: string;
    let rows: GridViewDataTable[] = [];
    if (!!this.selectedCommunicationFieldName) {
      rows = this.selectedCommunicationRow.filter(dsColumn => dsColumn.fieldName == this.selectedCommunicationFieldName);
    }
    else {
      rows = this.selectedCommunicationRow.filter(dsColumn => dsColumn.fieldName.indexOf("mobile") >= 0 || dsColumn.fieldName.indexOf("phone") >= 0);
    }
    if (!!rows.length) {
      mobilePhone = !!rows[0].value ? rows[0].value.toString() : ""
    }
    return mobilePhone;
  }
  private get email(): string {
    let emailID: string;
    let rows = this.selectedCommunicationRow.filter(dsColumn => dsColumn.fieldName == "email");
    if (!!rows.length) {
      emailID = !!rows[0].value ? rows[0].value.toString() : ""
    }
    return emailID;
  }
  private get targetTypeID(): number {
    let typeID: number;
    let rows = this.selectedCommunicationRow.filter(dsColumn => dsColumn.fieldName == "targetTypeID");
    if (!!rows.length) {
      typeID = parseInt(rows[0].value.toString());
    }
    return typeID;
  }
  private get staffID(): string {
    let id: string;
    let rows = this.selectedCommunicationRow.filter(dsColumn => dsColumn.fieldName == "staffID");
    if (!!rows.length) {
      id = !!rows[0].value ? rows[0].value.toString() : ""
    }
    return id;
  }
  private get mailMergeName(): string {
    let id: string;
    let rows = this.selectedCommunicationRow.filter(dsColumn => dsColumn.fieldName == "mailMergeName");
    if (!!rows.length) {
      id = !!rows[0].value ? rows[0].value.toString() : "";
    }
    return id;
  }


  //region FOR Mobile VIEW SORT
  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }

  //end region

  sortData(sort: Sort){
    this._loader.show();
    setTimeout(()=>{
      this.sortAllData(sort);
    },1000)
    
  }
  private sortAllData(sort: Sort) {
    
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.dataSource.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.dataSource.length; innerIndex++) {

        for(var columnIndex = 0; columnIndex < this.dataSource[index].length; columnIndex++) {
          if (sort.active == this.dataSource[index][columnIndex].header && !!this.dataSource[index][columnIndex].sortable && this.dataSource[index][columnIndex].sortable) {
            
            if (isAsc) {
              if (this.dataSource[index][columnIndex].value > this.dataSource[innerIndex][columnIndex].value) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            else {
              if (this.dataSource[index][columnIndex].value < this.dataSource[innerIndex][columnIndex].value) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }

          }
        }

      }
    }
    this.loadPageData();
    this._loader.hide();
  }

  // sortData(sort: Sort ){
  //   const isAsc = sort.direction === 'asc';
  //   for(var i = 0; i <= this.dataSource.length; i++)
  //   {
  //     for(var j = 0; j<= this.dataSource.length;j++)
  //     {
        
  //       if(sort.active.toLowerCase() == this.dataSource[i][j].header.toLowerCase())
  //       {
  //         if (isAsc) {
  //             if (this.dataSource[i][j].value > this.dataSource[i][j+1].value) {
  //               let temp = this.dataSource[i+1];
  //               this.dataSource[i] = this.dataSource[i+1];
  //               this.dataSource[i+1] = temp;
  //             }
  //           }
  //           else {
  //             if (this.dataSource[i][j].value < this.dataSource[i][j+1].value) {
  //               let temp = this.dataSource[i+1];
  //               this.dataSource[i] = this.dataSource[i+1];
  //               this.dataSource[i+1] = temp;
  //             }
  //           }
  //           break;
  //         }
  //     }
  //   }
  // }
  validateEmail(mail): boolean {
    //return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail));
    return emailValidation(mail);
  }
  //#region Pagination  
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: GridViewDataTable[][];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.pageData = this.dataSource;
    if (this.hasPagination) {
      this.pageData = this.dataSource.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }    
  }
  //#endegion

  //#region QRCode
  onOpenQRCode(event: MouseEvent, index) {
    if (index < this.dataSource.length) {
      this.openqrcode.emit(this.dataSource[index]);
    }
    else {
      this.openqrcode.emit([] as GridViewDataTable[]);
    }
  }
  //#endregion
}
export class GridViewDataTable {
  fieldName: string;
  value: string | number | Date | bigint | null | boolean;
  showText: string;
  header: string;
  visible: boolean;
  showPhoneIcon?: boolean;
  showSMSIcon?: boolean;
  showEmailIcon?: boolean;
  showCommunicationLogPhoneIcon?: boolean;
  showCommunicationLogSMSIcon?: boolean;
  showCommunicationLogEmailIcon?: boolean;
  showQRCodeButton?: boolean;
  sortable?: boolean;
}
