import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { Sort, SortDirection } from '@angular/material/sort';
import { Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Subscription } from "rxjs/Subscription";

import { IAppState } from "../../../store/store";
import { AuthService, IDNamePair, ServiceResponse } from "../../../services/authservice";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';
import { CloseAddPrivateInspectionPageActionPage } from './../../ofi.action';
import { OfiService } from './../../ofi.service';
import { IPersonDto } from './../../ofi';
import { ConfirmDialogComponent } from './../../../components/confirmdialog/confirmdialog.component';

@Component({
  selector: 'app-addprivateinspection',
  templateUrl: './addprivateinspection.component.html',
  styleUrls: ['./addprivateinspection.component.css']
})
export class AddPrivateInspectionComponent implements OnInit, OnDestroy {

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private _datePipe: DatePipe,
    private _authService: AuthService,
    private _service: OfiService
  ) {
    this.model = new FormGroup({
      contactID: new FormControl(null),
      mobileNumber: new FormControl(null),
      email: new FormControl(null),
      givenNames: new FormControl(null),
      surname: new FormControl(null),
      selectedPropertyText: new FormControl(null),
      dateFrom: new FormControl(null),
      dateTo: new FormControl(null),
    });
   }

  //#region Private Variables
  private model: FormGroup;
  private dialogRef: any;
  private staffIDs: IDNamePair<string>[];
  private isPropertyLoading: boolean = false;
  private isMobileSearchFinished: boolean = true;
  private propertyAddress: Observable<IDNamePair<string>[]>;
  //#endregion

  //#region Configs
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  private dropdownSettings: any = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  //#endregion

  //#region Subcription Variables
  private getPropertyAddress_Subcription: Subscription;
  //#endregion

  //#region Functions
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  displayFn(item: IDNamePair<string>): string {
    if (!!item && !!item.id) {
      return item.name;
    }
  }
  //#endregion

  //#region Events
  onKeyUp(event: any) {
    if (!!event && event.keyCode >= 37 && event.keyCode <= 40) return;
    if (!!this.model.controls.selectedPropertyText.value && this.model.controls.selectedPropertyText.value.length >= 3) {
      this.isPropertyLoading = true;
      this.getPropertyAddress_Subcription =
      this._service.GetPropertyAddress(
        this.model.controls.selectedPropertyText.value
      )
      .subscribe(data => {
        if (!!data && !data.isError && !!data.response) {
          this.propertyAddress = of(data.response);
          console.log("data.response: ", data.response);
        }
        else if (data.isError) {
          this.openDialog("Alert", data.message, "alertdialog");
        }
        this.isPropertyLoading = false;
      });
    }
  }
  onMobilePhoneChange(event) {
    this.isMobileSearchFinished = true;
    if 
    (
        !!this.model.controls.mobileNumber.value && !!this.model.controls.mobileNumber.value.toString().length
        ||
        !!this.model.controls.email.value && !!this.model.controls.email.value.toString().length
    ) {
      this.isMobileSearchFinished = false;
      this._service
      .GetPersonDetails(this.model.controls.mobileNumber.value, this.model.controls.email.value)
      .subscribe(
        (result: ServiceResponse<IPersonDto>) => {
          if (!!result && !result.isError && !!result.response) {
            this.model.controls.contactID.setValue(result.response.contactID);
            this.model.controls.givenNames.setValue(result.response.givenNames);
            this.model.controls.surname.setValue(result.response.surname);
            this.model.controls.email.setValue(result.response.email);
            console.log("Success: ", result);
          } else if (!!result && result.isError) {
            this.snackBar.open("ERROR: " + result.message, "Close", this.config);
          }
          else {
            this.model.controls.contactID.setValue(null);
            this.model.controls.givenNames.setValue(null);
            this.model.controls.surname.setValue(null);
            this.model.controls.email.setValue(null);
            console.log("No contact found");
          }
          this.isMobileSearchFinished = true;
        },
        function (error) {
          this.isMobileSearchFinished = true;
          console.log("ERROR: ", error);
          this.snackBar.open(error, "Close", this.snackBarConfig);
        }
      );
    }
    else {
      this.snackBar.open("Mobile number cannot be empty!", "Close", this.config);
      this.model.controls.contactID.setValue(null);
      this.model.controls.givenNames.setValue(null);
      this.model.controls.surname.setValue(null);
      this.model.controls.email.setValue(null);
      return;
    }
  }
  onClose() {
    let action = new CloseAddPrivateInspectionPageActionPage();
    this.ngRedux.dispatch({ type: action.type });
    this._router.navigate(["/ofi"])
  }
  //#endregion

  ngOnInit() {
  }
  ngOnDestroy() {
    if (!!this.getPropertyAddress_Subcription) this.getPropertyAddress_Subcription.unsubscribe();
  }

}
