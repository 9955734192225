import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Sort } from '@angular/material/sort';

import { IAppState } from "../../../store/store";
import { AuthService } from "../../../services/authservice";
import { PropertyManagementService } from "../../propertymanagement.service";
import { ICommunicationStatement } from "../../propertymanagement";
import { LoaderService } from "../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from '../../../dialog-body/dialog-body.component';
import { CommunicationLogComponent } from '../../../components/communicationlog/communicationlog.component';

@Component({
    selector: 'app-communicationstatement',
    templateUrl: './communicationstatement.component.html',
    styleUrls: ['./communicationstatement.component.css']
  })


export class CommunicationStatementComponent implements OnInit{
  private dialogRef: any;
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  @Input() dataSource : ICommunicationStatement[];
  ngOnInit(){
      
  }
  sortData(sort: Sort) {
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.dataSource.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.dataSource.length; innerIndex++) {
        switch(sort.active){
          case "Date Sent": {
            if (isAsc) {
              if (this.dataSource[index].sentDateTime > this.dataSource[innerIndex].sentDateTime) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            else {
              if (this.dataSource[index].sentDateTime < this.dataSource[innerIndex].sentDateTime) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            break;
          }
          case "From Staff": {
            if (isAsc) {
              if (this.dataSource[index].senderName > this.dataSource[innerIndex].senderName) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            else {
              if (this.dataSource[index].senderName < this.dataSource[innerIndex].senderName) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            break;
          }
          case "Type": {
            if (isAsc) {
              if (this.dataSource[index].typeID > this.dataSource[innerIndex].typeID) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            else {
              if (this.dataSource[index].typeID < this.dataSource[innerIndex].typeID) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            break;
          }
          case "Reason": {
            if (isAsc) {
              if (this.dataSource[index].reasonName > this.dataSource[innerIndex].reasonName) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            else {
              if (this.dataSource[index].reasonName < this.dataSource[innerIndex].reasonName) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            break;
          }
          case "Notes": {
            if (isAsc) {
              if (this.dataSource[index].notes > this.dataSource[innerIndex].notes) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            else {
              if (this.dataSource[index].notes < this.dataSource[innerIndex].notes) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            break;
          }
          case "Subject": {
            if (isAsc) {
              if (this.dataSource[index].subject > this.dataSource[innerIndex].subject) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            else {
              if (this.dataSource[index].subject < this.dataSource[innerIndex].subject) {
                let temp = this.dataSource[index];
                this.dataSource[index] = this.dataSource[innerIndex];
                this.dataSource[innerIndex] = temp;
              }
            }
            break;
          }

        }
      }
    }
  }
}

