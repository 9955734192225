import { ISearchVisitors } from './reports';
import { IReportsState, INITIAL_REPORTS_STATE } from './reports.state';
import { VisitorsAction, enActionTypes } from './reports.action';
import { Reducer, Action } from "redux";

export const reportsReducer: Reducer<IReportsState> = (state = INITIAL_REPORTS_STATE(), action: VisitorsAction): IReportsState => {
    switch(action.type) {
        case enActionTypes.ShowVisitorsSearchPageAction: {
            return {
                ...state,
                visitors: {
                    search: Object.assign({}, state.visitors.search, { showSearchResult: false })
                }
            }
        }
        case enActionTypes.GetCurrentStaffsVisitorsSearchPageAction: {
            return {
                ...state,
                visitors: {
                    search: Object.assign({}, state.visitors.search, { staffIDs: action.payload})
                }
            }
        }
        case enActionTypes.ShowEnquirersFollowUpSearchPageAction: {
            return {
                ...state,
                enquirersfollowup: {
                    search: Object.assign({}, state.enquirersfollowup.search, { showSearchResult: false })
                }
            }
        }
        case enActionTypes.GetCurrentStaffsEnquirersFollowUpSearchPageAction: {
            return {
                ...state,
                enquirersfollowup: {
                    search: Object.assign({}, state.enquirersfollowup.search, { staffs: action.payload})
                }
            }
        }
        case enActionTypes.GetGetEnquiriesSearchPageAction: {
            return {
                ...state,
                enquirersfollowup: {
                    search: Object.assign({}, state.enquirersfollowup.search, { enquiries: action.payload, showSearchResult: true })
                }
            }
        }
        case enActionTypes.CloseEnquiriersFollowupResultPageAction: {
            return {
                ...state,
                enquirersfollowup: {
                    search: Object.assign({}, state.enquirersfollowup.search, { showSearchResult: false })
                }
            }
        }
        case enActionTypes.ShowListingEnquiryStatusSearchPageAction: {
            return {
                ...state,
                listingenquiries: {
                    search: Object.assign({}, state.listingenquiries.search, { showSearchResult: false })
                }
            }
        }
        case enActionTypes.GetAppStatusListingEnquiryStatusSearchPageAction: {
            return {
                ...state,
                listingenquiries: {
                    search: Object.assign({}, state.listingenquiries.search, { appointmentStatuses: action.payload })
                }
            }
        }
        case enActionTypes.GetSaleStatusListingEnquiryStatusSearchPageAction: {
            return {
                ...state,
                listingenquiries: {
                    search: Object.assign({}, state.listingenquiries.search, { saleStatuses: action.payload })
                }
            }
        }
        case enActionTypes.GetStaffsListingEnquiryStatusSearchPageAction: {
            return {
                ...state,
                listingenquiries: {
                    search: Object.assign({}, state.listingenquiries.search, { staffs: action.payload})
                }
            }
        }
        case enActionTypes.GetListingEnquiryStatusSearchPageAction: {
            return {
                ...state,
                listingenquiries: {
                  search: Object.assign({}, state.listingenquiries.search, { listingEnquiries: action.payload, showSearchResult: true })
                }
            }
        }
        case enActionTypes.CloseListingEnquiryStatusResultPageAction: {
            return {
                ...state,
                listingenquiries: {
                    search: Object.assign({}, state.listingenquiries.search, { showSearchResult: false })
                }
            }
        }
        default:
          return state;
    }
}
