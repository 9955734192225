import { ISearchOpenPrivateInspections } from './ofi';

export interface IOfiState {
    openprivateinspections: {
        search: ISearchOpenPrivateInspections
    }
}
export const INITIAL_OFI_STATE = (): IOfiState => ({
    openprivateinspections: {
        search: {
            dateFrom: null,
            dateTo: null,
            staffIDs: [],
            departments: [],
            selectedStaffIDs: [],
            appointments: [],
            showSearchResult: false,
            showAddPrivateInspection: false
        } as ISearchOpenPrivateInspections
    }
});