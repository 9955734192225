import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from 'rxjs/Subscription';
import { QueryBuilderConfig } from 'angular2-query-builder';

import { IAppState } from "../../../../store/store";
import { EmarketingService } from "./../../../emarketing.service";
import { LoaderService } from "./../../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../../dialog-body/dialog-body.component';
import { IEmailContactList, IContactFilterRuleSet, enRuleSetCondition, enRuleOperator, IFilterItem, IContactFilterRule } from "./../../../emarketing";
import { CloseContactListAddEditPage } from "./../../../emarketing.action";
import "../../../../../../../wwwroot/js/scripts.js";
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'app-editcontactlists',
  templateUrl: './editcontactlists.component.html',
  styleUrls: ['./editcontactlists.component.css']
})
export class EditContactListsComponent implements OnInit, AfterViewInit, OnDestroy {

  private model: FormGroup;
  public queryCtrl: FormControl;
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  private dialogRef: any;

  private qcConfig: QueryBuilderConfig = {
    fields: {
      "Active Since": {
        name: "Active Since",
        type: "category",        
      }
    }
  };

  private subscription_SaveContactList: Subscription;

  constructor(private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: EmarketingService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private formBuilder: FormBuilder) {
      this.queryCtrl = this.formBuilder.control({
        condition: 'and',
        rules: []}, Validators.required);
      this.model = new FormGroup({
        id:  new FormControl(this.ngRedux.getState().emarketing.contactlists.addEditView.contactListID),
        name: new FormControl(this.ngRedux.getState().emarketing.contactlists.addEditView.name, Validators.required),
        description: new FormControl(this.ngRedux.getState().emarketing.contactlists.addEditView.description),
        //autoSubscribeNewContacts: new FormControl(this.ngRedux.getState().emarketing.contactlists.addEditView.autoSubscribeNewContacts),
        status: new FormControl(this.ngRedux.getState().emarketing.contactlists.addEditView.status, Validators.required),
        query: this.queryCtrl,
      });
  }

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }

  @select(state => (state as IAppState).emarketing.contactlists.addEditView.statuses) statuses$;
  @select(state => (state as IAppState).emarketing.contactlists.addEditView.filter) filter$;

  ngOnInit() {
    this.fillQuery();
  }
  ngAfterViewInit() {
  }
  ngOnDestroy() {
    if (!!this.subscription_SaveContactList) this.subscription_SaveContactList.unsubscribe();
  }

  //#region Functions

  //#region Query Builder
  private showQB: boolean = false;
  onShow() {    
    if (!this.showQB) {
      this.InitializeQB();
    }
    this.showQB = !this.showQB;
  }
  async InitializeQB() {
    await this._service.GetContactFilterFields()
    .then(fields => {

      let queryConfig: QueryBuilderConfig = {} as QueryBuilderConfig;
      fields.forEach((field, index) => {
        let name = "\"" + field.name + " [" + field.id.toString() + "]" + "\"";
        let fieldName = field.name;// + " [" + field.id.toString() + "]";
        switch(field.type) {
          case 0:
            console.log("Unknown");
            break;
          case 1:
            console.log("String");
            if (!!field.options && !!field.options.length) {
              
              let options = field.options.map(opt => { 
                return { name: opt.name, value: opt.value }
               });
              if (index == 0) {
                queryConfig.fields = Object.assign({}, { [name]: { "name": fieldName, "type": "category", "options": options, "operators": ['equal', 'not_equal', 'in'] } });
              }
              else {
                queryConfig.fields = Object.assign({}, queryConfig.fields, { [name]: { "name": fieldName, "type": "category", "options": options, "operators": ['equal', 'not_equal', 'in'] } });
              }

            }
            else {
              if (index == 0) {
                queryConfig.fields = Object.assign({}, { [name]: { "name": fieldName, "type": "string", "operators": ['equal', 'not_equal', 'in'] } });
              }
              else {
                queryConfig.fields = Object.assign({}, queryConfig.fields, { [name]: { "name": fieldName, "type": "string", "operators": ['equal', 'not_equal', 'in'] } });
              }
            }
            break;
          case 2:
            console.log("Number");
            if (!!field.options && !!field.options.length) {
              let options = field.options.map(opt => { 
                return { name: opt.name, value: opt.value }
              });
              if (index == 0) {
                queryConfig.fields = Object.assign({}, { [name]: { "name": fieldName, "type": "category", "operators": ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'], "options": options } });
              }
              else {
                queryConfig.fields = Object.assign({}, queryConfig.fields, { [name]: { "name": fieldName, "category": "number", "operators": ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'], "options": options } });
              }
            }
            else {
              if (index == 0) {
                queryConfig.fields = Object.assign({}, { [name]: { "name": fieldName, "type": "number", "operators": ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'] } });
              }
              else {
                queryConfig.fields = Object.assign({}, queryConfig.fields, { [name]: { "name": fieldName, "type": "number", "operators": ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'] } });
              }
            }
            break;
          case 3:
            console.log("Boolean");
            if (!!field.options && !!field.options.length) {
              let options = field.options.map(opt => { 
                return { name: opt.name, value: opt.value }
              });
              if (index == 0) {
                queryConfig.fields = Object.assign({}, queryConfig, { [name]: { "name": fieldName, "type": "category", "operators": ['equal'], "options": options } });
              }
              else {
                queryConfig.fields = Object.assign({}, queryConfig.fields, { [name]: { "name": fieldName, "type": "category", "operators": ['equal'], "options": options } });
              }
            }
            else {
              if (index == 0) {
                queryConfig.fields = Object.assign({}, { [name]: { "name": fieldName, "type": "boolean", "operators": ['equal'], "values": { 0: "No", 1: "Yes" } } });
              }
              else {
                queryConfig.fields = Object.assign({}, queryConfig.fields, { [name]: { "name": fieldName, "type": "boolean", "operators": ['equal'], "values": { 0: "No", 1: "Yes" } } });
              }
            }
            break;
          case 4:
            console.log("Date");
            if (!!field.options && !!field.options.length) {
              let options = field.options.map(opt => { 
                return { name: opt.name, value: opt.value }
              });
              if (index == 0) {
                queryConfig.fields = Object.assign({}, { [name]: { "name": fieldName, "type": "category", "operators": ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'], "options": options } });
              }
              else {
                queryConfig.fields = Object.assign({}, queryConfig.fields, { [name]: { "name": fieldName, "type": "category", "operators": ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'], "options": options } });
              }
            }
            else {
              if (index == 0) {
                queryConfig.fields = Object.assign({}, { [name]: { "name": fieldName, "type": "date", "operators": ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'] } });
              }
              else {
                queryConfig.fields = Object.assign({}, queryConfig.fields, { [name]: { "name": fieldName, "type": "date", "operators": ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'] } });
              }
            }
            break;
          case 5:
            console.log("Category");
            if (!!field.options && !!field.options.length) {
              let options = field.options.map(opt => { 
                return { name: opt.name, value: opt.value }
              });
              if (index == 0) {
                queryConfig.fields = Object.assign({}, { [name]: { "name": fieldName, "type": "category", "operators": ['equal', 'not_equal'], "options": options } });
              }
              else {
                queryConfig.fields = Object.assign({}, queryConfig.fields, { [name]: { "name": fieldName, "type": "category", "operators": ['equal', 'not_equal'], "options": options } });
              }
            }
            else {
              if (index == 0) {
                queryConfig.fields = Object.assign({}, { [name]: { "name": fieldName, "type": "string", "operators": ['equal', 'not_equal'] } });
              }
              else {
                queryConfig.fields = Object.assign({}, queryConfig.fields, { [name]: { "name": fieldName, "type": "string", "operators": ['equal', 'not_equal'] } });
              }
            }
            break;
        }

      });
      this.qcConfig = queryConfig;
      console.log("this.queryConfig: ", this.qcConfig.fields);
    });
  }
  fillQuery() {
    let contactListID = this.ngRedux.getState().emarketing.contactlists.addEditView.contactListID;
    if (!!contactListID && contactListID.indexOf('00000000') < 0) {
      this.showQB = false;
      this.onShow();
      this.filter$.subscribe((filter: IContactFilterRuleSet) => {
        if (!!filter && !!filter.items && !!filter.items.length) {
          setTimeout(() => {
            let query = this.buildFilterForQB(filter);
            this.queryCtrl = this.formBuilder.control(query);
          }, 5000);
          
        }
      });
    }
  }
  //#endregion

  //#region Save
  onSubmit() {
    console.log(this.queryCtrl);
    // let filter: IContactFilterRuleSet = {
    //   id: "",
    //   condition: enRuleSetCondition.and,
    //   items: [{
    //     id: "",
    //     condition: enRuleSetCondition.or,
    //     items: [
    //       {
    //         id: "",
    //         contactFilterFieldID: 0,
    //         _operator: enRuleOperator.equals,
    //         value: "45545"
    //       } as IContactFilterRule
    //     ]
    //   } as IContactFilterRuleSet]
    // }
    let filter: IFilterItem;
    if (!!this.queryCtrl && !!this.queryCtrl.value) {
      filter = this.buildFilter(this.queryCtrl.value);
    }
    
    if (this.model.status == "VALID" && this.queryCtrl.status == "VALID" && !!filter) {
      this._loader.show();
      this.subscription_SaveContactList = this._service.SaveContactList
      (
        this.model.controls.id.value,
        this.model.controls.name.value,
        this.model.controls.description.value,
        this.model.controls.status.value,
        filter
      )
      .subscribe(success => {
        this._loader.hide();
        if (success) {
          this.snackBar.open("Contact list saved successfully!!", "Close", this.config);
          this.model.reset();
          this.queryCtrl.reset();
        }
      },
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      })
    }
  }
  private buildFilter(rule: any): IFilterItem {
    let newRule: IFilterItem;
    if (!!rule) {
      if (rule.hasOwnProperty("condition")) {
        newRule = {
          id: "",
          condition: rule.condition == "and"? enRuleSetCondition.and : enRuleSetCondition.or,
          items: !rule.rules ? [] : rule.rules.map(itm => {
            return this.buildFilter(itm)
          })
        } as IContactFilterRuleSet;
        
      }
      else {
        let regexp: RegExp = /\[([^\]]+)\]/;
        var fieldIDs = regexp.exec(rule.field);
        newRule = {
          id: "",
          contactFilterFieldID: !!fieldIDs && !!fieldIDs.length ? fieldIDs[1] : -1,
          _operator: this.getOperator(rule.operator),
          value: rule.value
        } as IContactFilterRule;
        if (!!fieldIDs && !!fieldIDs.length && (parseInt(fieldIDs[1]) == 8 || parseInt(fieldIDs[1]) == 9)) {
          (newRule as IContactFilterRule).value = (newRule as IContactFilterRule).value == "" 
          || (newRule as IContactFilterRule).value == null 
          || (newRule as IContactFilterRule).value == undefined ? "false" : (newRule as IContactFilterRule).value;
        }
      }
    }
    return newRule;
  }
  private buildFilterForQB(rule: IFilterItem): any {
    let newRule: any;
    if (!!rule) {
      if (rule.hasOwnProperty("condition")) {
        newRule = {
          //id: (rule as IContactFilterRuleSet).id,
          condition: (rule as IContactFilterRuleSet).condition == enRuleSetCondition.and ? "and" : "or",
          rules: !!(rule as IContactFilterRuleSet).items && !!(rule as IContactFilterRuleSet).items.length ? (rule as IContactFilterRuleSet).items.map(item => { return this.buildFilterForQB(item) }) : []
        };
      }
      else {
        if ((rule as IContactFilterRule).value != "" && (rule as IContactFilterRule).value != null && (rule as IContactFilterRule).value != undefined) {
          newRule = {
            field: this.getContactFilterFieldName((rule as IContactFilterRule).contactFilterFieldID),
            operator: this.getOperatorSymbol((rule as IContactFilterRule)._operator),
            value: (rule as IContactFilterRule).value
          };
        }
        else {
          newRule = {
            field: this.getContactFilterFieldName((rule as IContactFilterRule).contactFilterFieldID),
            operator: this.getOperatorSymbol((rule as IContactFilterRule)._operator)
          };
        }
      }
    }
    return newRule;
  }
  getOperator(operator: string): enRuleOperator {
    switch(operator) {
      case "equal":
        return enRuleOperator.equals;
      case "not_equal":
        return enRuleOperator.notEquals;
      case "greater":
        return enRuleOperator.greaterThan;
      case "greater_or_equal":
        return enRuleOperator.greaterThanOrEqualTo;
      case "less":
        return enRuleOperator.lessThan;
      case "less_or_equal":
        return enRuleOperator.lessThanOrEqualTo;
    }
  }
  getOperatorSymbol(operator: enRuleOperator): string {
    switch (operator) {
      case enRuleOperator.equals:
        return "equal";
      case enRuleOperator.notEquals:
        return "not_equal";
      case enRuleOperator.greaterThan:
        return "greater";
      case enRuleOperator.greaterThanOrEqualTo:
        return "greater_or_equal";
      case enRuleOperator.lessThan:
        return "less";
      case enRuleOperator.lessThanOrEqualTo:
        return "less_or_equal";
    }
  }
  getContactFilterFieldName(fieldID: number): string {
    switch (fieldID) {
      case 0:
        return "\"Name [0]\"";
      case 1:
        return "\"Contact Group [1]\"";
      case 2:
        return "\"Contact Category [2]\"";
      case 3:
        return "\"Sales Staff [3]\"";
      case 4:
        return "\"Lead Source [4]\"";
      case 5:
        return "\"Active Since [5]\"";
      case 6:
        return "\"Birthday Day of Month [6]\"";
      case 7:
        return "\"Birthday Month of Year [7]\"";
      case 8:
        return "\"Is Person [8]\"";
      case 9:
        return "\"Is Company [9]\"";
    }
  }
  //#endregion

  //#region Close
  onClose() {
    let action = new CloseContactListAddEditPage();
    this.ngRedux.dispatch({type: action.type});
    this._router.navigate(["/contactlists"]);
  }
  //#endregion

  //#endregion

}
