import { GridViewDataTable } from './../../components/gridview/gridview.component';
import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Sort, SortDirection } from '@angular/material/sort';

import { IAppState } from "../../../store/store";
import { AuthService } from "../../../services/authservice";
import { PropertyManagementService } from "./../../propertymanagement.service";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';
import { CloseManagementAgreementResultPage, GetWorkOrder } from "./../../propertymanagement.action"
import { ICommunicationStatement, IManagementAgreement, IWorkOrderResult } from '../../propertymanagement';
import { GetManagementAgreements } from "./../../propertymanagement.action";
import { DatePipe } from '@angular/common';
import { emailValidation } from "./../../../utility/autounsubcribe";

import "../../../../../../wwwroot/js/scripts.js";
declare var $: JQueryStatic;
declare var LoadScript: any;


@Component({
  selector: 'app-managementagreementsearchresult',
  templateUrl: './managementagreementsearchresult.component.html',
  styleUrls: ['./managementagreementsearchresult.component.css']
})
export class ManagementAgreementSearchResultComponent implements OnInit, AfterViewInit, OnDestroy {

  private dialogRef: any;
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  private managementAgrements_Subscription: Subscription;
  private workOrder_Subscription: Subscription;

  //#region Communicatio Log
  private getSessionData_Subscription: Subscription;
  private showCommunication: boolean;
  private showWorkOrder: boolean;
  private actionCommunication: "tel" | "sms" | "mailto";
  private contactForCommunication: string;
  private contactID: string;
  private contactName: string;
  private mobile: string;
  private email: string;
  private targetTypeID: number;
  private staffID: string;
  private mailMergeName: string;
  private managementAgrements: IManagementAgreement[] = [];
  private managementAgreement: IManagementAgreement;

  private showSortingLoader: boolean = false;

  //#endregion


  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    //private _eMarketingService: PropertyManagementService,
    private _service: PropertyManagementService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private _datePipe: DatePipe
  ) { }


  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }


  @select(state => (state as IAppState).propertymanagement.managementagreement.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).propertymanagement.managementagreement.search.managementagreements) managementagreements$;

  ngOnInit() {
    this.managementAgrements = [];
    this.managementAgrements_Subscription = this.managementagreements$
      .subscribe(ma => {
        if (!!ma && !!ma.length) {
          this.managementAgrements = ma;
          this.loadPageData();
        }
      });

      

    this.getSessionData_Subscription = this._authService.GetSessionData()
      .subscribe(session => {
        this.staffID = session.staffID;
        this.mailMergeName = session.mailMergeName;
      });
  }
  ngAfterViewInit() {
    LoadScript.init();
    LoadScript.gotoTop();
    var clearIntv = null;
    clearIntv = setInterval(() => {
      if (!!$("#no-more-tables > table").length) {
        clearInterval(clearIntv);
        //FixedTableHeaderScript.fixMe($("table"));
        $(".up").click(function () {
          $("html, body").animate(
            {
              scrollTop: 0
            },
            2000
          );
        });
      }
    }, 2000);
  }
  ngOnDestroy() {
    //if (!!this.getSessionDataSubscription) this.getSessionDataSubscription.unsubscribe();
    if (!!this.getSessionData_Subscription) this.getSessionData_Subscription.unsubscribe();
    if (!!this.managementAgrements_Subscription) this.managementAgrements_Subscription.unsubscribe();
  }

  sanitizeSMS(ownerMobilePhone: string) {
    return this.sanitizer.bypassSecurityTrustUrl("sms:" + ownerMobilePhone);
  }

  onCommunicationClick(action: "tel" | "sms" | "mailto", row: IManagementAgreement) {
    this.actionCommunication = action;
    if (action == "tel" || action == "sms") {
      this.contactForCommunication = row.ownerMobilePhone;
    }
    else if (action == "mailto") {
      this.contactForCommunication = row.ownerEmail;
    }
    this.contactID = row.contactID;
    this.contactName = row.ownerNames;
    this.mobile = row.ownerMobilePhone;
    this.email = row.ownerEmail;
    this.showCommunication = !this.showCommunication;
  }
  onSave(event: any) {
    if (!!event && !event.isError) {
      this.showCommunication = false;
    }
  }

  onClose() {
    let action = new CloseManagementAgreementResultPage(true);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    this._router.navigate(["/managementagreements"])
  }

  onCommunicationExpand(ma: IManagementAgreement) {    
    var timezoneOffset =  new Date().getTimezoneOffset();
    if (!ma.showCommunication) {
      ma.showCommunication = false;
      ma.showCommunicationLoader = true;
      ma.communications = [];
      this._service.CommunicationDetails(ma.id, 1,timezoneOffset)
        .subscribe(data => {
          ma.communications = data;
          ma.showCommunication = true;
          ma.showCommunicationLoader = false;
        }),
        (error: string) => {
          this.openDialog("Alert", error, "alertdialog");
        };
    }
    else {
      ma.showCommunication = !ma.showCommunication;
    }
  }

  validateEmail(email: string){
    //return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));
    return emailValidation(email);
  }

  //#region Pagination  
  private hasPagination: boolean = true;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: IManagementAgreement[];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.pageData = this.managementAgrements;
    if (this.hasPagination) {
      this.pageData = this.managementAgrements.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }    
  }
  //#endegion

  onWorkOrderExpand(ma: IManagementAgreement) {
    var propertyManagerIDs: string[] = [];
    if (!ma.showWorkOrder) {
      propertyManagerIDs.push(ma.propertyManagerID);
      ma.showWorkOrder = false;
      ma.showWorkOrderLoader = true;
      this.workOrder_Subscription =
        this._service.GetWorkOrder
          (
          null,
          1,
          null,
          null,
          null,
          1,
          null,
          propertyManagerIDs,
          null,
          false,
          ma.id
          )
          .subscribe((data: any) => {
            if (data.isError) {
              this.openDialog("Error", data.message, "alertdialog");
            }
            else {
              ma.workOrders = data.response.map(item =>
                [
                  { fieldName: "dateIssued", value: this._datePipe.transform(item.dateIssued, "dd/MM/yyyy"), showText: this._datePipe.transform(item.dateIssued, "dd/MM/yyyy"), header: "Date Issued", visible: true },
                  { fieldName: "number", value: item.number, showText: !!item.number ? item.number.toString() : "-", header: "Number", visible: true },
                  { fieldName: "statusDetails", value: item.statusDetails, showText: !!item.statusDetails ? item.statusDetails.toString() : "-", header: "Status", visible: true },
                  { fieldName: "address", value: item.address, showText: !!item.address ? item.address.toString() : "-", header: "Property Address", visible: true },
                  { fieldName: "supplierName", value: item.supplierName, showText: !!item.supplierName ? item.supplierName.toString() : "-", header: "Supplier", visible: true },
                  { fieldName: "serviceTypeName", value: item.serviceTypeName, showText: !!item.serviceTypeName ? item.serviceTypeName.toString() : "-", header: "Reason", visible: true },
                  { fieldName: "supplierMobileNumber", value: item.supplierMobileNumber, showText: !!item.supplierMobileNumber ? item.supplierMobileNumber.toString() : "-", header: "Supplier Mobile", visible: true, showPhoneIcon: true, showSMSIcon: true },
                  { fieldName: "supplierEmail", value: item.supplierEmail, showText: !!item.supplierEmail ? item.supplierEmail.toString() : "-", header: "Supplier Email", visible: true, showEmailIcon: true },
                  { fieldName: "onSiteContactName", value: item.onSiteContactName, showText: !!item.onSiteContactName ? item.onSiteContactName.toString() : "-", header: "Onsite Contact", visible: true },
                  { fieldName: "onsiteContactMobile", value: item.onsiteContactMobile, showText: !!item.onsiteContactMobile ? item.onsiteContactMobile.toString() : "-", header: "Onsite Contact Mobile", visible: true, showPhoneIcon: true, showSMSIcon: true },
                  { fieldName: "workDetail", value: item.workDetail, showText: !!item.workDetail ? item.workDetail.toString() : "-", header: "Work Order Details", visible: true },
                ]
              );
              ma.showWorkOrder = true;
              ma.showWorkOrderLoader = false;
            }
          });
    }
    else {
      ma.showWorkOrder = !ma.showWorkOrder;
    }
  }

  onWorkOrderPopUpOpen(ma: IManagementAgreement) {
    this.showWorkOrder = true;
    this.managementAgreement = ma;
  }

  //region FOR Mobile VIEW SORT
  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }

  //end region
  sortData(sort: Sort) {
    //this.showSortingLoader = true;
    this._loader.show();
    setTimeout(() => { 
      this.sortAllData(sort);
    }, 10)
  }
  private sortAllData(sort: Sort) {    
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.managementAgrements.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.managementAgrements.length; innerIndex++) {
        switch (sort.active) {
          case "Address": {
            if (isAsc) {
              if (this.managementAgrements[index].addressText > this.managementAgrements[innerIndex].addressText) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            else {
              if (this.managementAgrements[index].addressText < this.managementAgrements[innerIndex].addressText) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            break;
          }
          case "Owners": {
            if (isAsc) {
              if (this.managementAgrements[index].ownerNames > this.managementAgrements[innerIndex].ownerNames) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            else {
              if (this.managementAgrements[index].ownerNames < this.managementAgrements[innerIndex].ownerNames) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            break;
          }
          case "Owner Mobile": {
            if (isAsc) {
              if (this.managementAgrements[index].ownerMobilePhone > this.managementAgrements[innerIndex].ownerMobilePhone) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            else {
              if (this.managementAgrements[index].ownerMobilePhone < this.managementAgrements[innerIndex].ownerMobilePhone) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            break;
          }
          case "Owner Email": {
            if (isAsc) {
              if (this.managementAgrements[index].ownerEmail > this.managementAgrements[innerIndex].ownerEmail) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            else {
              if (this.managementAgrements[index].ownerEmail < this.managementAgrements[innerIndex].ownerEmail) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            break;
          }
          case "Property Manager": {
            if (isAsc) {
              if (this.managementAgrements[index].propertyManagerName > this.managementAgrements[innerIndex].propertyManagerName) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            else {
              if (this.managementAgrements[index].propertyManagerName < this.managementAgrements[innerIndex].propertyManagerName) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            break;
          }
          case "Status": {
            if (isAsc) {
              if (this.managementAgrements[index].statusID > this.managementAgrements[innerIndex].statusID) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            else {
              if (this.managementAgrements[index].statusID < this.managementAgrements[innerIndex].statusID) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            break;
          }
          case "Management Start Date": {
            if (isAsc) {
              if (this.managementAgrements[index].fullManagementStartDate > this.managementAgrements[innerIndex].fullManagementStartDate) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
              else if (this.managementAgrements[index].fullManagementStartDate == null && this.managementAgrements[innerIndex].fullManagementStartDate == null)  {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            else {
              if (this.managementAgrements[index].fullManagementStartDate < this.managementAgrements[innerIndex].fullManagementStartDate) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
              else if (this.managementAgrements[index].fullManagementStartDate == null && this.managementAgrements[innerIndex].fullManagementStartDate == null)  {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            break;
          }

          case "Tenant": {
            if (isAsc) {
              if (this.managementAgrements[index].tenantNames > this.managementAgrements[innerIndex].tenantNames) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            else {
              if (this.managementAgrements[index].tenantNames < this.managementAgrements[innerIndex].tenantNames) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            break;
          }
          case "Weekly Rent": {
            if (isAsc) {
              if (this.managementAgrements[index].weeklyRentGSTInclusive > this.managementAgrements[innerIndex].weeklyRentGSTInclusive) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            else {
              if (this.managementAgrements[index].weeklyRentGSTInclusive < this.managementAgrements[innerIndex].weeklyRentGSTInclusive) {
                let temp = this.managementAgrements[index];
                this.managementAgrements[index] = this.managementAgrements[innerIndex];
                this.managementAgrements[innerIndex] = temp;
              }
            }
            break;
          }
        }
      }
    }
    this.loadPageData();
    let action = new GetManagementAgreements(this.managementAgrements);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    //this.showSortingLoader = false;
    this._loader.hide();
  }

  //#region Close Work Order Popup Dialog
  private IsWorkOrderSaved: boolean = false;
  private closeOnOutsideClick: boolean = false;
  onWorkOrderSave(event: any) {
    this.IsWorkOrderSaved = event;
  }
  onCloseWorkOrder(event: any) {
    if (!this.IsWorkOrderSaved) {
      this.showWorkOrder = !confirm("Work order not saved!! Still want to close?");
      this.closeOnOutsideClick = !this.showWorkOrder;
    }
    else {
      this.showWorkOrder = false;
    }
  }
  //#endregion

}
