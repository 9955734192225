import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { Sort, SortDirection } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { IAppState } from "../../../store/store";
import { AuthService, ServiceResponse } from "../../../services/authservice";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';

import { ReportsService } from "../../reports.service";
import { IListingEnquiryStatusDto } from './../../reports';
import { CloseListingEnquiryStatusResultPage } from './../../reports.action';

@Component({
  selector: 'app-listingenquirystatussearchresult',
  templateUrl: './listingenquirystatussearchresult.component.html',
  styleUrls: ['./listingenquirystatussearchresult.component.scss']
})
export class ListingEnquiryStatusSearchResultComponent implements OnInit, OnDestroy {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private _datePipe: DatePipe,
    private _authService: AuthService,
    private _service: ReportsService
  ) {
    this.enquiryDetailsModel = new FormGroup({
      selectedMotivationLevelID: new FormControl(null),
      selectedInterestLevelID: new FormControl(null),
      selectedSuitabilityID: new FormControl(null),
      followUpRequired: new FormControl(false),
      nextContactDate: new FormControl(false),
      enquirerComments: new FormControl(null),
      staffComments: new FormControl(null)
    });
  }

  @select(state => (state as IAppState).reports.listingenquiries.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).reports.listingenquiries.search.listingEnquiries) listingEnquiries$;

  //#region Private Variables
  private dialogRef: any;
  private enquiryDetailsModel: FormGroup;
  private selectedListingEnquiry: IListingEnquiryStatusDto;
  private msgHideAndShow: boolean = false;
  private textMessage: any;
  @ViewChild('closeListingEnquiriesModal') closeListingEnquiriesModal: ElementRef;
  //#endregion

  //#region Subcription Variables
  private saveListingEnquiryStatus_Subcription: Subscription;
  //#endregion

  //#region Snackbar Config
  private snackbarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //#region
  //#region Functions
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  //#endregion

  ngOnInit() {
    this.listingEnquiries = this.ngRedux.getState().reports.listingenquiries.search.listingEnquiries;
    this.loadPageData();

    this.formControlDataFill();
  }
  ngOnDestroy() {
    if (!!this.saveListingEnquiryStatus_Subcription) this.saveListingEnquiryStatus_Subcription.unsubscribe();
  }
  onClose() {
    let action = new CloseListingEnquiryStatusResultPage();
    this.ngRedux.dispatch({ type: action.type });
    this._router.navigate(["/listingenquirystatus"])
  }
  onClickCopyLink(bookingLink) {
    bookingLink.select();
    document.execCommand('copy');
    bookingLink.setSelectionRange(0, 0);
    this.textMessageFunc('Link');
  }
  onListingEnquiryClick(le: IListingEnquiryStatusDto) {
    this.selectedListingEnquiry = null;
    this.selectedListingEnquiry = le;
    
    this.formControlDataFill();
  }
  onSubmit() {

    //MotivationLevelID
    let motivationLevelID: number = null;
    if (!!this.enquiryDetailsModel.controls.selectedMotivationLevelID) {
      motivationLevelID = this.enquiryDetailsModel.controls.selectedMotivationLevelID.value;
      console.log("motivationLevelID: ", motivationLevelID);
    }

    //InterestLevelID
    let interestLevelID: number = null;
    if (!!this.enquiryDetailsModel.controls.selectedInterestLevelID) {
      interestLevelID = this.enquiryDetailsModel.controls.selectedInterestLevelID.value;
      console.log("interestLevelID: ", interestLevelID);
    }

    //SuitabilityID
    let suitabilityID: number = null;
    if (!!this.enquiryDetailsModel.controls.selectedSuitabilityID) {
      suitabilityID = this.enquiryDetailsModel.controls.selectedSuitabilityID.value;
      console.log("suitabilityID: ", suitabilityID);
    }

    //Next Contact Date
    let nextContactDate: Date | null = null;
    if (!!this.enquiryDetailsModel.controls.nextContactDate.value) {
      nextContactDate = this.enquiryDetailsModel.controls.nextContactDate.value;
      console.log("nextContactDate: ", nextContactDate);
    }

    //Enquirer Comments
    let enquirerComments: string = null;
    if (!!this.enquiryDetailsModel.controls.enquirerComments.value) {
      enquirerComments = this.enquiryDetailsModel.controls.enquirerComments.value;
      console.log("enquirerComments: ", enquirerComments);
    }

    //Staff's Comments
    let staffComments: string = null;
    if (!!this.enquiryDetailsModel.controls.staffComments.value) {
      staffComments = this.enquiryDetailsModel.controls.staffComments.value;
      console.log("staffComments: ", staffComments);
    }

    //ListingEnquiryID
    let listingEnquiryID: string = null;
    if ((!!this.selectedListingEnquiry && !!this.selectedListingEnquiry.id) && this.selectedListingEnquiry.id.indexOf("00000000-") < 0)
      listingEnquiryID = this.selectedListingEnquiry.id;
    else {
      this.openDialog("Alert", "Listing Enquiry not found", "alertdialog");
      return;
    }    

    //FollowUpRequired
    let followUpRequired: boolean = false;
    if (!!this.enquiryDetailsModel.controls.followUpRequired) {
      followUpRequired = this.enquiryDetailsModel.controls.followUpRequired.value;
    }

    this._loader.show();
    this.saveListingEnquiryStatus_Subcription =
      this._service
        .SaveListingEnquiryStatus
        (
          listingEnquiryID,
          (!!this.selectedListingEnquiry ? this.selectedListingEnquiry.appointmentContactID : null),
          (!!this.selectedListingEnquiry ? this.selectedListingEnquiry.appointmentListingID : null),
          motivationLevelID,
          interestLevelID,
          suitabilityID,
          nextContactDate,
          enquirerComments,
          staffComments,
          followUpRequired
        )
        .map((data: ServiceResponse<boolean>) => {
          return data;
        })
        .subscribe(data => {
          if (!data.isError) {
            //this.openDialog("Alert", "Data saved successfully", "alertdialog");
            let pd = this.pageData.find(pd => pd.id == this.selectedListingEnquiry.id);
            if (!!pd) {
              pd.motivationID = motivationLevelID;
              pd.interestLevelID = interestLevelID;
              pd.suitabilityID = suitabilityID;
              pd.nextContactDate = nextContactDate;
              pd.notes = enquirerComments;
              pd.staffComments = staffComments;
              pd.followUpRequired = followUpRequired;


              this.closeListingEnquiriesModal.nativeElement.click();
            }
          }
          else {
            this.snackBar.open(data.message, "Close", this.snackbarConfig);
          }
          this._loader.hide();
        }),
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      }

  }
  //#region Functions
  // Text Message   
  textMessageFunc(msgText) {
    this.textMessage = msgText + " Copied to Clipboard";
    this.msgHideAndShow = true;
    setTimeout(() => {
      this.textMessage = "";
      this.msgHideAndShow = false;
    }, 1000);

  }
  sanitizeSMS(mobileNumber: string) {
    return this.sanitizer.bypassSecurityTrustUrl("sms:" + mobileNumber);
  }
  formControlDataFill() {
    this.enquiryDetailsModel.controls.selectedMotivationLevelID.setValue((!!this.selectedListingEnquiry) ? this.selectedListingEnquiry.motivationID : null);
    this.enquiryDetailsModel.controls.selectedInterestLevelID.setValue((!!this.selectedListingEnquiry) ? this.selectedListingEnquiry.interestLevelID : null);
    this.enquiryDetailsModel.controls.selectedSuitabilityID.setValue((!!this.selectedListingEnquiry) ? this.selectedListingEnquiry.suitabilityID : null);
    this.enquiryDetailsModel.controls.followUpRequired.setValue((!!this.selectedListingEnquiry) ? this.selectedListingEnquiry.followUpRequired : false);
    this.enquiryDetailsModel.controls.nextContactDate.setValue((!!this.selectedListingEnquiry) ? this.selectedListingEnquiry.nextContactDate : null);
    this.enquiryDetailsModel.controls.enquirerComments.setValue((!!this.selectedListingEnquiry) ? this.selectedListingEnquiry.notes : null);
    this.enquiryDetailsModel.controls.staffComments.setValue((!!this.selectedListingEnquiry) ? this.selectedListingEnquiry.staffComments : null);
  }
  //#endregion
  //#region Pagination
  private listingEnquiries: IListingEnquiryStatusDto[] = [];
  private hasPagination: boolean = true;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: IListingEnquiryStatusDto[];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.pageData = this.listingEnquiries;
    if (this.hasPagination) {
      this.pageData = this.listingEnquiries.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }
  }
  //#endegion
  //#region Grid Data Sort
  clearSortTimeout: any = null;
  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }
  sortData(sort: Sort) {
    this._loader.show();
    this.clearSortTimeout = setTimeout(() => {
      this.sortAllData(sort);
    }, 10)
  }
  private sortAllData(sort: Sort) {
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.listingEnquiries.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.listingEnquiries.length; innerIndex++) {
        switch (sort.active) {
          case 'dateEntered': {
            if (isAsc) {
              if (this.listingEnquiries[index].dateEntered > this.listingEnquiries[innerIndex].dateEntered) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.listingEnquiries[index].dateEntered < this.listingEnquiries[innerIndex].dateEntered) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'department': {
            if (isAsc) {
              if (this.listingEnquiries[index].department > this.listingEnquiries[innerIndex].department) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.listingEnquiries[index].department < this.listingEnquiries[innerIndex].department) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'leadSource': {
            if (isAsc) {
              if (this.listingEnquiries[index].leadSource > this.listingEnquiries[innerIndex].leadSource) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.listingEnquiries[index].leadSource < this.listingEnquiries[innerIndex].leadSource) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'listerName': {
            if (isAsc) {
              if (this.listingEnquiries[index].listerName > this.listingEnquiries[innerIndex].listerName) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.listingEnquiries[index].listerName < this.listingEnquiries[innerIndex].listerName) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'propertyAddress': {
            if (isAsc) {
              if (this.listingEnquiries[index].propertyAddress > this.listingEnquiries[innerIndex].propertyAddress) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.listingEnquiries[index].propertyAddress < this.listingEnquiries[innerIndex].propertyAddress) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'name': {
            if (isAsc) {
              if (this.listingEnquiries[index].name > this.listingEnquiries[innerIndex].name) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.listingEnquiries[index].name < this.listingEnquiries[innerIndex].name) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'email': {
            if (isAsc) {
              if (this.listingEnquiries[index].email > this.listingEnquiries[innerIndex].email) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.listingEnquiries[index].email < this.listingEnquiries[innerIndex].email) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'mobilePhone': {
            if (isAsc) {
              if (this.listingEnquiries[index].mobilePhone > this.listingEnquiries[innerIndex].mobilePhone) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.listingEnquiries[index].mobilePhone < this.listingEnquiries[innerIndex].mobilePhone) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'statusID': {
            if (isAsc) {
              if (this.listingEnquiries[index].statusID > this.listingEnquiries[innerIndex].statusID) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.listingEnquiries[index].statusID < this.listingEnquiries[innerIndex].statusID) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'appointmentStatus': {
            if (isAsc) {
              if (this.listingEnquiries[index].appointmentStatus > this.listingEnquiries[innerIndex].appointmentStatus) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.listingEnquiries[index].appointmentStatus < this.listingEnquiries[innerIndex].appointmentStatus) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            break;
          }
          case 'listingStatusName': {
            if (isAsc) {
              if (this.listingEnquiries[index].listingStatusName > this.listingEnquiries[innerIndex].listingStatusName) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            else {
              if (this.listingEnquiries[index].listingStatusName < this.listingEnquiries[innerIndex].listingStatusName) {
                let temp = this.listingEnquiries[index];
                this.listingEnquiries[index] = this.listingEnquiries[innerIndex];
                this.listingEnquiries[innerIndex] = temp;
              }
            }
            break;
          }
        }
      }
    }
    this.loadPageData()
    this._loader.hide();
    clearTimeout(this.clearSortTimeout);
  }
  //#endregion
  //#region Properties
  private get motivationLevel(): string {
    if (!!this.selectedListingEnquiry) {
      switch (this.selectedListingEnquiry.motivationID) {
        case 1:
          return "High";
        case 2:
          return "Medium";
        case 4:
          return "Low";
        default:
          return "";
      }
    }
    else {
      return "";
    }
  }
  private get type(): string {
    if (!!this.selectedListingEnquiry) {
      if (!!this.selectedListingEnquiry.ofiTime) {
        return "Open for inspection";
      }
      else if (!!this.selectedListingEnquiry.privateInspectionTime) {
        return "Private inspection";
      }
      else {
        return "Not sure";
      }
    }
    else {
      return "Not sure";
    }
  }
  //#endregion
}
