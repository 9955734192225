import { combineReducers } from 'redux';
import {
  AuthService,
  enImageSize,
  SessionInfo,
  IDNamePair,
  //RentalTenantSearchResult
} from "./../services/authservice";
import { ILogonState, INITIAL_LOGON_STATE, loginReducer } from "./logon";
import { IContactState, INITIAL_CONTACT_STATE, contactReducer } from "./contacts";
import { IEmarketingState, INITIAL_EMARKETING_STATE } from '../emarketing/emarketing.state';
import { IPropertyManagementState, INITIAL_PROPERTYMANAGEMENT_STATE } from '../propertymanagement/propertymanagement.state';
import { IOfiState, INITIAL_OFI_STATE } from './../ofi/ofi.state';
import { IHomeState, INITIAL_HOME_STATE } from '../home/home.state';
import { IReportsState, INITIAL_REPORTS_STATE } from './../reports/reports.state';
import { LOGIN, LOGOUT } from "./actions";
import { emarketingReducer } from '../emarketing/emarketing.reducer';
import { ofiReducer } from './../ofi/ofi.reducer';
import { propertymanagementReducer } from '../propertymanagement/propertymanagement.reducer';
import { homemanagementReducer } from '../home/home.reducer';
import { reportsReducer } from './../reports/reports.reducer';
import { IMaintenanceState, INITIAL_MAINTENANCE_STATE } from '../maintenance/maintenance.state';
import { maintenanceReducer } from '../maintenance/maintenance.reducer';

export interface IAppState {
  login: ILogonState;
  contacts: IContactState;
  emarketing: IEmarketingState;
  propertymanagement: IPropertyManagementState;
  homemanagement: IHomeState;
  maintenance: IMaintenanceState,
  ofi: IOfiState,
  reports: IReportsState
}
export const INITIAL_STATE: IAppState = {
  login: INITIAL_LOGON_STATE,
  contacts: INITIAL_CONTACT_STATE,
  emarketing: INITIAL_EMARKETING_STATE(),
  propertymanagement: INITIAL_PROPERTYMANAGEMENT_STATE(),
  homemanagement: INITIAL_HOME_STATE(),
  maintenance: INITIAL_MAINTENANCE_STATE(),
  ofi: INITIAL_OFI_STATE(),
  reports: INITIAL_REPORTS_STATE()
};
const appReducer = combineReducers({
  login: loginReducer,
  contacts: contactReducer,
  emarketing: emarketingReducer,
  propertymanagement: propertymanagementReducer,
  homemanagement: homemanagementReducer,
  maintenance: maintenanceReducer,
  ofi: ofiReducer,
  reports: reportsReducer
});
export const rootReducer = (state, action) => {
  if (action.type === LOGOUT || action.type === LOGIN) {
    state = INITIAL_STATE;
  }
  return appReducer(state, action)
}
