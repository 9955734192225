import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";

import { IAppState } from "../../store/store";
import { PropertyManagementService } from "./../propertymanagement.service";
import { LoaderService } from "./../../core/loader/loader.service";
import { IDNamePair, AuthService, ServiceResponse } from "./../../services/authservice";

import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";
import { GetCommercialTenantInvoices, CloseCommercialTenantInvoiceResultPage, GetCommercialPropertyManagersInTenantInvoiceSearchPage, GetCommercialPropertyInfoInTenantInvoiceSearchPage } from "./../propertymanagement.action";
import { ITenantInvoiceSearchResult } from "./../propertymanagement";

import "../../../../../wwwroot/js/scripts.js";
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'app-commercialtenantinvoice',
  templateUrl: './commercialtenantinvoice.component.html',
  styleUrls: ['./commercialtenantinvoice.component.css']
})
export class CommercialTenantInvoiceComponent implements OnInit, OnDestroy, AfterViewInit {

  private model: FormGroup;
  private dropdownSettings: any = {};
  private dialogRef: any;
  private propertyManagerIDs: IDNamePair<string>[];
  private statusItems: IDNamePair<number>[];
  private selectedStatusIDs: IDNamePair<number>[];
  private tenantStatusItems: IDNamePair<number>[];
  private selectedtenantStatusIDs: IDNamePair<number>[];
  private selectedPropertyID: any = null;
  private selectedPropertyTextInfo: string;
  private selectedRadio: number = 3;
  private staffID: string;
  private propertyAddress: Observable<IDNamePair<string>[]>;
  private filterPropertyAddress: IDNamePair<string>[];


  private subscription_GetPropertyManagers: Subscription;
  private isRecurringSeriesChecked: boolean = false;
  private subscription_GetTenantInvoices: Subscription;
  private getSessionData_Subcription : Subscription;
  private getPropertyAddress_Subcription: Subscription;

  private eventAgrrement: any;
  private showMngAgreement: boolean;

  constructor(private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private _service: PropertyManagementService,
    private _authSercice: AuthService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) {
    this.statusItems = [
      // { id: 0, name: "Unspecified" },
      { id: 1, name: "Unpaid" },
      { id: 2, name: "Fully Paid" },
      { id: 4, name: "Cancelled" }
    ];
    this.selectedStatusIDs = this.statusItems.filter(f => f.id == 1);
    this.tenantStatusItems = [{ id: 1, name: "Current" }, { id: 2, name: "Old" }]
    this.selectedtenantStatusIDs = this.tenantStatusItems.filter(f => f.id == 1);
    this.model = new FormGroup({
      selectedPropertyText: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.selectedPropertyText),
      tenantNames: new FormControl(""),
      statusIDs: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.statusIDs),
      tenantStatusIDs: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.tenantStatusIDs),
      dateFrom: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.dateFrom),
      dateTo: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.dateTo),
      number: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.number),
      transactionReason: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.transactionReason),
      selectedPropertyManagerIDs: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.selectedPropertyManagerIDs),
      recurringSeriesOnly: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.recurringSeriesOnly),
      creditorTypeID: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.creditorTypeID),
      overdueDaysFrom: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.overdueDaysFrom),
      overdueDaysTo : new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.overdueDaysTo),
      includeJointTenants: new FormControl(this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.includeJointTenants)
      //sortBy: new FormControl(this.ngRedux.getState().propertymanagement.tenantInvoice.search.sortBy)

    });
  }

  @select(state => (state as IAppState).propertymanagement.commercialTenantInvoice.search.propertyManagerIDs) propertyManagerIDs$;
  @select(state => (state as IAppState).propertymanagement.commercialTenantInvoice.search.showSearchResult) showSearchResult$;

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this._loader.show();

    this.getSessionData_Subcription = this._authSercice.GetSessionData().subscribe(s=> {
      if(!!s){
        this.staffID = s.staffID;
      }
    })

    this.subscription_GetPropertyManagers =
      this._service.GetPropertyManagers()
        .map((data: IDNamePair<string>[]) => {
          return new GetCommercialPropertyManagersInTenantInvoiceSearchPage(data);
        })
        .subscribe(action => {
          this.propertyManagerIDs = action.payload.filter(f=> f.id == this.staffID );   ;
          this._loader.hide();
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
        }),
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      }
  }

  ngAfterViewInit() {
    LoadScript.init();
  }

  mgtAgreementOk() {
    this.selectedPropertyID = this.eventAgrrement[0][0].value;
    this.selectedPropertyTextInfo = (!!this.eventAgrrement[0][2].value) ? this.eventAgrrement[0][2].value + " " + ((!!this.eventAgrrement[0][3].value) ? "Owner(s) " + this.eventAgrrement[0][3].value : "") : (!!this.eventAgrrement[0][3].value) ? "Owner(s) " + this.eventAgrrement[0][3].value : ""; this.showMngAgreement = false;
  }
  onAgreementSelect(event) {
    this.eventAgrrement = event;
  }

  isChecked(event: any) {
    if (event.target.checked) {
      this.model.controls['dateFrom'].disable();
      this.model.controls['dateTo'].disable();
      this.model.controls['number'].disable();
      this.isRecurringSeriesChecked = true; 
    }
    else {
      this.model.controls['dateFrom'].enable();
      this.model.controls['dateTo'].enable();
      this.model.controls['number'].enable();
      this.isRecurringSeriesChecked = false;
    }
  }
  //#region Property Search
  private isPropertyLoading: boolean;
  onKeyUp(event: any) { 
    if (!!event && event.keyCode >= 37 && event.keyCode <= 40) return;
    if (!!this.model.controls.selectedPropertyText.value && this.model.controls.selectedPropertyText.value.length >= 3) {
      this.isPropertyLoading = true;
        this.getPropertyAddress_Subcription =
        this._service.GetCommercialPropertyAddresses(
          this.model.controls.selectedPropertyText.value
        )
        .subscribe(data => {
          if (!!data && !data.isError && !!data.response) {
            this.propertyAddress = of(data.response);
          }
          else if (data.isError) {
            this.openDialog("Alert", data.message, "alertdialog");
          }
          this.isPropertyLoading = false;
        });
    }
    else {
      this.propertyAddress = of([]);
    }    
  }
  displayFn(item: IDNamePair<string>): string {
    if (!!item && !!item.id) {
      return item.name;
    }
  }
  //#endregionh

  onSubmit() {
    var statusIDs: number = 0;
    if (!!this.model.controls.statusIDs.value) {
      let stItems: IDNamePair<number>[] = this.model.controls.statusIDs.value;
      stItems.forEach(type => {
        statusIDs = statusIDs | type.id;
      });
    }

    var tenantStatusIDs: number = 0;
    if (!!this.model.controls.tenantStatusIDs.value) {
      let tenantItems: IDNamePair<number>[] = this.model.controls.tenantStatusIDs.value;
      tenantItems.forEach(type => {
        tenantStatusIDs = tenantStatusIDs | type.id;
      });
    }

    var propertyManagerIDs: string[] = null;
    if (!!this.model.controls.selectedPropertyManagerIDs.value) {
      let propManagerIds: IDNamePair<string>[] = this.model.controls.selectedPropertyManagerIDs.value;
      if (!!this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.propertyManagerIDs
        && this.ngRedux.getState().propertymanagement.commercialTenantInvoice.search.propertyManagerIDs.length == propManagerIds.length) {
        propertyManagerIDs = null;
      }
      else {
        propertyManagerIDs = propManagerIds.map(item => { return item.id });
      }
    }

    this._loader.show();
    this.subscription_GetTenantInvoices =
      this._service.GetTenantInvoice(
        (!!this.model.controls.selectedPropertyText.value)? this.model.controls.selectedPropertyText.value.id: null,
        this.model.controls.tenantNames.value,
        (!!this.isRecurringSeriesChecked)? 3: statusIDs,
        this.model.controls.dateFrom.value,
        this.model.controls.dateTo.value,
        null,
        this.model.controls.number.value,
        propertyManagerIDs,
        null,
        this.model.controls.recurringSeriesOnly.value,
        this.model.controls.creditorTypeID.value,
        this.model.controls.transactionReason.value,
        tenantStatusIDs,
        null,
        this.model.controls.overdueDaysFrom.value,
        this.model.controls.overdueDaysTo.value,
        this.model.controls.includeJointTenants.value,
        0//,this.model.controls.sortBy.value
      )
        .map((data: ServiceResponse<ITenantInvoiceSearchResult[]>) => {
          return data;//new GetCommercialTenantInvoices(data);
        })
        .subscribe(data => {
          this._loader.hide();
          if (!data.isError) {
            let action = new GetCommercialTenantInvoices(data.response);
            if (!!action.payload && !!action.payload.length) {
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
              this._router.navigate(["/commercialtenantinvoices/search"]);
            }          
            else {
              this.snackBar.open("No record found", "Close", this.config);
            }
          }
          else {
            this.openDialog("Alert", data.message, "alertdialog");
          }
        },
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          })

  }

  ngOnDestroy() {
    if (!!this.subscription_GetTenantInvoices) this.subscription_GetTenantInvoices.unsubscribe();
    if (!!this.subscription_GetPropertyManagers) this.subscription_GetPropertyManagers.unsubscribe();
    if (!!this.getPropertyAddress_Subcription ) this.getPropertyAddress_Subcription.unsubscribe();
    if (!!this.getSessionData_Subcription) this.getSessionData_Subcription.unsubscribe();
  }

}
