import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs';
import { DatePipe } from '@angular/common';

import { IAppState } from "../../store/store";
import { LoaderService } from "./../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";
import { IDNamePair, IDNameExtraPair, AuthService, SessionInfo, ServiceResponse } from "./../../services/authservice";
import { ReportsService } from './../reports.service';
import { ShowEnquirersFollowUpSearchPage, GetCurrentStaffsEnquirersFollowUpSearchPage, GetGetEnquiriesSearchPage } from './../reports.action';
import { IEnquiryDto } from './../reports';

import "../../../../../wwwroot/js/scripts.js";
declare var $: JQueryStatic;
declare var LoadScript: any;


@Component({
  selector: 'app-enquirersfollowup',
  templateUrl: './enquirersfollowup.component.html',
  styleUrls: ['./enquirersfollowup.component.scss']
})
export class EnquirersFollowUpComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _authService: AuthService,
    private _service: ReportsService
  ) {
    let defaultFromDate: Date = new Date((new Date()).setDate(new Date().getDate() - 3));
    let defaultToDate: Date = new Date((new Date()).setDate(new Date().getDate() + 1));
    this.model = new FormGroup({
      selectedStaffIDs: new FormControl(this.ngRedux.getState().reports.enquirersfollowup.search.selectedStaffIDs),
      selectedPropertyText: new FormControl(this.ngRedux.getState().reports.enquirersfollowup.search.selectedPropertyText),
      currentListing: new FormControl(true, Validators.required),
      followUpRequired: new FormControl(false, Validators.required),
      //selectedInterestLevelFrom: new FormControl(0, Validators.required),
      //selectedInterestLevelTo: new FormControl(1, Validators.required),
      selectedInterestLevels: new FormControl(null, Validators.required),
      dateFrom: new FormControl(`${defaultFromDate.getFullYear()}-${(defaultFromDate.getMonth() + 1).toString().padStart(2, "0")}-${defaultFromDate.getDate().toString().padStart(2, "0")}`),
      dateTo: new FormControl(`${defaultToDate.getFullYear()}-${(defaultToDate.getMonth() + 1).toString().padStart(2, "0")}-${defaultToDate.getDate().toString().padStart(2, "0")}`)
    });
  }

  //#region Snackbar Config
  private snackbarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //#region 

  //#region Multi-Select Dropdown Settings
  private dropdownSettings: any = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  //#endregion

  //#region Subcription Variables
  private getCurrentStaffs_Subcription: Subscription;
  private getListingPropertyAddress_Subcription: Subscription;
  private getEnquiries_Subcription: Subscription;
  //#endregion

  //#region Private Variables
  private model: FormGroup;
  private dialogRef: any;
  private staffs: IDNamePair<string>[];
  private interestLevels: IDNamePair<number>[];
  private isPropertyLoading: boolean = false;
  private propertyAddress: Observable<IDNamePair<string>[]>;
  //#endregion

  //#region State Variables
  @select(state => (state as IAppState).reports.enquirersfollowup.search.staffs) staffs$;
  @select(state => (state as IAppState).reports.enquirersfollowup.search.interestLevels) interestLevels$;
  @select(state => (state as IAppState).reports.enquirersfollowup.search.showSearchResult) showSearchResult$;
  //#endregion

  //#region Functions
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  displayFn(item: IDNameExtraPair<string, string>): string {
    if (!!item && !!item.id) {
      return item.name;
    }
  }
  //#endregion

  //#region Events
  onKeyUp(event: any) {
    if (!!event && event.keyCode >= 37 && event.keyCode <= 40) return;
    if (!!this.model.controls.selectedPropertyText.value && this.model.controls.selectedPropertyText.value.length >= 3) {
      this.isPropertyLoading = true;
      this.getListingPropertyAddress_Subcription =
        this._service.GetListingPropertyAddress(
          this.model.controls.selectedPropertyText.value
        )
          .subscribe(data => {
            if (!!data && !data.isError && !!data.response) {
              this.propertyAddress = of(data.response);
              console.log("data.response: ", data.response);
            }
            else if (data.isError) {
              this.openDialog("Alert", data.message, "alertdialog");
            }
            this.isPropertyLoading = false;
          });
    }
  }
  onSubmit() {
    //Staffs
    let stffIDs: string[] = null;
    if (!!this.model.controls.selectedStaffIDs.value) {
      let selectedStaffIDs: IDNamePair<string>[] = this.model.controls.selectedStaffIDs.value;
      if (!!selectedStaffIDs && !!selectedStaffIDs.length) {
        stffIDs = selectedStaffIDs.map(item => { return item.id });
      }
      else {
        if (!!this.ngRedux.getState().ofi.openprivateinspections.search.staffIDs
          && !!this.ngRedux.getState().ofi.openprivateinspections.search.staffIDs.length) {
            stffIDs = this.ngRedux.getState().ofi.openprivateinspections.search.staffIDs.map(item => { return item.id });
          }
      }
      console.log("stffIDs: ", stffIDs);
    }

    //Address
    let addressID: string = null;
    if (!!this.model.controls.selectedPropertyText.value) {
      addressID = this.model.controls.selectedPropertyText.value;
      console.log("addressID: ", addressID);
    }

    /*//Interest Level From
    let interestLevelFrom: number | null = 0;
    if (!!this.model.controls.selectedInterestLevelFrom.value) {
      interestLevelFrom = parseInt(this.model.controls.selectedInterestLevelFrom.value);
      console.log("interestLevelFrom: ", interestLevelFrom);
    }

    //Interest Level To
    let interestLevelTo: number | null = 1;
    if (!!this.model.controls.selectedInterestLevelTo.value) {
      interestLevelTo = parseInt(this.model.controls.selectedInterestLevelTo.value);
      console.log("interestLevelTo: ", interestLevelTo);
    }*/

    //Interest Level
    let intrstLevels: number[] = [];
    if (!!this.model.controls.selectedInterestLevels.value && !!this.model.controls.selectedInterestLevels.value.length) {
      let intLevl = this.model.controls.selectedInterestLevels.value as IDNamePair<number>[];
      intrstLevels = intLevl.map(il => il.id);
    }

    //Date From
    let dateFrom: Date | null = null;
    if (!!this.model.controls.dateFrom.value) {
      dateFrom = this.model.controls.dateFrom.value;
      console.log("dateFrom: ", dateFrom);
    }

    //Date To
    let dateTo: Date | null = null;
    if (!!this.model.controls.dateFrom.value) {
      dateTo = this.model.controls.dateTo.value;
      console.log("dateTo: ", dateTo);
    }

    //Current Listing
    let currentListing: boolean = true;
    currentListing = this.model.controls.currentListing.value;
    console.log("currentListing: ", currentListing);

    //Followup Required
    let followUpRequired: boolean = true;
    followUpRequired = this.model.controls.followUpRequired.value;
    console.log("followUpRequired: ", followUpRequired);

    this._loader.show();
    this.getEnquiries_Subcription =
      this._service
        .GetEnquiries
        (
          stffIDs,
          addressID,
          /*interestLevelFrom,
          interestLevelTo,*/
          intrstLevels,
          dateFrom,
          dateTo,
          currentListing,
          followUpRequired
        )
        .map((data: ServiceResponse<IEnquiryDto[]>) => {
          debugger;
          if (!data.isError) {
            return new GetGetEnquiriesSearchPage(data.response);
          }
          else {
            Observable.throwError(data.message);
          }
        })
        .subscribe(action => {
          if (!!action && !!action.payload && !!action.payload.length) {
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            console.log("action.payload: ",  action.payload);
            this._router.navigate(["/enquirersfollowup/search"]);
          }
          else {
            this.snackBar.open("No record found", "Close", this.snackbarConfig);
          }
          this._loader.hide();
        }),
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      }

  }
  //#endregion

  ngOnInit() {
    this._loader.show();
    this.interestLevels = this.ngRedux.getState().reports.enquirersfollowup.search.interestLevels;
    let selectedDepartmentID = !!this.ngRedux.getState().login.departmentID ? this.ngRedux.getState().login.departmentID : null;
    this._authService.GetSessionData()
    .subscribe((session: SessionInfo) => {
      this.getCurrentStaffs_Subcription =
        this._service.GetEnquiryStaffs(selectedDepartmentID)
        .map((data: ServiceResponse<IDNamePair<string>[]>) => {
          if (!data.isError) {
            return new GetCurrentStaffsEnquirersFollowUpSearchPage(data.response);
          }
          else {
            Observable.throwError(data.message);
          }
        })
        .subscribe(action => {
          this.staffs = (!!action.payload.filter(staff => staff.id == session.staffID).length ? action.payload.filter(staff => staff.id == session.staffID) : null) || action.payload;
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this._loader.hide();
        }),
        error => {
          this._loader.hide();
          this.openDialog("Alert", error, "alertdialog");
        }
    });
  }
  ngAfterViewInit() {
    LoadScript.init();
  }
  ngOnDestroy() {
    if (!!this.getCurrentStaffs_Subcription) this.getCurrentStaffs_Subcription.unsubscribe();
    if (!!this.getListingPropertyAddress_Subcription) this.getListingPropertyAddress_Subcription.unsubscribe();
    if (!!this.getEnquiries_Subcription) this.getEnquiries_Subcription.unsubscribe();
  }
}
