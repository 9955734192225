import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { IAppState } from '../../store/store';
import { AuthService, IDNamePair, ServiceResponse } from '../../services/authservice';
import { LoaderService } from '../../core/loader/loader.service';
import { MaintenanceService } from '../maintenance.service';
import { DialogBodyComponent, enDialogRole } from '../../dialog-body/dialog-body.component';
import { IMaintenanceSearchResult } from '../maintenance.state';
import { GetMaintenanceLookFor, GetMaintenancesSearch } from '../maintenance.action';

@Component({
  selector: 'app-maintenancesearch',
  templateUrl: './maintenancesearch.component.html',
  styleUrls: ['./maintenancesearch.component.scss']
})
export class MaintenanceSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: MaintenanceService,
    private _authService: AuthService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.model = new FormGroup({
      selectedLookFor: new FormControl("1", Validators.required),
      lookForFilter: new FormControl(null, Validators.required),
      selectedStatus: new FormControl("1"),
      selectedPeriod: new FormControl("2")
    });
  }
  //#region Settings
  private snackbarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    horizontalPosition: "center",
    panelClass: ['notifyPanel']
  } as MatSnackBarConfig;
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  }
  //#endregion
  ngOnInit() {

    this.getSessionData_Subcription = this._authService.GetSessionData()
      .subscribe(session => {
        if (!!session) {
          this._sessionID = session.id;
          this.model.controls.lookForFilter.valueChanges.subscribe((filter: string) => {
            if (!!filter && filter.length >= 3) {
              this.getMaintenanceLookFor_Subcription =
                this._service.GetMaintenanceLookFor(null, session.id, this.model.controls.selectedLookFor.value, filter)
                  .subscribe((data: ServiceResponse<IDNamePair<string>[]>) => {
                    if (data.isError) {
                      this.openDialog("Error", data.message, "alertdialog");
                    }
                    else {
                      let action = new GetMaintenanceLookFor(data.response);
                      this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                    }
                  },
                    error => {
                      this._loader.hide();
                      this.openDialog("Alert", error, "alertdialog");
                    });
            }


          });

        }
      },
        error => {
          this._loader.hide();
          this.openDialog("Alert", error, "alertdialog");
        }
      );
  }
  ngAfterViewInit() {
    LoadScript.init();
  }
  ngOnDestroy() {
    if (!!this.getSessionData_Subcription) this.getSessionData_Subcription.unsubscribe();
  }

  //#region Subcription Variables
  private getSessionData_Subcription: Subscription;
  private getMaintenanceLookFor_Subcription: Subscription;
  //#endregion
  //#region Private Variables
  private model: FormGroup;
  private dialogRef: any;
  private _sessionID: string;
  private showSpinner: boolean = false;
  //#endregion

  //#region Properties
  get sessionID(): string {
    if (this.route.routeConfig.path.indexOf("maintenance") >= 0 && !!this.route.snapshot.paramMap.get("maintenance") && this.route.snapshot.paramMap.get("maintenance").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("sessionID");
    }
    else {
      return this._sessionID;
    }
  }
  //#endregion

  //#region Store Observables
  @select(state => (state as IAppState).maintenance.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).maintenance.search.lookForList) lookForList$;
  //#endregion

  //#region Events
  onLookForDeselect() {
    this.model.controls.lookForFilter.setValue(null);
    let action = new GetMaintenanceLookFor([]);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
  }
  onLookForChange(lookFor) {
  }
  onAddMaintenance() {
    this._router.navigate(["/newmaintenance"])
  }
  onSubmitClick() {
    /*if (!this.model.controls.lookForFilter.value) {
      this.snackBar.open("Please enter look for text", "Close", this.snackbarConfig);
      return;
    }*/
    this._loader.show();
    this.showSpinner = true;
    if (!!this.sessionID) {
      this._service.GetMaintenances
        (
          null,
          this.sessionID,
          this.model.controls.selectedLookFor.value,
          this.model.controls.lookForFilter.value,
          this.model.controls.selectedStatus.value,
          this.model.controls.selectedPeriod.value
        )
        .subscribe((data: ServiceResponse<IMaintenanceSearchResult[]>) => {
          this._loader.hide();
          this.showSpinner = false;
          if (data.isError) {
            this.openDialog("Error", data.message, "alertdialog");
          }
          else {
            let action = new GetMaintenancesSearch(data.response);
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            this._router.navigate([`/maintenance/search`]);
          }
        },
          error => {
            this._loader.hide();
            this.showSpinner = false;
            this.openDialog("Alert", error, "alertdialog");
          });

    }
  }
  //#endregion

}
