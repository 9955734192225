import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy, Pipe, PipeTransform, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';

import { IAppState } from "../../../../store/store";
import { ShowHidePreviewEmailFromSearchResultEditPage } from "./../../../emarketing.action";
import { EmarketingService } from "./../../../emarketing.service";
import { IContactEmail, enEmailStatus, IEmailSendEmailResponse } from "./../../../emarketing";
import { LoaderService } from "./../../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../../dialog-body/dialog-body.component';

@Component({
  selector: 'previewemail',
  templateUrl: './previewemail.component.html',
  styleUrls: ['./previewemail.component.css']
})
export class PreviewEmailComponent implements OnInit {

  @Input() showBackButton: boolean = false;

  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  private dialogRef: any;
  private model: FormGroup;
  private htmlBody: string;
  private filteredContacts: IContactEmail[];
  private isLoading = false;
  private showSendLaterDialog: boolean = false;
  private scheduleDate: Date = null;

  constructor(private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: EmarketingService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef) {
    this.model = new FormGroup({
      from: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.from, Validators.required),
      to: new FormControl({} as IContactEmail, Validators.required),
      subject: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.subject),
      selectedRecipientList: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.selectedRecipientList),
      htmlBody: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.htmlBody),
      attachments: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.attachments),
      previewToEmails: new FormControl(this.ngRedux.getState().emarketing.email.addEditView.from, Validators.required),
    });
  }

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }

  @select(state => (state as IAppState).emarketing.email.addEditView.from) from$;
  @select(state => (state as IAppState).emarketing.email.addEditView.to) to$;
  @select(state => (state as IAppState).emarketing.email.addEditView.subject) subject$;

  ngOnInit() {
    this.htmlBody = this.ngRedux.getState().emarketing.email.addEditView.htmlBody;

    let emailID = this.ngRedux.getState().emarketing.email.addEditView.id;
    if (!!emailID) {
      this.model.controls.to.valueChanges
        .pipe(
          debounceTime(300),
          tap(() => this.isLoading = true),
          switchMap(value => this._service.GetContactsForEmail(emailID, value, "")
            .pipe(
              finalize(() => this.isLoading = false),
            )
          )
        )
        .subscribe(contacts => this.filteredContacts = contacts);
    }
  }
  sendPreview() {
    let emailID = this.ngRedux.getState().emarketing.email.addEditView.id;
    if (!(!!emailID && emailID.indexOf('00000000') < 0)) {
      this.openDialog("Alert", "Invalid email!!", "alertdialog");
      return false;
    }
    if (!(!!this.model.controls.to && !!this.model.controls.to.value && !!this.model.controls.to.value.id && this.model.controls.to.value.id.indexOf('00000000') < 0)) {
      this.openDialog("Alert", "No valid recipient is chosen in 'To'!!", "alertdialog");
      return false;
    }
    if (!(!!this.model.controls.previewToEmails && !!this.model.controls.previewToEmails.value)) {
      this.openDialog("Alert", "No valid email is entered in 'Send Preview To'!!", "alertdialog");
      return false;
    }
    
    this._loader.show();
    this._service.SendPreviewEmail(emailID, this.model.controls.previewToEmails.value, this.model.controls.to.value.id)
    .subscribe(response => {
      this._loader.hide();
      this.snackBar.open("Preview email sent successfully!!", "Close", this.config);
    },
    (error) => {
      this._loader.hide();
      this.openDialog("Error", error, "alertdialog");
    });
  }
  sendEmail() {
    let emailID = this.ngRedux.getState().emarketing.email.addEditView.id;
    if (!(!!emailID && emailID.indexOf('00000000') < 0)) {
      this.openDialog("Alert", "Invalid email!!", "alertdialog");
      return false;
    }
    console.log("this.scheduleDate: ", this.scheduleDate);
    this._loader.show();
    this._service.SendEmail(emailID, this.scheduleDate)
    .subscribe((response: IEmailSendEmailResponse) => {
      this._loader.hide();
      let message: string = "";
      switch(response.status) {
        case enEmailStatus.unknown:
            message = "Email sending status is unknown!!";
          break;
        case enEmailStatus.draft:
            message = "Email is kept as draft!!";
          break;
        case enEmailStatus.scheduled:
            message = "Email sending is scheduled!!";
          break;
        case enEmailStatus.sending:
            message = "Email is being sent...";
          break;
        case enEmailStatus.sent:
            message = "Email sent successfully!!";
          break;
        case enEmailStatus.deleted:
            message = "Email is deleted!!";
          break;
        case enEmailStatus.archived:
            message = "Email is archived!!";
          break;
      }
      this.snackBar.open(message +  " Number of recipients is " + response.totalRecipients, "Close", this.config);
    },
    (error) => {
      this._loader.hide();
      this.openDialog("Error", error, "alertdialog");
    })
  }

  onBack() {
    let action = new ShowHidePreviewEmailFromSearchResultEditPage(false);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
  }

  displayFn(recipientContact: IContactEmail) {
    if (recipientContact) { return recipientContact.name; }
  }

}
