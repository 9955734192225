import { Options } from "ng5-slider";
import { Observable } from "rxjs/Observable";
import { Injectable, Inject, PLATFORM_ID, Injector } from "@angular/core";
import {
  Http,
  Headers,
  Response,
  RequestOptions,
  RequestMethod,
} from "@angular/http";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";

import { BaseService } from "../services/baseservice";
import {
  IDNamePair,
  IDNameExtraPair,
  ServiceResponse,
  ContactDetails,
} from "./../services/authservice";
import { IEnquiryDto, IEnquiryAppointmentDto, IListingEnquiryStatusDto } from './reports';

@Injectable()
export class ReportsService extends BaseService {
  constructor(
    private _http: Http,
    private HTTP: HttpClient,
    @Inject(PLATFORM_ID) private platformID: Object
  ) {
    super(HTTP, platformID);
  }

  GetCurrentStaffs(): Observable<ServiceResponse<IDNamePair<string>[]>> {
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Ofi/GetCurrentStaffs", {}, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetEnquiryStaffs(departmentID: string): Observable<ServiceResponse<IDNamePair<string>[]>> {
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    debugger;
    return this._http
      .post("api/reports/GetEnquiryStaffs", { departmentID: departmentID}, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetListingAddress(
    searchText: string
  ): Observable<ServiceResponse<IDNameExtraPair<string, string>[]>> {
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post(
        "api/reports/GetListingAddress",
        {
          searchText: searchText,
        },
        options
      )
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetListingPropertyAddress(
    searchText: string
  ): Observable<ServiceResponse<IDNameExtraPair<string, string>[]>> {
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post(
        "api/reports/GetListingPropertyAddress",
        {
          searchText: searchText,
        },
        options
      )
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetEnquiries(
    staffIDs: string[],
    addressID: string,
    /*interestLevelFrom: number | null,
    interestLevelTo: number | null,*/
    interestLevels: number[] | null,
    dateFrom: Date | null,
    dateTo: Date | null,
    currentListing: boolean,
    followupRequired: boolean
  ): Observable<ServiceResponse<IEnquiryDto[]>> {
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post(
        "api/reports/GetEnquiries",
        {
          staffIDs: staffIDs,
          addressID: addressID,
          /*interestLevelFrom: interestLevelFrom,
          interestLevelTo: interestLevelTo,*/
          interestLevels: interestLevels,
          dateFrom: dateFrom,
          dateTo: dateTo,
          currentListing: currentListing,
          followupRequired: followupRequired
        },
        options
      )
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetEnquiryAppointments(
    listingID: string,
    contactID: string
  ): Observable<ServiceResponse<IEnquiryAppointmentDto[]>> {
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post(
        "api/reports/GetEnquiryAppointments",
        {
          listingID: listingID,
          contactID: contactID
        },
        options
      )
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetAppointmentStatus(): Observable<ServiceResponse<IDNamePair<number>[]>> {
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post(
        "api/reports/GetAppointmentStatus",
        options
      )
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetSaleStatus(): Observable<ServiceResponse<IDNameExtraPair<number, string>[]>> {
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post(
        "api/reports/GetSaleStatus",
        options
      )
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetListingEnquiryStatusSearch(
    staffID: string,
    startDate: Date | null,
    endDate: Date | null,
    enquiryStatusID: number | null,
    appointmentStatusID: number | null,
    saleStatusID: number | null
  ): Observable<ServiceResponse<IListingEnquiryStatusDto[]>> {
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post(
        "api/reports/GetListingEnquiryStatusSearch",
        {
          staffID: staffID,
          startDate: startDate,
          endDate: endDate,
          enquiryStatusID: enquiryStatusID,
          appointmentStatusID: appointmentStatusID,
          saleStatusID: saleStatusID
        },
        options
      )
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  SaveListingEnquiryStatus(
    listingEnquiryID: string,
    appointmentContactID: string,
    appointmentListingID: string,
    motivationLevelID: number | null,
    interestLevelID: number | null,
    suitabilityID: number | null,
    nextContactDate: Date | null,
    enquirerComments: string,
    staffComments: string,
    followUpRequired: boolean
  ): Observable<ServiceResponse<boolean>> {
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post(
        "api/reports/SaveListingEnquiryStatus",
        {
          listingEnquiryID: listingEnquiryID,
          appointmentContactID: appointmentContactID,
          appointmentListingID: appointmentListingID,
          motivationLevelID: motivationLevelID,
          interestLevelID: interestLevelID,
          suitabilityID: suitabilityID,
          nextContactDate: nextContactDate,
          enquirerComments: enquirerComments,
          staffComments: staffComments,
          followUpRequired: followUpRequired
        },
        options
      )
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
}
