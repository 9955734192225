import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";

import { IAppState } from "../../store/store";
import { PropertyManagementService } from "./../propertymanagement.service";
import { LoaderService } from "./../../core/loader/loader.service";
import { IDNamePair, AuthService, ServiceResponse } from "./../../services/authservice";
import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";
import { ICommercialManagementAgreement } from "./../propertymanagement";

import "../../../../../wwwroot/js/scripts.js";
import { GetCommercialOwnerGroupsInMAgreementSearchPage, GetCommercialPropertyManagersInMAgreementSearchPage, GetCommercialManagementAgreements } from '../propertymanagement.action';
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'app-commercialmanagementagreements',
  templateUrl: './commercialmanagementagreements.component.html',
  styleUrls: ['./commercialmanagementagreements.component.css']
})
export class CommercialManagementAgreementsComponent implements OnInit, AfterViewInit, OnDestroy {

  private model: FormGroup;
  private dropdownSettings: any = {};
  private dialogRef: any;
  private managementTypes: IDNamePair<number>[];
  private propertyManagerIDs: IDNamePair<string>[];
  private ownerStatementGroupIDs: IDNamePair<number>[];
  private pLeases: IDNamePair<number>[];
  private isCommercial: boolean = false;
  private statusItems: IDNamePair<number>[];
  private selectedStatusIDs: IDNamePair<number>[];
  private selectedManagementTypes: IDNamePair<number>[];
  private selectedPLeases: IDNamePair<number>[];
  private staffID: string;

  //#region Subscriptions
  private subscription_GetPropertyManagers: Subscription;
  private subscription_GetOwnerGroups: Subscription;
  private subscription_GetManagementAgreements: Subscription;
  private getSessionData_Subcription: Subscription;
  //#endregion

  constructor(private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: PropertyManagementService,
    private _authSercice: AuthService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { 
      this.managementTypes = [{ id: 1, name: "Not Managed" }, { id: 2, name: "Managed" }, { id: 4, name: "Lease Only" }];
        this.statusItems = [{ id: 1, name: "Current" }, { id: 2, name: "Old" }];
        this.selectedStatusIDs = this.statusItems.filter(st => st.id == 1);
        //this.selectedManagementTypes = [{ id: 2, name: "Managed" }];
        this.pLeases = [{ id: 1, name: "Exclude" }, { id: 2, name: "Periodic Leases Only" }];
        this.selectedPLeases = this.pLeases;
        this.isCommercial = this.ngRedux.getState().login.isCommercial;
        this.model = new FormGroup({
          searchText: new FormControl(this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.searchText),
          searchFields: new FormControl(this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.searchFields),
          managementTypeIDs: new FormControl(this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.managementTypeIDs),
          statusIDs: new FormControl(this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.statusIDs, Validators.required),
          vacant: new FormControl(this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.vacant),
          alertNotes: new FormControl(this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.alertNotes),
          selectedOwnerStatementGroupIDs: new FormControl(this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.selectedOwnerStatementGroupIDs),
          selectedPeriodicLeases: new FormControl(this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.selectedPeriodicLeases),
          selectedPropertyManagerIDs: new FormControl(this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.selectedPropertyManagerIDs)
       })
    }

    
    openDialog(
      title: string,
      message: string,
      role: "alertdialog" | "dialog" = "dialog"
    ) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.role = role;
      dialogConfig.data = {
        title: title,
        message: message,
        role:
          role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
      };
      dialogConfig.panelClass = "dialog-body";
      this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
      return this.dialogRef;
    }
    private config = {
      duration: 5000,
      verticalPosition: "bottom",
      panelClass: "notifyPanel"
    } as MatSnackBarConfig;

    @select(state => (state as IAppState).propertymanagement.commercialmanagementagreement.search.ownerStatementGroupIDs) ownerStatementGroupIDs$;
    @select(state => (state as IAppState).propertymanagement.commercialmanagementagreement.search.showSearchResult) showSearchResult$;
    @select(state => (state as IAppState).propertymanagement.commercialmanagementagreement.search.propertyManagerIDs) propertyManagerIDs$

  ngOnInit() {   
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }; 

    this._loader.show();
    this.subscription_GetOwnerGroups =
      this._service.GetOwnerGroups()
        .map((data: IDNamePair<number>[]) => {
          return new GetCommercialOwnerGroupsInMAgreementSearchPage(data);
        })
        .subscribe(action => {
          this.ownerStatementGroupIDs = action.payload.filter(f => f.id );
          this._loader.hide();
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
        },
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
        })

        this.getSessionData_Subcription = this._authSercice.GetSessionData().subscribe(s => {
          if (!!s) {
            this.staffID = s.staffID;
          }
       })

    this.subscription_GetPropertyManagers =
       this._service.GetPropertyManagers()
         .map((data: IDNamePair<string>[]) => {
           return new GetCommercialPropertyManagersInMAgreementSearchPage(data);
         })
         .subscribe(action => {
           this.propertyManagerIDs = action.payload.filter(f => f.id == this.staffID);
           this._loader.hide();
           this.ngRedux.dispatch({ type: action.type, payload: action.payload });
         }),
       error => {
         this._loader.hide();
         this.openDialog("Alert", error, "alertdialog");
       }
  }

 

  ngAfterViewInit() {
    LoadScript.init();
  }
  ngOnDestroy() {
    if (!!this.subscription_GetPropertyManagers) this.subscription_GetPropertyManagers.unsubscribe();
    if (!!this.subscription_GetOwnerGroups) this.subscription_GetOwnerGroups.unsubscribe();
    if (!!this.subscription_GetManagementAgreements) this.subscription_GetManagementAgreements.unsubscribe();
    if (!!this.getSessionData_Subcription) this.getSessionData_Subcription.unsubscribe();
  }


  onSubmit() {
    if (this.model.status == "VALID") {
      // var managementTypeIDs: number = 0;
      // if (!!this.model.controls.managementTypeIDs.value) {
      //   let mTypeIDs: IDNamePair<number>[] = this.model.controls.managementTypeIDs.value;
      //   mTypeIDs.forEach(type => {
      //     managementTypeIDs = managementTypeIDs | type.id;
      //   });
      // }

      var statusIDs: number = 0;
      if (!!this.model.controls.statusIDs.value) {
        let stItems: IDNamePair<number>[] = this.model.controls.statusIDs.value;
        stItems.forEach(type => {
          statusIDs = statusIDs | type.id;
        });
      }

      // var ownerStatementGroupIDs: number | null = null;
      // if (!!this.model.controls.selectedOwnerStatementGroupIDs.value) {
      //   let ownerStmtIDs: IDNamePair<number>[] = this.model.controls.selectedOwnerStatementGroupIDs.value;
      //   if (
      //     !!this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.ownerStatementGroupIDs
      //     && this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.ownerStatementGroupIDs.length === ownerStmtIDs.length
      //   ) {
      //     ownerStatementGroupIDs = null;
      //   }
      //   else {
      //     ownerStatementGroupIDs = 0;
      //     ownerStmtIDs.forEach(type => (ownerStatementGroupIDs = ownerStatementGroupIDs | type.id));
      //   }
      // }

      var propertyManagerIDs: string[] = null;
      if (!!this.model.controls.selectedPropertyManagerIDs.value) {
        let propManagerIds: IDNamePair<string>[] = this.model.controls.selectedPropertyManagerIDs.value;
        if (!!this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.propertyManagerIDs
          && this.ngRedux.getState().propertymanagement.commercialmanagementagreement.search.propertyManagerIDs.length == propManagerIds.length) {
          propertyManagerIDs = null;
        }
        else {
          propertyManagerIDs = propManagerIds.map(item => { return item.id });
        }
      }

      var periodicLeases: number = 0;
      if (!!this.model.controls.selectedPeriodicLeases.value) {
        let prdLeases: IDNamePair<number>[] = this.model.controls.selectedPeriodicLeases.value;
        if (this.pLeases.length === prdLeases.length) {
          periodicLeases = 3;
        }
        else {
          prdLeases.forEach(type => (periodicLeases = periodicLeases | type.id));
        }
      }



      this._loader.show();
      this.subscription_GetManagementAgreements =
        this._service.GetManagementAgreements
          (
            this.model.controls.searchText.value,
            this.model.controls.searchFields.value,
            null,//managementTypeIDs,
            statusIDs,
            propertyManagerIDs,
            null,//ownerStatementGroupIDs,
            periodicLeases,
            false,//this.model.controls.alertNotes.value,
            this.isCommercial,
            this.model.controls.vacant.value,
          )
          .subscribe((data: ServiceResponse<ICommercialManagementAgreement[]>) => {
            this._loader.hide();
            if (data.isError) {
              this.openDialog("Error", data.message, "alertdialog");
            }
            else {
              let action = new GetCommercialManagementAgreements(data.response);
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
              this._router.navigate(["/commercialmanagementagreements/search"]);
            }
          },
            error => {
              this._loader.hide();
              this.openDialog("Alert", error, "alertdialog");
            });
    }
  }

  onManagementTypeSelect(event: any) {
    console.log("event: ", event);
  }
  onPropertyManagerSelect(event: any) {
    console.log("event: ", event);
  }
  onOwnerGroupSelect(event: any) {
    console.log("event: ", event);
  }
  onStatusSelect(event: any) {
    console.log("event: ", event);
  }

}
