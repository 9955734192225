import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from "rxjs/Subscription";

import { QrcodeViewerService } from './qrcodeviewer.service';
import { DialogBodyComponent, enDialogRole } from './../../dialog-body/dialog-body.component';

@Component({
  selector: 'app-qrcodeviewer',
  templateUrl: './qrcodeviewer.component.html',
  styleUrls: ['./qrcodeviewer.component.css']
})
export class QrcodeViewerComponent implements OnInit, OnDestroy {

  constructor(
    private _service: QrcodeViewerService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  //#region Private Variables
  private showWaitLoader: boolean;
  private widthQRCodeDiv: string = "400px";
  private heightQRCodeDiv: string = "400px";
  private qrcodeImageUrl: string;
  //#endregion

  //#region Subscription Variables
  private getOpenQRCodeLinkImageUrl_Subscription: Subscription;
  //#endregion

  //#region Input Variables
  @Input() QRCodeText: string;
  @Input() Title: string;
  @Input() FooterText: string;
  //#endregion

  //#region Dialog
  private dialogRef: any;
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  //#endregion
  //#region Snackbar Config
  private snackBarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //#endregion

  ngOnInit(): void {
    this.showWaitLoader = true;
    console.log("QRCodeText: ", this.QRCodeText);
    setTimeout(() => {
      let qrcodeBox: any = document.querySelector("#dvQRCode");
      this.widthQRCodeDiv = qrcodeBox.offsetWidth;
      this.heightQRCodeDiv = qrcodeBox.offsetHeight;

    }, 1000);

    this.getOpenQRCodeLinkImageUrl_Subscription =
    this._service.GetOpenQRCodeLinkImageUrl(this.QRCodeText)
      .subscribe(data => {
        if (!!data && !data.isError) {
          this.qrcodeImageUrl = data.response;
        }
        else if (!!data && data.isError) {
          this.snackBar.open(data.message, "Close", this.snackBarConfig);
        }
        this.showWaitLoader = false;
        console.log("data: ", data);
      }, function (error) {
        console.log("ERROR: ", error);
        this.showWaitLoader = false;        
        this.snackBar.open(error, "Close", this.snackBarConfig);
      }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  ngOnDestroy(): void {
    if (!!this.getOpenQRCodeLinkImageUrl_Subscription) this.getOpenQRCodeLinkImageUrl_Subscription.unsubscribe();
  }

}
