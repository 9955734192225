import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";

import { IAppState } from "../../../store/store";
import { PropertyManagementService } from "./../../propertymanagement.service";
import { LoaderService } from "./../../../core/loader/loader.service";
import { IDNamePair, AuthService, ServiceResponse } from "./../../../services/authservice";
import { DialogBodyComponent, enDialogRole } from "./../../../dialog-body/dialog-body.component";
import { CloseCommercialContactCreationPage } from "./../../propertymanagement.action";

@Component({
  selector: 'app-commercialcontactcreation',
  templateUrl: './commercialcontactcreation.component.html',
  styleUrls: ['./commercialcontactcreation.component.css']
})
export class CommercialContactCreationComponent implements OnInit {

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: PropertyManagementService,
    private _authSercice: AuthService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit() {
  }
  onClose() {
    let action = new CloseCommercialContactCreationPage();
    this.ngRedux.dispatch({ type: action.type });
    this._router.navigate(["/commercialcontacts"])
  }
}
