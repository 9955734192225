import { enImageSize, IDNamePair } from './../../../../services/authservice';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { IAppState } from "../../../../store/store";
import { EmarketingService } from "./../../../emarketing.service";
import { LoaderService } from "./../../../../core/loader/loader.service";
import {
  GetRecipientTypes,
  GetEmailTemplates,
  EditTempateClose,
  AddWidgetToTemplate,
  RemoveWidgetFromTemplate,
  GetTemplateImageTypes,
  GetTemplateEmailImages,
  LoadNewTemplatePage
} from "./../../../emarketing.action";
//import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
//import { CKEditorComponent }  from  'ckeditor4-angular';
import { TreeNode, ITreeOptions } from "angular-tree-component";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { IAddEditTemplate, IEmailImage, IRecipientType } from "../../../emarketing";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { ISubscription } from "rxjs/Subscription";
import { DialogBodyComponent, enDialogRole } from './../../../../dialog-body/dialog-body.component';
import * as $ from "jquery";
declare var LoadScript: any;
declare var CKEDITOR: any;

@Component({
  selector: "edittemplate",
  templateUrl: "./edittemplate.component.html",
  styleUrls: ["./edittemplate.component.css"]
})
export class EditTemplateComponent implements OnInit, AfterViewInit, OnDestroy {

  private selectedTemplateID: string;
  private nodes = [];
  private model: FormGroup;
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //private editor: any;
  private dialogRef: any;
  /*private editorConfig = {
    allowedContent: true, 
    removeButtons: 'Image',
    //extraPlugins: 'newplugin',
    //   toolbar: 'MyToolbar',
    //   toolbar_MyToolbar: [
    //     ['Newplugin', 'Preview'],
    //     ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Scayt','Image'],
    //     ['Undo', 'Redo', '-', 'Find', 'Replace', '-', 'SelectAll', 'RemoveFormat']
    //  ]
  };*/
  //@ViewChild(CKEditorComponent) ckeditor: CKEditorComponent;
  private showDialog: boolean;
  private showTemplateImageHandlingDialog: boolean;
  private showAddTemplateImageLoader: boolean = false;

  private selectedTreeNode: TreeNode;
  private subscription_editorEmailUpload: ISubscription;
  private subscription_addEditView: ISubscription;
  private subscription_templateID: ISubscription;
  private subscription_GetTemplateImageTypes: ISubscription;
  private subscription_AddWidgetToTemplate: ISubscription;
  private subscription_RemoveWidget: ISubscription;
  private subscription_GetImages: ISubscription;
  private subscription_GetImagePicture: ISubscription;
  private subscription_SaveTemplateImage: ISubscription;
  private subscription_RemoveTemplateImage: ISubscription;
  private subscription_SaveTemplate: ISubscription;
  private subscription_GetEmailAgencies: ISubscription;
  private subscription_GetRecipientTypes: ISubscription;

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: EmarketingService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.model = new FormGroup({
      name: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.template.name, Validators.required),
      description: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.template.description),
      subject: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.template.subject),
      htmlBody: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.template.htmlBody, Validators.required),
      selectedWidgetType: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.selectedWidgetType.id),
      selectedWidgetID: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.selectedWidgetID),
      selectedGetImagesFor: new FormControl(this.ngRedux.getState().emarketing.template.addEditView.selectedGetImagesFor)
    });
  }

  @select(state => (state as IAppState).emarketing.template.addEditView.showEditPage) showEditPage$;
  @select(state => (state as IAppState).emarketing.template.addEditView.recipientTypes) recipientTypes$;
  @select(state => (state as IAppState).emarketing.template.addEditView.template.id) templateID$;
  //@select(state => (state as IAppState).emarketing.template.addEditView.template.communicationReasonRecipientTypeName) communicationReasonRecipientTypeName$;
  //@select(state => (state as IAppState).emarketing.template.addEditView.template.communicationReasonName) communicationReasonName$;
  @select(state => (state as IAppState).emarketing.template.addEditView) addEditView$;
  @select(state => (state as IAppState).emarketing.template.addEditView.template.widgetTypes) widgetTypes$;
  @select(state => (state as IAppState).emarketing.template.addEditView.template.widgets) widgets$;
  @select(state => (state as IAppState).emarketing.template.addEditView.templateImageTypes) templateImageTypes$;
  @select(state => (state as IAppState).emarketing.template.addEditView.templateEmailImages) templateEmailImages$;

  openDialog(title: string, message: string, role: "alertdialog" | "dialog" = "dialog") {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = { title: title, message: message, role: role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }

  ngOnInit() {
    this.subscription_addEditView = this.addEditView$.subscribe(aev => {
      this.imageUrl = (aev as IAddEditTemplate).template.thumbnailImageURL;
    });
    this.subscription_templateID = this.templateID$.subscribe(tempID => {
      if (!!tempID) {
        this.selectedTemplateID = tempID;
        this._service.GetTemplateChildFields(tempID, "").then(childFields => {
          this.nodes = [];
          childFields.forEach(cf => {

            this.nodes.push(
              {
                id: parseInt(cf.id),
                name: cf.name,
                description: cf.description,
                fieldName: cf.fieldName,
                fieldTypeID: cf.fieldTypeID,
                parentTemplateFieldID: "",
                isField: cf.isField,
                hasChildren: cf.hasChildren
              });
          });
        });
      }
    });
    this.subscription_GetTemplateImageTypes = this._service.GetTemplateImageTypes()
      .map(imageTypes => { return new GetTemplateImageTypes(imageTypes); })
      .subscribe(action => {
        this.ngRedux.dispatch({ type: action.type, payload: action.payload });
      });
  }
  ngAfterViewInit() {
    LoadScript.init();
    try {
      CKEDITOR.replace('htmlBody');
      
      //this.editor = this.ckeditor;    
      //this.editor.config.allowedContent = true;
      //this.editor.config.removeButtons = "Image";
      CKEDITOR.config.allowedContent = true;
      CKEDITOR.config.removeButtons = "Image";
      CKEDITOR.config.height = 700;
    }
    catch {
      window.location.reload();
    }

    /*CKEDITOR.instances["htmlBody"].on('change', function (e) {
      let editedHtml = CKEDITOR.instances[e.editor.name].getData();      
    });/
    //#region Blocked
    /*
    CKEDITOR.instances["htmlBody"].addCommand('simpleLinkDialog', new CKEDITOR.dialogCommand('simpleLinkDialog'));
    CKEDITOR.instances["htmlBody"].ui.addButton("btnInsertImage", {
      label: 'Insert an Image',
      command: 'simpleLinkDialog',
      icon: "https://cdn.ckeditor.com/4.11.3/standard-all/plugins/icons.png?t=J1QB"
    });
    CKEDITOR.dialog.add("simpleLinkDialog", function (editor) {
      return {
        title: 'Link Properties',
        minWidth: 400,
        minHeight: 200,
        contents: [{
          id: 'tab1',
          label: 'Choose Image',
          elements: [{
            type: "html",
            html: '<select class="form-control m-bot15" id="selectedImageType" name="selectedImageType" tabindex="1"' +
                  '    (change)="onImageTypeChange($event)">' +
                  '    <option class="small-font" *ngFor="let imageType of (templateImageTypes$ | async)"' +
                  '      [value]="imageType.id">' +
                  '      {{ imageType.name }}' +
                  '    </option>' +
                  '</select>'
          }]
        },
        {
          id: 'tab2',
          label: 'Upload Image',
          elements:
            [
              {
                type: 'html',
                html: ""
              }
            ]
        }],
        onOk: function () {
          var dialog = this,
						data = {},
						link = editor.document.createElement('a');
            this.commitContent(data);
        }
      }
    });
    */
    //#endregion Blocked

    setTimeout(() => {
      $("#aInsertImage").remove();
      $(".cke_toolbox").append('<a id="aInsertImage" data-toggle="modal" data-toggle="modal" data-target="#myModal" (click)="showDialog=!showDialog" class="cke_button cke_button__image cke_button_off" href="#" title="Image" tabindex="-1" hidefocus="true" role="button" aria-haspopup="false" aria-disabled="false"><span class="cke_button_icon cke_button__image_icon" style="background-image:url(\'https://cdn.ckeditor.com/4.11.3/standard-all/plugins/icons.png?t=J1QB\');background-position:0 -1224px;background-size:auto;">&nbsp;</span></a>');
    }, 3000);
  }
  ngOnDestroy() {
    if (!!this.subscription_editorEmailUpload) { this.subscription_editorEmailUpload.unsubscribe(); }
    if (!!this.subscription_addEditView) { this.subscription_addEditView.unsubscribe(); }
    if (!!this.subscription_templateID) { this.subscription_templateID.unsubscribe(); }
    if (!!this.subscription_GetTemplateImageTypes) { this.subscription_GetTemplateImageTypes.unsubscribe(); }
    if (!!this.subscription_AddWidgetToTemplate) { this.subscription_AddWidgetToTemplate.unsubscribe(); }
    if (!!this.subscription_RemoveWidget) { this.subscription_RemoveWidget.unsubscribe(); }
    if (!!this.subscription_GetImages) { this.subscription_GetImages.unsubscribe(); }
    if (!!this.subscription_GetImagePicture) { this.subscription_GetImagePicture.unsubscribe(); }
    if (!!this.subscription_SaveTemplateImage) { this.subscription_SaveTemplateImage.unsubscribe(); }
    if (!!this.subscription_RemoveTemplateImage) { this.subscription_RemoveTemplateImage.unsubscribe(); }
    if (!!this.subscription_SaveTemplate) { this.subscription_SaveTemplate.unsubscribe(); }
    if (!!this.subscription_GetEmailAgencies) { this.subscription_GetEmailAgencies.unsubscribe(); }
    if (!!this.subscription_GetRecipientTypes) { this.subscription_GetRecipientTypes.unsubscribe(); }
  }
  updateHtml() {
    this.model.controls.htmlBody.setValue(CKEDITOR.instances["htmlBody"].getData());     
  }

  onClose() {
    let action = new EditTempateClose(true);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    this._router.navigate(["/templates/templatesearchresult"]);
  }
  addWidgetToTemplate(event: any) {
    if (!this.model.controls.selectedWidgetType.value) {
      this.snackBar.open("Please select widget type ", "Close", this.config);
      return false;
    }
    this._loader.show();
    this.subscription_AddWidgetToTemplate = this._service.AddWidgetToTemplate(this.selectedTemplateID, this.model.controls.selectedWidgetType.value)
      .map(widget => {
        //this.editor.insertHtml(widget.templateHTML);
        CKEDITOR.instances["htmlBody"].insertHtml(widget.templateHTML);

        return new AddWidgetToTemplate({
          htmlBody: this.model.controls.htmlBody.value,
          widget: {
            id: widget.id,
            settings: widget.settings,
            widgetType: widget.widgetType,
            templateHTML: widget.templateHTML
          }
        });
      })
      .subscribe(action => {
        if (
          !!action.payload.widget.id
          && action.payload.widget.id.indexOf("00000000") < 0
        ) {
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
        }
        this._loader.hide();
      },
      (error) => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      });
  }
  removeWidget(event: any) {
    if (!(!!this.model.controls.selectedWidgetID.value && this.model.controls.selectedWidgetID.value.indexOf('00000000') < 0)) {
      this.openDialog("Alert", "Please select the widget to delete!!", "alertdialog");
      return false;
    }

    this.openDialog("Confirmation", "Are you sure you want to delete the widget?", "dialog");
    this.dialogRef.afterClosed().subscribe(dResult => {
      console.log("result: ", dResult);
      if (!!dResult) {
        this._loader.show();
        this.subscription_RemoveWidget = this._service.RemoveWidget(this.selectedTemplateID, this.model.controls.selectedWidgetID.value)
          .map(result => {
            if (!!result && result == true) {
              return new RemoveWidgetFromTemplate(this.model.controls.selectedWidgetID.value);
            }
            else {
              return new RemoveWidgetFromTemplate("");
            }
          })
          .subscribe(action => {
            if (!!action.payload) {
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
              this._loader.hide();

              $(CKEDITOR.instances["htmlBody"].editable().$).find("div[id='" + action.payload + "']").remove();
              
              this.snackBar.open("Widget deleted successfully", "close", this.config)
            }
            else {
              this._loader.hide();
              this.snackBar.open("Error in widget deletion", "close", this.config)
            }
          },
          (error) => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          })
      }
    });
  }

  /*public onReady(event: CKEditor4.EventInfo) {
    this.editor = event.editor;
    // editor.ui
    //   .getEditableElement()
    //   .parentElement.insertBefore(
    //     editor.ui.view.toolbar.element,
    //     editor.ui.getEditableElement()
    //   );
    //   this.editor = editor;
    $("#cke_1_toolbox").append('<a data-toggle="modal" data-toggle="modal" data-target="#myModal" (click)="showDialog=!showDialog" class="cke_button cke_button__image cke_button_off" href="#" title="Image" tabindex="-1" hidefocus="true" role="button" aria-haspopup="false" aria-disabled="false"><span class="cke_button_icon cke_button__image_icon" style="background-image:url(\'https://cdn.ckeditor.com/4.11.3/standard-all/plugins/icons.png?t=J1QB\');background-position:0 -1224px;background-size:auto;">&nbsp;</span></a>');
  }*/

  options: ITreeOptions = {
    getChildren: this.getChildren.bind(this),
    useCheckbox: false
  };
  getChildren(node: any) {
    const newNodes = this._service.GetTemplateChildFields(this.selectedTemplateID, node.id);
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(newNodes), 1000);
    });
  }
  onDoubleclick(event: any) {
    if (!!this.selectedTreeNode) {
      //this.editor.insertText(this.selectedTreeNode.data.fieldName);
      CKEDITOR.instances["htmlBody"].insertText(this.selectedTreeNode.data.fieldName);
    }
  }
  onEvent(event: any) {
    if (!!event && !!event.node) {
      this.selectedTreeNode = event.node;
    }
  }
  private imageUrl: any;
  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.imageUrl = reader.result;

      reader.readAsDataURL(file);
    }
  }
  onImageTypeChange(event: any) {
    if (event.target.selectedIndex < 1) return false;
    if (!event.target.options.length) return false;
    let imageType = event.target.options[event.target.selectedIndex].value;
    if (imageType < 0) return false;
    this._loader.show();
    this.subscription_GetImages = this._service.GetImages(this.selectedTemplateID, imageType, 144, 174)
      .map(images => { return new GetTemplateEmailImages(images); })
      .subscribe(action => {
        this.ngRedux.dispatch({ type: action.type, payload: action.payload });
        this._loader.hide();
      },
      (error) => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      });

  }
  //#region Image Dialog
  private width: number;
  private height: number;
  private sizeType: enImageSize;
  private selectedEmailImage: IEmailImage;

  onSelectImage(emailImage: IEmailImage) {
    this.selectedEmailImage = emailImage;
  }
  onShowResizeDialog(emailImage: IEmailImage) {
    this.showDialog = true;
    this.selectedEmailImage = emailImage;
  }

  onResizeImage() {

    console.log("this.width: ", this.width);
    console.log("this.height: ", this.height);
    console.log("this.sizeType: ", this.sizeType);
    if (!!this.selectedEmailImage) {
      this._loader.show();
      this.subscription_GetImagePicture = this._service.GetImagePicture(this.sizeType, this.width, this.height, this.selectedEmailImage.imageID)
        .subscribe(url => {
          this.selectedEmailImage.imageUrl = url;
          this.snackBar.open("Image resize completed", "close", this.config);
        },
        (error) => {
          this._loader.hide();
          this.openDialog("Alert", error, "alertdialog");
        });
    }
  }
  onInsertImage() {
    let imageHtml: string = "<img src='" + this.selectedEmailImage.imageUrl + "' height='180' width='150px' />";
    //this.editor.insertHtml(imageHtml);
    CKEDITOR.instances["htmlBody"].insertHtml(imageHtml);
    this.selectedEmailImage = undefined;
  }
  //#endregion

  //#region Template Image Handling
  private templateImageUrl: any;
  private templateImageName: string;
  private templateImageCaption: string;
  readTemplateImageURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log(file);
      this.templateImageName = file.name;
      this.templateImageCaption = file.name;

      const reader = new FileReader();
      reader.onload = e => this.templateImageUrl = reader.result;

      reader.readAsDataURL(file);
    }
  }
  saveTemplateImage(event: any) {
    if (!this.templateImageUrl) {
      this.snackBar.open("Please select the template image to upload!!", "Close", this.config);
      return false;
    }
    this.showAddTemplateImageLoader = true;
    this._loader.show();
    this.subscription_SaveTemplateImage = this._service.SaveTemplateImage
      (
        this.selectedTemplateID,
        this.templateImageUrl,
        this.templateImageName,
        this.templateImageCaption
      )
      .map(templImg => { return templImg; })
      .subscribe(action => {
        this.closeTemplateImageDialog();
        this.showAddTemplateImageLoader = false;
        this._loader.hide();
        this.snackBar.open("Template image uploaded successfully!!", "Close", this.config);
      },
      (error) => {
        console.log(error);
        this.showAddTemplateImageLoader = false;
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");        
      });
  }
  closeTemplateImageDialog() {
    this.templateImageName = "";
    this.templateImageCaption = "";
    this.templateImageUrl = "";
    this.showTemplateImageHandlingDialog = false;
  }
  onDeleteTemplateImage(emailImage: IEmailImage) {
    if (!emailImage.id) {
      this.snackBar.open("Invalid image to remove!!", "Close", this.config);
      return false;
    }
    if (!(!!this.model.controls.selectedWidgetID.value && this.model.controls.selectedWidgetID.value.indexOf('00000000') < 0)) {
      this.openDialog("Alert", "Are you sure?", "alertdialog");
      return false;
    }
    this._loader.show();
    this.subscription_RemoveTemplateImage = this._service.RemoveTemplateImage
      (
        this.selectedTemplateID,
        emailImage,
        144,
        174
      )
      .subscribe(result => {
        if (!!result && result.templateImageRemoved) {
          let action = new GetTemplateEmailImages(result.templateImages);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this.snackBar.open("Image removed successfully!!", "Close", this.config);
        }
        this._loader.hide();
      },
      (error) => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      });
  }
  //#endregion

  //#region Edit Image Upload Handling
  private editorImageUrl: any;
  private editorImageName: string;
  private editorImageCaption: string;
  readEditorImageURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.editorImageName = file.name;
      this.editorImageCaption = file.name;
      const reader = new FileReader();
      reader.onload = e => this.editorImageUrl = reader.result;

      reader.readAsDataURL(file);
    }
  }
  uploadEditorImage(event: any) {
    if (!this.editorImageUrl) {
      this.snackBar.open("Please select the image to upload!!", "Close");
      return false;
    }
    this._loader.show();
    this.subscription_editorEmailUpload = this._service.SaveTemplateImage
      (
        this.selectedTemplateID,
        this.editorImageUrl,
        this.editorImageName,
        this.editorImageCaption
      )
      .map(templImg => { return templImg; })
      .subscribe(action => {
        this._loader.hide();
        this.snackBar.open("Email uploaded successfully!!", "Close", this.config);
      },
      (error) => {
        console.log(error);
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      });
  }
  //#endregion

  onSubmit() {
    this.updateHtml();
    if (!this.model.controls.htmlBody.value) {
      this.openDialog("Alert", "Please enter valid html!!", "alertdialog");
      return false;
    }
    if (this.model.status == "VALID") {
      this._loader.show();
      this.subscription_SaveTemplate = this._service.SaveTemplate(
        this.selectedTemplateID,
        this.model.controls.name.value,
        this.model.controls.description.value,
        this.model.controls.subject.value,
        this.model.controls.htmlBody.value,
        this.imageUrl
      )
      .subscribe(result => {
        if (result) {
          this.snackBar.open("Template modified successfully!!", "Close", this.config);
        }
        this._loader.hide();
      },
      (error) => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      });
    }
  }

  onNew() {
    this._loader.show();
    this.subscription_GetEmailAgencies = this._service
      .GetEmailAgencies()
      .subscribe((agencies: IDNamePair<string>[]) => {
        this.subscription_GetRecipientTypes = this._service
          .GetRecipientTypes()
          .map((data: IRecipientType[]) => {
            return new LoadNewTemplatePage({ recipientTypes: data, agencies: agencies });
          })
          .subscribe(action => {
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            this._loader.hide();
            this._router.navigate(["/templates/new"]);
          },
          (error) => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          });

      },
      (error) => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      });
    //this._router.navigate(["/templates/new"]);
  }
}
