import { ICommercialTenantInvoiceSearchResult } from './../../propertymanagement';
import { MatSnackBarConfig } from '@angular/material';
import { enDialogRole, DialogBodyComponent } from './../../../dialog-body/dialog-body.component';
import { MatDialogConfig } from '@angular/material';
import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { Sort, SortDirection } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

import { IAppState } from "../../../store/store";
import { select, NgRedux } from '@angular-redux/store';
import { CloseCommercialTenantContactsResultPage } from '../../propertymanagement.action';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../../../services/authservice";
import { PropertyManagementService } from '../../propertymanagement.service';
import { LoaderService } from "./../../../core/loader/loader.service";
import { MatSnackBar, MatDialog } from '@angular/material';
import { ICommercialTenantContactSearchResult } from '../../propertymanagement';
import { Subscription } from 'rxjs';
import { emailValidation } from "./../../../utility/autounsubcribe";

import "../../../../../../wwwroot/js/scripts.js";
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'app-commercialtenantcontactsearchresult',
  templateUrl: './commercialtenantcontactsearchresult.component.html',
  styleUrls: ['./commercialtenantcontactsearchresult.component.css']
})
export class CommercialTenantContactSearchResultComponent implements OnInit, AfterViewInit, OnDestroy {

  private tenantContacts: ICommercialTenantContactSearchResult[] = [];
  //#region Communicatio Log
  private getSessionData_Subscription: Subscription;
  private showCommunication: boolean;
  private actionCommunication: "tel" | "sms" | "mailto";
  private contactForCommunication: string;
  private contactID: string;
  private contactName: string;
  private mobile: string;
  private email: string;
  private targetTypeID: number;
  private staffID: string;
  private mailMergeName: string;
  private communication_Subcription: Subscription;

  //#endregion
  
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    //private _eMarketingService: PropertyManagementService,
    private _service: PropertyManagementService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private _datePipe: DatePipe
  ) { }

  @select(state => (state as IAppState).propertymanagement.commercialtenantcontact.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).propertymanagement.commercialtenantcontact.search.tenantContacts) tenantContacts$;

  ngOnInit() {
    this.tenantContacts = this.ngRedux.getState().propertymanagement.commercialtenantcontact.search.tenantContacts;
    this.loadPageData();

    this.getSessionData_Subscription = this._authService.GetSessionData()
    .subscribe(session => {
      this.staffID = session.staffID;
      this.mailMergeName = session.mailMergeName;
    });

  }

  private dialogRef: any;
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }


  ngAfterViewInit() {
    LoadScript.init();
    LoadScript.gotoTop();
    var clearIntv = null;
    clearIntv = setInterval(() => {
      if (!!$("#no-more-tables > table").length) {
        clearInterval(clearIntv);
        //FixedTableHeaderScript.fixMe($("table"));
        $(".up").click(function () {
          $("html, body").animate(
            {
              scrollTop: 0
            },
            2000
          );
        });
      }
    }, 2000);
  }
  ngOnDestroy() {
    if (!!this.workOrder_Subscription) this.workOrder_Subscription.unsubscribe();
    if (!!this.getOutstandingInvoices_Subscription) this.getOutstandingInvoices_Subscription.unsubscribe();
    if( !! this.getOutstandingInvoices_Subscription ) this.getOutstandingInvoices_Subscription.unsubscribe(); 
    if( !! this.getSessionData_Subscription) this.getSessionData_Subscription.unsubscribe();
    if( !! this.communication_Subcription) this.communication_Subcription.unsubscribe();
  }

  sanitizeSMS(mobilePhone: string) {
    return this.sanitizer.bypassSecurityTrustUrl("sms:" + mobilePhone);
  }

  onClose() {
    let action = new CloseCommercialTenantContactsResultPage(true);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
    this._router.navigate(["/commercialtenantcontacts"])
  }

  onCommunicationClick(action: "tel" | "sms" | "mailto", row: ICommercialTenantContactSearchResult) {
    this.actionCommunication = action;
    if (action == "tel" || action == "sms") {
      this.contactForCommunication = row.mobilePhone;
    }
    else if (action == "mailto") {
      this.contactForCommunication = row.emailAddress;
    }
    this.contactID = row.contactID;
    this.contactName = row.tenant;
    this.mobile = row.mobilePhone;
    this.email = row.emailAddress;
    this.showCommunication = !this.showCommunication;
  }
  onSave(event: any) {
    if (!!event && !event.isError) {
      this.showCommunication = false;
    }
  }


  validateEmail(email: string){
    //return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));
    return emailValidation(email);
  }

  //#region Pagination  
  private hasPagination: boolean = true;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: ICommercialTenantContactSearchResult[];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.pageData = this.tenantContacts;
    if (this.hasPagination) {
      this.pageData = this.tenantContacts.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }
    this.pageData.forEach(pd => {
      pd.emailAddress = emailValidation(pd.emailAddress) ? pd.emailAddress : "";
    });
  }
  //#endegion


  onExpand(tc: ICommercialTenantContactSearchResult) {
    var timezoneOffset =  new Date().getTimezoneOffset();
    if (!tc.showCommunication) {
      tc.showCommunication = false;
      tc.showCommunicationLoader = true;
      tc.communications = [];
      this.communication_Subcription = this._service.CommunicationDetails(tc.id,2,timezoneOffset)
        .subscribe(data => {
          this._loader.hide();
          tc.communications = data;
          tc.showCommunication = true;
          tc.showCommunicationLoader = false;
        }),
        error => {
          this._loader.hide();
          this.openDialog("Alert", error, "alertdialog");
        };
    }
    else {
      tc.showCommunication = !tc.showCommunication;
    }

  }


  //#region Outstanding Invoices
  private getOutstandingInvoices_Subscription: Subscription;
  onOutstandingInvoicesExpand(tc: ICommercialTenantContactSearchResult) {
    
    if (!tc.showOutstandingInvoices) {
      tc.showOutstandingInvoices = false;
      tc.showOutstandingInvoicesLoader = true;
      tc.outstandingInvoices = [];
      this.getOutstandingInvoices_Subscription = this._service.GetOutstandingInvoices(tc.rentalTenantID)
        .subscribe(data => {

          if (!!data && !data.isError) {
            tc.outstandingInvoices = data.response.map(item => 
              [
                { fieldName: "number", value: item.number, showText: item.number.toString(), header: "Invoice No", visible: true, sortable: true },
                //{ fieldName: "addressText", value: item.addressText, showText: item.addressText.toString(), header: "Property Address", visible: true, sortable: true },
                { fieldName: "contactID", value: item.contactID, showText: item.contactID, header: "Tenant ID", visible: false },
                //{ fieldName: "contactName", value: item.debtorName, showText: item.debtorName, header: "Tenant", visible: true, sortable: true },
                //{ fieldName: "mobile", value: item.mobilePhone, showText: (!!item.mobilePhone ? item.mobilePhone : "-"), header: "Moible", visible: true, showCommunicationLogPhoneIcon: true, showCommunicationLogSMSIcon: true, sortable: true },
                { fieldName: "email", value: item.tenantEmail, showText: (!!item.tenantEmail ? item.tenantEmail : "-"), header: "Email", visible: false, showCommunicationLogEmailIcon: true },
                { fieldName: "invoicedToName", value: item.invoicedToName, showText: item.invoicedToName.toString(), header: "Pay To", visible: true, sortable: true },
                { fieldName: "showText", value: item.status, showText: item.status.toString(), header: "Status", visible: true, sortable: true },
               // { fieldName: "propertyManagerName", value: item.propertyManagerName, showText: item.propertyManagerName.toString(), header: "Property Manager", visible: true, sortable: true },
                { fieldName: "totalAmount", value: item.totalAmount, showText: this.format2(item.totalAmount, '$'), header: "Total Amount", visible: true, sortable: true },
                { fieldName: "amountDue", value: item.amountDue, showText: this.format2(item.amountDue, '$'), header: "Amount Due", visible: true, sortable: true },
                { fieldName: "dueDate", value: item.dueDate, showText: this._datePipe.transform(item.dueDate, "dd/MM/yyyy"), header: "Due Date", visible: true, sortable: true },
                { fieldName: "daysPastDue", value: item.daysPastDue, showText: item.daysPastDue.toString(), header: "Days Past Due", visible: true, sortable: true },
                { fieldName: "targetTypeID", value: 36, showText: "36", header: "Target Type ID", visible: false }
              ]
            );
          }
          else if (!!data && data.isError) {
            this.openDialog("Alert", data.message, "alertdialog");
          }
          tc.showOutstandingInvoices = true;
          tc.showOutstandingInvoicesLoader = false;
        }),
        error => {
          this.openDialog("Alert", error, "alertdialog");
        };
    }
    else {
      tc.showOutstandingInvoices = !tc.showOutstandingInvoices;
    }

  }
  onGetSelectedOutstandingInvoices(event: any) {
  }


  //#region RentReviewSchedule
  private rentReviewSchedule_Subcription: Subscription;
  onRentReviewScheduleExpand(tc: ICommercialTenantContactSearchResult){
      if(!!tc && !tc.showRentReviewSchedule){
          tc.showRentReviewSchedule = false;
          tc.showRentReviewScheduleLoader = true;
          tc.rentReviewScheduleList = [];
          this.rentReviewSchedule_Subcription = 
          this._service.GetRentReviewScheduleInfo(tc.rentalTenantID)
          .subscribe(data=> {
              if(!!data &&!data.isError)
              {
                  tc.rentReviewScheduleList = data.response.map(item =>
                      [
                        { fieldName: "contactID", value: this.getRentReviewMethodName(item.rentReviewMethodId), showText: (!!item.rentReviewMethodId ? this.getRentReviewMethodName(item.rentReviewMethodId): "-"), header: "Rent Review Metheod", visible: true, sortable: true },
                        //{ fieldName: "code", value: item.code, showText: (!!item.code? item.code.toString(): "-"), header: "Code", visible: true, sortable: true },
                        { fieldName: "name", value: item.name, showText: (!!item.name? item.name.toString(): "-"), header: "Code And Name", visible: true, sortable: true },
                        { fieldName: "fixedPercent", value: item.fixedPercent, showText: item.fixedPercent, header: "Fixed Percent", visible: true, sortable: true },
                        { fieldName: "cpiIndexNumber", value: item.cpiIndexNumber, showText: item.cpiIndexNumber, header: "CPI Price Index", visible: true, sortable: true },
                        { fieldName: "cpiPercent", value: item.cpiPercent, showText: item.cpiPercent, header: "CPI Percent", visible: true, sortable: true },
                        { fieldName: "startDate", value: item.startDate, showText: this._datePipe.transform(item.startDate, "dd/MM/yyyy"), header: "Start Date", visible: true, sortable: true },
                        { fieldName: "endDate", value: item.endDate, showText: this._datePipe.transform(item.endDate, "dd/MM/yyyy"), header: "End Date", visible: true, sortable: true },
                        { fieldName: "yearlyAmountExGst", value: item.yearlyAmountExGst, showText: this.format2(item.yearlyAmountExGst, '$'), header: "Yearly $ (Ex GST)", visible: true, sortable: true },
                        { fieldName: "monthlyAmountExGst", value: item.monthlyAmountExGst, showText: this.format2(item.monthlyAmountExGst, '$'), header: "Monthly $ (Ex GST)", visible: true, sortable: true },
                      ]  
                  );
              } 
              else if (!!data && data.isError) {
                this.openDialog("Alert", data.message, "alertdialog");
              }
              tc.showRentReviewSchedule = true;
              tc.showRentReviewScheduleLoader = false;
          });
      }
      else {
        tc.showRentReviewSchedule = !tc.showRentReviewSchedule;
      }
  }

  getRentReviewMethodName(id: number): string {
    var nextRentReviewMethod = '';
    switch (id) {
      case 0:
        return  "Unspecified";
      case 1:
        return "Commencement";
      case 2:
        return "Fixed percent";
      case 3:
        return "CPI";
      case 4:
        return "Maximum of CPI and fixed percent";
      case 5:
        return "CPI plus fixed percent";
      case 6:
        return "Market value";
      case 7:
        return "Reduction";
      case 8:
        return "Fixed ";
      default:        
          break;  
     
  }
}
  //#endrgion

  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }

  sortData(sort: Sort) {
    //this.showSortingLoader = true;
    this._loader.show();
    setTimeout(() => { 
      this.sortAllData(sort);
    }, 10)
  }

  private sortAllData(sort: Sort) {
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.tenantContacts.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.tenantContacts.length; innerIndex++) {
        switch (sort.active) {
          case 'tenant': {
            if (isAsc) {
              if (this.tenantContacts[index].tenant > this.tenantContacts[innerIndex].tenant) {
                let temp = this.tenantContacts[index];
                this.tenantContacts[index] = this.tenantContacts[innerIndex];
                this.tenantContacts[innerIndex] = temp;
              }
            }
            else {
              if (this.tenantContacts[index].tenant < this.tenantContacts[innerIndex].tenant) {
                let temp = this.tenantContacts[index];
                this.tenantContacts[index] = this.tenantContacts[innerIndex];
                this.tenantContacts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'rentPaidTo': {
            if (isAsc) {
              if (this.tenantContacts[index].rentPaidTo > this.tenantContacts[innerIndex].rentPaidTo) {
                let temp = this.tenantContacts[index];
                this.tenantContacts[index] = this.tenantContacts[innerIndex];
                this.tenantContacts[innerIndex] = temp;
              }
            }
            else {
              if (this.tenantContacts[index].rentPaidTo < this.tenantContacts[innerIndex].rentPaidTo) {
                let temp = this.tenantContacts[index];
                this.tenantContacts[index] = this.tenantContacts[innerIndex];
                this.tenantContacts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'address': {
            if (isAsc) {
              if (this.tenantContacts[index].address > this.tenantContacts[innerIndex].address) {
                let temp = this.tenantContacts[index];
                this.tenantContacts[index] = this.tenantContacts[innerIndex];
                this.tenantContacts[innerIndex] = temp;
              }
            }
            else {
              if (this.tenantContacts[index].address < this.tenantContacts[innerIndex].address) {
                let temp = this.tenantContacts[index];
                this.tenantContacts[index] = this.tenantContacts[innerIndex];
                this.tenantContacts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'vacancyDate': {
            if (isAsc) {
              if (this.tenantContacts[index].vacancyDate > this.tenantContacts[innerIndex].vacancyDate) {
                let temp = this.tenantContacts[index];
                this.tenantContacts[index] = this.tenantContacts[innerIndex];
                this.tenantContacts[innerIndex] = temp;
              }
            }
            else {
              if (this.tenantContacts[index].vacancyDate < this.tenantContacts[innerIndex].vacancyDate) {
                let temp = this.tenantContacts[index];
                this.tenantContacts[index] = this.tenantContacts[innerIndex];
                this.tenantContacts[innerIndex] = temp;
              }
            }
            break;
          }
          case 'weeklyRent': {
            if (isAsc) {
              if (this.tenantContacts[index].weeklyRent > this.tenantContacts[innerIndex].weeklyRent) {
                let temp = this.tenantContacts[index];
                this.tenantContacts[index] = this.tenantContacts[innerIndex];
                this.tenantContacts[innerIndex] = temp;
              }
            }
            else {
              if (this.tenantContacts[index].weeklyRent < this.tenantContacts[innerIndex].weeklyRent) {
                let temp = this.tenantContacts[index];
                this.tenantContacts[index] = this.tenantContacts[innerIndex];
                this.tenantContacts[innerIndex] = temp;
              }
            }
            break;
          }
          default:
            break;
        }
      }
    }
    this.loadPageData();
    this._loader.hide();
  }

  //#region Work Order
  private showWorkOrder: boolean;
  private tenantContact: ICommercialTenantContactSearchResult;
  private workOrder_Subscription: Subscription;

  onWorkOrderPopUpOpen(tc: ICommercialTenantContactSearchResult){
    this.showWorkOrder = true;
    this.tenantContact = tc;
  }

  onWorkOrderExpand(tc: ICommercialTenantContactSearchResult){
    var propertyManagerIDs : string[]=[];
    if(!tc.showWorkOrder) {
        tc.showWorkOrder = false;
        tc.showWorkOrderLoader = true;
        tc.workOrders = [];
        propertyManagerIDs.push(tc.propertyManagerID);
        this.workOrder_Subscription = 
        this._service.GetWorkOrder
        (
          null,
          1,
          null,
          null,
          null,
          1,
          null,
          propertyManagerIDs,
          null,
          false,
          tc.managementAgreementID
        )
        .subscribe((data: any) => {
          if (data.isError) {
            this.openDialog("Error", data.message, "alertdialog");
          }
          else {
            tc.workOrders =  data.response.map(item=>
                [
                  { fieldName: "dateIssued", value: this._datePipe.transform(item.dateIssued, "dd/MM/yyyy"), showText: this._datePipe.transform(item.dateIssued, "dd/MM/yyyy"), header: "Date Issued", visible: true },
                  { fieldName: "number", value: item.number, showText: !!item.number ? item.number.toString() : "-", header: "Number", visible: true },
                  { fieldName: "statusDetails", value: item.statusDetails, showText: !!item.statusDetails ? item.statusDetails.toString() : "-", header: "Status", visible: true },
                  { fieldName: "address", value: item.address, showText: !!item.address ? item.address.toString() : "-", header: "Property Address", visible: true },
                  { fieldName: "supplierName", value: item.supplierName, showText: !!item.supplierName ? item.supplierName.toString() : "-", header: "Supplier", visible: true },
                  { fieldName: "serviceTypeName", value: item.serviceTypeName, showText: !!item.serviceTypeName ? item.serviceTypeName.toString() : "-", header: "Service Type", visible: true },
                  { fieldName: "supplierMobileNumber", value: item.supplierMobileNumber, showText: !!item.supplierMobileNumber ? item.supplierMobileNumber.toString() : "-", header: "Supplier Mobile", visible: true, showPhoneIcon: true, showSMSIcon: true },
                  { fieldName: "supplierWorkPhone", value: item.supplierWorkPhone, showText: !!item.supplierWorkPhone ? item.supplierWorkPhone.toString() : "-", header: "Supplier W PH", visible: true, showPhoneIcon: true },
                  { fieldName: "supplierEmail", value: item.supplierEmail, showText: !!item.supplierEmail ? item.supplierEmail.toString() : "-", header: "Supplier Email", visible: true, showEmailIcon: true },
                  { fieldName: "onSiteContactName", value: item.onSiteContactName, showText: !!item.onSiteContactName ? item.onSiteContactName.toString() : "-", header: "Onsite Contact", visible: true },
                  { fieldName: "onsiteContactMobile", value: item.onsiteContactMobile, showText: !!item.onsiteContactMobile ? item.onsiteContactMobile.toString() : "-", header: "Onsite Contact Mobile", visible: true, showPhoneIcon: true, showSMSIcon: true },
                  { fieldName: "workDetail", value: item.workDetail, showText: !!item.workDetail ? item.workDetail.toString() : "-", header: "Work Order Details", visible: true },
                ]
              );
            tc.showWorkOrder = true;
            tc.showWorkOrderLoader = false;
          }
        });
    }
    else {
        tc.showWorkOrder = !tc.showWorkOrder;
    }
  }
  //#endregion

  //#region Close Work Order Popup Dialog
  private IsWorkOrderSaved: boolean = false;
  private closeOnOutsideClick: boolean = false;
  onWorkOrderSave(event: any) {
    this.IsWorkOrderSaved = event;
  }
  onCloseWorkOrder(event: any) {
    if (!this.IsWorkOrderSaved) {
      this.showWorkOrder = !confirm("Work order not saved!! Still want to close?");
      this.closeOnOutsideClick = !this.showWorkOrder;
    }
    else {
      this.showWorkOrder = false;
    }
  }
  //#endregion

  format2(n, currency) {
    return currency + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }

}
