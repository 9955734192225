import { Action } from 'redux';
import { IDNameExtraPair, IDNamePair } from "../services/authservice";
import { IEnquiryDto, IListingEnquiryStatusDto } from "./reports";

export enum enActionTypes {
    ShowVisitorsSearchPageAction = "[VISITORS] Shows Visitors Search Page Action",
    GetCurrentStaffsVisitorsSearchPageAction = "Fetches The Current Staffs For Filling Dropdown In Visitors Search Page Action",
    ShowEnquirersFollowUpSearchPageAction = "[ENQUIRERSFOLLOWUP] Shows Enquirers Follow Up Search Page Action",
    GetCurrentStaffsEnquirersFollowUpSearchPageAction = "[ENQUIRERSFOLLOWUP] Fetches The Current Staffs For Filling Dropdown In Enquirers Follow Up Search Page Action",
    GetGetEnquiriesSearchPageAction = "[ENQUIRERSFOLLOWUP] Searches Enquiries And Returns The Result Action",
    CloseEnquiriersFollowupResultPageAction = "[ENQUIRERSFOLLOWUP] Closes Enquiriers Followup Search Result Page And Moves To Enquiriers Followup Search Page",
    ShowListingEnquiryStatusSearchPageAction = "[LISTINGENQUIRYSTATUS] Shows Listing Enquiry & Status Search Page Action",
    GetAppStatusListingEnquiryStatusSearchPageAction = "[LISTINGENQUIRYSTATUS] Fetches Appointment Statuses for the dropdown in Listing Enquiry & Status Search Page Action",
    GetSaleStatusListingEnquiryStatusSearchPageAction = "[LISTINGENQUIRYSTATUS] Fetches Sale Statuses for the dropdown in Listing Enquiry & Status Search Page Action",
    GetStaffsListingEnquiryStatusSearchPageAction = "[LISTINGENQUIRYSTATUS] Fetches The Staffs For Filling Dropdown In Listing Enquiry & Status Search Page Action",
    GetListingEnquiryStatusSearchPageAction = "[LISTINGENQUIRYSTATUS] Searches Listing Enquiry & Status And Returns The Result Action",
    CloseListingEnquiryStatusResultPageAction = "[LISTINGENQUIRYSTATUS] Closes Listing Enquiry & Status Result Page",
}

//#region Visitors
export class ShowVisitorsSearchPage implements Action {
    readonly type = enActionTypes.ShowVisitorsSearchPageAction;
    constructor() {}
}
export class GetCurrentStaffsVisitorsSearchPage implements Action {
    readonly type = enActionTypes.GetCurrentStaffsVisitorsSearchPageAction;
    constructor(public readonly payload: IDNamePair<string>[]) {}
}
//#endregion

//#region Enquirers Follow Up
export class ShowEnquirersFollowUpSearchPage implements Action {
    readonly type = enActionTypes.ShowEnquirersFollowUpSearchPageAction;
    constructor() {}
}
export class GetCurrentStaffsEnquirersFollowUpSearchPage implements Action {
    readonly type = enActionTypes.GetCurrentStaffsEnquirersFollowUpSearchPageAction;
    constructor(public readonly payload: IDNamePair<string>[]) {}
}
export class GetGetEnquiriesSearchPage implements Action {
    readonly type = enActionTypes.GetGetEnquiriesSearchPageAction;
    constructor(public readonly payload: IEnquiryDto[]) {}
}
export class CloseEnquiriersFollowupResultPage implements Action {
    readonly type = enActionTypes.CloseEnquiriersFollowupResultPageAction;
    constructor() {}
}
//#endregion

//#region Listing Enquiry & Status
export class ShowListingEnquiryStatusSearchPage implements Action {
  readonly type = enActionTypes.ShowListingEnquiryStatusSearchPageAction;
  constructor() { }
}
export class GetAppStatusListingEnquiryStatusSearchPage implements Action {
  readonly type = enActionTypes.GetAppStatusListingEnquiryStatusSearchPageAction;
  constructor(public readonly payload: IDNamePair<number>[]) { }
}
export class GetSaleStatusListingEnquiryStatusSearchPage implements Action {
  readonly type = enActionTypes.GetSaleStatusListingEnquiryStatusSearchPageAction;
  constructor(public readonly payload: IDNameExtraPair<number, string>[]) { }
}
export class GetStaffsListingEnquiryStatusSearchPage implements Action {
  readonly type = enActionTypes.GetStaffsListingEnquiryStatusSearchPageAction;
  constructor(public readonly payload: IDNamePair<string>[]) { }
}
export class GetListingEnquiryStatusSearchPage implements Action {
  readonly type = enActionTypes.GetListingEnquiryStatusSearchPageAction;
  constructor(public readonly payload: IListingEnquiryStatusDto[]) { }
}
export class CloseListingEnquiryStatusResultPage implements Action {
  readonly type = enActionTypes.CloseListingEnquiryStatusResultPageAction;
  constructor() { }
}
//#endregion

export type VisitorsAction = 
ShowVisitorsSearchPage
| GetCurrentStaffsVisitorsSearchPage
| ShowEnquirersFollowUpSearchPage
| GetCurrentStaffsEnquirersFollowUpSearchPage
| GetGetEnquiriesSearchPage
| CloseEnquiriersFollowupResultPage
| ShowListingEnquiryStatusSearchPage
| GetAppStatusListingEnquiryStatusSearchPage
| GetSaleStatusListingEnquiryStatusSearchPage
| GetStaffsListingEnquiryStatusSearchPage
| GetListingEnquiryStatusSearchPage
| CloseListingEnquiryStatusResultPage;
