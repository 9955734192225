import { Injectable, Inject, PLATFORM_ID, Injector } from "@angular/core";
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

 
@Injectable()
 export class BaseService {
     constructor (private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object){}

     //#region Dashboard
     public GetAll<T>(url: string, params?: any): Observable<T> {
         if (!!params) {
            return this.http.get<T>(url, { params: params } );
         }
         else {
            return this.http.get<T>(url);
         }
    }
     //#endregion
 }