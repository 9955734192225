import { IDashboard, IDashboardItem } from './home';


export interface IHomeState {
    dashboard: IDashboard
}


export const INITIAL_HOME_STATE = (): IHomeState => ({
    dashboard: {
        propertyManagement: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        } as IDashboardItem,
        propertiesGained: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        } as IDashboardItem,
        propertiesLost: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        } as IDashboardItem,
        propertiesAgreementRenewalDue: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        } as IDashboardItem,
        propertiesVacantLessThan14: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        } as IDashboardItem,
        propertiesVacantGraterThan14: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        }  as IDashboardItem,
        propertiesAgreementExpired: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        } as IDashboardItem,
        propertiesPeriodicLeases: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        } as IDashboardItem,
        propertiesAverageWeeklyRent: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesOutstandingWorkOrder3To14Days: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesOutstandingWorkOrderGraterThan14Days: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesLeaseRenewedDays: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesLeasedDays: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesTenantInvoicesOverdueWithin2To14Days: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesTenantInvoicesOverdueGreaterThan14Days: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesLeaseExpiryOverdue: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesLeaseExpiryDueWithinDays: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesTenantVacatingWithinDays: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesInspectionOverdue: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesInspectionDueWithin7Days: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesInspectionDueWithin30Days: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesTenantsInArrearsGraterThan3: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesTenantsInArrearsGraterThan10: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        },
        propertiesAverageCommissionPercent: {
            id: "",
            companyTotal: 0,
            myTotal: 0,
            myPercentage: 0
        }
    } as IDashboard
});
