import { OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatDialog, MatDialogConfig, MatSnackBarConfig, DateAdapter } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

import { ServiceResponse, ContactDetails, IDNameExtraPair } from './../../../services/authservice';
import { enDialogRole, DialogBodyComponent } from './../../../dialog-body/dialog-body.component';
import { IManagementAgreement, ITenantContactSearchResult, IWorkOrderSave, IWorkOrderSearchByID } from './../../propertymanagement';
import { PropertyManagementService } from '../../propertymanagement.service';
import { AuthService, IDNamePair } from "../../../services/authservice";
import { LoaderService } from "./../../../core/loader/loader.service";
import { IAppState } from "../../../store/store";
import { MaintenanceService } from '../../../maintenance/maintenance.service';
import { IRentalTenantPropertyAddress } from '../../../maintenance/maintenance.state';
import { GetPropertyAddresses, GetSupplierServiceTypes } from '../../../maintenance/maintenance.action';
import { ConfirmDialogComponent } from '../../../components/confirmdialog/confirmdialog.component';


@Component({
  selector: 'app-workordercreation',
  templateUrl: './workordercreation.component.html',
  styleUrls: ['./workordercreation.component.css']
})
export class WorkOrderCreationComponent implements OnInit, OnDestroy {

  private model: FormGroup;
  private staffEmail: string;
  private staffMobile: string;
  private mailmergeName: string;
  private serviceTypeIDs: IDNamePair<number>[];
  private suppliers: IDNamePair<string>[];
  private dialogRef: any;
  private isError: boolean;
  private isSaved: boolean;
  private showWaitLoader: boolean;
  private message: string;
  private supplierEmail: string;
  private supplierMobile: string;
  private isOwnerChecked: boolean = false;
  private isTenantChecked: boolean = false;
  private onSitesContacts: IDNamePair<number>[];
  private onSiteCompanyPersons: ContactDetails[];
  private onSiteTenantOwnerType: number;
  private workOrderPdfUrl: string;
  private _database: string;
  private _departmentID: string;
  private selectedProperty: IRentalTenantPropertyAddress;
  private _imageID1: string;
  private _imageID2: string;
  private _imageID3: string;

  private supplierEmailCss = "";
  private supplierMobileCss = "";
  private contactEmailCss = "";
  private contactMobileCss = "";

  private dropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    enableCheckAll: false,
    itemsShowLimit: 10,
    searchPlaceholderText: 'Search',
    allowSearchFilter: true
  };

  private subscription_GetSupplierServiceTypes: Subscription;
  private subscription_GetSupplierInfo: Subscription;
  private subscription_GetContactDetails: Subscription;
  private subscription_GetOnSiteContactDetails: Subscription;
  private subscription_GetCompanyPersons: Subscription;
  private getSessionData_Subscription: Subscription;
  private saveWorkOrder_Subscription: Subscription;
  private subcription_GetPropertyAddressByAddressString: Subscription;
  private subscription_GetManagementAgreements: Subscription;
  private subcription_GetMaintenance: Subscription;
  private subscription_RemoveWorkOrderImage: Subscription;

  @Input() id: string | undefined | null = null;
  @Input() solo: boolean = false;
  @Input() managementAgreement: IManagementAgreement;
  @Input() tenantContact: ITenantContactSearchResult;
  @Input() onsitecontactValue: number;
  @Input() callLogID: string | undefined | null = null;
  @Input() photo1FileName: string;
  @Input() photo1Base64: string;
  @Input() photo2FileName: string;
  @Input() photo2Base64: string;
  @Input() photo3FileName: string;
  @Input() photo3Base64: string;
  @Output() save = new EventEmitter<boolean>();

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _service: PropertyManagementService,
    private _authService: AuthService,
    private _maintenanceService: MaintenanceService,
    private sanitizer: DomSanitizer,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('en-AU');
    this.onSitesContacts = [{ id: 1, name: "Tenant" }, { id: 2, name: "Owner" }, /*{ id: 4, name: "Strata Manager" },*/ { id: 8, name: "Property Manager" }];
    this.model = new FormGroup({
      managementAgreementID: new FormControl(null, Validators.required),
      issueDate: new FormControl(null, Validators.required),
      jobNumber: new FormControl(null),
      woNumber: new FormControl(null),
      siteContactType: new FormControl(null, Validators.required),
      selectedTenantOwnerContact: new FormControl(null),
      supplierID: new FormControl(null/*, Validators.required*/),
      selectedSupplier: new FormControl(null, Validators.required),
      contactID: new FormControl("", Validators.required),
      contactName: new FormControl(""),
      email: new FormControl(""),
      mobilePhone: new FormControl(""),
      homePhone: new FormControl(""),
      workPhone: new FormControl(""),
      supplierServiceTypeID: new FormControl(null),
      propertyID: new FormControl("", Validators.required),
      propertyName: new FormControl(""),
      ownerInstructions: new FormControl(""),
      maintenanceNotes: new FormControl(""),
      screenAlertNotes: new FormControl(""),
      workToBeDone: new FormControl("", Validators.required),
      costEstimate: new FormControl(),
      authorizedBy: new FormControl(""),
      gstRegistered: new FormControl(false),
      gstInclusivePricing: new FormControl(false),
      agreedCompletionDate: new FormControl(),
      supplierEmail: new FormControl(),
      supplierMobile: new FormControl(),
      workOrderStatusID: new FormControl(1, Validators.required),
      woImgInput1: new FormControl(null),
      woImgInput2: new FormControl(null),
      woImgInput3: new FormControl(null)
    });
  }

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  ngOnInit() {
    this.contactEmailCss = "supemail";
    this.contactMobileCss = "supmob";
    this.getSessionData_Subscription = this._authService.GetSessionData()
      .subscribe(session => {
        //this.staffID = session.staffID;
        this.model.controls.authorizedBy.setValue(session.firstName + " " + session.lastName);
        this.mailmergeName = session.mailMergeName;
        this.staffMobile = session.mobile;
        this.staffEmail = session.email;
        this._database = session.connectionStringName;
        this._departmentID = session.departmentID;

        if (this.solo) {
          this.model.controls.propertyName.valueChanges.subscribe((address: string) => {
            if (!!address && address.length >= 3) {
              this.subcription_GetPropertyAddressByAddressString =
                this._maintenanceService.GetPropertyAddressByAddressString(null, session.id, address)
                  .subscribe
                  ((data: ServiceResponse<IRentalTenantPropertyAddress[]>) => {
                    this._loader.hide()
                    if (data.isError) {
                      this.openDialog("Error", data.message, "alertdialog");
                    }
                    else {
                      let action = new GetPropertyAddresses(data.response)
                      this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                    }
                  },
                    error => {
                      this._loader.hide();
                      this.openDialog("Alert", error, "alertdialog");
                    }
                  );
            }
            else {
              let action = new GetPropertyAddresses([]);
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            }

          });
        }
        else {
          this.subcription_GetMaintenance =
            this._maintenanceService.GetMaintenance(null, session.id, this.callLogID)
              .subscribe({
                next: (result) => {
                  if (!!result && !!result.response) {
                    this.model.controls.supplierServiceTypeID.setValue(result.response.supplierServiceTypeID);
                    this.model.controls.workToBeDone.setValue(result.response.text);
                    this.onSupplierBind('');
                  }
                },
                error: (error) => {
                  console.error(error);
                  if (!!error) {
                    this.openDialog("Error", error.message, "alertdialog");
                  }
                },
                complete: () => {
                  console.info('Maintence data fetched');
                }
              });
        }

        this.subscription_GetSupplierServiceTypes =
          this._maintenanceService.GetSupplierServiceTypesBySessionAgency(null, session.id)
            .subscribe
            ((data: ServiceResponse<IDNameExtraPair<number, number | undefined | null>[]>) => {
              this._loader.hide()
              if (data.isError) {
                this.openDialog("Error", data.message, "alertdialog");
              }
              else {
                let action = new GetSupplierServiceTypes(data.response)
                this.ngRedux.dispatch({ type: action.type, payload: action.payload });
              }
            },
              error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
              }
            );

      });
    this.model.controls.siteContactType.setValue(!!this.onsitecontactValue ? this.onsitecontactValue : 1);

    setTimeout(() => {
      this.initialize();
    }, 1000);


    /*this.subscription_GetSupplierServiceTypes =
      this._service.GetSupplierServiceTypes()
        .subscribe((data: ServiceResponse<IDNamePair<number>[]>) => {
          if (!data.isError) {
            this.serviceTypeIDs = data.response;
            // if (!!data && !!data.response.length) {
            //   this.model.controls.supplierServiceTypeID.setValue(this.serviceTypeIDs[0].id);
            //   this.onSupplierBind("");
            // }
          }
          else {
            this.openDialog("Alert", data.message, "alertdialog")
          }
        },
          err => this.openDialog("Alert", err, "alertdialog")
        );*/

    //this.onSupplierBind("");

    // // if (!!this.managementAgreement) {
    // //   this.subscription_GetContactDetails =
    // //     this._service.GetContactDetails(this.managementAgreement.contactID)
    // //       .subscribe((data: ServiceResponse<ContactDetails>) => {
    // //         if (!data.isError && !!data.response) {

    // //           if (this.onSiteTenantOwnerType != 2 || (this.model.controls.siteContactType.value != 1 && this.model.controls.siteContactType.value != 2)) {

    // //             this.model.controls.contactID.setValue(data.response.id);
    // //             this.model.controls.contactName.setValue(data.response.name);
    // //             this.model.controls.email.setValue(data.response.email);
    // //             this.model.controls.mobilePhone.setValue(data.response.mobilePhone);
    // //             this.model.controls.homePhone.setValue(data.response.homePhone);
    // //             this.model.controls.workPhone.setValue(data.response.workPhone);
    // //           }
    // //         }
    // //         else {
    // //           this.openDialog("Alert", data.message, "alertdialog");
    // //         }
    // //       });
    // // }
    // // else if (!!this.tenantContact) {
    // //   this.subscription_GetContactDetails =
    // //     this._service.GetContactDetails(this.tenantContact.contactID)
    // //       .subscribe((data: ServiceResponse<ContactDetails>) => {
    // //         if (!data.isError && !!data.response) {
    // //           if (this.onSiteTenantOwnerType != 2 || (this.model.controls.siteContactType.value != 1 && this.model.controls.siteContactType.value != 2)) {
    // //             this.model.controls.contactID.setValue(data.response.id);
    // //             this.model.controls.contactName.setValue(data.response.name);
    // //             this.model.controls.email.setValue(data.response.email);
    // //             this.model.controls.mobilePhone.setValue(data.response.mobilePhone);
    // //             this.model.controls.homePhone.setValue(data.response.homePhone);
    // //             this.model.controls.workPhone.setValue(data.response.workPhone);
    // //           }
    // //         }
    // //         else {
    // //           this.openDialog("Alert", data.message, "alertdialog");
    // //         }
    // //       });
    // // }


  }

  //#region Store Observables
  @select(state => (state as IAppState).maintenance.newMaintenance.propertyAddresses) propertyAddresses$;
  @select(state => (state as IAppState).maintenance.newMaintenance.supplierServiceTypes) supplierServiceTypes$;
  //#endregion

  //#region Properties
  get database(): string {
    if (!!this.route.snapshot.paramMap.get("database")) {
      return this.route.snapshot.paramMap.get("database");
    }
    else {
      return this._database;
    }
  }
  get departmentID(): string {
    return this._departmentID;
  }
  get hasImageID1(): boolean {
    return (!!this._imageID1 && this._imageID1.indexOf('00000000-') <= 0);
  }
  get hasImageID2(): boolean {
    return (!!this._imageID2 && this._imageID2.indexOf('00000000-') <= 0);
  }
  get hasImageID3(): boolean {
    return (!!this._imageID3 && this._imageID3.indexOf('00000000-') <= 0);
  }
  //#endregion

  //#region Service Type
  onServiceTypeChange(event: any) {
    debugger;
    if (!!this.model.controls.supplierServiceTypeID && !!this.model.controls.supplierServiceTypeID.value) {
      this.onSupplierBind("");
    }
    else {
      this.suppliers = [];
    }
    this.model.controls.supplierID.setValue(null);
    this.model.controls.selectedSupplier.setValue(null);
    this.model.controls.supplierMobile.setValue(null);
    this.model.controls.supplierEmail.setValue(null);
  }
  //#endregion

  //#region Supplier
  private isSupplierLoading: boolean;
  onSupplierBind(textEntered: string, wo: IWorkOrderSearchByID | undefined | null = null) {
    this.isSupplierLoading = true;
    this.subscription_GetSupplierInfo =
      this._service.GetSupplierDetails(textEntered, this.model.controls.supplierServiceTypeID.value)
        .subscribe((data: ServiceResponse<IDNamePair<string>[]>) => {
          if (!data.isError) {
            this.suppliers = data.response;
            if (!!data.response && !!data.response.length) {
              if (!!wo && !!wo.addressText && (!!this.suppliers && !!this.suppliers.length)) {
                this.model.controls.selectedSupplier.setValue(this.suppliers.find(sup => sup.id == wo.supplierContactID));
                this.model.controls.supplierMobile.setValue(wo.supplierMobile);
                this.model.controls.supplierEmail.setValue(wo.supplierEmail);
              }
            }
          }
          else if (data.isError) {
            this.openDialog("Alert", data.message, "alertdialog");
          }
          this.isSupplierLoading = false;
        });
  }
  onSupplierFilterChange(event: any) {
    if (event.keyCode >= 33 && event.keyCode <= 40) return;
    if (!!this.model.controls.selectedSupplier.value) {
      let textEntered: string = this.model.controls.selectedSupplier.value;
      if (!!textEntered && textEntered.length >= 3) {
        this.onSupplierBind(textEntered);
      }
      else {
        this.suppliers = [];
        this.model.controls.supplierID.setValue(null);
        this.model.controls.supplierEmail.setValue(null);
        this.model.controls.supplierMobile.setValue(null);
        this.supplierMobileCss = "";
        this.supplierEmailCss = "";
      }
    }
    else {
      this.suppliers = [];
      this.model.controls.supplierID.setValue(null);
      this.model.controls.supplierEmail.setValue(null);
      this.model.controls.supplierMobile.setValue(null);
      this.supplierMobileCss = "";
      this.supplierEmailCss = "";
    }
  }
  displayFn(item: IDNamePair<string>): string {
    if (!!item && !!item.id) {
      return item.name;
    }
  }
  onSupplierChange(event: any) {
    this.supplierEmail = "";
    this.supplierMobile = "";
    if (!!event && !!event.option && !!event.option.value && !!event.option.value.id) {
      this.model.controls.supplierID.setValue(event.option.value.id);
    }
    this.subscription_GetContactDetails =
      this._service.GetContactDetails(event.option.value.id)
        .subscribe((data: ServiceResponse<ContactDetails>) => {
          if (!data.isError && !!data.response) {
            this.supplierEmail = data.response.email;
            this.supplierMobile = data.response.mobilePhone;
            this.model.controls.supplierEmail.setValue(this.supplierEmail);
            this.model.controls.supplierMobile.setValue(this.supplierMobile);
          }
          else if (data.isError) {
            this.openDialog("Error", data.message, "alertdialog");
            this.model.controls.supplierEmail.setValue('');
            this.model.controls.supplierMobile.setValue('');
            this.supplierEmailCss = '';
            this.supplierMobileCss = '';
          }
        });
  }

  /*onSupplierDeselect(event: any) {
    this.model.controls.supplierEmail.setValue('');
    this.model.controls.supplierMobile.setValue('');
    this.supplierEmail ="";
    this.supplierMobile = "";
    this.supplierEmailCss = "";
    this.supplierMobileCss = "";
  }

  onSupplierChange(event: any) {
    this.supplierEmail = "";
    this.supplierMobile = "";
    this.subscription_GetContactDetails =
      this._service.GetContactDetails(event.id)
        .subscribe((data: ServiceResponse<ContactDetails>) => {
          if (!data.isError && !!data.response) {
            this.supplierEmail = data.response.email;
            this.supplierMobile = data.response.mobilePhone;
            this.model.controls.supplierEmail.setValue(this.supplierEmail);
            this.model.controls.supplierMobile.setValue(this.supplierMobile);
            // this.supplierEmailCss = (!!data.response.email && data.response.email.length > 0) ? "supemail" : "";
            // this.supplierMobileCss = (!!data.response.mobilePhone && data.response.mobilePhone.length > 0) ? "supmob" : "";
          }
          else if (data.isError) {
            this.openDialog("Error", data.message, "alertdialog");
            this.model.controls.supplierEmail.setValue('');
            this.model.controls.supplierMobile.setValue('');
          }
        });
  }*/
  //#endregion

  //#region On Site Contact
  onSiteContactTypeChange(type: number) {
    this.model.controls.contactID.setValue("");
    this.model.controls.contactName.setValue("");
    this.model.controls.email.setValue("");
    this.model.controls.mobilePhone.setValue("");
    this.model.controls.homePhone.setValue("");
    this.model.controls.workPhone.setValue("");
    this.model.controls.selectedTenantOwnerContact.setValue(null);

    this.onSiteCompanyPersons = [];
    this.subscription_GetOnSiteContactDetails =
      this._service.GetOnSiteContactDetails(this.model.controls.managementAgreementID.value, type)
        .subscribe((data: ServiceResponse<ContactDetails>) => {
          if (!data.isError && !!data.response) {
            this.onSiteTenantOwnerType = data.response.type;
            if (data.response.type != 2 || (type != 1 && type != 2)) {
              this.model.controls.contactID.setValue(data.response.id);
              this.model.controls.contactName.setValue(data.response.name);
              this.model.controls.email.setValue(data.response.email);
              this.model.controls.mobilePhone.setValue(data.response.mobilePhone);
              this.model.controls.homePhone.setValue(data.response.homePhone);
              this.model.controls.workPhone.setValue(data.response.workPhone);
            }
            else {
              this.subscription_GetCompanyPersons =
                this._service.GetCompanyPersons(data.response.id)
                  .subscribe((result: ServiceResponse<ContactDetails[]>) => {
                    if (!result.isError && !!result.response) {
                      this.onSiteCompanyPersons = result.response;
                      this.model.controls.selectedTenantOwnerContact.setValue(result.response[0]);
                      this.onTenantOwnerContactChange(this.model.controls.selectedTenantOwnerContact.value);
                    }
                    else if (!result.isError && !result.response) {
                      this.onSiteCompanyPersons = [];
                    }
                    else if (result.isError) {
                      this.onSiteCompanyPersons = [];
                      this.openDialog("Alert", result.message, "alertdialog");
                    }
                  });

              this.model.controls.contactID.setValue("");
              this.model.controls.contactName.setValue("");
              this.model.controls.email.setValue("");
              this.model.controls.mobilePhone.setValue("");
              this.model.controls.homePhone.setValue("");
              this.model.controls.workPhone.setValue("");

            }
          }
          else if (!data.isError && !data.response) {
            this.model.controls.contactID.setValue("");
            this.model.controls.contactName.setValue("");
            this.model.controls.email.setValue("");
            this.model.controls.mobilePhone.setValue("");
            this.model.controls.homePhone.setValue("");
            this.model.controls.workPhone.setValue("");
          }
          else if (data.isError) {
            this.openDialog("Alert", data.message, "alertdialog");
          }
        });
  }
  onSiteContactChange(event: any) {
    this.onSiteContactTypeChange(event.value);
  }
  onTenantOwnerContactChange(event: any) {
    if (!!this.model.controls.selectedTenantOwnerContact && !!this.model.controls.selectedTenantOwnerContact.value) {
      let toContact: ContactDetails = this.model.controls.selectedTenantOwnerContact.value as ContactDetails;
      this.model.controls.contactID.setValue(toContact.id);
      this.model.controls.contactName.setValue(toContact.name);
      this.model.controls.email.setValue(toContact.email);
      this.model.controls.mobilePhone.setValue(toContact.mobilePhone);
      this.model.controls.homePhone.setValue(toContact.homePhone);
      this.model.controls.workPhone.setValue(toContact.workPhone);
    }
    else {
      this.model.controls.contactID.setValue("");
      this.model.controls.contactName.setValue("");
      this.model.controls.email.setValue("");
      this.model.controls.mobilePhone.setValue("");
      this.model.controls.homePhone.setValue("");
      this.model.controls.workPhone.setValue("");
    }
  }
  //#endregion

  initialize() {
    debugger;
    this.model.controls.workOrderStatusID.enable();
    if (!!this.id && this.id.indexOf('00000000-') <= 0) {
      this._service.GetWorkOrderSearchByID(this.id)
        .subscribe((data: ServiceResponse<IWorkOrderSearchByID>) => {
          this._loader.hide()
          if (data.isError) {
            this.openDialog("Error", data.message, "alertdialog");
          }
          else {
            console.log(data.response);
            this.model.controls.managementAgreementID.setValue(data.response.managementAgreementID);
            this.model.controls.propertyID.setValue(data.response.propertyID);
            this.model.controls.propertyName.setValue(data.response.addressText);
            this.model.controls.ownerInstructions.setValue(data.response.maintenanceNotes);
            this.model.controls.contactID.setValue(data.response.onSiteContactID);
            this.model.controls.contactName.setValue(data.response.onSiteContactName);
            this.model.controls.mobilePhone.setValue(data.response.onSiteContactMobile);
            this.model.controls.email.setValue(data.response.onSiteContactEmail);
            this.model.controls.issueDate.setValue(data.response.dateIssued);
            this.model.controls.authorizedBy.setValue(data.response.authorisedByStaffName);
            this.model.controls.supplierServiceTypeID.setValue(data.response.serviceTypeID);

            this.onSupplierBind(data.response.supplierContactName, data.response);

            this.model.controls.agreedCompletionDate.setValue(data.response.agreedCompletionDate);
            this.model.controls.costEstimate.setValue(data.response.costEstimate);
            this.model.controls.workToBeDone.setValue(data.response.workDetail);
            this.model.controls.workOrderStatusID.setValue(data.response.statusID);

            this.model.controls.woImgInput1.setValue(null);
            this.model.controls.woImgInput2.setValue(null);
            this.model.controls.woImgInput3.setValue(null);

            this._imageID1 = null;
            this._imageID2 = null;
            this._imageID3 = null;
            document.getElementById("woImg1").setAttribute("src", "/images/noimage.png");
            document.getElementById("woImg2").setAttribute("src", "/images/noimage.png");
            document.getElementById("woImg3").setAttribute("src", "/images/noimage.png");
            if (!!data.response.imageID1Url) {
              document.getElementById("woImg1").setAttribute("src", data.response.imageID1Url);
              if (!!data.response.imageID1 && data.response.imageID1.indexOf('00000000-') <= 0) {
                this._imageID1 = data.response.imageID1;
              }
            }
            if (!!data.response.imageID2Url) {
              document.getElementById("woImg2").setAttribute("src", data.response.imageID2Url);
              if (!!data.response.imageID2 && data.response.imageID2.indexOf('00000000-') <= 0) {
                this._imageID2 = data.response.imageID2;
              }
            }
            if (!!data.response.imageID3Url) {
              document.getElementById("woImg3").setAttribute("src", data.response.imageID3Url);
              if (!!data.response.imageID3 && data.response.imageID3.indexOf('00000000-') <= 0) {
                this._imageID3 = data.response.imageID3;
              }
            }

            this.isTenantChecked = data.response.onSiteContactTypeID == 1;
            this.isOwnerChecked = data.response.onSiteContactTypeID == 2;
            this.model.controls.siteContactType.setValue(data.response.onSiteContactTypeID);

          }
        },
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          });
    }
    else {
      if (!!this.photo1Base64) {
        document.getElementById("woImg1").setAttribute("src", this.photo1Base64);
      }
      if (!!this.photo2Base64) {
        document.getElementById("woImg2").setAttribute("src", this.photo2Base64);
      }
      if (!!this.photo3Base64) {
        document.getElementById("woImg3").setAttribute("src", this.photo3Base64);
      }

      if (!!this.managementAgreement) {
        this.model.controls.managementAgreementID.setValue(this.managementAgreement.id);
        this.model.controls.propertyID.setValue(this.managementAgreement.propertyID);
        this.model.controls.propertyName.setValue(this.managementAgreement.addressText);
        this.model.controls.ownerInstructions.setValue(this.managementAgreement.maintenanceInstructions);
        this.model.controls.maintenanceNotes.setValue(this.managementAgreement.maintenanceNotes);
        this.model.controls.screenAlertNotes.setValue(this.managementAgreement.screenAlertNotes);
        this.model.controls.contactID.setValue(this.managementAgreement.contactID);
        this.model.controls.contactName.setValue(this.managementAgreement.contactName);
        this.model.controls.issueDate.setValue(new Date());
        this.isOwnerChecked = true;
        this.isTenantChecked = false;
        this.onSiteContactTypeChange(1);
      }
      else if (!!this.tenantContact) {
        this.model.controls.managementAgreementID.setValue(this.tenantContact.managementAgreementID);
        this.model.controls.propertyID.setValue(this.tenantContact.propertyID);
        this.model.controls.propertyName.setValue(this.tenantContact.address);
        //this.model.controls.ownerInstructions.setValue(this.tenantContact.maintenanceInstructions);
        //this.model.controls.maintenanceNotes.setValue(this.tenantContact.maintenanceNotes);
        //this.model.controls.screenAlertNotes.setValue(this.tenantContact.screenAlertNotes);
        this.model.controls.contactID.setValue(this.tenantContact.contactID);
        //this.model.controls.contactName.setValue(this.tenantContact.contactName);
        this.model.controls.issueDate.setValue(new Date());
        this.isTenantChecked = true;
        this.isOwnerChecked = false;
        this.onSiteContactTypeChange(1);
      }
      //this.model.controls.workOrderStatusID.disable();
    }
  }

  onSubmit() {
    this.isSaved = false;
    this.workOrderPdfUrl = "";
    if (this.model.status == "VALID") {
      let supplierID: string = this.model.controls.supplierID.value; //this.model.controls.supplierID.value[0].id;
      if (!!(this.model.controls.selectedSupplier.value as IDNamePair<string>)) {
        supplierID = (this.model.controls.selectedSupplier.value as IDNamePair<string>).id;
      }
      this.photo1Base64 = !!this.photo1FileName ? document.getElementById('woImg1').getAttribute("src") : undefined;
      this.photo2Base64 = !!this.photo2FileName ? document.getElementById('woImg2').getAttribute("src") : undefined;
      this.photo3Base64 = !!this.photo3FileName ? document.getElementById('woImg3').getAttribute("src") : undefined;


      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        data: {
          title: "Confirmation",
          message: `Do you want to save and email the work order to the supplier?`
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.showWaitLoader = true;
          this.saveWorkOrder_Subscription =
            this._service.SaveWorkOrder
              (
                this.model.controls.managementAgreementID.value,
                this.model.controls.propertyID.value,
              /*this.model.controls.supplierID.value*/supplierID,
                this.model.controls.costEstimate.value,
                null,
                null,
                this.model.controls.gstRegistered.value,
                this.model.controls.gstInclusivePricing.value,
                this.model.controls.workToBeDone.value,
                this.model.controls.agreedCompletionDate.value,
                this.model.controls.supplierServiceTypeID.value,
                this.model.controls.contactID.value,
                this.model.controls.siteContactType.value,
                null,
                new Date(),
                this.model.controls.workOrderStatusID.value,
                this.photo1FileName,
                this.photo1Base64,
                this.photo2FileName,
                this.photo2Base64,
                this.photo3FileName,
                this.photo3Base64,
                this.callLogID,
                this.id
              )
              .subscribe((result: ServiceResponse<IWorkOrderSave>) => {
                this.isError = result.isError;
                this.message = result.message;
                if (!result.isError) {
                  this.isSaved = true;
                  this.model.controls.woNumber.setValue(result.response.workOrderNumber);
                  this.model.controls.jobNumber.setValue(result.response.jobNumber);
                  this.workOrderPdfUrl = result.response.workOrderPdfUrl;

                  this.supplierEmailCss = (!!this.supplierEmail && this.supplierEmail.length > 0) ? "supemail" : "";
                  this.supplierMobileCss = (!!this.supplierMobile && this.supplierMobile.length > 0) ? "supmob" : "";

                  this.showWaitLoader = false;
                  //this.openDialog("Success", result.message, "alertdialog");
                  this.openDialog("Success", "Work Order saved and emailed to the supplier successfully", "alertdialog");

                  //this.closeDeeplinkBrowser(this.openDeepLink(this.deepLink(this.database as string, result.response.agencyID, this.departmentID, result.response.workOrderID)));

                  this.save.emit(!result.isError);
                }
                else {
                  this.showWaitLoader = false;
                  this.openDialog("Error", result.message, "alertdialog");
                }
              });
        }
      });
    }
  }

  onEmailClick(email: string) {
    let mailto: string = "";
    if (!!email && !!email.length) {
      mailto = "mailto:" + email + "?subject=Work Order – " + this.model.controls.propertyName.value;
    }
    return mailto;
  }
  onSupplierEmailClick(email: string) {
    let mailto: string = "";
    if (!!email && !!email.length) {
      mailto = "mailto:" + email + "?subject=Work Order – " + this.model.controls.propertyName.value;
      var bodyText: string = '';
      bodyText = 'Dear Sir/Madam %0D%0A%0D%0APlease find attached the work order link for ' + this.managementAgreement.addressText + '%0D%0A%0D%0A' + this.workOrderPdfUrl + '%0D%0A%0D%0A' +
        'If you have any queries, please do not hesitate to contact me. %0D%0A%0D%0A';

      if (!!this.mailmergeName) {
        bodyText += 'Regards, %0D%0A' + this.mailmergeName + '%0D%0A';
      }
      if (!!this.staffMobile) {
        bodyText += this.staffMobile + '%0D%0A';
      }
      if (!!this.staffEmail) {
        bodyText += this.staffEmail;
      }
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1) {
        if (!!this.workOrderPdfUrl && !!this.workOrderPdfUrl.length) {
          mailto += "&body=" + bodyText;
        }
      }
      else if (ua.indexOf("android") > -1 || ua.indexOf("android") > -1) {
        if (!!this.workOrderPdfUrl && !!this.workOrderPdfUrl.length) {
          mailto += "&body=";
        }
      }
      else {
        if (!!this.workOrderPdfUrl && !!this.workOrderPdfUrl.length) {
          mailto += "&body=" + bodyText;
        }
      }
    }
    return mailto;
  }
  onCopyLinkPDF() {
    if (!!this.workOrderPdfUrl && !!this.workOrderPdfUrl.length) {
      var bodyText: string = '';
      bodyText = 'Dear Sir/Madam \r\n\r\nPlease find attached the work order link for ' + this.managementAgreement.addressText + '\r\n\r\n' + this.workOrderPdfUrl + '\r\n\r\n' +
        'If you have any queries, please do not hesitate to contact me. \r\n\r\n';

      if (!!this.mailmergeName) {
        bodyText += 'Regards, \r\n' + this.mailmergeName + '\r\n';
      }
      if (!!this.staffMobile) {
        bodyText += this.staffMobile + '\r\n';
      }
      if (!!this.staffEmail) {
        bodyText += this.staffEmail;
      }
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      //selBox.value = this.workOrderPdfUrl;
      selBox.value = bodyText;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.snackBar.open("Link Copied", "Close", this.config);
    }
  }

  ngOnDestroy() {
    if (!!this.subscription_GetSupplierServiceTypes) this.subscription_GetSupplierServiceTypes.unsubscribe();
    if (!!this.subscription_GetSupplierInfo) this.subscription_GetSupplierInfo.unsubscribe();
    if (!!this.saveWorkOrder_Subscription) this.saveWorkOrder_Subscription.unsubscribe();
    if (!!this.subscription_GetOnSiteContactDetails) this.subscription_GetOnSiteContactDetails.unsubscribe();
    if (!!this.subscription_GetCompanyPersons) this.subscription_GetCompanyPersons.unsubscribe();
    if (!!this.subcription_GetPropertyAddressByAddressString) this.subcription_GetPropertyAddressByAddressString.unsubscribe();
    if (!!this.subcription_GetMaintenance) this.subcription_GetMaintenance.unsubscribe();
    if (!!this.subscription_RemoveWorkOrderImage) this.subscription_RemoveWorkOrderImage.unsubscribe();
  }

  sanitizeSMS(mobile: string) {
    return this.sanitizer.bypassSecurityTrustUrl("sms:" + mobile);
  }
  //#region Property Autocomplete
  onPropertyChange(property: IRentalTenantPropertyAddress) {
    if (!property) return;
    this.selectedProperty = property;
    this.model.controls.propertyName.setValue(property.propertyAddress);
    this._loader.show();
    this.subscription_GetManagementAgreements =
      this._service.GetManagementAgreements
        (
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          property.managementAgreementID
        )
        .subscribe((data: ServiceResponse<IManagementAgreement[]>) => {
          this._loader.hide();
          if (data.isError) {
            this.openDialog("Error", data.message, "alertdialog");
          }
          else {
            if (!!data.response && !!data.response.length) {
              this.managementAgreement = data.response[0];
              this.model.controls.propertyName.setValue(property.propertyAddress);
              this.initialize();
            }
          }
        },
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          });
  }
  onPropertyDeselect() {
    this.selectedProperty = null;
    this.model.controls.propertyName.setValue(null);
    this.managementAgreement = null;
    this.tenantContact = null;
  }
  //#endregion

  //#region Deep Link
  closeDeeplinkBrowser(deepLinkNewWindow: Window | null) {
    if (!!deepLinkNewWindow) {
      setTimeout(() => {
        deepLinkNewWindow.close();
      }, 25000);
    }
    return false;
  }
  openDeepLink(deeplink: string) {
    return window.open(deeplink, '_blank');
  }
  deepLink(database: string, agencyID: string, departmentID: string, workOrderID: string, emailTemplateType: string = "WorkOrderForSupplier"): string {
    let link: string = '';
    if (!!database && !!workOrderID && workOrderID.indexOf('00000000-') < 0) {
      link = `multiarray:rem/db/${database}/agency/${agencyID}/Email/FromTemplate?DepartmentID=${departmentID}&EmailTemplateType=${emailTemplateType}&RecipientSourceType=WorkOrder&RecipientSourceIDs=${workOrderID}&open=true`;
    }
    return link;
  }
  //#endregion

  //#region Photo upload
  uploadPicture(event) {
    if (!event.target.files || !event.target.files[0]) return;
    var file = event.target.files[0];
    let fr = new FileReader();

    if (event.target.id == "woImgInput1") {
      this.photo1FileName = file.name;
      fr.addEventListener("load", function (evt: any) {
        document.getElementById('woImg1').setAttribute("src", evt.target.result.toString());

        //document.querySelector("#Photo1").textContent = evt.target.result;
        //$("#Photo1").val(evt.target.result);
      });
    }
    else if (event.target.id == "woImgInput2") {
      this.photo2FileName = file.name;
      fr.addEventListener("load", function (evt: any) {
        document.getElementById('woImg2').setAttribute("src", evt.target.result.toString());
      });
    }
    else if (event.target.id == "woImgInput3") {
      this.photo3FileName = file.name;
      fr.addEventListener("load", function (evt: any) {
        document.getElementById('woImg3').setAttribute("src", evt.target.result.toString());
      });
    }


    fr.readAsDataURL(file);
  }
  onPhotoRemoveClick(imageID: string) {
    if (!!imageID && imageID.indexOf('00000000-') <= 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        data: {
          title: "Confirmation",
          message: `Do you want to delete this image?`
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.showWaitLoader = true;
          this.subscription_RemoveWorkOrderImage =
            this._service.RemoveWorkOrderImage(imageID)
              .subscribe((result) => {
                if (result) {
                  this.openDialog("Success", "Image deleted successfully", "alertdialog");
                  this.initialize();
                }
                else {
                  this.openDialog("Error", "Image deletion failed", "alertdialog");
                }
                this.showWaitLoader = false;
              }, error => {
                this.showWaitLoader = false;
                this.openDialog("Alert", error, "alertdialog");
              });

        }
      });
    }
  }
  //#endregion
}
