import { Http } from "@angular/http";
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { state } from "@angular/animations";
import {
  Router,
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs/Observable";
import { AuthService, SessionInfo } from "../services/authservice";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";

import { NgRedux, select } from "@angular-redux/store";
import { IAppState } from "../store/store";

@Injectable()
export class ResidentialGuard implements CanActivate, CanActivateChild {
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _http: Http,
    private _service: AuthService,
    private _router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (isPlatformBrowser(this.platformId)) {
      return this._service
        .GetSessionData()
        .map((data: SessionInfo) => {
          if (data.categoryID != 1) {
            if (data.trisetSystemID == 8 && data.categoryID == 1) {
              this._router.navigate(['/dashboard']);
            }
            else {
              this._router.navigate(['/listingenquirystatus']);
            }
          }          
          //return data.categoryID == 1;
          return data.trisetSystemID == 8 && data.categoryID == 1;
        })
        .catch(() => {
          this._router.navigate(['/dashboard']);
          return Observable.of(false);
        });
    } else {
      this._router.navigate(['/dashboard']);
      return Observable.of(false);
    }
  }

  canActivateChild() {
    console.log("checking child route access");
    return true;
  }
}


@Injectable()
export class CommercialGuard implements CanActivate, CanActivateChild {
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _http: Http,
    private _service: AuthService,
    private _router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (isPlatformBrowser(this.platformId)) {
      return this._service
        .GetSessionData()
        .map((data: SessionInfo) => {
          if (data.categoryID != 2) {
            if (data.trisetSystemID == 2 && data.categoryID == 1) {
              this._router.navigate(['/dashboard']);
            }
            else {
              this._router.navigate(['/listingenquirystatus']);
            }
          }          
          return data.categoryID == 2;
        })
        .catch(() => {
          this._router.navigate(['/dashboard']);
          return Observable.of(false);
        });
    } else {
      this._router.navigate(['/dashboard']);
      return Observable.of(false);
    }
  }

  canActivateChild() {
    console.log("checking child route access");
    return true;
  }
}
