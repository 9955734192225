import { ISearchOpenPrivateInspections } from './ofi';
import { IOfiState, INITIAL_OFI_STATE } from './ofi.state';
import { OfiAction, enActionTypes, ShowAddPrivateInspectionPage } from './ofi.action';
import { Reducer, Action } from "redux";

export const ofiReducer: Reducer<IOfiState> = (state = INITIAL_OFI_STATE(), action: OfiAction): IOfiState => {
    switch(action.type) {
        case enActionTypes.ShowOpenPrivateInspectionSearchPageAction: {
            return {
                ...state,
                openprivateinspections: {
                    search: Object.assign({}, state.openprivateinspections.search, {showSearchResult: false, showAddPrivateInspection: false})
                }
            }
        }
        case enActionTypes.GetCurrentStaffsOpenPrivateInspectionSearchPageAction: {
            return {
                ...state,
                openprivateinspections: {
                    search: Object.assign({}, state.openprivateinspections.search, { departments: action.payload.departments, staffIDs: action.payload.staffs })
                }
            }
        }
        case enActionTypes.GetAppointmentsOpenPrivateInspectionSearchPageAction: {
            return {
                ...state,
                openprivateinspections: {
                    search: Object.assign({}, state.openprivateinspections.search, { appointments: action.payload, showSearchResult: true})
                }
            }
        }
        case enActionTypes.CloseOpenPrivateInspectionResultPageAction: {
            return {
                ...state,
                openprivateinspections: {
                    search: Object.assign({}, state.openprivateinspections.search, { showSearchResult: false})
                }
            }
        }
        case enActionTypes.ShowAddPrivateInspectionPageAction: {
            return {
                ...state,
                openprivateinspections: {                    
                    search: Object.assign({}, state.openprivateinspections.search, { showAddPrivateInspection: true})
                }
            }
        }
        case enActionTypes.CloseAddPrivateInspectionPageAction: {
            return {
                ...state,
                openprivateinspections: {
                    search: Object.assign({}, state.openprivateinspections.search, { showSearchResult: false, showAddPrivateInspection: false})
                }
            }
        }
        default:
          return state;
    }
}