import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { IAppState } from "../../store/store";
import { PropertyManagementService } from "./../propertymanagement.service";
import { LoaderService } from "./../../core/loader/loader.service";
import { IDNamePair, AuthService, ServiceResponse, IDNameExtraPair, SessionInfo } from "./../../services/authservice";
import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";
import { ILeaseRenewalSearch, ILeaseRenewalSearchResult, ILeaseRenewalPropertyAddress } from '../propertymanagement';
import { GetLeaseRenewalSearch, CloseLeaseRenewalResultPage } from '../propertymanagement.action';
import { DataTransferService } from '../../services/datatransferservice';

@Component({
  selector: 'app-leaserenewal',
  templateUrl: './leaserenewal.component.html',
  styleUrls: ['./leaserenewal.component.scss']
})
export class LeaseRenewalComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: PropertyManagementService,
    private _authService: AuthService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private dataTransfer: DataTransferService) {
    let now = new Date();
    let dateFrom = new Date();
    let dateTo = new Date();
    //dateFrom = new Date(dateFrom.setMonth(dateFrom.getMonth() - 1));
    //dateTo = new Date(dateTo.setMonth(dateTo.getMonth() + 4));
    dateTo = new Date(dateTo.setMonth(dateTo.getMonth() + 3));

    this.model = new FormGroup({
      selectedPropertyManagerIDs: new FormControl(null),
      leaseEndDateFrom: new FormControl(/*`${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString().padStart(2, "0")}-${dateFrom.getDate().toString().padStart(2, "0")}`*/dateFrom.toYYYYMMDD(), Validators.required),
      leaseEndDateTo: new FormControl(/*`${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 6).toString().padStart(2, "0")}-${dateFrom.getDate().toString().padStart(2, "0")}`*/dateTo.toYYYYMMDD(), Validators.required),
      processCompleted: new FormControl(false),
      bypass: new FormControl(false),
      convertPeriodicLeaseToNormalLease: new FormControl(false),
      autoRefreshInMinute: new FormControl(5),
      propertyAddress: new FormControl(null)
      //leaseEndDateBeforeFromDate: new FormControl(false, Validators.required),
    });

  }
  //#region Settings
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  //#region Snackbar Config
  private snackbarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //#region
  //#region Multi-Select Dropdown Settings
  private dropdownSettings: any = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  //#endregion
  //#endregion
  //#region Private Variables
  private model: FormGroup;
  private dialogRef: any;
  private staffIDs: IDNamePair<string>[];
  private propertyManagerIDs: IDNameExtraPair<string, boolean>[];
  private autoRefreshIntervalHandler: any;
  private lastAutoRefreshTime: Date;
  private rmsettingPeriodInWeeksShownOnDesktop: number;
  private propertyAddresses$: Observable<ILeaseRenewalPropertyAddress[]>;
  private selectedPropertyAddress: ILeaseRenewalPropertyAddress;
  private loggedInSession: SessionInfo;
  private isPropertyManagerDropdownDisable: boolean = false;
  private agencyID: string;
  private staffID: string;
  //#endregion

  //#region Subcription Variables
  private getSessionData_Subcription: Subscription;
  private getLeaseRenewalPropertyManagers_Subcription: Subscription;
  private getLeaseRenewals_Subcription: Subscription;
  private sharedData_Subcription: Subscription;
  private getRMSetting_Subcription: Subscription;
  private getStaffBySessionID_Subcription: Subscription;
  //#endregion

  //#region State Variables
  @select(state => (state as IAppState).propertymanagement.leaseRenewal.search.showSearchResult) showSearchResult$;
  //#endregion

  ngOnInit() {
    console.log("sessionID: ", this.sessionID);
    this._loader.show();

    if (!this.departmentID) {
      this.getSessionData_Subcription = this._authService.GetSessionData()
        .subscribe(session => {
          this.loggedInSession = session;
          if (!!session) {
            this.getRMSetting_Subcription = this._authService.GetRMSetting(this.database, session.departmentID)
              .subscribe(rms => {
                if (!!rms && !rms.isError && !!rms.response) {
                  if (rms.response.leaseRenewalHomePageRefreshInMinutes >= 0)
                    this.model.controls.autoRefreshInMinute.setValue((rms.response.leaseRenewalHomePageRefreshInMinutes));

                  this.rmsettingPeriodInWeeksShownOnDesktop = (!!rms.response.rmsettingPeriodInWeeksShownOnDesktop ? rms.response.rmsettingPeriodInWeeksShownOnDesktop : 16);
                  let dateTo: Date = new Date(this.model.controls.leaseEndDateFrom.value);
                  dateTo = new Date(dateTo.setDate(dateTo.getDate() + (this.rmsettingPeriodInWeeksShownOnDesktop * 7)));
                  this.model.controls.leaseEndDateTo.reset();
                  this.model.controls.leaseEndDateTo.setValue(dateTo.toYYYYMMDD());
                  this.model.controls.leaseEndDateTo.updateValueAndValidity();
                }
              });
          }
        });
    }
    else {
      this.getRMSetting_Subcription = this._authService.GetRMSetting(this.database, this.departmentID)
        .subscribe(rms => {
          if (!!rms && !rms.isError && !!rms.response) {
            if (rms.response.leaseRenewalHomePageRefreshInMinutes >= 0)
              this.model.controls.autoRefreshInMinute.setValue((rms.response.leaseRenewalHomePageRefreshInMinutes));

            this.rmsettingPeriodInWeeksShownOnDesktop = (!!rms.response.rmsettingPeriodInWeeksShownOnDesktop ? rms.response.rmsettingPeriodInWeeksShownOnDesktop : 16);
            let dateTo: Date = new Date(this.model.controls.leaseEndDateFrom.value);
            dateTo = new Date(dateTo.setDate(dateTo.getDate() + (this.rmsettingPeriodInWeeksShownOnDesktop * 7)));
            this.model.controls.leaseEndDateTo.reset();
            this.model.controls.leaseEndDateTo.setValue(dateTo.toYYYYMMDD());
            this.model.controls.leaseEndDateTo.updateValueAndValidity();
          }
        });

      this.getStaffBySessionID_Subcription = this._authService.GetStaffBySessionID(this.database, this.sessionID)
        .subscribe(staff => {
          if (!!staff && !staff.isError && !!staff.response) {
            this.agencyID = staff.response.agencyID;
            this.staffID = staff.response.id;
          }
        });
    }

    this.getLeaseRenewalPropertyManagers_Subcription =
      this._service.GetLeaseRenewalPropertyManagers(this.database, this.sessionID)
        .subscribe((data: ServiceResponse<IDNameExtraPair<string, boolean>[]>) => {
          if (!data.isError) {
            this.propertyManagerIDs = data.response;
            let loggedInStaff = data.response.find(pm => pm.extra);
            if (!!loggedInStaff) {
              this.model.controls.selectedPropertyManagerIDs.setValue([loggedInStaff]);
            }
            else {
              this.model.controls.selectedPropertyManagerIDs.setValue(data.response);
            }
          }
          else {
            if (this.calledByREM) {
              console.error(data.message)
            }
            else {
              this.snackBar.open(data.message, "Close", this.snackbarConfig);
            }
          }
          this._loader.hide();
        }),
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      }

    setInterval(() => {

      if (!!this.lastAutoRefreshTime) {
        this.dataTransfer.data$.subscribe(sd => {
          if (!!sd && sd.lastAutoRefreshTime > this.lastAutoRefreshTime) {
            this.lastAutoRefreshTime = sd.lastAutoRefreshTime;
          }
        });
      }

    }, 2000);

    let propertyAddresses = this.model.controls.propertyAddress.valueChanges.pipe(
      startWith(''),
      map(value => this.getPropertyAddresses(value || ''))
    ).subscribe(result => {
      this.propertyAddresses$ = result;
    });

  }
  ngAfterViewInit() {
    LoadScript.init();
  }
  ngOnDestroy() {
    if (!!this.getSessionData_Subcription) this.getSessionData_Subcription.unsubscribe();
    if (!!this.getLeaseRenewalPropertyManagers_Subcription) this.getLeaseRenewalPropertyManagers_Subcription.unsubscribe();
    if (!!this.getLeaseRenewals_Subcription) this.getLeaseRenewals_Subcription.unsubscribe();
    if (!!this.getRMSetting_Subcription) this.getRMSetting_Subcription.unsubscribe();
    if (!!this.getStaffBySessionID_Subcription) this.getStaffBySessionID_Subcription.unsubscribe();
  }

  //#region Properties
  get calledByREM(): boolean {
    return (this.route.routeConfig.path.indexOf("leaserenewal") >= 0 && !!this.route.snapshot.paramMap.get("sessionID") && this.route.snapshot.paramMap.get("sessionID").indexOf('00000000-') < 0);
  }
  get database(): string {
    if (this.route.routeConfig.path.indexOf("leaserenewal") >= 0 && !!this.route.snapshot.paramMap.get("database")) {
      return this.route.snapshot.paramMap.get("database");
    }
    else {
      return null;
    }
  }
  get sessionID(): string {
    if (this.route.routeConfig.path.indexOf("leaserenewal") >= 0 && !!this.route.snapshot.paramMap.get("sessionID") && this.route.snapshot.paramMap.get("sessionID").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("sessionID");
    }
    else {
      return null;
    }
  }
  get departmentID(): string {
    if (this.route.routeConfig.path.indexOf("leaserenewal") >= 0 && !!this.route.snapshot.paramMap.get("departmentID") && this.route.snapshot.paramMap.get("departmentID").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("departmentID");
    }
    else {
      return null;
    }
  }
  /*get departmentID(): string {
    if (this.route.routeConfig.path.indexOf("leaserenewal") >= 0 && !!this.route.snapshot.paramMap.get("departmentID") && this.route.snapshot.paramMap.get("departmentID").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("departmentID");
    }
    else {
      //return this.ngRedux.getState().login.departmentID;
      return null;
    }
  }*/
  get leaseRenewalUrl() {
    let u: string;
    u = `/leaserenewal/${this.database}/${this.sessionID}/${this.departmentID}`;
    return u;
  }
  get newLeasesCountUrl() {
    let u: string;
    u = `/newleasescount/${this.database}/${this.sessionID}/${this.departmentID}`;
    return u;
  }
  get leaseRenewalStatisticsUrl() {
    let u: string;
    u = `/leaserenewalstatistics/${this.database}/${this.sessionID}/${this.departmentID}`;
    return u;
  }
  //#endregion

  //#region Events
  onFromDateChange(event: any) {
    if (!!event && !!event.target && !!event.target.value) {
      let dateFrom: Date = new Date(event.target.value);
      if (!!this.model.controls.leaseEndDateTo.value) {
        if (dateFrom > new Date(this.model.controls.leaseEndDateTo.value)) {
          let dateTo = dateFrom;
          dateTo = new Date(dateTo.setDate(dateTo.getDate() + 14));
          this.model.controls.leaseEndDateTo.setValue(dateTo.toYYYYMMDD());
        }
      }
      /*let dateTo: Date = new Date(event.target.value);      
      dateTo = new Date(dateTo.setDate(dateTo.getDate() + (this.rmsettingPeriodInWeeksShownOnDesktop * 7)));
      this.model.controls.leaseEndDateTo.setValue(dateTo.toUtcYYYYMMDD());*/
    }
  }
  onToDateChange(event: any) {
    if (!!event && !!event.target && !!event.target.value) {
      let dateTo: Date = new Date(event.target.value);
      if (dateTo < new Date(this.model.controls.leaseEndDateFrom.value)) {
        this.model.controls.leaseEndDateTo.setValue(null);
      }
    }
  }
  onAutoRefreshMinuteChange(event: any) {
    if (!!event && !!event.target && !!event.target.value) {
      if (!(parseInt(event.target.value) >= 5 && parseInt(event.target.value) <= 120)) {
        this.model.controls.autoRefreshInMinute.setValue(5);
      }
      this.startAutoRefresh();
    }
    else {
      clearInterval(this.autoRefreshIntervalHandler);
    }
  }
  onPropertyAddressChange(property: ILeaseRenewalPropertyAddress) {
    this.selectedPropertyAddress = property;
    if (!!this.selectedPropertyAddress) {
      this.model.controls.propertyAddress.setValue(`${property.textFormatLS} - ${property.primaryJointTenant}`);
      this.model.controls.selectedPropertyManagerIDs.setValue(null);
    }
  }
  onPropertyAddressClearClick() {
    this.selectedPropertyAddress = null;
    this.model.controls.propertyAddress.setValue(null);
  }
  onPeriodicLeaseChange(event: any) {
    this.onSubmit();
  }
  //#endregion

  //#region Functions
  sharedHandlerCleared: boolean = false;
  startAutoRefresh() {
    if (!!this.model.controls.propertyAddress.value) {
      return;
    }
    clearInterval(this.autoRefreshIntervalHandler);

    if (!!this.model.controls.autoRefreshInMinute.value) {
      this.autoRefreshIntervalHandler = setInterval(() => {
        this.onSubmit();
      }, 1000 * 60 * parseInt(this.model.controls.autoRefreshInMinute.value));
    }

    //#region Share data to child route component
    //Property Managers
    let prManagerIDs: string[] = null;
    if (!!this.model.controls.selectedPropertyManagerIDs.value) {
      let selectedPropertyManagerIDs: IDNameExtraPair<string, boolean>[] = this.model.controls.selectedPropertyManagerIDs.value;
      if (!!selectedPropertyManagerIDs && !!selectedPropertyManagerIDs.length) {
        prManagerIDs = selectedPropertyManagerIDs.map(item => { return item.id });
      }
    }

    //Lease End Date From
    let leaseEndDateFrom: Date | null = null;
    if (!!this.model.controls.leaseEndDateFrom.value) {
      leaseEndDateFrom = this.model.controls.leaseEndDateFrom.value;
      console.log("leaseEndDateFrom: ", leaseEndDateFrom);
    }

    //Next Months
    let leaseEndDateTo: Date | null = null;
    if (!!this.model.controls.leaseEndDateTo.value) {
      leaseEndDateTo = this.model.controls.leaseEndDateTo.value;
      console.log("leaseEndDateTo: ", leaseEndDateTo);
    }

    //Process Completed
    let processCompleted: boolean = false;
    processCompleted = this.model.controls.processCompleted.value;
    console.log("processCompleted: ", processCompleted);

    //Include/Exclude Bypass
    let bypass: boolean = false;
    bypass = this.model.controls.bypass.value;
    console.log("bypass: ", bypass);

    let sharedData = {
      autoRefreshIntervalHandler: this.autoRefreshIntervalHandler,
      autoRefreshIntervalChildHandler: undefined,
      autoRefreshInMinute: this.model.controls.autoRefreshInMinute.value,
      prManagerIDs: !!this.selectedPropertyAddress ? null : prManagerIDs,
      leaseEndDateFrom: !!this.selectedPropertyAddress ? null : leaseEndDateFrom,
      leaseEndDateTo: !!this.selectedPropertyAddress ? null : leaseEndDateTo,
      processCompleted: !!this.selectedPropertyAddress ? true : processCompleted,
      bypass: !!this.selectedPropertyAddress ? true : bypass,
      lastAutoRefreshTime: this.lastAutoRefreshTime,
      propertyID: null,
      convertPeriodicLeaseToNormalLease: this.model.controls.convertPeriodicLeaseToNormalLease.value
    };
    this.shareData(sharedData);
    //#endregion

  }
  shareData(data) {
    this.dataTransfer.sendAnything(data) // to send something
  }
  getPropertyAddresses(filter: string): Observable<ILeaseRenewalPropertyAddress[]> {
    if (!filter) {
      this.selectedPropertyAddress = null;
      this.filterControlsActivate(true);
      return;
    }
    else {
      this.filterControlsActivate(false);
    }
    if (!!this.sessionID && !!this.departmentID) {
      return this._service
        .GetLeaseRenewalPropertyAddress(this.database, this.sessionID, this.departmentID, filter)
        .map((data) => {
          if (!!data && !data.isError) {
            return data.response
          }
          else if (!!data && data.isError) {
            Observable.throwError(data.message);
          }
          else {
            Observable.throwError("Error in fetching data!");
          }
        });
    }
    else {
      if (!!this.loggedInSession) {
        return this._service
          .GetLeaseRenewalPropertyAddress(this.database, this.loggedInSession.id, this.loggedInSession.departmentID, filter)
          .map((data) => {
            if (!!data && !data.isError) {
              return data.response
            }
            else if (!!data && data.isError) {
              Observable.throwError(data.message);
            }
            else {
              Observable.throwError("Error in fetching data!");
            }
          });
      }
    }

  }
  filterControlsActivate(enable: boolean) {
    this.isPropertyManagerDropdownDisable = !enable;
    if (enable) {
      this.model.controls.selectedPropertyManagerIDs.enable();
      this.model.controls.leaseEndDateFrom.enable();
      this.model.controls.leaseEndDateTo.enable();
      this.model.controls.processCompleted.enable();
      this.model.controls.bypass.enable();
      this.model.controls.autoRefreshInMinute.enable();
    }
    else {
      this.model.controls.selectedPropertyManagerIDs.disable();
      this.model.controls.leaseEndDateFrom.disable();
      this.model.controls.leaseEndDateTo.disable();
      this.model.controls.processCompleted.disable();
      this.model.controls.bypass.disable();
      this.model.controls.autoRefreshInMinute.disable();
    }
  }
  //#endregion
  onSubmitClick() {
    this.sharedData_Subcription =
      this.dataTransfer.data$.subscribe(sd => {
        if (!!sd) {
          clearInterval(sd.autoRefreshIntervalChildHandler);
          if (!!this.sharedData_Subcription) this.sharedData_Subcription.unsubscribe();
        }
      });
    if (!!this.sharedData_Subcription) this.sharedData_Subcription.unsubscribe();
    this.onSubmit();
  }
  onSubmit() {
    //Property Managers
    let prManagerIDs: string[] = null;
    if (!!this.model.controls.selectedPropertyManagerIDs.value) {
      let selectedPropertyManagerIDs: IDNameExtraPair<string, boolean>[] = this.model.controls.selectedPropertyManagerIDs.value;
      if (!!selectedPropertyManagerIDs && !!selectedPropertyManagerIDs.length) {
        prManagerIDs = selectedPropertyManagerIDs.map(item => { return item.id });
      }
      //else {
      //  if (!!this.ngRedux.getState().ofi.openprivateinspections.search.staffIDs
      //    && !!this.ngRedux.getState().ofi.openprivateinspections.search.staffIDs.length) {
      //    stffIDs = this.ngRedux.getState().ofi.openprivateinspections.search.staffIDs.map(item => { return item.id });
      //  }
      //}
      console.log("prManagerIDs: ", prManagerIDs);
    }

    //Lease End Date From
    let leaseEndDateFrom: Date | null = null;
    if (!!this.model.controls.leaseEndDateFrom.value) {
      leaseEndDateFrom = this.model.controls.leaseEndDateFrom.value;
      console.log("leaseEndDateFrom: ", leaseEndDateFrom);
    }

    //Next Months
    let leaseEndDateTo: Date | null = null;
    if (!!this.model.controls.leaseEndDateTo.value) {
      leaseEndDateTo = this.model.controls.leaseEndDateTo.value;
      console.log("leaseEndDateTo: ", leaseEndDateTo);
    }

    //Process Completed
    let processCompleted: boolean = false;
    processCompleted = this.model.controls.processCompleted.value;
    console.log("processCompleted: ", processCompleted);

    //Include/Exclude Bypass
    let bypass: boolean = false;
    bypass = this.model.controls.bypass.value;
    console.log("bypass: ", bypass);

    //#region Refresh Search Result Grid
    let action = new CloseLeaseRenewalResultPage(true);
    this.ngRedux.dispatch({ type: action.type });
    if (!!this.database && !!this.database.length) {
      this._router.navigate([`/leaserenewal/${this.database}/${this.sessionID}/${this.departmentID}`]);
    }
    else {
      this._router.navigate(["/leaserenewal"]);
    }
    //#endregion

    this._loader.show();
    if (!!this.sessionID && !!this.departmentID) {
      this.getLeaseRenewals_Subcription =
        this._service
          .GetLeaseRenewals
          (
            this.database,
            this.sessionID,
            this.departmentID,
            !!this.selectedPropertyAddress ? null : leaseEndDateFrom,
            !!this.selectedPropertyAddress ? null : leaseEndDateTo,
            !!this.selectedPropertyAddress ? true : processCompleted,
            !!this.selectedPropertyAddress ? true : bypass,
            !!this.selectedPropertyAddress ? null : prManagerIDs,
            !this.selectedPropertyAddress ? null : this.selectedPropertyAddress.propertyID,
            this.model.controls.convertPeriodicLeaseToNormalLease.value
          )
          .map((data: ServiceResponse<ILeaseRenewalSearchResult[]>) => {
            if (!data.isError) {
              return new GetLeaseRenewalSearch(data.response);
            }
            else {
              Observable.throwError(data.message);
              this.openDialog("Error", data.message, "alertdialog");
            }
          })
          .subscribe(action => {
            if (!!action && !!action.payload && !!action.payload.length) {
              this.lastAutoRefreshTime = new Date();
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
              console.log("action.payload: ", action.payload);
              if (!!this.database)
                //this._router.navigate([`/leaserenewal/${this.database}/${this.sessionID}/${this.departmentID}/search`]);
                this._router.navigate([`/leaserenewal/${this.database}/${this.sessionID}/${this.departmentID}/search`]);
              else
                this._router.navigate([`/leaserenewal/search`]);
            }
            else {
              if (this.calledByREM) {
                console.log("No record found");
              }
              else {
                this.snackBar.open("No record found", "Close", this.snackbarConfig);
              }
            }
            this._loader.hide();
          }),
        error => {
          this._loader.hide();
          this.openDialog("Alert", error, "alertdialog");
        }
    }
    else {
      this.getSessionData_Subcription = this._authService.GetSessionData()
        .subscribe(session => {

          this.getLeaseRenewals_Subcription =
            this._service
              .GetLeaseRenewals
              (
                this.database,
                session.id,
                session.departmentID,
                !!this.selectedPropertyAddress ? null : leaseEndDateFrom,
                !!this.selectedPropertyAddress ? null : leaseEndDateTo,
                !!this.selectedPropertyAddress ? true : processCompleted,
                !!this.selectedPropertyAddress ? true : bypass,
                !!this.selectedPropertyAddress ? null : prManagerIDs,
                !this.selectedPropertyAddress ? null : this.selectedPropertyAddress.propertyID,
                this.model.controls.convertPeriodicLeaseToNormalLease.value
              )
              .map((data: ServiceResponse<ILeaseRenewalSearchResult[]>) => {
                if (!data.isError) {
                  return new GetLeaseRenewalSearch(data.response);
                }
                else {
                  Observable.throwError(data.message);
                  this.openDialog("Error", data.message, "alertdialog");
                }
              })
              .subscribe(action => {
                if (!!action && !!action.payload && !!action.payload.length) {
                  this.lastAutoRefreshTime = new Date();
                  this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                  console.log("action.payload: ", action.payload);
                  if (!!this.database)
                    //this._router.navigate([`/leaserenewal/${this.database}/${this.sessionID}/${this.departmentID}/search`]);
                    this._router.navigate([`/leaserenewal/${this.database}/${this.sessionID}/${this.departmentID}/search`]);
                  else
                    this._router.navigate([`/leaserenewal/search`]);
                }
                else {
                  if (this.calledByREM) {
                    console.log("No record found");
                  }
                  else {
                    this.snackBar.open("No record found", "Close", this.snackbarConfig);
                  }
                }
                this._loader.hide();
              }),
            error => {
              this._loader.hide();
              this.openDialog("Alert", error, "alertdialog");
            }

        });
    }
    this.startAutoRefresh();


  }
}
