import { Action } from 'redux';
import { IDNamePair } from "../services/authservice";
import { ISearchOpenPrivateInspections, IAppointmentDetailsSearchResult, IDepartmentDto } from './ofi';

export enum enActionTypes {
    ShowOpenPrivateInspectionSearchPageAction = "[OPEN PRIVATE INSPECTION] Shows Open Private Inspection Search Page Action",
    GetCurrentStaffsOpenPrivateInspectionSearchPageAction = "[OPEN PRIVATE INSPECTION] Fetches The Current Staffs For Filling Dropdown In Open Private Inspection Search Page Action",
    GetAppointmentsOpenPrivateInspectionSearchPageAction = "[OPEN PRIVATE INSPECTION] Searches Appointments And Returns The Result Action",
    CloseOpenPrivateInspectionResultPageAction = "[OPEN PRIVATE INSPECTION] Closes Open Private Inspection Search Result Page And Moves To Open Private Inspection Search Page",
    ShowAddPrivateInspectionPageAction = "[OPEN PRIVATE INSPECTION] Shows Add New Private Inspection Page Action",
    CloseAddPrivateInspectionPageAction = "[OPEN PRIVATE INSPECTION] Closes Add Open Private Inspection Page And Moves To Open Private Inspection Search Page",
}

//#region Open Private Inspection
export class ShowOpenPrivateInspectionSearchPage implements Action {
    readonly type = enActionTypes.ShowOpenPrivateInspectionSearchPageAction;
    constructor() {}
}
export class GetCurrentStaffsOpenPrivateInspectionSearchPage implements Action {
    readonly type = enActionTypes.GetCurrentStaffsOpenPrivateInspectionSearchPageAction;
    constructor(public readonly payload: { departments: IDepartmentDto[], staffs: IDNamePair<string>[] }){}
}
export class GetAppointmentsOpenPrivateInspectionSearchPage implements Action {
    readonly type = enActionTypes.GetAppointmentsOpenPrivateInspectionSearchPageAction;
    constructor(public readonly payload: IAppointmentDetailsSearchResult[]){}
}
export class CloseOpenPrivateInspectionResultPage implements Action{
    readonly type = enActionTypes.CloseOpenPrivateInspectionResultPageAction;
    constructor(public readonly payload:boolean){}
}
export class ShowAddPrivateInspectionPage implements Action{
    readonly type = enActionTypes.ShowAddPrivateInspectionPageAction;
    constructor() {}
}
export class CloseAddPrivateInspectionPageActionPage implements Action {
    readonly type = enActionTypes.CloseAddPrivateInspectionPageAction;
    constructor() {}
}
//#endregion

export type OfiAction = 
ShowOpenPrivateInspectionSearchPage
| GetCurrentStaffsOpenPrivateInspectionSearchPage
| GetAppointmentsOpenPrivateInspectionSearchPage
| CloseOpenPrivateInspectionResultPage
| ShowAddPrivateInspectionPage
| CloseAddPrivateInspectionPageActionPage;