import { Action } from 'redux';
import { 
    IDashboardItem, 
    IDashboard, 
    IPropertiesTenantsInArrearsDetails, 
    IPropertiesLeaseExpiryOverdueDetails, 
    IPropertiesVacantDetails, 
    IPropertiesGainedDetails, 
    IPropertiesLostDetails, 
    IPropertiesTenantInvoicesOverdueWithinDaysDetails, 
    IPropertiesPeriodicLeasesDetails,
    IPropertiesLeasedDaysDetails,
    IPropertiesLeaseRenewedDaysDetails,
    IPropertiesUnderManagementDetails,
    IPropertiesInspectionOverdueDetails,
    IPropertiesInspectionDueDetails,
    IPropertiesTenantVacatingWithinDaysDetails,
    IPropertiesOutstandingWorkOrderDaysDetails,
    IPropertiesAgreementRenewalDueDetails,
    IPropertiesAgreementExpiredDetails
} from './home';

export enum enActionTypes {
    GetPropertiesUnderManagementAction = "[DASHBOARD MANAGEMENT] Get Property Management Summery Information",
    GetPropertiesGainedAction = "[DASHBOARD MANAGEMENT] Get Property Gained Summery Information",
    GetPropertiesLostAction = "[DASHBOARD MANAGEMENT] Get Property Lost Summery Information",
    GetPropertiesAgreementRenewalDueAction = "[DASHBOARD MANAGEMENT] Get Property Agreement Renewal Due Summery Information",
    GetPropertiesVacantLessThan14Action = "[DASHBOARD MANAGEMENT] Get Property Vacant Less Then 14 Summery Information",
    GetPropertiesVacantGraterThan14Action = "[DASHBOARD MANAGEMENT] Get Property Vacant Grater Then 14 Summery Information",
    GetPropertiesPeriodicLeasesAction = "[DASHBOARD MANAGEMENT] Get Property Periodic Leases Summery Information",
    GetPropertiesAgreementExpiredAction = "[DASHBOARD MANAGEMENT] Get Property Agreement Expired Summery Information",
    GetPropertiesAverageWeeklyRentAction = "[DASHBOARD MANAGEMENT] Get Property Average Weekly Rent Summery Information",

    GetPropertiesOutstandingWorkOrder3To14DaysAction = "[DASHBOARD MANAGEMENT] Get Property Outstanding Work Order 3 to 14 Days",
    GetPropertiesOutstandingWorkOrderGraterThan14DaysAction = "[DASHBOARD MANAGEMENT] Get Property Outstanding Work Order Grater Than 14 Days",
    GetPropertiesLeaseRenewedDaysAction = "[DASHBOARD MANAGEMENT] Get Property Lease Renewed Days",
    GetPropertiesLeasedDaysAction = "[DASHBOARD MANAGEMENT] Get Property Lease Days",
    GetPropertiesTenantInvoicesOverdueWithin2To14DaysAction = "[DASHBOARD MANAGEMENT] Get Property Tenant Invoices Overdue Within 2 to 14 Days",
    GetPropertiesTenantInvoicesOverdueGreaterThan14DaysAction = "[DASHBOARD MANAGEMENT] Get Property Tenant Invoices Overdue Greater Than 14 Days",
    GetPropertiesLeaseExpiryOverdueAction = "[DASHBOARD MANAGEMENT] Get Property Lease Expiry Over Due",
    GetPropertiesLeaseExpiryDueWithinDaysAction = "[DASHBOARD MANAGEMENT] Get Property Lease Expiry Due Within Days" , 
    GetPropertiesTenantVacatingWithinDaysAction = "[DASHBOARD MANAGEMENT] Get Property Tenant Vacating Within Days",
    GetPropertiesInspectionOverdueAction = "[DASHBOARD MANAGEMENT] Get Property Inspection Over Due",
    GetPropertiesInspectionDueWithin7DaysAction = "[DASHBOARD MANAGEMENT] Get Property Inspection Due Within 7 Days",
    GetPropertiesInspectionDueWithin30DaysAction = "[DASHBOARD MANAGEMENT] Get Property Inspection Due Within 30 Days",
    GetPropertiesTenantsInArrearsGraterThan3Action = "[DASHBOARD MANAGEMENT] Get Property Tenants In Arrears Grater Than 3",
    GetPropertiesTenantsInArrearsGraterThan10Action = "[DASHBOARD MANAGEMENT] Get Property Tenants In Arrears Grater Than 10",
    GetPropertiesAverageCommissionPercentAction = "[DASHBOARD MANAGEMENT] Get Property Average Commission" ,

    GetPropertiesTenantsInArrearsGraterThan3DetailsAction = "[DASHBOARD MANAGEMENT] Get Property Tenants In Arrears Grater Than 3 Details",
    GetPropertiesTenantsInArrearsGraterThan10DetailsAction = "[DASHBOARD MANAGEMENT] Get Property Tenants In Arrears Grater Than 10 Details",
    GetPropertiesLeaseExpiryOverdueDetailsAction = "[DASHBOARD MANAGEMENT] Get Property Lease Expiry Overdue Details",
    GetPropertiesLeaseExpiryDueWithinDaysDetailsAction = "[DASHBOARD MANAGEMENT] Get Properties Lease Expiry Due Within 30 Days Details",
    GetPropertiesVacantLessThanEqual14DaysDetailsAction = "[DASHBOARD MANAGEMENT] Get Vacant Properties Less Than And Equal To 14 Days Details",
    GetPropertiesVacantGraterThan14DaysDetailsAction = "[DASHBOARD MANAGEMENT] Get Vacant Properties Greater Than 14 Days Details",
    GetPropertiesGainedLast6MonthsDetailsAction = "[DASHBOARD MANAGEMENT] Get Properties Gained In Last 6 Months Details",
    GetPropertiesLostLast6MonthsDetailsAction = "[DASHBOARD MANAGEMENT] Get Properties Lost In Last 6 Months Details",
    GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetailsAction = "[DASHBOARD MANAGEMENT] Get Overdue Tenant Invoices 2-14 days Details",
    GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetailsAction = "[DASHBOARD MANAGEMENT] Get Overdue Tenant Invoices Greater Than 14 days Details",
    GetPropertiesPeriodicLeasesDetailsAction = "[DASHBOARD MANAGEMENT] Get Properties Periodic Leases Details",
    GetPropertiesLeasedLast30DaysDetailsAction = "[DASHBOARD MANAGEMENT] Properties Leased Last 30 Days Details",
    GetPropertiesLeaseRenewedLast30DaysDetailsAction = "[DASHBOARD MANAGEMENT] Properties Lease Renewed Last 30 Days Details",
    GetPropertiesUnderManagementDetailsAction = "[DASHBOARD MANAGEMENT] Properties Under Management Details",
    GetPropertiesInspectionOverdueDetailsAction = "[DASHBOARD MANAGEMENT] Properties Inspection Overdue Details",
    GetPropertiesInspectionDueWithin7DaysDetailsAction = "[DASHBOARD MANAGEMENT] Properties Inspection Due Within 7 Days Details",
    GetPropertiesInspectionDueWithin30DaysDetailsAction = "[DASHBOARD MANAGEMENT] Properties Inspection Due Within 30 Days Details",
    GetPropertiesTenantVacatingWithin30DaysDetailsAction = "[DASHBOARD MANAGEMENT] Properties Tenant Vacating Within 30 Days Details",
    GetPropertiesOutstandingWorkOrderDue3To14DaysDetailsAction = "[DASHBOARD MANAGEMENT] Properties Outstanding Work Order Due 3 To 14 Days Details",
    GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetailsAction = "[DASHBOARD MANAGEMENT] Properties Outstanding Work Order Greater Than 14 Days Details",
    GetPropertiesAgreementRenewalDueWithin90DaysDetailsAction = "[DASHBOARD MANAGEMENT] Properties Agreement Renewal Due Within 90 Days Details",
    GetPropertiesAgreementExpiredDetailsAction = "[DASHBOARD MANAGEMENT] Properties Agreement Expired Details",
}

export class GetPropertiesUnderManagement implements Action {
    readonly type = enActionTypes.GetPropertiesUnderManagementAction;
    constructor(public readonly payload:IDashboardItem){}
}

export class GetPropertiesGained implements Action {
    readonly type = enActionTypes.GetPropertiesGainedAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesLost implements Action {
    readonly type = enActionTypes.GetPropertiesLostAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesAgreementRenewalDue implements Action {
    readonly type = enActionTypes.GetPropertiesAgreementRenewalDueAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesVacantLessThan14 implements Action {
    readonly type = enActionTypes.GetPropertiesVacantLessThan14Action;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesVacantGraterThan14 implements Action {
    readonly type = enActionTypes.GetPropertiesVacantGraterThan14Action;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesPeriodicLeases implements Action {
    readonly type = enActionTypes.GetPropertiesPeriodicLeasesAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesAgreementExpired implements Action {
    readonly type = enActionTypes.GetPropertiesAgreementExpiredAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesAverageWeeklyRent implements Action {
    readonly type = enActionTypes.GetPropertiesAverageWeeklyRentAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesOutstandingWorkOrder3To14Days implements Action {
    readonly type = enActionTypes.GetPropertiesOutstandingWorkOrder3To14DaysAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesOutstandingWorkOrderGraterThan14Days implements Action {
    readonly type = enActionTypes.GetPropertiesOutstandingWorkOrderGraterThan14DaysAction;
    constructor(public readonly payload: IDashboardItem){}
}


export class GetPropertiesLeaseRenewedDays implements Action {
    readonly type = enActionTypes.GetPropertiesLeaseRenewedDaysAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesLeasedDays implements Action {
    readonly type = enActionTypes.GetPropertiesLeasedDaysAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesTenantInvoicesOverdueWithin2To14Days implements Action {
    readonly type = enActionTypes.GetPropertiesTenantInvoicesOverdueWithin2To14DaysAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesTenantInvoicesOverdueGreaterThan14Days implements Action {
    readonly type = enActionTypes.GetPropertiesTenantInvoicesOverdueGreaterThan14DaysAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesLeaseExpiryOverdue implements Action {
    readonly type = enActionTypes.GetPropertiesLeaseExpiryOverdueAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesLeaseExpiryDueWithinDays implements Action {
    readonly type = enActionTypes.GetPropertiesLeaseExpiryDueWithinDaysAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesTenantVacatingWithinDays implements Action {
    readonly type = enActionTypes.GetPropertiesTenantVacatingWithinDaysAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesInspectionOverdue implements Action {
    readonly type = enActionTypes.GetPropertiesInspectionOverdueAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesInspectionDueWithin7Days implements Action {
    readonly type = enActionTypes.GetPropertiesInspectionDueWithin7DaysAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesInspectionDueWithin30Days implements Action {
    readonly type = enActionTypes.GetPropertiesInspectionDueWithin30DaysAction;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesTenantsInArrearsGraterThan3 implements Action {
    readonly type = enActionTypes.GetPropertiesTenantsInArrearsGraterThan3Action;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesTenantsInArrearsGraterThan10 implements Action {
    readonly type = enActionTypes.GetPropertiesTenantsInArrearsGraterThan10Action;
    constructor(public readonly payload: IDashboardItem){}
}

export class GetPropertiesAverageCommissionPercent implements Action {
    readonly type = enActionTypes.GetPropertiesAverageCommissionPercentAction;
    constructor(public readonly payload: IDashboardItem){}
}

//#region Dashboard Child Details
export class GetPropertiesTenantsInArrearsGraterThan3Details implements Action {
    readonly type = enActionTypes.GetPropertiesTenantsInArrearsGraterThan3DetailsAction;
    constructor(public readonly payload: IPropertiesTenantsInArrearsDetails[]){}
}
export class GetPropertiesTenantsInArrearsGraterThan10Details implements Action {
    readonly type = enActionTypes.GetPropertiesTenantsInArrearsGraterThan10DetailsAction;
    constructor(public readonly payload: IPropertiesTenantsInArrearsDetails[]){}
}
export class GetPropertiesLeaseExpiryOverdueDetails implements Action {
    readonly type = enActionTypes.GetPropertiesLeaseExpiryOverdueDetailsAction;
    constructor(public readonly payload: IPropertiesLeaseExpiryOverdueDetails[]){}
}
export class GetPropertiesLeaseExpiryDueWithinDaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesLeaseExpiryDueWithinDaysDetailsAction;
    constructor(public readonly payload: IPropertiesLeaseExpiryOverdueDetails[]){}
}
export class GetPropertiesVacantLessThanEqual14DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesVacantLessThanEqual14DaysDetailsAction;
    constructor(public readonly payload: IPropertiesVacantDetails[]){}
}
export class GetPropertiesVacantGraterThan14DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesVacantGraterThan14DaysDetailsAction;
    constructor(public readonly payload: IPropertiesVacantDetails[]){}
}
export class GetPropertiesGainedLast6MonthsDetails implements Action {
    readonly type = enActionTypes.GetPropertiesGainedLast6MonthsDetailsAction;
    constructor(public readonly payload: IPropertiesGainedDetails[]){}
}
export class GetPropertiesLostLast6MonthsDetails implements Action {
    readonly type = enActionTypes.GetPropertiesLostLast6MonthsDetailsAction;
    constructor(public readonly payload: IPropertiesLostDetails[]){}
}
export class GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetailsAction;
    constructor(public readonly payload: IPropertiesTenantInvoicesOverdueWithinDaysDetails[]){}
}
export class GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetailsAction;
    constructor(public readonly payload: IPropertiesTenantInvoicesOverdueWithinDaysDetails[]){}
}
export class GetPropertiesPeriodicLeasesDetails implements Action {
    readonly type = enActionTypes.GetPropertiesPeriodicLeasesDetailsAction;
    constructor(public readonly payload: IPropertiesPeriodicLeasesDetails[]){}
}
export class GetPropertiesLeasedLast30DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesLeasedLast30DaysDetailsAction;
    constructor(public readonly payload: IPropertiesLeasedDaysDetails[]){}
}
export class GetPropertiesLeaseRenewedLast30DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesLeaseRenewedLast30DaysDetailsAction;
    constructor(public readonly payload: IPropertiesLeaseRenewedDaysDetails[]){}
}
export class GetPropertiesUnderManagementDetails implements Action {
    readonly type = enActionTypes.GetPropertiesUnderManagementDetailsAction;
    constructor(public readonly payload: IPropertiesUnderManagementDetails[]){}
}
export class GetPropertiesInspectionOverdueDetails implements Action {
    readonly type = enActionTypes.GetPropertiesInspectionOverdueDetailsAction;
    constructor(public readonly payload: IPropertiesInspectionOverdueDetails[]){}
}
export class GetPropertiesInspectionDueWithin7DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesInspectionDueWithin7DaysDetailsAction;
    constructor(public readonly payload: IPropertiesInspectionDueDetails[]){}
}
export class GetPropertiesInspectionDueWithin30DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesInspectionDueWithin30DaysDetailsAction;
    constructor(public readonly payload: IPropertiesInspectionDueDetails[]){}
}
export class GetPropertiesTenantVacatingWithin30DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesTenantVacatingWithin30DaysDetailsAction;
    constructor(public readonly payload: IPropertiesTenantVacatingWithinDaysDetails[]){}
}
export class GetPropertiesOutstandingWorkOrderDue3To14DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesOutstandingWorkOrderDue3To14DaysDetailsAction;
    constructor(public readonly payload: IPropertiesOutstandingWorkOrderDaysDetails[]){}
}
export class GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetailsAction;
    constructor(public readonly payload: IPropertiesOutstandingWorkOrderDaysDetails[]){}
}
export class GetPropertiesAgreementRenewalDueWithin90DaysDetails implements Action {
    readonly type = enActionTypes.GetPropertiesAgreementRenewalDueWithin90DaysDetailsAction;
    constructor(public readonly payload: IPropertiesAgreementRenewalDueDetails[]){}
}
export class GetPropertiesAgreementExpiredDetails implements Action {
    readonly type = enActionTypes.GetPropertiesAgreementExpiredDetailsAction;
    constructor(public readonly payload: IPropertiesAgreementExpiredDetails[]){}
}
//#endregion

export type HomeManagementAction = 
| GetPropertiesUnderManagement
| GetPropertiesGained
| GetPropertiesLost
| GetPropertiesAgreementRenewalDue
| GetPropertiesVacantLessThan14
| GetPropertiesVacantGraterThan14
| GetPropertiesPeriodicLeases
| GetPropertiesAgreementExpired
| GetPropertiesAverageWeeklyRent
| GetPropertiesOutstandingWorkOrder3To14Days
| GetPropertiesOutstandingWorkOrderGraterThan14Days
| GetPropertiesLeaseRenewedDays
| GetPropertiesLeasedDays
| GetPropertiesTenantInvoicesOverdueWithin2To14Days
| GetPropertiesTenantInvoicesOverdueGreaterThan14Days
| GetPropertiesLeaseExpiryOverdue
| GetPropertiesLeaseExpiryDueWithinDays
| GetPropertiesTenantVacatingWithinDays
| GetPropertiesInspectionOverdue
| GetPropertiesInspectionDueWithin7Days
| GetPropertiesInspectionDueWithin30Days
| GetPropertiesTenantsInArrearsGraterThan3
| GetPropertiesTenantsInArrearsGraterThan10
| GetPropertiesAverageCommissionPercent

| GetPropertiesTenantsInArrearsGraterThan3Details
| GetPropertiesTenantsInArrearsGraterThan10Details
| GetPropertiesLeaseExpiryOverdueDetails
| GetPropertiesLeaseExpiryDueWithinDaysDetails
| GetPropertiesVacantLessThanEqual14DaysDetails
| GetPropertiesVacantGraterThan14DaysDetails
| GetPropertiesGainedLast6MonthsDetails
| GetPropertiesLostLast6MonthsDetails
| GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetails
| GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetails
| GetPropertiesPeriodicLeasesDetails
| GetPropertiesLeasedLast30DaysDetails
| GetPropertiesLeaseRenewedLast30DaysDetails
| GetPropertiesUnderManagementDetails
| GetPropertiesInspectionOverdueDetails
| GetPropertiesInspectionDueWithin7DaysDetails
| GetPropertiesInspectionDueWithin30DaysDetails
| GetPropertiesTenantVacatingWithin30DaysDetails
| GetPropertiesOutstandingWorkOrderDue3To14DaysDetails
| GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetails
| GetPropertiesAgreementRenewalDueWithin90DaysDetails
| GetPropertiesAgreementExpiredDetails;