import { Reducer } from 'redux';
import { IHomeState, INITIAL_HOME_STATE } from './home.state';
import { HomeManagementAction, enActionTypes } from './home.action';

export const homemanagementReducer: Reducer<IHomeState> = (state = INITIAL_HOME_STATE(), action: HomeManagementAction): IHomeState => {
    switch (action.type) {
        case enActionTypes.GetPropertiesUnderManagementAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertyManagement: action.payload })
            };
        }
        case enActionTypes.GetPropertiesGainedAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesGained: action.payload })
            };
        }
        case enActionTypes.GetPropertiesLostAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesLost: action.payload })
            };
        }
        case enActionTypes.GetPropertiesAgreementRenewalDueAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesAgreementRenewalDue: action.payload })
            };
        }
        case enActionTypes.GetPropertiesVacantLessThan14Action: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesVacantLessThan14: action.payload })
            };
        }
        case enActionTypes.GetPropertiesVacantGraterThan14Action: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesVacantGraterThan14: action.payload })
            };
        }
        case enActionTypes.GetPropertiesAgreementExpiredAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesAgreementExpired: action.payload })
            };
        }
        case enActionTypes.GetPropertiesPeriodicLeasesAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesPeriodicLeases: action.payload })
            };
        }
        case enActionTypes.GetPropertiesAverageWeeklyRentAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesAverageWeeklyRent: action.payload })
            };
        }





        case enActionTypes.GetPropertiesOutstandingWorkOrder3To14DaysAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesOutstandingWorkOrder3To14Days: action.payload })
            };
        }

        case enActionTypes.GetPropertiesOutstandingWorkOrderGraterThan14DaysAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesOutstandingWorkOrderGraterThan14Days: action.payload })
            };
        }
        case enActionTypes.GetPropertiesLeaseRenewedDaysAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesLeaseRenewedDays: action.payload })
            };
        }
        case enActionTypes.GetPropertiesLeasedDaysAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesLeasedDays: action.payload })
            };
        }
        case enActionTypes.GetPropertiesTenantInvoicesOverdueWithin2To14DaysAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesTenantInvoicesOverdueWithin2To14Days: action.payload })
            };
        }
        case enActionTypes.GetPropertiesTenantInvoicesOverdueGreaterThan14DaysAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesTenantInvoicesOverdueGreaterThan14Days: action.payload })
            };
        }
        case enActionTypes.GetPropertiesLeaseExpiryOverdueAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesLeaseExpiryOverdue: action.payload })
            };
        }
        case enActionTypes.GetPropertiesLeaseExpiryDueWithinDaysAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesLeaseExpiryDueWithinDays: action.payload })
            };
        }
        case enActionTypes.GetPropertiesTenantVacatingWithinDaysAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesTenantVacatingWithinDays: action.payload })
            };
        }
        case enActionTypes.GetPropertiesInspectionOverdueAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesInspectionOverdue: action.payload })
            };
        }
        case enActionTypes.GetPropertiesInspectionDueWithin7DaysAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesInspectionDueWithin7Days: action.payload })
            };
        }
        case enActionTypes.GetPropertiesInspectionDueWithin30DaysAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesInspectionDueWithin30Days: action.payload })
            };
        }
        case enActionTypes.GetPropertiesTenantsInArrearsGraterThan3Action: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesTenantsInArrearsGraterThan3: action.payload })
            };
        }
        case enActionTypes.GetPropertiesTenantsInArrearsGraterThan10Action: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesTenantsInArrearsGraterThan10: action.payload })
            };
        }
        case enActionTypes.GetPropertiesAverageCommissionPercentAction: {
            return {
                ...state,
                dashboard: Object.assign({}, state.dashboard, { propertiesAverageCommissionPercent: action.payload })
            };
        }

        case enActionTypes.GetPropertiesTenantsInArrearsGraterThan3DetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesTenantsInArrearsGraterThan3: Object.assign({}, state.dashboard.propertiesTenantsInArrearsGraterThan3, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesTenantsInArrearsGraterThan10DetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesTenantsInArrearsGraterThan10: Object.assign({}, state.dashboard.propertiesTenantsInArrearsGraterThan10, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesLeaseExpiryOverdueDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesLeaseExpiryOverdue: Object.assign({}, state.dashboard.propertiesLeaseExpiryOverdue, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesLeaseExpiryDueWithinDaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesLeaseExpiryDueWithinDays: Object.assign({}, state.dashboard.propertiesLeaseExpiryDueWithinDays, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesVacantLessThanEqual14DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesVacantLessThan14: Object.assign({}, state.dashboard.propertiesVacantLessThan14, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesVacantGraterThan14DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesVacantGraterThan14: Object.assign({}, state.dashboard.propertiesVacantGraterThan14, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesGainedLast6MonthsDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesGained: Object.assign({}, state.dashboard.propertiesGained, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesLostLast6MonthsDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesLost: Object.assign({}, state.dashboard.propertiesLost, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesTenantInvoicesOverdueWithin2To14Days: Object.assign({}, state.dashboard.propertiesTenantInvoicesOverdueWithin2To14Days, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesTenantInvoicesOverdueGreaterThan14Days: Object.assign({}, state.dashboard.propertiesTenantInvoicesOverdueGreaterThan14Days, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesPeriodicLeasesDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesPeriodicLeases: Object.assign({}, state.dashboard.propertiesPeriodicLeases, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesLeasedLast30DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesLeasedDays: Object.assign({}, state.dashboard.propertiesLeasedDays, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesLeaseRenewedLast30DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesLeaseRenewedDays: Object.assign({}, state.dashboard.propertiesLeaseRenewedDays, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesUnderManagementDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertyManagement: Object.assign({}, state.dashboard.propertyManagement, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesInspectionOverdueDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesInspectionOverdue: Object.assign({}, state.dashboard.propertiesInspectionOverdue, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesInspectionDueWithin7DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesInspectionDueWithin7Days: Object.assign({}, state.dashboard.propertiesInspectionDueWithin7Days, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesInspectionDueWithin30DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesInspectionDueWithin30Days: Object.assign({}, state.dashboard.propertiesInspectionDueWithin30Days, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesTenantVacatingWithin30DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesTenantVacatingWithinDays: Object.assign({}, state.dashboard.propertiesTenantVacatingWithinDays, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesOutstandingWorkOrderDue3To14DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesOutstandingWorkOrder3To14Days: Object.assign({}, state.dashboard.propertiesOutstandingWorkOrder3To14Days, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesOutstandingWorkOrderGraterThan14Days: Object.assign({}, state.dashboard.propertiesOutstandingWorkOrderGraterThan14Days, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesAgreementRenewalDueWithin90DaysDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesAgreementRenewalDue: Object.assign({}, state.dashboard.propertiesAgreementRenewalDue, { details: action.payload })
                               }
                           )
            };
        }
        case enActionTypes.GetPropertiesAgreementExpiredDetailsAction: {
            return {
                ...state,
                dashboard: Object.assign
                           (
                               {}, 
                               state.dashboard,
                               { 
                                    propertiesAgreementExpired: Object.assign({}, state.dashboard.propertiesAgreementExpired, { details: action.payload })
                               }
                           )
            };
        }


        default:
          return state;
    }
}
