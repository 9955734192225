import { Options } from 'ng5-slider';
import { Observable } from "rxjs/Observable";
import { Injectable, Inject, PLATFORM_ID, Injector } from "@angular/core";
import {
    Http,
    Headers,
    Response,
    RequestOptions,
    RequestMethod
  } from "@angular/http";
  import "rxjs/add/operator/catch";
  import "rxjs/add/operator/map";
  import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

  import { BaseService } from '../services/baseservice';
  import { IDNamePair, ServiceResponse, ContactDetails } from "./../services/authservice";
  import { IAppointmentDetailsSearchResult, IAppointmentChildDetails, IAppointmentContact, IAppointmentContactOpenPrivate, IPersonDto, IDepartmentDto } from './ofi';

  @Injectable()
  export class OfiService extends BaseService {
    constructor(private _http: Http, private HTTP: HttpClient, @Inject(PLATFORM_ID) private platformID: Object) {
        super(HTTP, platformID);
    }

    //#region Open Private Inspection
    GetCurrentStaffs(): Observable<ServiceResponse<IDNamePair<string>[]>> {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
        return this._http
          .post("api/Ofi/GetCurrentStaffs", {}, options)
          .map((res: Response) => res.json())
          .catch((error: any) =>
            Observable.throw(error.json().error || "Server Error")
          );
    }
    GetOfiPiStaffs(dateFrom: Date, dateTo: Date): Observable<ServiceResponse<IDNamePair<string>[]>> {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
        return this._http
          .post("api/Ofi/GetOfiPiStaffs", { 
            dateFrom: dateFrom,
            dateTo: dateTo
          }, options)
          .map((res: Response) => res.json())
          .catch((error: any) =>
            Observable.throw(error.json().error || "Server Error")
          );
    }
    GetDepartmentsForOfiSearch(): Observable<ServiceResponse<IDepartmentDto[]>> {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
      return this._http
        .post("api/Ofi/GetDepartmentsForOfiSearch", {}, options)
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
    }
    GetPropertyAddress(
      searchText: string
    ): Observable<ServiceResponse<IDNamePair<string>[]>> {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
      return this._http
        .post("api/Ofi/GetPropertyAddress", {
          searchText: searchText
        }, options)
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
    }
    GetAppointments
    (
      departmentID: string,
      staffIDs: string[],
      dateFrom: Date | null,
      dateTo: Date | null,      
      addressID: string | null,
      currentListing: boolean
    ): Observable<ServiceResponse<IAppointmentDetailsSearchResult[]>> {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
      return this._http
        .post("api/Ofi/GetAppointments", {
          departmentID: departmentID,
          staffIDs: staffIDs,
          dateFrom: dateFrom,
          dateTo: dateTo,          
          addressID: addressID,
          currentListing: currentListing
        }, options)
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
    }
    GetAppointmentDetails
    (
      appointmentID: string
    ): Observable<ServiceResponse<IAppointmentChildDetails[]>> {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
      return this._http
        .post("api/Ofi/GetAppointmentDetails", {
          appointmentID: appointmentID
        }, options)
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
    }
    GetAppointmentContact
    (
      appointmentID: string,
      contactID: string
    ): Observable<ServiceResponse<IAppointmentContactOpenPrivate>> {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
      return this._http
        .post("api/Ofi/GetAppointmentContact", {
          appointmentID: appointmentID,
          contactID: contactID
        }, options)
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
    }
    ModifyAppointmentDetails
    (
      appointmentID: string,
      contactID: string,
      opinionOfListPrice: number,
      opinionPriceFrom: number,
      opinionPriceTo: number,
      opinionOfPresentation: number,
      interestLevel: number,
      suitability: number,
      notes: string,
      followUpRequired: boolean,
      nextContactDate: Date | null,
      bookingStatusID: number | null
    ): Observable<ServiceResponse<boolean>> {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
      return this._http
        .post("api/Ofi/ModifyAppointmentDetails", {
          appointmentID: appointmentID,
          contactID: contactID,
          opinionOfListPrice: opinionOfListPrice,
          opinionPriceFrom: opinionPriceFrom,
          opinionPriceTo: opinionPriceTo,
          opinionOfPresentation: opinionOfPresentation,
          interestLevel: interestLevel,
          suitability: suitability,
          notes: notes,
          followUpRequired: followUpRequired,
          nextContactDate: nextContactDate,
          bookingStatusID: bookingStatusID
        }, options)
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
    }
    GetPersonDetails
    (
      mobile: string,
      email: string
    ): Observable<ServiceResponse<IPersonDto>> {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
      return this._http
        .post("api/Ofi/GetPersonDetails", {
          mobile: mobile,
          email: email
        }, options)
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
    }
    //#endregion

  }
