import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CKEditorModule } from "ckeditor4-angular";
import { TreeModule } from "angular-tree-component";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatDialogModule, MatFormFieldModule, MatInputModule, MatTooltipModule, MatAutocompleteModule, MatProgressSpinnerModule, MatNativeDateModule, MatDatepickerModule, MatSortModule, MatTableModule, MatRadioModule, MatPaginatorModule, MatSelectModule, MatCheckboxModule, MatIconModule, MatMenuModule, MatButtonModule } from "@angular/material";
import { Ng5SliderModule } from 'ng5-slider';
import { QueryBuilderModule } from "angular2-query-builder";
import {
  ChartModule,
  ChartAllModule,
  AccumulationChartModule,
  AccumulationChartAllModule,
  RangeNavigatorAllModule

} from '@syncfusion/ej2-angular-charts';


//import { HomeComponent } from './home/home.component';
//import { CounterComponent } from './counter/counter.component';
//import { FetchDataComponent } from './fetch-data/fetch-data.component';

import { LoginGuard } from "./login.guard";
import { AuthGuard } from "./auth.guard";
import { ContactsGuard } from "./guard/contacts.guard";
import { ResidentialGuard, CommercialGuard } from "./guard/permission.guard";
import { LoginComponent } from "./login/login.component";
//import { ContactsComponent } from "./contacts/contacts.component";
import { RentalTenantSearchResultComponent } from "./contacts/rentaltenantsearchresult/rentaltenantsearchresult.component";
import { TemplatesComponent } from "./emarketing/templates/templates.component";
import { TemplateSearchResultComponent } from "./emarketing/templates/templatesearchresult/templatesearchresult.component";
import { EditTemplateComponent } from "./emarketing/templates/templatesearchresult/edittemplate/edittemplate.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogComponent } from './dialog/dialog.component';
import { NewTemplateComponent } from './emarketing/templates/newtemplate/newtemplate.component';
import { EmailsComponent } from './emarketing/emails/emails.component';
import { NewEmailComponent } from './emarketing/emails/newemail/newemail.component';
import { EmailSearchResultComponent } from './emarketing/emails/emailsearchresult/emailsearchresult.component';
import { EditEmailComponent, SafeHtmlPipe } from './emarketing/emails/emailsearchresult/editemail/editemail.component';
import { PreviewEmailComponent } from './emarketing/emails/emailsearchresult/previewemail/previewemail.component';
import { ContactListsComponent } from './emarketing/contactlists/contactlists.component';
import { ContactListsSearchResultComponent } from './emarketing/contactlists/contactlistssearchresult/contactlistssearchresult.component';
import { EditContactListsComponent } from './emarketing/contactlists/contactlistssearchresult/editcontactlists/editcontactlists.component';
import { CommunicationStatementComponent } from './propertymanagement/components/communicationstatement/communicationstatement.component';

import { ManagementAgreementsComponent } from './propertymanagement/managementagreements/managementagreements.component';
import { ManagementAgreementSearchResultComponent } from './propertymanagement/managementagreements/managementagreementsearchresult/managementagreementsearchresult.component';

import { TenantContactsComponent } from './propertymanagement/tenantcontacts/tenantcontacts.component';
import { TenantContactSearchResultComponent } from './propertymanagement/tenantcontacts/tenantcontactsearchresult/tenantcontactsearchresult.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { WorkOrdersComponent } from './propertymanagement/workorders/workorders.component';
import { WorkorderSearchResultComponent } from './propertymanagement/workorders/workordersearchresult/workordersearchresult.component';
import { TenantInvoicesComponent } from './propertymanagement/tenantinvoices/tenantinvoices.component';
import { ManagementagreementsearchComponent } from './propertymanagement/components/managementagreementsearch/managementagreementsearch.component';
import { GridViewComponent } from './propertymanagement/components/gridview/gridview.component';
import { TenantInvoiceSearchResultComponent } from './propertymanagement/tenantinvoices/tenantinvoicesearchresult/tenantinvoicesearchresult.component';
import { CommunicationLogComponent } from './components/communicationlog/communicationlog.component';
import { QrcodeViewerComponent } from './components/qrcodeviewer/qrcodeviewer.component';
import { WorkOrderCreationComponent } from './propertymanagement/components/workordercreation/workordercreation.component';

import { CommercialManagementAgreementsComponent } from './propertymanagement/commercialmanagementagreements/commercialmanagementagreements.component';
import { CommercialTenantContactsComponent } from './propertymanagement/commercialtenantcontacts/commercialtenantcontacts.component';
import { CommercialTenantContactSearchResultComponent } from './propertymanagement/commercialtenantcontacts/commercialtenantcontactsearchresult/commercialtenantcontactsearchresult.component';
import { CommercialManagementAgreementResultComponent } from './propertymanagement/commercialmanagementagreements/commercialmanagementagreementresult/commercialmanagementagreementresult.component';
import { CommercialWorkOrderComponent } from './propertymanagement/commercialworkorder/commercialworkorder.component';
import { CommercialWorkOrderSearchResultComponent } from './propertymanagement/commercialworkorder/commercialworkordersearchresult/commercialworkordersearchresult.component';
import { CommercialTenantInvoiceComponent } from './propertymanagement/commercialtenantinvoice/commercialtenantinvoice.component';
import { CommercialTenantInvoiceSearchResultComponent } from './propertymanagement/commercialtenantinvoice/commercialtenantinvoicesearchresult/commercialtenantinvoicesearchresult.component';
import { CommercialContactsComponent } from './propertymanagement/commercialcontacts/commercialcontacts.component';
import { CommercialContactsResultsComponent } from './propertymanagement/commercialcontacts/commercialcontactsresults/commercialcontactsresults.component';

import { ContactsComponent } from './propertymanagement/contacts/contacts.component';
import { ContactsResultsComponent } from './propertymanagement/contacts/contactsresults/contactsresults.component';

import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { VacantPropertiesLessThan14Component } from './propertymanagement/vacantpropertieslessthan14/vacantpropertieslessthan14.component';
import { VacantPropertiesGreaterThan14Component } from './propertymanagement/vacantpropertiesgreaterthan14/vacantpropertiesgreaterthan14.component';
import { OpenPrivateInspectionComponent } from './ofi/openprivateinspection/openprivateinspection.component';
import { OpenPrivateInspectionSearchResultComponent } from './ofi/openprivateinspection/openprivateinspectionsearchresult/openprivateinspectionsearchresult.component';
import { VisitorsComponent } from './reports/visitors/visitors.component';
import { AddPrivateInspectionComponent } from './ofi/openprivateinspection/addprivateinspection/addprivateinspection.component';
import { EnquirersFollowUpComponent } from './reports/enquirersfollowup/enquirersfollowup.component';
import { EnquirersFollowupSearchResultComponent } from './reports/enquirersfollowup/enquirersfollowupsearchresult/enquirersfollowupsearchresult.component';
import { ListingEnquiryStatusComponent } from "./reports/listingenquirystatus/listingenquirystatus.component";
import { ListingEnquiryStatusSearchResultComponent } from "./reports/listingenquirystatus/listingenquirystatussearchresult/listingenquirystatussearchresult.component";
import { CommercialContactCreationComponent } from './propertymanagement/commercialcontacts/commercialcontactcreation/commercialcontactcreation.component';
import { AddEditContactComponent } from "./propertymanagement/components/addeditcontact/addeditcontact.component";
import { LeaseRenewalComponent } from './propertymanagement/leaserenewal/leaserenewal.component';
import { LeaseRenewalResultComponent } from "./propertymanagement/leaserenewal/leaserenewalresult/leaserenewalresult.component";
import { LeaseRenewalLogComponent } from "./propertymanagement/leaserenewallog/leaserenewallog.component";
import { LeaseRenewalNewLeasesCountComponent } from "./propertymanagement/leaserenewalnewleasescount/leaserenewalnewleasescount.component";
import { LeaseRenewalStatisticsComponent } from "./propertymanagement/leaserenewalstatistics/leaserenewalstatistics.component";
import { LeaseRenewalNewLeasesCountResultComponent } from "./propertymanagement/leaserenewalnewleasescount/leaserenewalnewleasescountresult/leaserenewalnewleasescountresult.component";
import { LeaseRenewalStatisticsResultComponent } from "./propertymanagement/leaserenewalstatistics/leaserenewalstatisticsresult/leaserenewalstatisticsresult.component";
import { MaintenanceComponent } from "./maintenance/maintenance.component";
import { MaintenanceSearchComponent } from "./maintenance/maintenancesearch/maintenancesearch.component";
import { MaintenanceSearchResultComponent } from "./maintenance/maintenancesearch/maintenancesearchresult/maintenancesearchresult.component";
import { SaveMaintenanceComponent } from "./maintenance/savemaintenance/savemaintenance.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent, canActivate: [LoginGuard] },
  // {
  //   path: "contacts",
  //   component: ContactsComponent,
  //   canActivate: [AuthGuard, ContactsGuard],
  //   children: [
  //     {
  //       path: "rentaltenantsearchresult",
  //       component: RentalTenantSearchResultComponent
  //     }
  //   ]
  // },
  { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard, ResidentialGuard] },
  /*{
    path: 'dashboard/:databaseName/:sessionID/:agencyID/:departmentID/:staffID',
    component: DashboardComponent,
    pathMatch: 'full'
  },*/
  {
    path: 'dashboard/:databaseName/:sessionID/:agencyID/:departmentID/:staffID',
    component: DashboardComponent,
    pathMatch: 'full'
  },
  {
    path: "templates",
    component: TemplatesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "templatesearchresult",
        component: TemplateSearchResultComponent,
        children: [
          {
            path: "edit",
            component: EditTemplateComponent
          }
        ]
      },
      {
        path: "new",
        component: NewTemplateComponent
      },
    ]
  },
  {
    path: "emails",
    component: EmailsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "emailsearchresult",
        component: EmailSearchResultComponent,
        children: [
          {
            path: "edit",
            component: EditEmailComponent
          }
        ]
      },
      {
        path: "new",
        component: NewEmailComponent,
        children: [
          {
            path: "edit",
            component: EditEmailComponent
          }
        ]
      }
    ]
  },
  {
    path: "contactlists",
    component: ContactListsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "edit",
        component: EditContactListsComponent
      },
      {
        path: "new",
        component: EditContactListsComponent
      },
    ]
  },
  {
    path: "managementagreements",
    component: ManagementAgreementsComponent,
    canActivate: [AuthGuard, ResidentialGuard],
    children: [
      {
        path: "search",
        component: ManagementAgreementSearchResultComponent,
        // children: [
        //   {
        //     path: "edit",
        //     component: EditEmailComponent
        //   }
        // ]
      }
    ]
  },
  {
    path: "tenantcontacts",
    component: TenantContactsComponent,
    canActivate: [AuthGuard, ResidentialGuard],
    children: [
      {
        path: "search",
        component: TenantContactSearchResultComponent,
        // children: [
        //   {
        //     path: "edit",
        //     component: EditEmailComponent
        //   }
        // ]
      }
    ]
  },
  {
    path: "contacts",
    component: ContactsComponent,
    canActivate: [AuthGuard, ResidentialGuard],
    children: [
      {
        path: "search",
        component: ContactsResultsComponent,
        // children: [
        //   {
        //     path: "edit",
        //     component: EditEmailComponent
        //   }
        // ]
      }
    ]
  },
  {
    path: "workorders",
    component: WorkOrdersComponent,
    canActivate: [AuthGuard, ResidentialGuard],
    children: [
      {
        path: "search",
        component: WorkorderSearchResultComponent,
        // children: [
        //   {
        //     path: "edit",
        //     component: EditEmailComponent
        //   }
        // ]
      }
    ]
  },
  {
    path: "tenantinvoices",
    component: TenantInvoicesComponent,
    canActivate: [AuthGuard, ResidentialGuard],
    children: [
      {
        path: "search",
        component: TenantInvoiceSearchResultComponent,
        // children: [
        //   {
        //     path: "edit",
        //     component: EditEmailComponent
        //   }
        // ]
      }
    ]
  },
  {
    path: "vacantpropertieslessthan14",
    component: VacantPropertiesLessThan14Component,
    canActivate: [AuthGuard, ResidentialGuard]
    // children: [
    //   {
    //     path: "search",
    //     component: TenantInvoiceSearchResultComponent,
    //     // children: [
    //     //   {
    //     //     path: "edit",
    //     //     component: EditEmailComponent
    //     //   }
    //     // ]
    //   }
    // ]
  },

  {
    path: "vacantpropertiesgreaterthan14",
    component: VacantPropertiesGreaterThan14Component,
    canActivate: [AuthGuard, ResidentialGuard]
    // children: [
    //   {
    //     path: "search",
    //     component: TenantInvoiceSearchResultComponent,
    //     // children: [
    //     //   {
    //     //     path: "edit",
    //     //     component: EditEmailComponent
    //     //   }
    //     // ]
    //   }
    // ]
  },



  //#region Commercial
  {
    path: "commercialcontacts",
    component: CommercialContactsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "search",
        component: CommercialContactsResultsComponent,
        // children: [
        //   {
        //     path: "edit",
        //     component: EditEmailComponent
        //   }
        // ]
      },
      {
        path: "contactcreation",
        component: CommercialContactCreationComponent
      }
    ]
  },
  {
    path: "commercialmanagementagreements",
    component: CommercialManagementAgreementsComponent,
    canActivate: [AuthGuard, CommercialGuard],
    children: [
      {
        path: "search",
        component: CommercialManagementAgreementResultComponent,
      }
    ]
  },
  {
    path: "commercialtenantcontacts",
    component: CommercialTenantContactsComponent,
    canActivate: [AuthGuard, CommercialGuard],
    children: [
      {
        path: "search",
        component: CommercialTenantContactSearchResultComponent,
      }
    ]
  }, 
  {
    path: "commercialworkorder",
    component: CommercialWorkOrderComponent,
    canActivate: [AuthGuard, CommercialGuard],
    children: [
      {
        path: "search",
        component: CommercialWorkOrderSearchResultComponent,
      }
    ]
  },
  {
    path: "commercialtenantinvoices",
    component: CommercialTenantInvoiceComponent,
    canActivate: [AuthGuard, CommercialGuard],
    children: [
      {
        path: "search",
        component: CommercialTenantInvoiceSearchResultComponent,
      }
    ]
  },
  //#endregion
  {
    path: "ofi",
    component: OpenPrivateInspectionComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "search",
        component: OpenPrivateInspectionSearchResultComponent,
      },
      {
        path: "add",
        component: AddPrivateInspectionComponent
      }
    ]
  },
  {
    path: "visitors",
    component: VisitorsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "search",
        component: OpenPrivateInspectionSearchResultComponent,
      }
    ]
  },
  {
    path: "enquirersfollowup",
    component: EnquirersFollowUpComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "search",
        component: EnquirersFollowupSearchResultComponent,
      }
    ]
  },
  {
    path: "listingenquirystatus",
    component: ListingEnquiryStatusComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "search",
        component: ListingEnquiryStatusSearchResultComponent,
      }
    ]
  },
  {
    path: "leaserenewal",
    component: LeaseRenewalComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "search",
        component: LeaseRenewalResultComponent,
      }
    ]
  },
  {
    path: "leaserenewal/:database/:sessionID/:departmentID",
    component: LeaseRenewalComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "search",
        component: LeaseRenewalResultComponent
      }
    ]
  },
  {
    path: "leaserenewallog/:database/:sessionID/:leaserenewalID",
    component: LeaseRenewalLogComponent,
    canActivate: [AuthGuard],
    children: []
  },
  {
    path: "newleasescount/:database/:sessionID/:departmentID",
    component: LeaseRenewalNewLeasesCountComponent,
    children: [
      {
        path: "search",
        component: LeaseRenewalNewLeasesCountResultComponent
      }
    ]
  },
  {
    path: "leaserenewalstatistics/:database/:sessionID/:departmentID",
    component: LeaseRenewalStatisticsComponent,
    children: [
      {
        path: "search",
        component: LeaseRenewalStatisticsResultComponent
      }
    ]
  },
  {
    path: "maintenance",
    component: MaintenanceSearchComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "search",
        component: MaintenanceSearchResultComponent
      }
    ]
  },
  {
    path: "newmaintenance",
    component: MaintenanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "newmaintenance/:database/:sessionID/:departmentID",
    component: MaintenanceComponent,
    canActivate: [AuthGuard],
  },


  { path: "**", redirectTo: "login" }
];

@NgModule({
  declarations: [
    /*HomeComponent,
    CounterComponent,
    FetchDataComponent,*/
    NavMenuComponent,
    LoginComponent,
    ContactsComponent,
    ContactsResultsComponent,
    RentalTenantSearchResultComponent,
    TemplatesComponent,
    TemplateSearchResultComponent,
    EditTemplateComponent,
    DialogComponent,
    NewTemplateComponent,
    EmailsComponent,
    EmailSearchResultComponent,
    EditEmailComponent,
    NewEmailComponent,
    SafeHtmlPipe,
    PreviewEmailComponent,
    ContactListsComponent,
    ContactListsSearchResultComponent,
    EditContactListsComponent,
    ManagementAgreementsComponent,
    ManagementAgreementSearchResultComponent,
    TenantContactsComponent,
    TenantContactSearchResultComponent,
    DashboardComponent,
    WorkOrdersComponent,
    WorkorderSearchResultComponent,
    TenantInvoicesComponent,
    ManagementagreementsearchComponent,
    GridViewComponent,
    TenantInvoiceSearchResultComponent,
    CommunicationLogComponent,
    QrcodeViewerComponent,
    CommunicationStatementComponent,
    WorkOrderCreationComponent,

    CommercialManagementAgreementsComponent,
    CommercialTenantContactsComponent,
    CommercialTenantContactSearchResultComponent,
    CommercialManagementAgreementResultComponent,
    CommercialWorkOrderComponent,
    CommercialWorkOrderSearchResultComponent,
    CommercialTenantInvoiceComponent,
    CommercialTenantInvoiceSearchResultComponent,
    CommercialContactsComponent,
    CommercialContactsResultsComponent,
    VacantPropertiesLessThan14Component,
    VacantPropertiesGreaterThan14Component,
    OpenPrivateInspectionComponent,
    OpenPrivateInspectionSearchResultComponent,
    VisitorsComponent,
    AddPrivateInspectionComponent,
    EnquirersFollowUpComponent,
    EnquirersFollowupSearchResultComponent,
    ListingEnquiryStatusComponent,
    ListingEnquiryStatusSearchResultComponent,
    CommercialContactCreationComponent,
    AddEditContactComponent,
    LeaseRenewalComponent,
    LeaseRenewalResultComponent,
    LeaseRenewalLogComponent,
    LeaseRenewalNewLeasesCountComponent,
    LeaseRenewalStatisticsComponent,
    LeaseRenewalNewLeasesCountResultComponent,
    LeaseRenewalStatisticsResultComponent,
    MaintenanceSearchComponent,
    MaintenanceSearchResultComponent,
    MaintenanceComponent,
    SaveMaintenanceComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CKEditorModule,
    TreeModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatInputModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSortModule,
    MatTableModule,
    MatRadioModule,
    MatPaginatorModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    QueryBuilderModule,
    ChartModule,
    ChartAllModule,
    AccumulationChartModule,
    AccumulationChartAllModule,
    RangeNavigatorAllModule,
    RouterModule.forRoot(routes, {
      /*onSameUrlNavigation: "reload",*/ enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
