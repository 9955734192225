import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from 'rxjs/Subscription';

import { IAppState } from "../../../store/store";
import { EmarketingService } from "./../../emarketing.service";
import { LoaderService } from "./../../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from './../../../dialog-body/dialog-body.component';
import { IEmailContactList } from "./../../emarketing";
import { GetContactListsSearch, EditContactList } from "./../../emarketing.action";
import { IDNamePair } from "./../../../services/authservice";
import "../../../../../../wwwroot/js/scripts.js";
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'contactlistssearchresult',
  templateUrl: './contactlistssearchresult.component.html',
  styleUrls: ['./contactlistssearchresult.component.css']
})
export class ContactListsSearchResultComponent implements OnInit, AfterViewInit, OnDestroy {

  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  private dialogRef: any;

  private subscription_GetContactLists: Subscription;
  private subscription_EditContactList: Subscription;
  private subscription_GetEmailContactStatuses: Subscription;

  constructor(private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: EmarketingService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  openDialog(
      title: string,
      message: string,
      role: "alertdialog" | "dialog" = "dialog"
    ) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.role = role;
      dialogConfig.data = {
        title: title,
        message: message,
        role:
          role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
      };
      dialogConfig.panelClass = "dialog-body";
      this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
      return this.dialogRef;
  }

  @select(state => (state as IAppState).emarketing.contactlists.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).emarketing.contactlists.search.contactLists) contactLists$;

  ngOnInit() {
    this._loader.show();
    this. subscription_GetContactLists = this._service.GetContactLists()
      .map((contactlists: IEmailContactList[]) => {
        return new GetContactListsSearch(contactlists);
      })
      .subscribe(action => {
        this.ngRedux.dispatch({ type: action.type, payload: action.payload });
        this._loader.hide();
      },
      error => {
        this._loader.hide();
        this.openDialog("Error", (error.json().error || "Server Error"), "alertdialog");
      });
  }
  ngAfterViewInit() {

  }
  ngOnDestroy() {
    if (!!this.subscription_GetContactLists) this.subscription_GetContactLists.unsubscribe();
    if (!!this.subscription_EditContactList) this.subscription_EditContactList.unsubscribe();
  }

  onEditClick(contactList: IEmailContactList) {
    if (!!contactList.id && contactList.id.indexOf("00000000") < 0) {
      this._loader.show();
      this. subscription_GetEmailContactStatuses = this._service.GetEmailContactStatuses()
      .subscribe((statuses: IDNamePair<number>[]) => {
      
        this.subscription_EditContactList = this._service.EditContactList(contactList.id)
        .map((contactList: IEmailContactList) => {
          return new EditContactList({statuses: statuses, contactList: contactList});
        })
        .subscribe(action => {
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this._loader.hide();
          this._router.navigate(["/contactlists/edit"]);
        },
        error => {
          this._loader.hide();
          this.openDialog("Error", (error.json().error || "Server Error"), "alertdialog");
        });

      },
      error => {
        this._loader.hide();
        this.openDialog("Error", (error.json().error || "Server Error"), "alertdialog");
      });
    }
  }
}
