import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig, SortDirection, Sort } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { IAppState } from '../../../store/store';
import { LoaderService } from '../../../core/loader/loader.service';
import { AuthService } from '../../../services/authservice';
import { MaintenanceService } from '../../maintenance.service';
import { DialogBodyComponent, enDialogRole } from '../../../dialog-body/dialog-body.component';
import { IMaintenanceSearchResult } from '../../maintenance.state';
import { CloseMaintenanceResultPage } from '../../maintenance.action';

@Component({
  selector: 'app-maintenancesearchresult',
  templateUrl: './maintenancesearchresult.component.html',
  styleUrls: ['./maintenancesearchresult.component.scss']
})
export class MaintenanceSearchResultComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private _datePipe: DatePipe,
    private _authService: AuthService,
    private _service: MaintenanceService
  ) { }
  //#region Settings
  private snackbarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    horizontalPosition: "center",
    panelClass: ['notifyPanel']
  } as MatSnackBarConfig;
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  }
  //#endregion

  ngOnInit() {
    this.getSessionData_Subcription = this._authService.GetSessionData()
      .subscribe(session => {
        if (!!session) {
          this._sessionID = session.id;
          this.loadPageData();
        }
      },
        error => {
          this._loader.hide();
          this.openDialog("Alert", error, "alertdialog");
        }
      );
  }
  ngAfterViewInit() {
  }
  ngOnDestroy() {
    if (!!this.getSessionData_Subcription) this.getSessionData_Subcription.unsubscribe();
  }

  //#region Events
  onClose() {
    let action = new CloseMaintenanceResultPage(true);
    this.ngRedux.dispatch({ type: action.type });
    this._router.navigate(["/maintenance"]);
  }
  onShowEditClick(pd: IMaintenanceSearchResult) {
    this.pageData.forEach((value, index) => {
      value.showEdit = false;
    });
    pd.showEdit = true;
  }
  //#endregion

  //#region Subcription Variables
  private getSessionData_Subcription: Subscription;
  //#endregion
  //#region Private Variables
  private dialogRef: any;
  private _sessionID: string;
  //#endregion
  //#region Properties
  get sessionID(): string {
    if (this.route.routeConfig.path.indexOf("maintenance") >= 0 && !!this.route.snapshot.paramMap.get("maintenance") && this.route.snapshot.paramMap.get("maintenance").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("sessionID");
    }
    else {
      return this._sessionID;
    }

  }
  //#endregion
  //#region Store Observables
  @select(state => (state as IAppState).maintenance.search.showSearchResult) showSearchResult$;
  //#endregion
  //#region Pagination
  private maintenances: IMaintenanceSearchResult[] = [];
  private hasPagination: boolean = true;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 100];
  pageData: IMaintenanceSearchResult[];
  pageEvent(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPageData();
    console.log("event: ", event);
  }
  private loadPageData() {
    this.maintenances = this.ngRedux.getState().maintenance.search.maintenances;
    this.pageData = this.maintenances;
    if (this.hasPagination) {
      this.pageData = this.maintenances.slice((this.pageIndex * this.pageSize), (this.pageIndex * this.pageSize) + this.pageSize);
    }
  }
  //#endegion
  //#region Functions
  statusName(pd: IMaintenanceSearchResult): string {
    if (pd.statusID == 1)
      return "Open";
    else if (pd.statusID == 2)
      return "Closed";
    else
      return "";
  }
  //#endregion
  //#region Grid Data Sort  
  mobileSort = {
    active: "",
    direction: "asc" as SortDirection
  };
  sortDataMobileView(active: string) {
    this.mobileSort.active = active;
    if (this.mobileSort.direction == "asc") {
      this.mobileSort.direction = "desc";
    }
    else if (this.mobileSort.direction == "desc") {
      this.mobileSort.direction = "asc";
    }
    else {
      this.mobileSort.direction = "asc";
    }
    this.sortData(this.mobileSort);
  }
  sortData(sort: Sort) {
    this.sortAllData(sort);
    this.loadPageData();
  }
  private sortAllData(sort: Sort) {
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.maintenances.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.maintenances.length; innerIndex++) {
        switch (sort.active) {
          case "dateTime":
            if (isAsc) {
              if (this.maintenances[index].dateTime > this.maintenances[innerIndex].dateTime) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            else {
              if (this.maintenances[index].dateTime < this.maintenances[innerIndex].dateTime) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            break;
          case "propertyAddress":
            if (isAsc) {
              if (this.maintenances[index].propertyAddress > this.maintenances[innerIndex].propertyAddress) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            else {
              if (this.maintenances[index].propertyAddress < this.maintenances[innerIndex].propertyAddress) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            break;
          case "callerContactName":
            if (isAsc) {
              if (this.maintenances[index].callerContactName > this.maintenances[innerIndex].callerContactName) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            else {
              if (this.maintenances[index].callerContactName < this.maintenances[innerIndex].callerContactName) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            break;
          case "supplierServiceTypeName":
            if (isAsc) {
              if (this.maintenances[index].supplierServiceTypeName > this.maintenances[innerIndex].supplierServiceTypeName) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            else {
              if (this.maintenances[index].supplierServiceTypeName < this.maintenances[innerIndex].supplierServiceTypeName) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            break;
          case "propertyManagerStaffName":
            if (isAsc) {
              if (this.maintenances[index].propertyManagerStaffName > this.maintenances[innerIndex].propertyManagerStaffName) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            else {
              if (this.maintenances[index].propertyManagerStaffName < this.maintenances[innerIndex].propertyManagerStaffName) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            break;
          case "status":
            if (isAsc) {
              if (this.maintenances[index].statusID > this.maintenances[innerIndex].statusID) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            else {
              if (this.maintenances[index].statusID < this.maintenances[innerIndex].statusID) {
                let temp = this.maintenances[index];
                this.maintenances[index] = this.maintenances[innerIndex];
                this.maintenances[innerIndex] = temp;
              }
            }
            break;
        }
      }
    }
  }
  //#endregion
}
