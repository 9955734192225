import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { Sort, SortDirection } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';

import { IAppState } from "../../store/store";
import { LoaderService } from "./../../core/loader/loader.service";
import { HomeService } from '../home.service';
import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";

import "../../../../../wwwroot/js/scripts.js";
import {
  IDashboardItem,
  IDashboard,
  IPropertiesTenantsInArrearsDetails,
  IPropertiesLeaseExpiryOverdueDetails,
  IPropertiesVacantDetails,
  IPropertiesGainedDetails,
  IPropertiesLostDetails,
  IPropertiesTenantInvoicesOverdueWithinDaysDetails,
  IPropertiesPeriodicLeasesDetails,
  IPropertiesLeasedDaysDetails,
  IPropertiesLeaseRenewedDaysDetails,
  IPropertiesUnderManagementDetails,
  IPropertiesInspectionOverdueDetails,
  IPropertiesInspectionDueDetails,
  IPropertiesTenantVacatingWithinDaysDetails,
  IPropertiesOutstandingWorkOrderDaysDetails,
  IPropertiesAgreementRenewalDueDetails,
  IPropertiesAgreementExpiredDetails
} from '../home';
import {
  GetPropertiesUnderManagement,
  GetPropertiesGained,
  GetPropertiesLost,
  GetPropertiesAgreementRenewalDue,
  GetPropertiesPeriodicLeases,
  GetPropertiesAgreementExpired,
  GetPropertiesAverageWeeklyRent,
  GetPropertiesVacantGraterThan14,
  GetPropertiesVacantLessThan14,
  GetPropertiesOutstandingWorkOrder3To14Days,
  GetPropertiesOutstandingWorkOrderGraterThan14Days,
  GetPropertiesLeaseRenewedDays,
  GetPropertiesLeasedDays,
  GetPropertiesTenantInvoicesOverdueWithin2To14Days,
  GetPropertiesTenantInvoicesOverdueGreaterThan14Days,
  GetPropertiesLeaseExpiryOverdue,
  GetPropertiesLeaseExpiryDueWithinDays,
  GetPropertiesTenantVacatingWithinDays,
  GetPropertiesInspectionOverdue,
  GetPropertiesInspectionDueWithin7Days,
  GetPropertiesInspectionDueWithin30Days,
  GetPropertiesTenantsInArrearsGraterThan3,
  GetPropertiesTenantsInArrearsGraterThan10,
  GetPropertiesAverageCommissionPercent,
  GetPropertiesTenantsInArrearsGraterThan3Details,
  GetPropertiesTenantsInArrearsGraterThan10Details,
  GetPropertiesLeaseExpiryOverdueDetails,
  GetPropertiesLeaseExpiryDueWithinDaysDetails,
  GetPropertiesVacantLessThanEqual14DaysDetails,
  GetPropertiesVacantGraterThan14DaysDetails,
  GetPropertiesGainedLast6MonthsDetails,
  GetPropertiesLostLast6MonthsDetails,
  GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetails,
  GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetails,
  GetPropertiesPeriodicLeasesDetails,
  GetPropertiesLeasedLast30DaysDetails,
  GetPropertiesLeaseRenewedLast30DaysDetails,
  GetPropertiesUnderManagementDetails,
  GetPropertiesInspectionOverdueDetails,
  GetPropertiesInspectionDueWithin7DaysDetails,
  GetPropertiesInspectionDueWithin30DaysDetails,
  GetPropertiesTenantVacatingWithin30DaysDetails,
  GetPropertiesOutstandingWorkOrderDue3To14DaysDetails,
  GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetails,
  GetPropertiesAgreementRenewalDueWithin90DaysDetails,
  GetPropertiesAgreementExpiredDetails
} from '../home.action';
import { AuthService, SessionInfo, StaffRole } from '../../services/authservice';
import { IS_LOGGED_IN } from '../../store/actions';
declare var $: JQueryStatic;
declare var LoadScript: any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  dialogRef: any;

  //private showOld: boolean = true;

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: HomeService,
    private _authservice: AuthService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }


  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  //#region Private Variables

  private userRoles: StaffRole[] = [];

  //#region Without Login
  private databaseName: string = null;
  private sessionID: string = null;
  private agencyID: string = null;
  private departmentID: string = null;
  private staffID: string = null;
  //#endregion

  private showDetails: boolean;
  private showWaitLoader: boolean;
  private detailsTitle: string;
  private detailsRecordFound: boolean;
  private _reportName: enReportName;
  private pageData: any = [];
  //#endregion

  //#region Properties
  public get reportName(): typeof enReportName {
    return enReportName;
  }
  public get isAllVisible(): boolean {
    if (!!this.userRoles && !!this.userRoles.length) {
      return this.userRoles.filter(ur => ur.name.toLowerCase() == "Office Administrator".toLowerCase()).length > 0;
    }
    else
      return false;
  }
  //#endregion

  //#region Subscription Variables
  private subcription_GetPropertiesTenantsInArrearsGraterThan3Details: Subscription;
  private subcription_GetPropertiesTenantsInArrearsGraterThan10Details: Subscription;
  private subcription_GetPropertiesLeaseExpiryOverdueDetails: Subscription;
  private subcription_GetPropertiesLeaseExpiryDueWithinDaysDetails: Subscription;
  private subcription_GetPropertiesVacantLessThanEqual14DaysDetails: Subscription;
  private subcription_GetPropertiesVacantGraterThan14DaysDetails: Subscription;
  private subcription_GetPropertiesGainedLast6MonthsDetails: Subscription;
  private subcription_GetPropertiesLostLast6MonthsDetails: Subscription;
  private subcription_GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetails: Subscription;
  private subcription_GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetails: Subscription;
  private subcription_GetPropertiesPeriodicLeasesDetails: Subscription;
  private subcription_GetPropertiesLeasedLast30DaysDetails: Subscription;
  private subcription_GetPropertiesLeaseRenewedLast30DaysDetails: Subscription;
  private subcription_GetPropertiesUnderManagementDetails: Subscription;
  private subcription_GetPropertiesInspectionOverdueDetails: Subscription;
  private subcription_GetPropertiesInspectionDueWithin7DaysDetails: Subscription;
  private subcription_GetPropertiesInspectionDueWithin30DaysDetails: Subscription;
  private subcription_GetPropertiesTenantVacatingWithin30DaysDetails: Subscription;
  private subcription_GetPropertiesOutstandingWorkOrderDue3To14DaysDetails: Subscription;
  private subcription_GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetails: Subscription;
  private subcription_GetPropertiesAgreementRenewalDueWithin90DaysDetails: Subscription;
  private subcription_GetPropertiesAgreementExpiredDetails: Subscription;
  //#endregion

  @select(state => (state as IAppState).homemanagement.dashboard.propertyManagement) propertyManagement$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesGained) propertiesGained$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesLost) propertiesLost$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesAgreementRenewalDue) propertiesAgreementRenewalDue$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesVacantLessThan14) propertiesVacantLessThan14$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesVacantGraterThan14) propertiesVacantGraterThan14$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesPeriodicLeases) propertiesPeriodicLeases$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesAgreementExpired) propertiesAgreementExpired$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesAverageWeeklyRent) propertiesAverageWeeklyRent$;

  @select(state => (state as IAppState).homemanagement.dashboard.propertiesOutstandingWorkOrder3To14Days) propertiesOutstandingWorkOrder3To14Days$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesOutstandingWorkOrderGraterThan14Days) propertiesOutstandingWorkOrderGraterThan14Days$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesLeaseRenewedDays) propertiesLeaseRenewedDays$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesLeasedDays) propertiesLeasedDays$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesTenantInvoicesOverdueWithin2To14Days) propertiesTenantInvoicesOverdueWithin2To14Days$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesTenantInvoicesOverdueGreaterThan14Days) propertiesTenantInvoicesOverdueGreaterThan14Days$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesLeaseExpiryOverdue) propertiesLeaseExpiryOverdue$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesLeaseExpiryDueWithinDays) propertiesLeaseExpiryDueWithinDays$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesTenantVacatingWithinDays) propertiesTenantVacatingWithinDays$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesInspectionOverdue) propertiesInspectionOverdue$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesInspectionDueWithin7Days) propertiesInspectionDueWithin7Days$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesInspectionDueWithin30Days) propertiesInspectionDueWithin30Days$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesTenantsInArrearsGraterThan3) propertiesTenantsInArrearsGraterThan3$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesTenantsInArrearsGraterThan10) propertiesTenantsInArrearsGraterThan10$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesAverageCommissionPercent) propertiesAverageCommissionPercent$;

  @select(state => (state as IAppState).homemanagement.dashboard.propertiesTenantsInArrearsGraterThan3.details) propertiesTenantsInArrearsGraterThan3Details$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesLeaseExpiryOverdue.details) propertiesLeaseExpiryOverdueDetails$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesLeaseExpiryDueWithinDays.details) propertiesLeaseExpiryDueWithinDaysDetails$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesVacantLessThan14.details) propertiesVacantLessThan14Details$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesVacantGraterThan14.details) propertiesVacantGraterThan14Details$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesGained.details) propertiesGainedLast6MonthsDetails$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesLost.details) propertiesLostLast6MonthsDetails$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesTenantInvoicesOverdueWithin2To14Days.details) propertiesTenantInvoicesOverdueWithin2To14DaysDetails$;
  @select(state => (state as IAppState).homemanagement.dashboard.propertiesTenantInvoicesOverdueGreaterThan14Days.details) propertiesTenantInvoicesOverdueGreaterThan14DaysDetails$;

  itemLoaded(item: IDashboardItem) {
    return (!!item && !!item.id && item.id.length > 0 && item.id.indexOf("00000000") < 0);
  }

  //#region Dashboard Child Details
  clearDetails() {
    this.pageData = [];
  }
  onCloseDetails(event) {
    this.detailsTitle = "";
    this.showDetails = false;
  }
  //#region Sort Details Data
  sortDetailsData(sort: Sort) {
    this.showWaitLoader = true;
    setTimeout(() => {
      this.sortAllDetailsData(sort);
    }, 1000)
  }
  private sortAllDetailsData(sort: Sort) {
    const isAsc = sort.direction === 'asc';
    for (var index = 0; index < this.pageData.length; index++) {
      for (var innerIndex = index + 1; innerIndex < this.pageData.length; innerIndex++) {
        switch (sort.active) {
          case 'propertyManagerName': {
            if (isAsc) {
              if (this.pageData[index].propertyManagerName > this.pageData[innerIndex].propertyManagerName) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].propertyManagerName < this.pageData[innerIndex].propertyManagerName) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'textFormatLS': {
            if (isAsc) {
              if (this.pageData[index].textFormatLS > this.pageData[innerIndex].textFormatLS) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].textFormatLS < this.pageData[innerIndex].textFormatLS) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'tenantName': {
            if (isAsc) {
              if (this.pageData[index].tenantName > this.pageData[innerIndex].tenantName) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].tenantName < this.pageData[innerIndex].tenantName) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'tenantMobilePhone': {
            if (isAsc) {
              if (this.pageData[index].tenantMobilePhone > this.pageData[innerIndex].tenantMobilePhone) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].tenantMobilePhone < this.pageData[innerIndex].tenantMobilePhone) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'paidToDate': {
            if (isAsc) {
              if (this.pageData[index].paidToDate > this.pageData[innerIndex].paidToDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].paidToDate < this.pageData[innerIndex].paidToDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'vacancyDate': {
            if (isAsc) {
              if (this.pageData[index].vacancyDate > this.pageData[innerIndex].vacancyDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].vacancyDate < this.pageData[innerIndex].vacancyDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'amountDue': {
            if (isAsc) {
              if (this.pageData[index].amountDue > this.pageData[innerIndex].amountDue) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].amountDue < this.pageData[innerIndex].amountDue) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'leaseEndDate': {
            if (isAsc) {
              if (this.pageData[index].leaseEndDate > this.pageData[innerIndex].leaseEndDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].leaseEndDate < this.pageData[innerIndex].leaseEndDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'vacantDays': {
            if (isAsc) {
              if (this.pageData[index].vacantDays > this.pageData[innerIndex].vacantDays) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].vacantDays < this.pageData[innerIndex].vacantDays) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'isNewManagement': {
            if (isAsc) {
              if (this.pageData[index].isNewManagement > this.pageData[innerIndex].isNewManagement) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].isNewManagement < this.pageData[innerIndex].isNewManagement) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'ownerNames': {
            if (isAsc) {
              if (this.pageData[index].ownerNames > this.pageData[innerIndex].ownerNames) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].ownerNames < this.pageData[innerIndex].ownerNames) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'fullManagementStartDate': {
            if (isAsc) {
              if (this.pageData[index].fullManagementStartDate > this.pageData[innerIndex].fullManagementStartDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].fullManagementStartDate < this.pageData[innerIndex].fullManagementStartDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'dateManagementLost': {
            if (isAsc) {
              if (this.pageData[index].dateManagementLost > this.pageData[innerIndex].dateManagementLost) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].dateManagementLost < this.pageData[innerIndex].dateManagementLost) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'description': {
            if (isAsc) {
              if (this.pageData[index].description > this.pageData[innerIndex].description) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].description < this.pageData[innerIndex].description) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'reasonForTermination': {
            if (isAsc) {
              if (this.pageData[index].reasonForTermination > this.pageData[innerIndex].reasonForTermination) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].reasonForTermination < this.pageData[innerIndex].reasonForTermination) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'dueDate': {
            if (isAsc) {
              if (this.pageData[index].dueDate > this.pageData[innerIndex].dueDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].dueDate < this.pageData[innerIndex].dueDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'amount': {
            if (isAsc) {
              if (this.pageData[index].amount > this.pageData[innerIndex].amount) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].amount < this.pageData[innerIndex].amount) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'amountPaid': {
            if (isAsc) {
              if (this.pageData[index].amountPaid > this.pageData[innerIndex].amountPaid) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].amountPaid < this.pageData[innerIndex].amountPaid) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'periodicLeaseFrom': {
            if (isAsc) {
              if (this.pageData[index].periodicLeaseFrom > this.pageData[innerIndex].periodicLeaseFrom) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].periodicLeaseFrom < this.pageData[innerIndex].periodicLeaseFrom) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'rentStartDate': {
            if (isAsc) {
              if (this.pageData[index].rentStartDate > this.pageData[innerIndex].rentStartDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].rentStartDate < this.pageData[innerIndex].rentStartDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'leaseStartDate': {
            if (isAsc) {
              if (this.pageData[index].leaseStartDate > this.pageData[innerIndex].leaseStartDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].leaseStartDate < this.pageData[innerIndex].leaseStartDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'invoiceNumber': {
            if (isAsc) {
              if (this.pageData[index].invoiceNumber > this.pageData[innerIndex].invoiceNumber) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].invoiceNumber < this.pageData[innerIndex].invoiceNumber) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'transactionReasonName': {
            if (isAsc) {
              if (this.pageData[index].transactionReasonName > this.pageData[innerIndex].transactionReasonName) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].transactionReasonName < this.pageData[innerIndex].transactionReasonName) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'agreementSignedOn': {
            if (isAsc) {
              if (this.pageData[index].agreementSignedOn > this.pageData[innerIndex].agreementSignedOn) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].agreementSignedOn < this.pageData[innerIndex].agreementSignedOn) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'agreementExpires': {
            if (isAsc) {
              if (this.pageData[index].agreementExpires > this.pageData[innerIndex].agreementExpires) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].agreementExpires < this.pageData[innerIndex].agreementExpires) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'tenantStatusID': {
            if (isAsc) {
              if (this.pageData[index].tenantStatusID > this.pageData[innerIndex].tenantStatusID) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].tenantStatusID < this.pageData[innerIndex].tenantStatusID) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'tenantStatus': {
            if (isAsc) {
              if (this.pageData[index].tenantStatus > this.pageData[innerIndex].tenantStatus) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].tenantStatus < this.pageData[innerIndex].tenantStatus) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'nextInspectionDate': {
            if (isAsc) {
              if (this.pageData[index].nextInspectionDate > this.pageData[innerIndex].nextInspectionDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].nextInspectionDate < this.pageData[innerIndex].nextInspectionDate) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'daysOver': {
            if (isAsc) {
              if (this.pageData[index].daysOver > this.pageData[innerIndex].daysOver) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].daysOver < this.pageData[innerIndex].daysOver) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'daysDue': {
            if (isAsc) {
              if (this.pageData[index].daysDue > this.pageData[innerIndex].daysDue) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].daysDue < this.pageData[innerIndex].daysDue) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'vacatingDays': {
            if (isAsc) {
              if (this.pageData[index].vacatingDays > this.pageData[innerIndex].vacatingDays) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].vacatingDays < this.pageData[innerIndex].vacatingDays) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'supplierName': {
            if (isAsc) {
              if (this.pageData[index].supplierName > this.pageData[innerIndex].supplierName) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].supplierName < this.pageData[innerIndex].supplierName) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'workDetail': {
            if (isAsc) {
              if (this.pageData[index].workDetail > this.pageData[innerIndex].workDetail) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].workDetail < this.pageData[innerIndex].workDetail) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }
          case 'dateIssued': {
            if (isAsc) {
              if (this.pageData[index].dateIssued > this.pageData[innerIndex].dateIssued) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            else {
              if (this.pageData[index].dateIssued < this.pageData[innerIndex].dateIssued) {
                let temp = this.pageData[index];
                this.pageData[index] = this.pageData[innerIndex];
                this.pageData[innerIndex] = temp;
              }
            }
            break;
          }


        }
      }
    }
    this.showWaitLoader = false;
  }
  //#endregion

  //#region Tenant In Arrears
  onPropertiesTenantsInArrearsGraterThan3Details(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.TenantInArrearsGreaterThan3Days;
    this.detailsTitle = "Tenant In Arrears (Paid To Date) >= 3 days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesTenantsInArrearsGraterThan3Details =
      this._service.GetPropertiesTenantsInArrearsGraterThan3Details
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesTenantsInArrearsDetails[]) => {
          return new GetPropertiesTenantsInArrearsGraterThan3Details(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesTenantsInArrearsGraterThan3.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  onPropertiesTenantsInArrearsGraterThan10Details(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.TenantInArrearsGreaterThan10Days;
    this.detailsTitle = "Tenant In Arrears (Paid To Date) >= 10 days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesTenantsInArrearsGraterThan10Details =
      this._service.GetPropertiesTenantsInArrearsGraterThan10Details
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesTenantsInArrearsDetails[]) => {
          return new GetPropertiesTenantsInArrearsGraterThan10Details(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesTenantsInArrearsGraterThan10.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#region Lease Expiry Overdue
  onPropertiesLeaseExpiryOverdueDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.LeaseExpiryOverdue;
    this.clearDetails()
    this.detailsTitle = "Lease Expiry Over Due";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesLeaseExpiryOverdueDetails =
      this._service.GetPropertiesLeaseExpiryOverdueDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesLeaseExpiryOverdueDetails[]) => {
          return new GetPropertiesLeaseExpiryOverdueDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesLeaseExpiryOverdue.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  onPropertiesLeaseExpiryDueWithinDaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.LeaseExpiryDueWithin30Days;
    this.clearDetails()
    this.detailsTitle = "Lease Expiry Due within 30 Days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesLeaseExpiryDueWithinDaysDetails =
      this._service.GetPropertiesLeaseExpiryDueWithinDaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesLeaseExpiryOverdueDetails[]) => {
          return new GetPropertiesLeaseExpiryDueWithinDaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesLeaseExpiryDueWithinDays.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#region Vacant Properties Details
  onPropertiesVacantLessThanEqual14DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.VacantPropertiesLessThanEqual14Days;
    this.clearDetails()
    this.detailsTitle = "Vacant Properties <= 14 days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesVacantLessThanEqual14DaysDetails =
      this._service.GetPropertiesVacantLessThanEqual14DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesVacantDetails[]) => {
          return new GetPropertiesVacantLessThanEqual14DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesVacantLessThan14.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  onPropertiesVacantGraterThan14DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.VacantPropertiesGreaterThan14Days;
    this.clearDetails()
    this.detailsTitle = "Vacant Properties > 14 Days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesVacantGraterThan14DaysDetails =
      this._service.GetPropertiesVacantGraterThan14DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesVacantDetails[]) => {
          return new GetPropertiesVacantGraterThan14DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesVacantGraterThan14.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#region Properties Gained/Lost
  onPropertiesGainedLast6MonthsDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.PropertiesGainedLast6Months;
    this.clearDetails()
    this.detailsTitle = "Properties Gained Last 6 months";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesGainedLast6MonthsDetails =
      this._service.GetPropertiesGainedLast6MonthsDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesGainedDetails[]) => {
          return new GetPropertiesGainedLast6MonthsDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesGained.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  onPropertiesLostLast6MonthsDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.PropertiesLostLast6Months;
    this.clearDetails()
    this.detailsTitle = "Properties Lost Last 6 months - Full Management";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesLostLast6MonthsDetails =
      this._service.GetPropertiesLostLast6MonthsDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesLostDetails[]) => {
          return new GetPropertiesLostLast6MonthsDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesLost.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#region Overdue Tenant Invoices
  onPropertiesTenantInvoicesOverdueWithin2To14DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.OverdueTenantInvoices2To14Days;
    this.clearDetails()
    this.detailsTitle = "Overdue Tenant Invoices 2-14 days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetails =
      this._service.GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesTenantInvoicesOverdueWithinDaysDetails[]) => {
          return new GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesTenantInvoicesOverdueWithin2To14Days.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  onPropertiesTenantInvoicesOverdueGreaterThan14DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.OverdueTenantInvoicesGreaterThan14Days;
    this.clearDetails()
    this.detailsTitle = "Overdue Tenant Invoices > 14 days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetails =
      this._service.GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesTenantInvoicesOverdueWithinDaysDetails[]) => {
          return new GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesTenantInvoicesOverdueGreaterThan14Days.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#egion Periodic Leases
  onPropertiesPeriodicLeasesDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.PeriodicLeases;
    this.clearDetails()
    this.detailsTitle = "Periodic Leases";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesPeriodicLeasesDetails =
      this._service.GetPropertiesPeriodicLeasesDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesPeriodicLeasesDetails[]) => {
          return new GetPropertiesPeriodicLeasesDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesPeriodicLeases.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#egion Properties Leased
  onPropertiesLeasedLast30DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.PropertiesLeasedLast30Days;
    this.clearDetails()
    this.detailsTitle = "Properties Leased Last 30 days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesLeasedLast30DaysDetails =
      this._service.GetPropertiesLeasedLast30DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesLeasedDaysDetails[]) => {
          return new GetPropertiesLeasedLast30DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesLeasedDays.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  onPropertiesLeaseRenewedLast30DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.LeaseRenewedLast30Days;
    this.clearDetails()
    this.detailsTitle = "Lease Renewed Last 30 Days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesLeaseRenewedLast30DaysDetails =
      this._service.GetPropertiesLeaseRenewedLast30DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesLeaseRenewedDaysDetails[]) => {
          return new GetPropertiesLeaseRenewedLast30DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesLeaseRenewedDays.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#region Properties Under Management
  onPropertiesUnderManagementDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.PropertiesUnderManagement;
    this.clearDetails()
    this.detailsTitle = "Properties Under Management";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesUnderManagementDetails =
      this._service.GetPropertiesUnderManagementDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesUnderManagementDetails[]) => {
          return new GetPropertiesUnderManagementDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertyManagement.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#region Inspection
  onPropertiesInspectionOverdueDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.InspectionOverdue;
    this.clearDetails()
    this.detailsTitle = "Inspection Overdue";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesInspectionOverdueDetails =
      this._service.GetPropertiesInspectionOverdueDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesInspectionOverdueDetails[]) => {
          return new GetPropertiesInspectionOverdueDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesInspectionOverdue.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  onPropertiesInspectionDueWithin7DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.InspectionDueWithin7Days;
    this.clearDetails()
    this.detailsTitle = "Inspection Due within 7 Days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesInspectionDueWithin7DaysDetails =
      this._service.GetPropertiesInspectionDueWithin7DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesInspectionDueDetails[]) => {
          return new GetPropertiesInspectionDueWithin7DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesInspectionDueWithin7Days.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  onPropertiesInspectionDueWithin30DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.InspectionDueWithin30Days;
    this.clearDetails()
    this.detailsTitle = "Inspection Due Date Not Entered";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesInspectionDueWithin30DaysDetails =
      this._service.GetPropertiesInspectionDueWithin30DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesInspectionDueDetails[]) => {
          return new GetPropertiesInspectionDueWithin30DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesInspectionDueWithin30Days.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#region Tenant Vacating
  onPropertiesTenantVacatingWithin30DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.TenantVacatingWithin30Days;
    this.clearDetails()
    this.detailsTitle = "Tenant Vacating within 30 Days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesTenantVacatingWithin30DaysDetails =
      this._service.GetPropertiesTenantVacatingWithin30DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesTenantVacatingWithinDaysDetails[]) => {
          return new GetPropertiesTenantVacatingWithin30DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesTenantVacatingWithinDays.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#region Outstanding Work Order
  onPropertiesOutstandingWorkOrderDue3To14DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.OutstandingWorkOrderDue3To14Days;
    this.clearDetails()
    this.detailsTitle = "Outstanding Work Order Due 3–14 days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesOutstandingWorkOrderDue3To14DaysDetails =
      this._service.GetPropertiesOutstandingWorkOrderDue3To14DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesOutstandingWorkOrderDaysDetails[]) => {
          return new GetPropertiesOutstandingWorkOrderDue3To14DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesOutstandingWorkOrder3To14Days.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  onPropertiesOutstandingWorkOrderGreaterThan14DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.OutstandingWorkOrderGreaterThan14Days;
    this.clearDetails()
    this.detailsTitle = "Outstanding Work Order > 14 days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetails =
      this._service.GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesOutstandingWorkOrderDaysDetails[]) => {
          return new GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesOutstandingWorkOrderGraterThan14Days.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#region Agreement
  onPropertiesAgreementRenewalDueWithin90DaysDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.AgreementRenewalDueWithin90Days;
    this.clearDetails()
    this.detailsTitle = "Agreement Renewal Due within 90 days";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesAgreementRenewalDueWithin90DaysDetails =
      this._service.GetPropertiesAgreementRenewalDueWithin90DaysDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesAgreementRenewalDueDetails[]) => {
          return new GetPropertiesAgreementRenewalDueWithin90DaysDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesAgreementRenewalDue.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  onPropertiesAgreementExpiredDetails(event) {
    this.detailsRecordFound = false;
    this._reportName = enReportName.AgreementExpired;
    this.clearDetails()
    this.detailsTitle = "Agreement Expired";
    this.showDetails = true;
    this.showWaitLoader = true;
    this.subcription_GetPropertiesAgreementExpiredDetails =
      this._service.GetPropertiesAgreementExpiredDetails
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        )
        .map((data: IPropertiesAgreementExpiredDetails[]) => {
          return new GetPropertiesAgreementExpiredDetails(data);
        })
        .subscribe(action => {
          this.showWaitLoader = false;
          this.detailsRecordFound = (!!action.payload && !!action.payload.length);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          setTimeout(() => {
            this.pageData = this.ngRedux.getState().homemanagement.dashboard.propertiesAgreementExpired.details;
          }, 1000);
        }),
      error => {
        this.showWaitLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  //#endregion

  //#endregion

  //#region Without Login Function
  get calledWithoutLogin(): boolean {
    this.databaseName = this.route.snapshot.paramMap.get("databaseName");
    this.sessionID = this.route.snapshot.paramMap.get("sessionID");
    this.agencyID = this.route.snapshot.paramMap.get("agencyID");
    this.departmentID = this.route.snapshot.paramMap.get("departmentID");
    this.staffID = this.route.snapshot.paramMap.get("staffID");

    let result: boolean =
      (
        (!!this.databaseName && !!this.databaseName.length)
        && (!!this.sessionID && !!this.sessionID.length)
        && (!!this.agencyID && !!this.agencyID.length)
        && (!!this.departmentID && !!this.departmentID.length)
        && (!!this.staffID && !!this.staffID.length)
      );
    return result;
  }
  //#endregion

  sanitizeSMS(mobileNumber: string) {
    return this.sanitizer.bypassSecurityTrustUrl("sms:" + mobileNumber);
  }

  ngOnInit() {
    if (this.calledWithoutLogin) {
      this._authservice
        .GetStaffRoles(this.databaseName, this.staffID)
        .subscribe((result) => {
          if (!!result && !result.isError) {
            this.userRoles = result.response;
          }
          else if (!!result && result.isError) {
            throw new Error(result.message)
          }
          else {
            throw new Error("Unknown Error");
          }
        });
      this._service.ReloadDashboard
        (
          this.databaseName,
          this.sessionID,
          this.agencyID,
          this.departmentID,
          this.staffID
        );
    }
    else {
      this._authservice
        .GetSessionData()
        .subscribe((result) => {
          if (!!result) {
            this.userRoles = result.userRoles;
          }
        });
      this._service.ReloadDashboard();
    }
  }


  ngAfterViewInit() {
    LoadScript.init();
  }
  ngOnDestroy() {
    if (!!this.subcription_GetPropertiesTenantsInArrearsGraterThan3Details) this.subcription_GetPropertiesTenantsInArrearsGraterThan3Details.unsubscribe();
    if (!!this.subcription_GetPropertiesTenantsInArrearsGraterThan10Details) this.subcription_GetPropertiesTenantsInArrearsGraterThan10Details.unsubscribe();
    if (!!this.subcription_GetPropertiesLeaseExpiryOverdueDetails) this.subcription_GetPropertiesLeaseExpiryOverdueDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesLeaseExpiryDueWithinDaysDetails) this.subcription_GetPropertiesLeaseExpiryDueWithinDaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesVacantLessThanEqual14DaysDetails) this.subcription_GetPropertiesVacantLessThanEqual14DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesVacantGraterThan14DaysDetails) this.subcription_GetPropertiesVacantGraterThan14DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesGainedLast6MonthsDetails) this.subcription_GetPropertiesGainedLast6MonthsDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesLostLast6MonthsDetails) this.subcription_GetPropertiesLostLast6MonthsDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetails) this.subcription_GetPropertiesTenantInvoicesOverdueWithin2To14DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetails) this.subcription_GetPropertiesTenantInvoicesOverdueGreaterThan14DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesPeriodicLeasesDetails) this.subcription_GetPropertiesPeriodicLeasesDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesLeasedLast30DaysDetails) this.subcription_GetPropertiesLeasedLast30DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesLeaseRenewedLast30DaysDetails) this.subcription_GetPropertiesLeaseRenewedLast30DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesUnderManagementDetails) this.subcription_GetPropertiesUnderManagementDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesInspectionOverdueDetails) this.subcription_GetPropertiesInspectionOverdueDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesInspectionDueWithin7DaysDetails) this.subcription_GetPropertiesInspectionDueWithin7DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesInspectionDueWithin30DaysDetails) this.subcription_GetPropertiesInspectionDueWithin30DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesTenantVacatingWithin30DaysDetails) this.subcription_GetPropertiesTenantVacatingWithin30DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesOutstandingWorkOrderDue3To14DaysDetails) this.subcription_GetPropertiesOutstandingWorkOrderDue3To14DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetails) this.subcription_GetPropertiesOutstandingWorkOrderGreaterThan14DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesAgreementRenewalDueWithin90DaysDetails) this.subcription_GetPropertiesAgreementRenewalDueWithin90DaysDetails.unsubscribe();
    if (!!this.subcription_GetPropertiesAgreementExpiredDetails) this.subcription_GetPropertiesAgreementExpiredDetails.unsubscribe();
  }
}
enum enReportName {
  TenantInArrearsGreaterThan3Days,
  TenantInArrearsGreaterThan10Days,
  OverdueTenantInvoices2To14Days,
  OverdueTenantInvoicesGreaterThan14Days,
  InspectionOverdue,
  InspectionDueWithin7Days,
  InspectionDueWithin30Days,
  LeaseExpiryOverdue,
  LeaseExpiryDueWithin30Days,
  TenantVacatingWithin30Days,
  OutstandingWorkOrderDue3To14Days,
  OutstandingWorkOrderGreaterThan14Days,
  VacantPropertiesLessThanEqual14Days,
  VacantPropertiesGreaterThan14Days,
  PropertiesGainedLast6Months,
  PropertiesLostLast6Months,
  AgreementRenewalDueWithin90Days,
  AgreementExpired,
  PeriodicLeases,
  PropertiesLeasedLast30Days,
  LeaseRenewedLast30Days,
  PropertiesUnderManagement
}
