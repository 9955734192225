import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { PropertyManagementService } from "./../propertymanagement.service";

import { IAppState } from "../../store/store";
import { LoaderService } from "./../../core/loader/loader.service";
import { IDNamePair, AuthService, SessionInfo, ServiceResponse } from "./../../services/authservice";

import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";

import "../../../../../wwwroot/js/scripts.js";
import { GetPropertyManagerWorkOrderSearchPage, GetServiceTypesInWorkOrderSearchPage, GetWorkOrder } from '../propertymanagement.action';
import { IManagementAgreement, IWorkOrderResult } from '../propertymanagement';
declare var $: JQueryStatic;
declare var LoadScript: any;


@Component({
  selector: 'app-workorders',
  templateUrl: './workorders.component.html',
  styleUrls: ['./workorders.component.css']
})
export class WorkOrdersComponent implements OnInit {
  private model: FormGroup;
  private dropdownSettings: any = {};
  private dialogRef: any;
  private propertyManagerIDs: IDNamePair<string>[];
  private statusItems: IDNamePair<number>[];
  private selectedStatusIDs: IDNamePair<number>[];
  private serviceTypeIDs: IDNamePair<number>[];
  private subscription_GetPropertyManagers: Subscription;
  private subscription_GetSupplierServiceTypes: Subscription;
  private subscription_GetWorkOrders: Subscription;

  constructor(private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _service: PropertyManagementService,
    private _authService: AuthService
  ) {
    this.statusItems = [
      { id: 1, name: "Progress" },
      { id: 2, name: "Paid" },
      { id: 4, name: "Cancelled" },
      { id: 8, name: "Invoiced" },
      { id: 16, name: "Completed" }
    ];
    this.selectedStatusIDs = [{ id: 1, name: "Progress" }];
    this.model = new FormGroup({
      searchText: new FormControl(this.ngRedux.getState().propertymanagement.workorder.search.searchText),
      statusIDs: new FormControl(this.ngRedux.getState().propertymanagement.workorder.search.statusIDs, Validators.required),
      serviceTypeIDs: new FormControl(this.ngRedux.getState().propertymanagement.workorder.search.serviceTypeIDs),
      dueDateFrom: new FormControl(this.ngRedux.getState().propertymanagement.workorder.search.dueDateFrom),
      dueDateTo: new FormControl(this.ngRedux.getState().propertymanagement.workorder.search.dueDateTo),
      searchFields: new FormControl(this.ngRedux.getState().propertymanagement.workorder.search.searchFields),
      no: new FormControl(this.ngRedux.getState().propertymanagement.workorder.search.no),
      selectedPropertyManagerIDs: new FormControl(this.ngRedux.getState().propertymanagement.workorder.search.selectedPropertyManagerIDs),
      selectedServiceTypesIDs: new FormControl(this.ngRedux.getState().propertymanagement.workorder.search.selectedServiceTypesIDs)
    });
  }


  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  @select(state => (state as IAppState).propertymanagement.workorder.search.propertyManagerIDs) propertyManagerIDs$;
  @select(state => (state as IAppState).propertymanagement.workorder.search.serviceTypeIDs) serviceTypeIDs$;
  @select(state => (state as IAppState).propertymanagement.workorder.search.showSearchResult) showSearchResult$;

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this._loader.show();

    this._authService.GetSessionData()
      .subscribe((session: SessionInfo) => {
        this.subscription_GetPropertyManagers =
          this._service.GetPropertyManagers()
            .map((data: IDNamePair<string>[]) => {
              return new GetPropertyManagerWorkOrderSearchPage(data);
            })
            .subscribe(action => {
              this.propertyManagerIDs = (!!action.payload.filter(staff => staff.id == session.staffID).length ? action.payload.filter(staff => staff.id == session.staffID) : null) || action.payload;
              this._loader.hide();
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            }),
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          }
      });

    this.subscription_GetSupplierServiceTypes =
      this._service.GetSupplierServiceTypes()
        // .map((data: ServiceResponse<IDNamePair<number>[]>) => {
        //   return new GetServiceTypesInWorkOrderSearchPage(data);
        // })
        .subscribe((data: ServiceResponse<IDNamePair<number>[]>) => {
          if (data.isError) {
            this.openDialog("Error", data.message, "alertdialog");
          }
          else {
            let action = new GetServiceTypesInWorkOrderSearchPage(data.response);
            this.serviceTypeIDs = action.payload;
            this._loader.hide();
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          }
        })
    error => {
      this._loader.hide();
      this.openDialog("Alert", error, "alertdialog");
    }

    this.model
  }

  ngAfterViewInit() {
    LoadScript.init();
  }
  ngOnDestroy() {
    if (!!this.subscription_GetPropertyManagers) this.subscription_GetPropertyManagers.unsubscribe();
    if (!!this.subscription_GetSupplierServiceTypes) this.subscription_GetSupplierServiceTypes.unsubscribe();
  }

  onSubmit() {
    var statusIDs: number = 0;
    if (!!this.model.controls.statusIDs.value) {
      let stItems: IDNamePair<number>[] = this.model.controls.statusIDs.value;
      stItems.forEach(type => {
        statusIDs = statusIDs | type.id;
      });
    }

    var serviceTypeIds: number[] = null;
    /*if (!!this.serviceTypeIDs) {
      serviceTypeIds = this.serviceTypeIDs.map(item => item.id);
    }*/
    if (!!this.model.controls.selectedServiceTypesIDs.value) {
      let supplierServiceTypes: IDNamePair<number>[] = this.model.controls.selectedServiceTypesIDs.value;
      if (
        !!this.ngRedux.getState().propertymanagement.workorder.search.serviceTypeIDs
        && this.ngRedux.getState().propertymanagement.workorder.search.serviceTypeIDs.length === supplierServiceTypes.length
      ) {
        //serviceTypeIds = null;
      }
      else {
        //serviceTypeIds = "";
        //supplierServiceTypes.forEach(type => (serviceTypeIds = serviceTypeIds | type.id));
        //supplierServiceTypes.forEach(type=> (serviceTypeIds += type.id + ","))
        serviceTypeIds = supplierServiceTypes.map(item => item.id);
      }
    }

    var propertyManagerIDs: string[] = null;
    if (!!this.model.controls.selectedPropertyManagerIDs.value) {
      let propManagerIds: IDNamePair<string>[] = this.model.controls.selectedPropertyManagerIDs.value;
      if (!!this.ngRedux.getState().propertymanagement.workorder.search.propertyManagerIDs
        && this.ngRedux.getState().propertymanagement.workorder.search.propertyManagerIDs.length == propManagerIds.length) {
        propertyManagerIDs = null;
      }
      else {
        propertyManagerIDs = propManagerIds.map(item => { return item.id });
      }
    }
    this._loader.show();
    this.subscription_GetWorkOrders =
      this._service.GetWorkOrder(
        this.model.controls.searchText.value,
        statusIDs,
        serviceTypeIds,
        this.model.controls.dueDateFrom.value,
        this.model.controls.dueDateTo.value,
        this.model.controls.searchFields.value,
        this.model.controls.no.value,
        propertyManagerIDs,
        null,
        false
      )
      .subscribe(data => {
        this._loader.hide();
        if (data.isError) {
          this.openDialog("Error", data.message, "alertdialog");
        }
        else {
          let action = new GetWorkOrder(data.response);
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this._router.navigate(["/workorders/search"]);
        }
      },
        error => {
          this._loader.hide();
          this.openDialog("Error", error, "alertdialog");
      });



  }

  //#region Close Work Order Popup Dialog
  private managementAgreement: IManagementAgreement = {} as IManagementAgreement;
  private IsWorkOrderSaved: boolean = false;
  private closeOnOutsideClick: boolean = false;
  private showWorkOrder: boolean = false;
  onWorkOrderSave(event: any) {
    this.IsWorkOrderSaved = event;    
  }
  onCloseWorkOrder(event: any) {
    if (!this.IsWorkOrderSaved) {
      this.showWorkOrder = !confirm("Work order not saved!! Still want to close?");
      this.closeOnOutsideClick = !this.showWorkOrder;
    }
    else {
      this.showWorkOrder = false;
    }
  }
  onAddWorkOrder() {
    this.showWorkOrder = true;
  }
  //#endregion

}
