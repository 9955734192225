import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from '@angular/material';
import { Sort, SortDirection } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { IAppState } from '../../store/store';
import { AuthService } from '../../services/authservice';
import { LoaderService } from "./../../core/loader/loader.service";
import { PropertyManagementService } from '../propertymanagement.service';
import { GridViewDataTable } from '../components/gridview/gridview.component';
import { DialogBodyComponent, enDialogRole } from '../../dialog-body/dialog-body.component';



@Component({
  selector: 'app-leaserenewallog',
  templateUrl: './leaserenewallog.component.html',
  styleUrls: ['./leaserenewallog.component.css']
})
export class LeaseRenewalLogComponent implements OnInit, OnDestroy {

  constructor(private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private _datePipe: DatePipe,
    private _authService: AuthService,
    private _service: PropertyManagementService
  ) { }

  //#region Properties
  get calledFromOutside(): boolean {
    return (this.route.snapshot.routeConfig.path.indexOf("leaserenewallog") >= 0 && !!this.route.snapshot.paramMap.get("sessionID") && this.route.snapshot.paramMap.get("sessionID").indexOf('00000000-') < 0);
  }
  get database(): string {
    if (this.route.snapshot.routeConfig.path.indexOf("leaserenewallog") >= 0 && !!this.route.snapshot.paramMap.get("database")) {
      return this.route.snapshot.paramMap.get("database");
    }
    else {
      return null;
    }
  }
  get sessionID(): string {
    if (this.route.snapshot.routeConfig.path.indexOf("leaserenewallog") >= 0 && !!this.route.snapshot.paramMap.get("sessionID") && this.route.snapshot.paramMap.get("sessionID").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("sessionID");
    }
    else {
      return null;
    }
  }
  get leaseRenewalID(): string {
    if (this.route.snapshot.routeConfig.path.indexOf("leaserenewallog") >= 0 && !!this.route.snapshot.paramMap.get("leaserenewalID") && this.route.snapshot.paramMap.get("leaserenewalID").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("leaserenewalID");
    }
    else {
      return null;
    }
  }
  //#endregion
  //#region Variables
  private logs: GridViewDataTable[][] = [];
  private showLogLoader: boolean = false;
  //#endregion
  //#region Dialog
  private dialogRef: any;
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  //#endregion
  //#region Functions
  waterChargesPM(waterUsageCondition: number | null): string {
    if (waterUsageCondition == 1) {
      return 'Usage and Supply';
    }
    else if (waterUsageCondition == 2) {
      return 'Supply Only';
    }
    else if (waterUsageCondition == 3) {
      return 'Usage Only';
    }
    else if (waterUsageCondition == 4) {
      return 'No Charge';
    }
    else {
      return '';
    }
  }
  proposedRentPeriod(proposedNewRentPeriod: number): string {
    if (proposedNewRentPeriod == 7)
      return "Weekly";
    else if (proposedNewRentPeriod == 14)
      return "Fortnightly";
    else if (proposedNewRentPeriod == 30)
      return "Monthly";
  }
  //#endregion

  ngOnInit() {
    this.showLogLoader = true;
    this._service.GetLeaseRenewalLogs(this.database, this.sessionID, this.leaseRenewalID)
      .subscribe(data => {        
        if (!!data && !data.isError) {
          this.logs = data.response.map(item => [
            { fieldName: "id", value: item.id, showText: (!!item.id ? item.id : "-"), header: "ID", visible: false },
            { fieldName: "leaseRenewalID", value: item.leaseRenewalID, showText: (!!item.leaseRenewalID ? item.leaseRenewalID : "-"), header: "Lease Renewal ID", visible: false },
            { fieldName: "proposedBy", value: item.proposedBy, showText: (!!item.proposedBy ? item.proposedBy : "-"), header: "Proposed By", visible: true, sortable: true },
            { fieldName: "proposedByContactID", value: item.proposedByContactID, showText: (!!item.proposedByContactID ? item.proposedByContactID : "-"), header: "Proposed By ContactID ID", visible: false, sortable: true },
            { fieldName: "proposedByContactName", value: item.proposedByContactName, showText: (!!item.proposedByContactName ? item.proposedByContactName : "-"), header: "Proposed By Contact Name", visible: true, sortable: true },
            /*{ fieldName: "proposedLeasePeriodInMonths", value: item.proposedLeasePeriodInMonths, showText: (!!item.proposedLeasePeriodInMonths ? item.proposedLeasePeriodInMonths.toString() : "-"), header: "Proposed Lease Period (Months)", visible: true, sortable: true },*/
            { fieldName: "proposedLeasePeriodInMonths", value: item.proposedLeasePeriodInMonths, showText: (!!item.proposedLeasePeriodInMonths ? `${item.proposedLeasePeriodInMonths.toString()} Months` : (!!item.proposedLeasePeriodInWeeks ? `${item.proposedLeasePeriodInWeeks.toString()} Weeks` : "-")), header: "Proposed Lease Period", visible: true, sortable: true },
            { fieldName: "proposedNewRent", value: item.proposedNewRent, showText: (!!item.proposedNewRent ? "$" + item.proposedNewRent.toString() : "-"), header: "Proposed New Rent", visible: true },
            { fieldName: "proposedNewRentPeriod", value: item.proposedNewRentPeriod, showText: (!!item.proposedNewRentPeriod ? this.proposedRentPeriod(item.proposedNewRentPeriod) : "-"), header: "Proposed New Rent Period", visible: true, sortable: true },
            { fieldName: "waterUsageCondition", value: item.waterUsageCondition, showText: this.waterChargesPM(item.waterUsageCondition), header: "Water Usage", visible: true, sortable: true },
            { fieldName: "comment", value: item.comment, showText: (!!item.comment ? item.comment : "-"), header: "Comment", visible: true, sortable: true },
            { fieldName: "dateTimeLocal", value: item.dateTimeLocal, showText: this._datePipe.transform(item.dateTimeLocal, "dd/MM/yyyy hh:mm a"), header: "Date", visible: true, sortable: true },
          ]);
        }
        else {
          this.openDialog("Error", data.message, "alertdialog");
          Observable.throwError(data.message);
        }
        this.showLogLoader = false;
      }),
      error => {
        this.showLogLoader = false;
        this.openDialog("Alert", error, "alertdialog");
      };
  }
  ngOnDestroy() {
  }
  //#region Events
  onGetSelectedRecord(event) {

  }
  //#endregion

}
