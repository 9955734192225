import { Options } from 'ng5-slider';
import { Observable } from "rxjs/Observable";
import { Injectable, Inject, PLATFORM_ID, Injector } from "@angular/core";
import {
    Http,
    Headers,
    Response,
    RequestOptions,
    RequestMethod
} from "@angular/http";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { BaseService } from './../../services/baseservice';
import { IDNamePair, ServiceResponse, ContactDetails } from "./../../services/authservice";

@Injectable()
export class QrcodeViewerService extends BaseService {
    constructor(private _http: Http, private HTTP: HttpClient, @Inject(PLATFORM_ID) private platformID: Object) {
        super(HTTP, platformID);
    }

    GetOpenQRCodeLinkImageUrl
    (
        qrCodeText: string
    ): Observable<ServiceResponse<string>> {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
      return this._http
        .post("api/Ofi/GetOpenQRCodeLinkImageUrl", {
            qrCodeText: qrCodeText
        }, options)
        .map((res: Response) => res.json())
        .catch((error: any) =>
          Observable.throw(error.json().error || "Server Error")
        );
    }
}