import { IRecipientType, IEmailTemplate, ISearchTemplate, IAddEditTemplate, IWidget, IEmailImage, IEmailContactList, IContactEmail, IEmail, ISearchEmail, IAddEditEmail, IAddEditContactLists } from './emarketing';
import { Action } from 'redux';
import { IDNamePair } from "../services/authservice";

export enum enActionTypes {
    GetRecipientTypesAction = "[TEMPLATES] Get Template Recipient Types Action",
    GetEmailTemplatesAction = "[TEMPLATES] Get Email Templates Action",
    CloseEmailTemplatesSearchResultAction = "[TEMPLATES] Closing Email Template Search Result Action",
    EditTempateAction = "[TEMPLATES] Viewing A Tempate Action",
    EditTempateCloseAction = "[TEMPLATES] Closing An Edit Tempate Action",
    AddWidgetToTemplateAction = "[TEMPLATES] Adding An Widget To Template Action",
    RemoveWidgetFromTemplateAction = "[TEMPLATES] Removing An Widget From Template Action",
    GetTemplateImageTypesAction = "[TEMPLATES] Get The Image Type List Action",
    GetTemplateEmailImagesAction = "[TEMPLATES] Get The Images Of The Selected Image Type Action",
    LoadNewTemplatePageAction = "[TEMPLATES] Load New Template Page Action",
    GetTemplateDepartmentsAndStaffsAction = "[TEMPLATES] Load Departments And Staffs Action",
    CloseNewTemplatePageAction = "[TEMPLATES] Closing New Email Template Page Action",

    LoadStaffsAndStatusesOfEmailSearchPageAction = "[EMAILS] Load Staffs and Statuses Of Email Search Page Dropdowns' Items Action",
    LoadNewEmailPageAction = "[EMAILS] Load New Email Page Action",
    CloseNewEmailPageAction = "[EMAILS] Closing New Email Page Action",
    GetRecipientTypesOnNewEmailPageAction = "[EMAILS] Get Template Recipient Types On New Email Page Action",
    GetEmailTemplatesOnNewEmailPageAction = "[EMAILS] Get Email Templates On New Email Page Action",
    AfterNewEmailCreationGotoEmailEditPageAction = "[EMAILS] After New Email Creation Moving To Email Edit (For The Newly Created Email) Page Action",
    SelectEmailTemplateOnNewEmailPageAction = "[EMAILS] Select Email Template On New Email Page Action",
    GetEmailsSearchAction = "[EMAILS] Search And Get Emails Action",
    EditEmailFromSearchResultAction = "[EMAILS] Moving To Edit Email Page From Email Search Result Action",
    ShowHidePreviewEmailFromSearchResultEditPageAction = "[EMAILS] Show/Hide Preview Email Page From Edit Email Page Opened From Email Search Result Page Action",    
    ModifyEmailAction = "[EMAILS] Modifying Email Action",
    RemoveEmailAction = "[EMAILS] Removing An Email Action",

    LoadContactListsSearchPageAction = "[CONTACT LISTS] Initialize Contact List Search Page By Loading Contact Statuses Action",
    GetContactListsSearchAction = "[CONTACT LISTS] Get Contact Lists Search Action",
    LoadNewContactListsPageAction = "[CONTACT LISTS] Load New Contact Lists Page Action",
    EditContactListAction = "[CONTACT LISTS] Load The Contact Lists With The Selected Contact List ID Action",
    CloseContactListAddEditPageAction = "[CONTACT LISTS] Close Contact List Add/Edit Page Action",
}
export class GetRecipientTypes implements Action {
    readonly type = enActionTypes.GetRecipientTypesAction;
    constructor(public readonly payload: IRecipientType[]) {}
}
export class GetEmailTemplates implements Action {    
    readonly type = enActionTypes.GetEmailTemplatesAction;
    constructor(public readonly payload: ISearchTemplate) {}
}
export class CloseEmailTemplatesSearchResult implements Action {    
    readonly type = enActionTypes.CloseEmailTemplatesSearchResultAction;
    constructor(public readonly payload: boolean) {}
}
export class EditTempate implements Action {    
    readonly type = enActionTypes.EditTempateAction;
    constructor(public readonly payload: IAddEditTemplate) {}
}
export class EditTempateClose implements Action {
    readonly type = enActionTypes.EditTempateCloseAction;
    constructor(public readonly payload: boolean) {}
}
export class AddWidgetToTemplate implements Action {
    readonly type = enActionTypes.AddWidgetToTemplateAction;
    constructor(public readonly payload: { htmlBody: string, widget: IWidget }) {}
}
export class RemoveWidgetFromTemplate implements Action {
    readonly type = enActionTypes.RemoveWidgetFromTemplateAction;
    constructor(public readonly payload: string) {}
}
export class GetTemplateImageTypes implements Action {
    readonly type = enActionTypes.GetTemplateImageTypesAction;
    constructor(public readonly payload: IDNamePair<number>[]) {}
}
export class GetTemplateEmailImages implements Action {
    readonly type = enActionTypes.GetTemplateEmailImagesAction;
    constructor(public readonly payload: IEmailImage[]) {}
}
export class LoadNewTemplatePage implements Action {
    readonly type = enActionTypes.LoadNewTemplatePageAction;
    constructor(public readonly payload: { recipientTypes: IRecipientType[], agencies: IDNamePair<string>[] }) {}
}
export class GetTemplateDepartmentsAndStaffs implements Action {
    readonly type = enActionTypes.GetTemplateDepartmentsAndStaffsAction;
    constructor(public readonly payload: { departments: IDNamePair<string>[], staffs: IDNamePair<string>[] }) {}    
}
export class CloseNewTemplatePage implements Action {
    readonly type = enActionTypes.CloseNewTemplatePageAction;
    constructor(public readonly payload: boolean) {}    
}
export class LoadStaffsAndStatusesOfEmailSearchPage implements Action {
    readonly type = enActionTypes.LoadStaffsAndStatusesOfEmailSearchPageAction;
    constructor(public readonly payload: { staffs: IDNamePair<string>[], statuses: IDNamePair<number>[] }) {}
}
export class LoadNewEmailPage implements Action {
    readonly type = enActionTypes.LoadNewEmailPageAction;
    constructor(public readonly payload: { recipientSourceTypes: IDNamePair<number>[], recipientTypes: IRecipientType[], contactLists: IEmailContactList[], selectedRecipientSourceType: number }) {}
}
export class CloseNewEmailPage implements Action {
    readonly type = enActionTypes.CloseNewEmailPageAction;
    constructor(public readonly payload: boolean) {}    
}
export class GetRecipientTypesOnNewEmailPage implements Action {
    readonly type = enActionTypes.GetRecipientTypesOnNewEmailPageAction;
    constructor(public readonly payload: IRecipientType[]) {}
}
export class GetEmailTemplatesOnNewEmailPage implements Action {    
    readonly type = enActionTypes.GetEmailTemplatesOnNewEmailPageAction;
    constructor(public readonly payload: IEmailTemplate[]) {}
}
export class AfterNewEmailCreationGotoEmailEditPage implements Action {    
    readonly type = enActionTypes.AfterNewEmailCreationGotoEmailEditPageAction;
    constructor(public readonly payload: { email: IEmail, recipients: IContactEmail[], from: string }) {}
}
export class SelectEmailTemplateOnNewEmailPage implements Action {    
    readonly type = enActionTypes.SelectEmailTemplateOnNewEmailPageAction;
    constructor(public readonly payload: string) {}
}
export class GetEmailsSearch implements Action {    
    readonly type = enActionTypes.GetEmailsSearchAction;
    constructor(public readonly payload: ISearchEmail) {}
}
export class EditEmailFromSearchResult implements Action {    
    readonly type = enActionTypes.EditEmailFromSearchResultAction;
    constructor(public readonly payload: IAddEditEmail) {}
}
export class ShowHidePreviewEmailFromSearchResultEditPage implements Action {    
    readonly type = enActionTypes.ShowHidePreviewEmailFromSearchResultEditPageAction;
    constructor(public readonly payload: boolean) {}
}
export class ModifyEmail implements Action {    
    readonly type = enActionTypes.ModifyEmailAction;
    constructor(public readonly payload: IEmail) {}
}
export class RemoveEmail implements Action {    
    readonly type = enActionTypes.RemoveEmailAction;
    constructor(public readonly payload: IEmail) {}
}
export class LoadContactListsSearchPage implements Action {    
    readonly type = enActionTypes.LoadContactListsSearchPageAction;
    constructor(public readonly payload: IDNamePair<number>[]) {}
}
export class GetContactListsSearch implements Action {    
    readonly type = enActionTypes.GetContactListsSearchAction;
    constructor(public readonly payload: IEmailContactList[]) {}
}
export class LoadNewContactListsPage implements Action {    
    readonly type = enActionTypes.LoadNewContactListsPageAction;
    constructor(public readonly payload: IDNamePair<number>[]) {}
}
export class EditContactList implements Action {    
    readonly type = enActionTypes.EditContactListAction;
    constructor(public readonly payload: { statuses: IDNamePair<number>[], contactList: IEmailContactList }) {}
}
export class CloseContactListAddEditPage implements Action {    
    readonly type = enActionTypes.CloseContactListAddEditPageAction;
    constructor() {}
}
export type EmarketingAction =
| GetRecipientTypes
| GetEmailTemplates
| CloseEmailTemplatesSearchResult
| EditTempate
| EditTempateClose
| AddWidgetToTemplate
| RemoveWidgetFromTemplate
| GetTemplateImageTypes
| GetTemplateEmailImages
| LoadNewTemplatePage
| GetTemplateDepartmentsAndStaffs
| CloseNewTemplatePage
| LoadStaffsAndStatusesOfEmailSearchPage
| LoadNewEmailPage
| CloseNewEmailPage
| GetRecipientTypesOnNewEmailPage
| GetEmailTemplatesOnNewEmailPage
| AfterNewEmailCreationGotoEmailEditPage
| SelectEmailTemplateOnNewEmailPage
| GetEmailsSearch
| EditEmailFromSearchResult
| ShowHidePreviewEmailFromSearchResultEditPage
| ModifyEmail
| RemoveEmail
| LoadContactListsSearchPage
| GetContactListsSearch
| LoadNewContactListsPage
| EditContactList
| CloseContactListAddEditPage;
