import { Reducer } from 'redux';
import { enActionTypes, MaintenanceAction } from './maintenance.action';
import { IMaintenanceState, INITIAL_MAINTENANCE_STATE } from './maintenance.state';

export const maintenanceReducer: Reducer<IMaintenanceState> = (state = INITIAL_MAINTENANCE_STATE(), action: MaintenanceAction): IMaintenanceState => {
  switch (action.type) {
    case enActionTypes.ShowMaintenanceSearchPageAction: {
      return {
        ...state,
        search: Object.assign({}, state.search, { showSearchResult: false, maintenances: [] })
      };
    }
    case enActionTypes.GetMaintenancesSearchAction: {
      return {
        ...state,
        search: Object.assign({}, state.search, { showSearchResult: true, maintenances: action.payload })
      };
    }
    case enActionTypes.CloseMaintenanceResultPageAction: {
      return {
        ...state,
        search: Object.assign({}, state.search, { showSearchResult: action.payload })
      };
    }
    case enActionTypes.GetMaintenanceLookForAction: {
      return {
        ...state,
        search: Object.assign({}, state.search, { lookForList: action.payload })
      };
    }
    case enActionTypes.GetPropertyAddressesAction: {
      return {
        ...state,
        newMaintenance: Object.assign({}, state.newMaintenance, { propertyAddresses: action.payload })
      };
    }
    case enActionTypes.GetJointTenantsAction: {
      return {
        ...state,
        newMaintenance: Object.assign({}, state.newMaintenance, { tenants: action.payload })
      };
    }
    case enActionTypes.GetSupplierServiceTypesAction: {
      return {
        ...state,
        newMaintenance: Object.assign({}, state.newMaintenance, { supplierServiceTypes: action.payload })
      };
    }
    default:
      return state;
  }
}
