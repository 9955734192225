import { Injectable, Inject, PLATFORM_ID, Injector } from "@angular/core";
import {
  Http,
  Headers,
  Response,
  RequestOptions,
  RequestMethod
} from "@angular/http";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { BaseService } from "../services/baseservice";
import { Observable } from "rxjs";
import { IDNameExtraPair, IDNamePair, ServiceResponse } from "../services/authservice";
import { IJointTenant, IMaintenanceSearchResult, IMaintenanceSelect, IRentalTenantPropertyAddress } from "./maintenance.state";

@Injectable()
export class MaintenanceService extends BaseService {
  constructor(private _http: Http, private HTTP: HttpClient, @Inject(PLATFORM_ID) private platformID: Object) {
    super(HTTP, platformID);
  }

  GetPropertyAddressByAddressString(
    database: string,
    sessionID: string,
    addressFilter: string
  ): Observable<ServiceResponse<IRentalTenantPropertyAddress[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Maintenance/GetPropertyAddressByAddressString", {
        database: database,
        sessionID: sessionID,
        addressFilter: addressFilter
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetJointTenantsBySessionAgency(
    database: string,
    sessionID: string,
    rentalTenantID: string,
    callLogID: string
  ): Observable<ServiceResponse<IJointTenant[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Maintenance/GetJointTenantsBySessionAgency", {
        database: database,
        sessionID: sessionID,
        rentalTenantID: rentalTenantID,
        callLogID: callLogID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetSupplierServiceTypesBySessionAgency(
    database: string,
    sessionID: string
  ): Observable<ServiceResponse<IDNameExtraPair<number, number | undefined | null>[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Maintenance/GetSupplierServiceTypesBySessionAgency", {
        database: database,
        sessionID: sessionID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  SaveMaintenance(
    database: string,
    sessionID: string,
    departmentID: string,
    managementAgreementID: string,
    propertyID: string,
    dateTime: string,
    callerContactID: string,
    supplierServiceTypeID: number,
    statusID: number,
    notes: string,
    photo1Name: string,
    photo1Base64: string,
    photo2Name: string,
    photo2Base64: string,
    photo3Name: string,
    photo3Base64: string,
    callLogID: string | undefined | null = null
  ): Observable<ServiceResponse<IMaintenanceSelect>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Maintenance/SaveMaintenance", {
        database: database,
        sessionID: sessionID,
        departmentID: departmentID,
        managementAgreementID: managementAgreementID,
        propertyID: propertyID,
        maintenanceDateTime: dateTime,
        callerContactID: callerContactID,
        supplierServiceTypeID: supplierServiceTypeID,
        statusID: statusID,
        notes: notes,
        photo1Name: photo1Name,
        photo1Base64: photo1Base64,
        photo2Name: photo2Name,
        photo2Base64: photo2Base64,
        photo3Name: photo3Name,
        photo3Base64: photo3Base64,
        callLogID: callLogID
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetMaintenances(
    database: string,
    sessionID: string,
    lookFor: string | null | undefined,
    lookForFilter: string | null | undefined,
    status: number | null | undefined,
    period: number | null | undefined
  ): Observable<ServiceResponse<IMaintenanceSearchResult[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Maintenance/GetMaintenances", {
        database: database,
        sessionID: sessionID,
        lookFor: lookFor,
        lookForFilter: lookForFilter,
        status: status,
        period: period
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetMaintenance(
    database: string,
    sessionID: string,
    id: string
  ): Observable<ServiceResponse<IMaintenanceSelect>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Maintenance/GetMaintenance", {
        database: database,
        sessionID: sessionID,
        id: id
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  GetMaintenanceLookFor(
    database: string,
    sessionID: string,
    lookFor: number,
    lookForFilter: string
  ): Observable<ServiceResponse<IDNamePair<string>[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Maintenance/GetMaintenanceLookFor", {
        database: database,
        sessionID: sessionID,
        lookFor: lookFor,
        lookForFilter: lookForFilter
      }, options)
      .map((res: Response) => res.json())
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
}
