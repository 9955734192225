import { DatePipe } from '@angular/common';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs';

import { IAppState } from "../../store/store";
import { LoaderService } from "./../../core/loader/loader.service";
import { DialogBodyComponent, enDialogRole } from "./../../dialog-body/dialog-body.component";
import { IDNamePair, AuthService, SessionInfo, ServiceResponse } from "./../../services/authservice";
import { OfiService } from './../ofi.service';
import { GetCurrentStaffsOpenPrivateInspectionSearchPage, GetAppointmentsOpenPrivateInspectionSearchPage, ShowAddPrivateInspectionPage } from './../ofi.action';
import { IAppointmentDetailsSearchResult, IDepartmentDto } from '../ofi';

import "../../../../../wwwroot/js/scripts.js";
declare var $: JQueryStatic;
declare var LoadScript: any;

@Component({
  selector: 'app-openprivateinspection',
  templateUrl: './openprivateinspection.component.html',
  styleUrls: ['./openprivateinspection.component.css']
})
export class OpenPrivateInspectionComponent implements OnInit, AfterViewInit, OnDestroy {

  private model: FormGroup;
  private dialogRef: any;
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  private dropdownSettings: any = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  private staffIDs: IDNamePair<string>[];
  private isPropertyLoading: boolean = false;
  private propertyAddress: Observable<IDNamePair<string>[]>;
  private defaultFromDate: Date;
  private defaultToDate: Date;
  private departments: IDepartmentDto[] = [];
  private session: SessionInfo;
  private staffLoading: boolean = false;

  //#region Subcription Variables
  private subscription_GetCurrentStaffs: Subscription;
  private getPropertyAddress_Subcription: Subscription;
  private getAppointments_Subcription: Subscription;
  private getDepartmentsForOfiSearch_Subscription: Subscription;
  //#endregion

  constructor(private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _authService: AuthService,
    private _service: OfiService
  ) {
    this.defaultFromDate = new Date();//new Date((new Date()).setDate(new Date().getDate() - 14));
    this.defaultToDate = new Date((new Date()).setDate(new Date().getDate() + 14));

    this.model = new FormGroup({
      dateFrom: new FormControl(`${this.defaultFromDate.getFullYear()}-${(this.defaultFromDate.getMonth() + 1).toString().padStart(2, "0")}-${this.defaultFromDate.getDate().toString().padStart(2, "0")}`),
      dateTo: new FormControl(`${this.defaultToDate.getFullYear()}-${(this.defaultToDate.getMonth() + 1).toString().padStart(2, "0")}-${this.defaultToDate.getDate().toString().padStart(2, "0")}`),
      selectedDepartment: new FormControl(null),
      selectedStaffIDs: new FormControl(this.ngRedux.getState().ofi.openprivateinspections.search.selectedStaffIDs),
      selectedPropertyText: new FormControl(this.ngRedux.getState().ofi.openprivateinspections.search.selectedPropertyText),
      currentListing: new FormControl(true, Validators.required),
    });
  }

  @select(state => (state as IAppState).ofi.openprivateinspections.search.departments) departments$;
  @select(state => (state as IAppState).ofi.openprivateinspections.search.staffIDs) staffIDs$;
  @select(state => (state as IAppState).ofi.openprivateinspections.search.showSearchResult) showSearchResult$;
  @select(state => (state as IAppState).ofi.openprivateinspections.search.showAddPrivateInspection) showAddPrivateInspection$;

  ngOnInit() {
    this._loader.show();
    this._authService.GetSessionData()
      .subscribe((session: SessionInfo) => {
        this.session = session;
        this.getDepartmentsForOfiSearch_Subscription =
          this._service.GetDepartmentsForOfiSearch()
            .subscribe((dataDepts: ServiceResponse<IDepartmentDto[]>) => {
              this.departments = [];
              if (!!dataDepts && !dataDepts.isError && !!dataDepts.response && !!dataDepts.response.length) {
                this.departments = dataDepts.response;
                let defaultDept: IDepartmentDto = null;
                this.model.controls.selectedDepartment.setValue(null);
                /*if (!!this.departments.filter(dep => dep.selected) && !!this.departments.filter(dep => dep.selected).length) {
                  defaultDept = this.departments.filter(dep => dep.selected)[0];
                  this.model.controls.selectedDepartment.setValue(defaultDept);
                }*/
                if (!!this.departments.filter(dep => dep.id == session.departmentID) && !!this.departments.filter(dep => dep.id == session.departmentID).length) {
                  defaultDept = this.departments.filter(dep => dep.id == session.departmentID)[0];
                  this.model.controls.selectedDepartment.setValue(defaultDept);
                }
              }

              this.loadStaff();

            },
              errorDepts => {
                this._loader.hide();
                this.openDialog("Alert", errorDepts, "alertdialog");
              });

      });

  }
  ngAfterViewInit() {
    LoadScript.init();
  }
  ngOnDestroy() {
    if (!!this.subscription_GetCurrentStaffs) this.subscription_GetCurrentStaffs.unsubscribe();
    if (!!this.getPropertyAddress_Subcription) this.getPropertyAddress_Subcription.unsubscribe();
    if (!!this.getAppointments_Subcription) this.getAppointments_Subcription.unsubscribe();
    if (!!this.getDepartmentsForOfiSearch_Subscription) this.getDepartmentsForOfiSearch_Subscription.unsubscribe();
  }

  //#region Events
  onKeyUp(event: any) {
    if (!!event && event.keyCode >= 37 && event.keyCode <= 40) return;
    if (!!this.model.controls.selectedPropertyText.value && this.model.controls.selectedPropertyText.value.length >= 3) {
      this.isPropertyLoading = true;
      this.getPropertyAddress_Subcription =
        this._service.GetPropertyAddress(
          this.model.controls.selectedPropertyText.value
        )
          .subscribe(data => {
            if (!!data && !data.isError && !!data.response) {
              this.propertyAddress = of(data.response);
              console.log("data.response: ", data.response);
            }
            else if (data.isError) {
              this.openDialog("Alert", data.message, "alertdialog");
            }
            this.isPropertyLoading = false;
          });
    }
  }
  onSubmit() {
    //Date From
    let dateFrom: Date | null = null;
    if (!!this.model.controls.dateFrom.value) {
      dateFrom = this.model.controls.dateFrom.value;
      console.log("dateFrom: ", dateFrom);
    }

    //Date To
    let dateTo: Date | null = null;
    if (!!this.model.controls.dateFrom.value) {
      dateTo = this.model.controls.dateTo.value;
      console.log("dateTo: ", dateTo);
    }

    //Department
    let departmentID: string = null;
    if (!!this.model.controls.selectedDepartment.value) {
      departmentID = this.model.controls.selectedDepartment.value.id;
      console.log("departmentID: ", departmentID);
    }

    //Staffs
    let stffIDs: string[] = null;
    if (!!this.model.controls.selectedStaffIDs.value) {
      let selectedStaffIDs: IDNamePair<string>[] = this.model.controls.selectedStaffIDs.value;
      if (!!selectedStaffIDs && !!selectedStaffIDs.length) {
        stffIDs = selectedStaffIDs.map(item => { return item.id });
      }
      else {
        if (!!this.ngRedux.getState().ofi.openprivateinspections.search.staffIDs
          && !!this.ngRedux.getState().ofi.openprivateinspections.search.staffIDs.length) {
          stffIDs = this.ngRedux.getState().ofi.openprivateinspections.search.staffIDs.map(item => { return item.id });
        }
      }
      console.log("stffIDs: ", stffIDs);
    }

    //Address
    let addressID: string = null;
    if (!!this.model.controls.selectedPropertyText.value) {
      addressID = this.model.controls.selectedPropertyText.value.id;
      console.log("addressID: ", addressID);
    }

    //Current Listing
    let currentListing: boolean = true;
    currentListing = this.model.controls.currentListing.value;
    console.log("currentListing: ", currentListing);


    this._loader.show();
    this.getAppointments_Subcription =
      this._service
        .GetAppointments
        (
          departmentID,
          stffIDs,
          dateFrom,
          dateTo,
          addressID,
          currentListing
        )
        .map((data: ServiceResponse<IAppointmentDetailsSearchResult[]>) => {
          if (!data.isError) {
            return new GetAppointmentsOpenPrivateInspectionSearchPage(data.response);
          }
          else {
            Observable.throwError(data.message);
          }
        })
        .subscribe(action => {
          if (!!action.payload && !!action.payload.length) {
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            console.log("action.payload: ", action.payload);
            this._router.navigate(["/ofi/search"]);
          }
          else {
            this.snackBar.open("No record found", "Close", this.config);
          }
          this._loader.hide();
        }),
      error => {
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      }
  }
  onAddPrivateInspectionClick(event) {
    let action = new ShowAddPrivateInspectionPage();
    this.ngRedux.dispatch({ type: action.type });
    this._router.navigate(["/ofi/add"]);
    console.log("hello");
  }
  //#endregion

  //#region Functions
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  displayFn(item: IDNamePair<string>): string {
    if (!!item && !!item.id) {
      return item.name;
    }
  }
  loadStaff(): void {
    if (!this.session || !this.departments) {
      setTimeout(() => this.loadStaff(), 1000);
      return;
    }
    this.staffLoading = true;
    //Date From
    let dateFrom: Date = this.defaultFromDate;
    if (!!this.model.controls.dateFrom.value) {
      dateFrom = this.model.controls.dateFrom.value;
      console.log("dateFrom: ", dateFrom);
    }

    //Date To
    let dateTo: Date = this.defaultToDate;
    if (!!this.model.controls.dateFrom.value) {
      dateTo = this.model.controls.dateTo.value;
      console.log("dateTo: ", dateTo);
    }

    this.subscription_GetCurrentStaffs =
      //this._service.GetCurrentStaffs()
      this._service.GetOfiPiStaffs(dateFrom, dateTo)
        .map((data: ServiceResponse<IDNamePair<string>[]>) => {
          if (!data.isError) {
            return new GetCurrentStaffsOpenPrivateInspectionSearchPage({ departments: this.departments, staffs: data.response });
          }
          else {
            Observable.throwError(data.message);
          }
        })
        .subscribe(action => {
          this.staffIDs = (!!action.payload.staffs.filter(staff => staff.id == this.session.staffID).length ? action.payload.staffs.filter(staff => staff.id == this.session.staffID) : null) || action.payload.staffs;
          this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          this.staffLoading = false;
          this._loader.hide();
        }),
      error => {
        this.staffLoading = false;
        this._loader.hide();
        this.openDialog("Alert", error, "alertdialog");
      }
  }
  //#endregion

}
