import { Action } from 'redux';
import { IDNameExtraPair, IDNamePair } from '../services/authservice';
import { IJointTenant, IMaintenanceSearchResult, IRentalTenantPropertyAddress } from './maintenance.state';

export enum enActionTypes {
  ShowMaintenanceSearchPageAction = "[MAINTENANCE] Shows Maintenance Search Page Action",
  GetMaintenancesSearchAction = "[MAINTENANCE] Searches Maintenances And Returns The Result Action",
  CloseMaintenanceResultPageAction = "[MAINTENANCE] Closes Maintenance Search Page Action",
  GetMaintenanceLookForAction = "[MAINTENANCE] Get Maintenance Search Page Look For List For Autocomplete Dropdown Action",
  GetPropertyAddressesAction = "[NEW MAINTENANCE] Get Property Addresses For AutoComplete Dropdown",
  GetJointTenantsAction = "[NEW MAINTENANCE] Get Joint Tenants For Dropdown",
  GetSupplierServiceTypesAction = "[NEW MAINTENANCE] Get Supplier Service Types For Dropdown",
}

export class ShowMaintenanceSearchPage implements Action {
  readonly type = enActionTypes.ShowMaintenanceSearchPageAction;
  constructor() { }
}
export class GetMaintenancesSearch implements Action {
  readonly type = enActionTypes.GetMaintenancesSearchAction;
  constructor(public readonly payload: IMaintenanceSearchResult[]) { }
}
export class CloseMaintenanceResultPage implements Action {
  readonly type = enActionTypes.CloseMaintenanceResultPageAction;
  constructor(public readonly payload: boolean) { }
}
export class GetMaintenanceLookFor implements Action {
  readonly type = enActionTypes.GetMaintenanceLookForAction;
  constructor(public readonly payload: IDNamePair<string>[]) { }
}


export class GetPropertyAddresses implements Action {
  readonly type = enActionTypes.GetPropertyAddressesAction;
  constructor(public readonly payload: IRentalTenantPropertyAddress[]) { }
}
export class GetJointTenants implements Action {
  readonly type = enActionTypes.GetJointTenantsAction;
  constructor(public readonly payload: IJointTenant[]) { }
}
export class GetSupplierServiceTypes implements Action {
  readonly type = enActionTypes.GetSupplierServiceTypesAction;
  constructor(public readonly payload: IDNameExtraPair<number, number | undefined | null>[]) { }
}


export type MaintenanceAction =
  | ShowMaintenanceSearchPage
  | GetMaintenancesSearch
  | CloseMaintenanceResultPage
  | GetMaintenanceLookFor
  | GetPropertyAddresses
  | GetJointTenants
  | GetSupplierServiceTypes;
