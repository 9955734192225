import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig, MatSelectChange } from "@angular/material";
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';

import { IAppState } from '../store/store';
import { MaintenanceService } from './maintenance.service';
import { AuthService, IDNameExtraPair, ServiceResponse } from '../services/authservice';
import { LoaderService } from '../core/loader/loader.service';
import { GetJointTenants, GetPropertyAddresses, GetSupplierServiceTypes } from './maintenance.action';
import { DialogBodyComponent, enDialogRole } from '../dialog-body/dialog-body.component';
import { IJointTenant, IMaintenanceSelect, IRentalTenantPropertyAddress } from './maintenance.state';
import { ConfirmDialogComponent } from '../components/confirmdialog/confirmdialog.component';
import { PropertyManagementService } from '../propertymanagement/propertymanagement.service';
import { IManagementAgreement } from '../propertymanagement/propertymanagement';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>,
    private _router: Router,
    private route: ActivatedRoute,
    private _service: MaintenanceService,
    private _authService: AuthService,
    private _loader: LoaderService,
    private _propManagementService: PropertyManagementService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.model = new FormGroup({
      datetime: new FormControl(`${new Date().toDDMMYYYY()} ${new Date().toLocaleTimeString()}`),
      propertyAddress: new FormControl(null, Validators.required),
      selectedTenant: new FormControl(null as IJointTenant, Validators.required),
      selectedSupplierServiceType: new FormControl(null, Validators.required),
      callLogStatusID: new FormControl(1, Validators.required),
      notes: new FormControl(null, Validators.required),
      imgInput1: new FormControl(null),
      imgInput2: new FormControl(null),
      imgInput3: new FormControl(null)
    });
  }
  //#region Settings
  private snackbarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    horizontalPosition: "center",
    panelClass: ['notifyPanel']
  } as MatSnackBarConfig;
  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  }
  //#endregion
  ngOnInit() {

    this.getSessionData_Subcription = this._authService.GetSessionData()
      .subscribe(session => {
        if (!!session) {
          this._sessionID = session.id;
          this.model.controls.propertyAddress.valueChanges.subscribe((address: string) => {
            if (!!address && address.length >= 3) {
              this.getPropertyAddressByAddressString_Subcription =
                this._service.GetPropertyAddressByAddressString(null, session.id, address)
                  .subscribe
                  ((data: ServiceResponse<IRentalTenantPropertyAddress[]>) => {
                    this._loader.hide()
                    if (data.isError) {
                      this.openDialog("Error", data.message, "alertdialog");
                    }
                    else {
                      let action = new GetPropertyAddresses(data.response)
                      this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                    }
                  },
                    error => {
                      this._loader.hide();
                      this.openDialog("Alert", error, "alertdialog");
                    }
                  );
            }
            else {
              let action = new GetPropertyAddresses([]);
              this.ngRedux.dispatch({ type: action.type, payload: action.payload });
            }

          });



          this.getSupplierServiceTypesBySessionAgency_Subcription =
            this._service.GetSupplierServiceTypesBySessionAgency(null, session.id)
              .subscribe
              ((data: ServiceResponse<IDNameExtraPair<number, number | undefined | null>[]>) => {
                this._loader.hide()
                if (data.isError) {
                  this.openDialog("Error", data.message, "alertdialog");
                }
                else {
                  let action = new GetSupplierServiceTypes(data.response)
                  this.ngRedux.dispatch({ type: action.type, payload: action.payload });
                }
              },
                error => {
                  this._loader.hide();
                  this.openDialog("Alert", error, "alertdialog");
                }
              );

        }
      });
  }
  ngAfterViewInit() {
    LoadScript.init();
  }
  ngOnDestroy() {
    if (!!this.getPropertyAddressByAddressString_Subcription) this.getPropertyAddressByAddressString_Subcription.unsubscribe();
    if (!!this.getJointTenantsBySessionAgency_Subcription) this.getJointTenantsBySessionAgency_Subcription.unsubscribe();
    if (!!this.getSupplierServiceTypesBySessionAgency_Subcription) this.getSupplierServiceTypesBySessionAgency_Subcription.unsubscribe();
    if (!!this.getSessionData_Subcription) this.getSessionData_Subcription.unsubscribe();
    if (!!this.getManagementAgreements_Subscription) this.getManagementAgreements_Subscription.unsubscribe();
  }

  //#region Subcription Variables
  private getSessionData_Subcription: Subscription;
  private getJointTenantsBySessionAgency_Subcription: Subscription;
  private getSupplierServiceTypesBySessionAgency_Subcription: Subscription;
  private getPropertyAddressByAddressString_Subcription: Subscription;
  private getManagementAgreements_Subscription: Subscription;
  //#endregion

  //#region Private Variables
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  private model: FormGroup;
  private dialogRef: any;
  private _sessionID: string;
  private selectedProperty: IRentalTenantPropertyAddress;
  private photo1FileName: string;
  private photo1Base64: string;
  private photo2FileName: string;
  private photo2Base64: string;
  private photo3FileName: string;
  private photo3Base64: string;
  private showSpinner: boolean = false;
  //#endregion

  //#region Properties
  get sessionID(): string {
    if (this.route.routeConfig.path.indexOf("maintenance") >= 0 && !!this.route.snapshot.paramMap.get("maintenance") && this.route.snapshot.paramMap.get("maintenance").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("sessionID");
    }
    else {
      return this._sessionID;
    }
  }
  get departmentID(): string {
    if (this.route.routeConfig.path.indexOf("maintenance") >= 0 && !!this.route.snapshot.paramMap.get("departmentID") && this.route.snapshot.paramMap.get("departmentID").indexOf('00000000-') < 0) {
      return this.route.snapshot.paramMap.get("departmentID");
    }
    else {
      return this.ngRedux.getState().login.departmentID;
    }
  }
  get tenantMobile(): string {
    if (!!this.model.controls.selectedTenant && !!this.model.controls.selectedTenant.value)
      return (this.model.controls.selectedTenant.value as IJointTenant).tenantNumber;
    else
      return '';
  }
  get ownerName(): string {
    if (!!this.model.controls.selectedTenant && !!this.model.controls.selectedTenant.value)
      return (this.model.controls.selectedTenant.value as IJointTenant).ownerContactName;
    else
      return '';
  }
  get ownerMobile(): string {
    if (!!this.model.controls.selectedTenant && !!this.model.controls.selectedTenant.value)
      return (this.model.controls.selectedTenant.value as IJointTenant).ownerContactNumber;
    else
      return '';
  }
  get propertyManagerName(): string {
    if (!!this.model.controls.selectedTenant && !!this.model.controls.selectedTenant.value)
      return (this.model.controls.selectedTenant.value as IJointTenant).propertyManagerName;
    else
      return '';
  }
  //#endregion

  //#region Store Observables
  @select(state => (state as IAppState).maintenance.newMaintenance.propertyAddresses) propertyAddresses$
  @select(state => (state as IAppState).maintenance.newMaintenance.tenants) tenants$;
  @select(state => (state as IAppState).maintenance.newMaintenance.supplierServiceTypes) supplierServiceTypes$;

  //#endregion

  //#region Events
  onPropertyAddressChange(property) {
    if (!property) return;
    this.selectedProperty = property;
    this.model.controls.propertyAddress.setValue(property.propertyAddress);
    this._loader.show();
    this.getJointTenantsBySessionAgency_Subcription =
      this._service.GetJointTenantsBySessionAgency(null, this.sessionID, property.rentalTenantID, null)
        .subscribe
        ((data: ServiceResponse<IJointTenant[]>) => {
          this._loader.hide();
          if (data.isError) {
            this.openDialog("Error", data.message, "alertdialog");
          }
          else {
            let action = new GetJointTenants(data.response);
            this.ngRedux.dispatch({ type: action.type, payload: action.payload });
          }
        },
          error => {
            this._loader.hide();
            this.openDialog("Alert", error, "alertdialog");
          }
        );
  }
  onPropertyDeselect() {
    this.selectedProperty = null;
    this.model.controls.propertyAddress.setValue(null);
    this.model.controls.selectedTenant.setValue(null);
    let action = new GetJointTenants([]);
    this.ngRedux.dispatch({ type: action.type, payload: action.payload });
  }
  uploadPicture(event) {
    if (!event.target.files || !event.target.files[0]) return;
    var file = event.target.files[0];
    let fr = new FileReader();

    if (event.target.id == "imgInput1") {
      this.photo1FileName = file.name;
      fr.addEventListener("load", function (evt: any) {
        document.getElementById('img1').setAttribute("src", evt.target.result.toString());

        //document.querySelector("#Photo1").textContent = evt.target.result;
        //$("#Photo1").val(evt.target.result);
      });
    }
    else if (event.target.id == "imgInput2") {
      this.photo2FileName = file.name;
      fr.addEventListener("load", function (evt: any) {
        document.getElementById('img2').setAttribute("src", evt.target.result.toString());
      });
    }
    else if (event.target.id == "imgInput3") {
      this.photo3FileName = file.name;
      fr.addEventListener("load", function (evt: any) {
        document.getElementById('img3').setAttribute("src", evt.target.result.toString());
      });
    }


    fr.readAsDataURL(file);
  }
  onCallLogStatusSelectionChange(event: MatSelectChange) {
    if (!!event && event.value == 2) {
      this.model.controls.callLogStatusID.setValue(1);
    }
  }
  onSubmitClick(event) {
    if (!(!!this.departmentID && this.departmentID.indexOf('00000000-') < 0)) {
      this.snackBar.open("Department not found", "Close", this.snackbarConfig);
      return;
    }
    if (!(!!this.selectedProperty && !!this.selectedProperty.propertyID && this.selectedProperty.propertyID.indexOf('00000000-') < 0)) {
      this.snackBar.open("Please select property", "Close", this.snackbarConfig);
      return;
    }
    if (!(!!this.model.controls.selectedTenant.value && !!this.model.controls.selectedTenant.value.id && this.model.controls.selectedTenant.value.id.indexOf('00000000-') < 0)) {
      this.snackBar.open("Please select tenant", "Close", this.snackbarConfig);
      return;
    }
    if (!this.model.controls.selectedSupplierServiceType.value) {
      this.snackBar.open("Please select supplier service type", "Close", this.snackbarConfig);
      return;
    }
    if (!this.model.controls.notes.value) {
      this.snackBar.open("Please enter notes", "Close", this.snackbarConfig);
      return;
    }
    if (this.model.status == "VALID") {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        data: {
          title: "Confirmation",
          message: `Do you want to continue?`
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.showSpinner = true;
          this._loader.show();
          this.photo1Base64 = !!this.photo1FileName ? document.getElementById('img1').getAttribute("src") : undefined;
          this.photo2Base64 = !!this.photo2FileName ? document.getElementById('img2').getAttribute("src") : undefined;
          this.photo3Base64 = !!this.photo3FileName ? document.getElementById('img3').getAttribute("src") : undefined;
          this._service.SaveMaintenance
            (
              null,
              this.sessionID,
              this.departmentID,
              this.selectedProperty.managementAgreementID,
              this.selectedProperty.propertyID,
              `${new Date().toYYYYMMDD()} ${new Date().toLocaleTimeString()}`,
              this.model.controls.selectedTenant.value.id,
              this.model.controls.selectedSupplierServiceType.value.id,
              this.model.controls.callLogStatusID.value,
              this.model.controls.notes.value,
              this.photo1FileName,
              this.photo1Base64,
              this.photo2FileName,
              this.photo2Base64,
              this.photo3FileName,
              this.photo3Base64
            )
            .subscribe((data: ServiceResponse<IMaintenanceSelect>) => {
              if (data.isError) {
                this.openDialog("Error", data.message, "alertdialog");
              }
              else {
                this.callLogID = data.response.id;
                if (event.submitter.name == "callLogSave") {
                  this.openDialog("Success", "Maintenance Request Saved", "alertdialog");
                  window.location.reload();
                }
                else if (event.submitter.name == "callLogWOSave") {
                  this.openDialog("Success", "Maintenance Request Saved", "alertdialog");
                  this.getManagementAgreements_Subscription =
                    this._propManagementService.GetManagementAgreements
                      (
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        this.callLogID
                      )
                      .subscribe((maData: ServiceResponse<IManagementAgreement[]>) => {
                        this._loader.hide();
                        if (maData.isError) {
                          this.openDialog("Error", data.message, "alertdialog");
                        }
                        else {
                          if (!!maData.response && !!maData.response.length) {
                            this.managementAgreement = maData.response[0];
                            this.showWorkOrder = true;
                          }
                        }
                      },
                        error => {
                          this._loader.hide();
                          this.openDialog("Alert", error, "alertdialog");
                        });
                }
              }
              this._loader.hide();
              this.showSpinner = false;
            },
              error => {
                this._loader.hide();
                this.openDialog("Alert", error, "alertdialog");
                this._loader.hide();
                this.showSpinner = false;
              });

        }

      });

    }
  }
  //#endregion
  //#region Close Work Order Popup Dialog
  private managementAgreement: IManagementAgreement;
  private callLogID: string;
  private IsWorkOrderSaved: boolean = false;
  private closeOnOutsideClick: boolean = false;
  private showWorkOrder: boolean = false;
  onWorkOrderSave(event: any) {
    this.IsWorkOrderSaved = event;
    if (event) {
      //this.openDialog("Success", "Maintenance Request Saved", "alertdialog");
      //window.location.reload();
      this.showWorkOrder = false;
    }
  }
  onCloseWorkOrder(event: any) {
    if (!this.IsWorkOrderSaved) {
      this.showWorkOrder = !confirm("Work order not saved!! Still want to close?");
      this.closeOnOutsideClick = !this.showWorkOrder;
    }
    else {
      this.showWorkOrder = false;
    }
  }
  //#endregion
  //#region Functions
  triggerResize() {
    // Wait for content to render, then trigger textarea resize.
    this.autosize.resizeToFitContent(true);
  }
  //#endregion
}
