import { Http } from "@angular/http";
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { state } from "@angular/animations";
import {
  Router,
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs/Observable";
import { AuthService, SessionInfo } from "./services/authservice";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";

import { NgRedux, select } from "@angular-redux/store";
import { IAppState } from "./store/store";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _http: Http,
    private _service: AuthService,
    private _router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (next.routeConfig.path.indexOf("leaserenewal") >= 0 && !!next.paramMap.get("sessionID") && next.paramMap.get("sessionID").indexOf('00000000-') < 0) {
      return true;
    }
    if (next.routeConfig.path.indexOf("leaserenewallog") >= 0 && !!next.paramMap.get("sessionID") && next.paramMap.get("sessionID").indexOf('00000000-') < 0) {
      return true;
    }
    else {
      if (isPlatformBrowser(this.platformId)) {
        return this._service
          .GetSessionData()
          .map((data: SessionInfo) => {
            if (!data.isLoggedIn) {
              this._router.navigate(['/login']);
            }
            return data.isLoggedIn;
          })
          .catch(() => {
            this._router.navigate(['/login']);
            return Observable.of(false);
          });
      } else {
        this._router.navigate(['/login']);
        return Observable.of(false);
      }
    }
  }

  canActivateChild() {
    console.log("checking child route access");
    return true;
  }
}
