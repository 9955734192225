import { Component, OnInit, AfterViewInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgRedux, select } from "@angular-redux/store";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig } from "@angular/material";
import { Subscription } from "rxjs/Subscription";

import { IAppState } from "../../../store/store";
import { PropertyManagementService } from "./../../propertymanagement.service";
import { LoaderService } from "./../../../core/loader/loader.service";
import { IDNamePair, AuthService, SessionInfo, ServiceResponse } from "./../../../services/authservice";
import { IManagementAgreement } from "./../../propertymanagement";
import { DialogBodyComponent, enDialogRole } from "./../../../dialog-body/dialog-body.component";
import { GridViewDataTable } from '../gridview/gridview.component';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-managementagreementsearch',
  templateUrl: './managementagreementsearch.component.html',
  styleUrls: ['./managementagreementsearch.component.css'],
  providers: [DatePipe]
})
export class ManagementagreementsearchComponent implements OnInit {
  private dropdownSettings: any = {};
  private dialogRef: any;
  private managementTypes: IDNamePair<number>[];
  private propertyManagerIDs: IDNamePair<string>[];
  private ownerStatementGroupIDs: IDNamePair<number>[];
  private pLeases: IDNamePair<number>[];
  private isCommercial: boolean = false;
  private statusItems: IDNamePair<number>[];
  private selectedStatusIDs: IDNamePair<number>[];
  private selectedPLeases: IDNamePair<number>[];
  private selectedManagementTypes: IDNamePair<number>[];
  private selectedPropertyManagerIDs: IDNamePair<string>[];
  private selectedOwnerStatementGroupIDs: IDNamePair<number>[];
  private searchFields: IDNamePair<number>[];
  private selectedSearchField: number;
  private showGrid: boolean = false;
  private headerNames: string[] = ["Id", "Address", "Owners", "Owner's Mobile", "Owner's Email", "Property Manager", "Status", "Management Start Date", "Tenants", "Weekly Rent"];
  private columns: string[] = ["id", "addressText", "ownerNames", "ownerMobilePhone", "ownerEmail", "propertyManagerName", "statusID", "fullManagementStartDate", "tenantNames", "weeklyRentGSTInclusive"];
  private selectedGridRecord: GridViewDataTable[][];
  private showGridLoader: boolean = false;

  private subscription_GetPropertyManagers: Subscription;
  private subscription_GetOwnerGroups: Subscription;
  private subscription_GetManagementAgreements: Subscription;

  @Output() select = new EventEmitter<any>();

  constructor(private ngRedux: NgRedux<IAppState>,
    private _service: PropertyManagementService,
    private _loader: LoaderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    public _authService: AuthService) {
    this.searchFields = [{ id: 1, name: "Address" }, { id: 2, name: "Owner Name" }, { id: 4, name: "Tenant Name" }, { id: 8, name: "Strata Plan" }];
    this.selectedSearchField = 1;
    this.managementTypes = [{ id: 1, name: "Not Managed" }, { id: 2, name: "Managed" }, { id: 4, name: "Lease Only" }];
    this.selectedManagementTypes = [{ id: 2, name: "Managed" }, { id: 4, name: "Lease Only" }];
    this.statusItems = [{ id: 1, name: "Current" }, { id: 2, name: "Old" }];
    this.selectedStatusIDs = this.statusItems.filter(st => st.id == 1);
    this.pLeases = [{ id: 1, name: "Exclude" }, { id: 2, name: "Periodic Leases Only" }];
    this.selectedPLeases = this.pLeases;
    this.isCommercial = this.ngRedux.getState().login.isCommercial;
  }

  private managementAgreementList: GridViewDataTable[][] = [];

  openDialog(
    title: string,
    message: string,
    role: "alertdialog" | "dialog" = "dialog"
  ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.role = role;
    dialogConfig.data = {
      title: title,
      message: message,
      role:
        role == "alertdialog" ? enDialogRole.alertdialog : enDialogRole.dialog
    };
    dialogConfig.panelClass = "dialog-body";
    this.dialogRef = this.dialog.open(DialogBodyComponent, dialogConfig);
    return this.dialogRef;
  }
  private config = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.subscription_GetOwnerGroups =
      this._service.GetOwnerGroups()
        .subscribe(action => {
          this.ownerStatementGroupIDs = action;
          this.selectedOwnerStatementGroupIDs = action;
        },
          error => {
            this.openDialog("Alert", error, "alertdialog");
          });

    this._authService.GetSessionData()
      .subscribe((session: SessionInfo) => {
        this.subscription_GetPropertyManagers =
          this._service.GetPropertyManagers()
            .subscribe(action => {
              this.propertyManagerIDs = action;
              this.selectedPropertyManagerIDs = (!!action.filter(f=> f.id == session.staffID).length ? action.filter(f=> f.id == session.staffID): null) || action;;
            }),
          error => {
            this.openDialog("Alert", error, "alertdialog");
          }
      });

  }
  onSubmit(data: any) {
    this.showGrid = false;
    this.showGridLoader = true;
    let managementTypeIds: number = 0;
    if (!!data.selectedManagementTypeIDs.length) {
      let mgtTypeIds: IDNamePair<number>[] = data.selectedManagementTypeIDs;
      mgtTypeIds.forEach(type => {
        managementTypeIds = managementTypeIds | type.id;
      });
    }

    let statusIds: number = 0;
    if (!!data.selectedStatusIDs.length) {
      let stItems: IDNamePair<number>[] = data.selectedStatusIDs;
      stItems.forEach(type => {
        statusIds = statusIds | type.id;
      });
    }

    var ownerStMntGroupIDs: number | null = null;
    if (!!data.selectedOwnerStatementGroupIDs.length) {
      let ownerStmtIDs: IDNamePair<number>[] = data.selectedOwnerStatementGroupIDs;
      if (!!this.ownerStatementGroupIDs && this.ownerStatementGroupIDs.length === ownerStmtIDs.length
      ) {
        ownerStMntGroupIDs = null;
      }
      else {
        ownerStMntGroupIDs = 0;
        ownerStmtIDs.forEach(type => (ownerStMntGroupIDs = ownerStMntGroupIDs | type.id));
      }
    }

    var propManagerIDs: string[] = null;
    if (!!data.selectedPropertyManagerIDs) {
      let propManagerIds: IDNamePair<string>[] = data.selectedPropertyManagerIDs;
      if (!!this.propertyManagerIDs
        && this.propertyManagerIDs.length === propManagerIds.length) {
        propManagerIDs = null;
      }
      else {
        propManagerIDs = propManagerIds.map(item => { return item.id });
      }
    }

    var periodicLeases: number = 0;
    if (!!data.selectedPLeases.length) {
      let prdLeases: IDNamePair<number>[] = data.selectedPLeases;
      if (this.pLeases.length === prdLeases.length) {
        periodicLeases = 3;
      }
      else {
        prdLeases.forEach(type => (periodicLeases = periodicLeases | type.id));
      }
    }

    this.subscription_GetManagementAgreements =
      this._service.GetManagementAgreements
        (
          data.searchText,
          this.selectedSearchField,
          managementTypeIds,
          statusIds,
          propManagerIDs,
          ownerStMntGroupIDs,
          periodicLeases,
          !!data.alertNotes ? data.alertNotes : false,
          this.isCommercial,
          !!data.vacant ? data.vacant : false
        )
        .subscribe((action: ServiceResponse<IManagementAgreement[]>) => {
          this.managementAgreementList = action.response.map(item =>
            [
              { fieldName: "propertyID", value: item.propertyID, showText: item.propertyID, header: "Property ID", visible: false },
              , { fieldName: "addressText", value: item.addressText, showText: item.addressText, header: "Address", visible: true }
              , { fieldName: "ownerNames", value: item.ownerNames, showText: item.ownerNames, header: "Owners", visible: true }
              , { fieldName: "ownerMobilePhone", value: item.ownerMobilePhone, showText: item.ownerMobilePhone, header: "Owner's Mobile", visible: true }
              , { fieldName: "ownerEmail", value: item.ownerEmail, showText: item.ownerEmail, header: "Owner's Email", visible: true }
              , { fieldName: "propertyManagerName", value: item.propertyManagerName, showText: item.propertyManagerName, header: "Property Manager", visible: true }
              , { fieldName: "statusID", value: item.statusID.toString(), showText: item.statusID == 1 ? "Current" : "Old", header: "Status", visible: true }
              , { fieldName: "fullManagementStartDate", value: item.fullManagementStartDate, showText: !!item.fullManagementStartDate ? this.datepipe.transform(item.fullManagementStartDate, "dd/MM/yyyy").toString() : "-", header: "Management Start Date", visible: true }
              , { fieldName: "tenantNames", value: item.tenantNames, showText: item.tenantNames != "" ? item.tenantNames : "-", header: "Tenant", visible: true }
              , { fieldName: "weeklyRentGSTInclusive", value: item.weeklyRentGSTInclusive, showText: !!item.weeklyRentGSTInclusive ? item.weeklyRentGSTInclusive.toString() : "-", header: "Weekly Rent", visible: true }
              , { fieldName: "id", value: item.id, showText: item.id, header: "ID", visible: false }
            ]
          );
          this.showGrid = true;
          this.showGridLoader = false;
        },
          error => {
            this.openDialog("Alert", error, "alertdialog");
          });

  }

  onGetSelectedRecord(event) {
    this.selectedGridRecord = event;
    this.select.emit(this.selectedGridRecord);
  }
}
