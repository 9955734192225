import { ContactsGuard } from './guard/contacts.guard';
import { ResidentialGuard, CommercialGuard } from "./guard/permission.guard";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpModule } from "@angular/http";
import { HttpClientModule } from '@angular/common/http';
//import { RouterModule } from '@angular/router';
import { CookieModule } from 'ngx-cookie';

import { MatDialogModule, MatCheckboxModule } from "@angular/material";
import { MatProgressBarModule } from '@angular/material';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

import {
  PieSeriesService,
  AccumulationLegendService,
  AccumulationTooltipService,
  AccumulationAnnotationService,
  AccumulationDataLabelService,
  BarSeriesService,
  StackingBarSeriesService,
  CategoryService
} from '@syncfusion/ej2-angular-charts';

import { AppComponent } from './app.component';
// import { NavMenuComponent } from './nav-menu/nav-menu.component';

import { LoginGuard } from "./login.guard";
import { AuthGuard } from "./auth.guard";
import { AuthService } from "./services/authservice";
import { EmarketingService } from './emarketing/emarketing.service';
import { PropertyManagementService } from './propertymanagement/propertymanagement.service';
import { OfiService } from './ofi/ofi.service';
import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { IAppState, rootReducer, INITIAL_STATE } from './store/store';

import * as $ from 'jquery';



import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { DialogBodyComponent } from './dialog-body/dialog-body.component';
import { HomeService } from './home/home.service';
import { EncryptionService } from './services/encryptionservice';
import { ConfirmDialogComponent } from './components/confirmdialog/confirmdialog.component';
import { ReportsService } from './reports/reports.service';
import { QrcodeViewerService } from './components/qrcodeviewer/qrcodeviewer.service';
import { DataTransferService } from './services/datatransferservice';
import { MaintenanceService } from './maintenance/maintenance.service';


//import { DialogComponent } from './dialog/dialog.component';

//#region Extensions
declare global {
  interface Array<T> {
    sum(prop): number;
  }
  interface Date {
    toUtcYYYYMMDD: () => string;
    toYYYYMMDD: () => string;
    toDDMMYYYY: () => string;
  }
  interface Number {
    padLeft: (totalLength: number, repeatChar: string) => string;
  }
}
if (!Array.prototype.sum) {
  Array.prototype.sum = function (prop) {
    var total = 0
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += this[i][prop]
    }
    return total
  };
}
if (!Date.prototype.toUtcYYYYMMDD) {
  Date.prototype.toUtcYYYYMMDD = function () {
    let dateString: string = "";
    dateString = this.getUTCFullYear()
      + "-"
      + (this.getUTCMonth() + 1).toString().padStart(2, "0")
      + "-"
      + this.getUTCDate().toString().padStart(2, "0");
    return dateString;
  }
}
if (!Date.prototype.toYYYYMMDD) {
  Date.prototype.toYYYYMMDD = function () {
    let dateString: string = "";
    dateString = this.getFullYear()
      + "-"
      + (this.getMonth() + 1).toString().padStart(2, "0")
      + "-"
      + this.getDate().toString().padStart(2, "0");
    return dateString;
  }
}
if (!Date.prototype.toDDMMYYYY) {
  Date.prototype.toDDMMYYYY = function () {
    let dateString: string = "";
    dateString = this.getDate().toString().padStart(2, "0")
      + "/"
      + (this.getMonth() + 1).toString().padStart(2, "0")
      + "/"
      + this.getFullYear();
    return dateString;
  }
}
if (!Number.prototype.padLeft) {
  Number.prototype.padLeft = function (totalLength, repeatChar) {
    var len = (totalLength - String(this).length) + 1;
    return len > 0 ? new Array(len).join(repeatChar || '0') + this : this;
  }
}
//#endregion



@NgModule({
  declarations: [
    AppComponent,
    // NavMenuComponent,
    DialogBodyComponent,
    ConfirmDialogComponent,
    //DialogComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgReduxModule,
    HttpClientModule,
    CommonModule,
    HttpModule,
    FormsModule,
    CoreModule,

    MatProgressBarModule,
    MatDialogModule,
    MatSortModule ,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,

    AppRoutingModule,
    CookieModule.forRoot()
  ],
  providers: [AuthService, EmarketingService, PropertyManagementService, LoginGuard, AuthGuard, ContactsGuard, ResidentialGuard, CommercialGuard, HomeService, DatePipe, EncryptionService, OfiService, ReportsService, QrcodeViewerService, DataTransferService
    , PieSeriesService, AccumulationLegendService, AccumulationTooltipService, AccumulationDataLabelService, AccumulationAnnotationService
    , BarSeriesService, StackingBarSeriesService, CategoryService, MaintenanceService
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogBodyComponent, ConfirmDialogComponent]
})
export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>) {
    ngRedux.configureStore(rootReducer, INITIAL_STATE);
  }
}
