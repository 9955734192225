import "../../../../../wwwroot/js/scripts.js";
import {
  LOGIN,
  LOGOUT,
  IS_LOGGED_IN,
  CONTACT_RENTALTENANT_SEARCH,
  CONTACT_RENTALTENANT_SORTORDER_CHANGE,
  CONTACT_SEARCH_TYPE_CHANGE,
  CONTACT_RENTALTENANT_SEARCH_RESULT_SHOW,
  CONTACT_RENTALTENANT_SEARCH_RESULT_CLOSE
} from "../../store/actions";
import { IContactState } from "../../store/contacts";
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input
} from "@angular/core";
import { state } from "@angular/animations";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { NgRedux, select } from "@angular-redux/store";
import { DomSanitizer } from '@angular/platform-browser';

import { IAppState, INITIAL_STATE } from "../../store/store";
import {
  AuthService,
  enImageSize,
  SessionInfo,
  IDNamePair,
  RentalTenantSearchResult
} from "./../../services/authservice";
import { LoaderService } from './../../core/loader/loader.service';
import { Observable } from 'rxjs/Observable';
declare var $: JQueryStatic;
declare var LoadScript: any;
declare var FixedTableHeaderScript: any;

@Component({
  selector: 'rentaltenantsearchresult',
  templateUrl: './rentaltenantsearchresult.component.html',
  styleUrls: ['./rentaltenantsearchresult.component.css']
})
export class RentalTenantSearchResultComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private sanitizer: DomSanitizer,
    private ngRedux: NgRedux<IAppState>, 
    private _router: Router, 
    private route: ActivatedRoute, 
    private _service: AuthService, 
    private _loader: LoaderService) {
    // this._router.events.subscribe((e: any) => {
    //   if (e instanceof NavigationEnd) {
    //     if (e.id > 1) {
    //       this.refreshLoading();
    //     }
    //     console.log(e);
    //   }
    // });
  }

  @select(state => state.contacts.showSearchResult) showSearchResult$;
  @select(state => state.contacts.rentalTenantSearch.searchResult) searchResult;
  @select(state => state.contacts.rentalTenantSearch.address) address$;
  @select(state => state.contacts.rentalTenantSearch.rentalTenantSortOrderID) rentalTenantSortOrderID$;


  @select(state => state.contacts.searchType) searchType$;
  @select(state => state.contacts.allSearch.show) showAllSearch$;
  @select(state => state.contacts.vendorSearch.show) showVendorSearch$;
  @select(state => state.contacts.propertyOwnerSearch.show) showPropertyOwnerSearch$;
  @select(state => state.contacts.rentalTenantSearch.show) showRentalTenant$;
  @select(state => state.contacts.propertyManagementSupplierSearch.show) showPropertyManagementSupplierSearch$;
  paramAddress: string = "";
  paramSortOrder: number | null = null;

  model: IContactState = {
    searchType: "RentalTenant",
    allSearch: { show: false },
    vendorSearch: { show: false },
    propertyOwnerSearch: { show: false },
    rentalTenantSearch: {
      show: true,
      address: "",
      rentalTenantSortOrderID: "",
      rentalTenantSortOrders: [],
      searchResult: []
    },
    propertyManagementSupplierSearch: { show: false },
    showSearchResult: true
  };
  sanitizeSMS(mobileNumber: string) {
    return this.sanitizer.bypassSecurityTrustUrl("sms:" + mobileNumber);
  }
  refreshLoading() {
    this._loader.show();

    this.address$.subscribe(addr => {
      this.rentalTenantSortOrderID$.subscribe(sortOrd => {
        let so: number | null = null;
        if (!!sortOrd && !!parseInt(sortOrd)) {
          so = sortOrd;
        }

        this._service
          .GetRentalTenantSearchResult(addr, so)
          .map((data: RentalTenantSearchResult[]) => {
            return {
              type: CONTACT_RENTALTENANT_SEARCH_RESULT_SHOW,
              contacts: {
                searchType: this.model.searchType,
                allSearch: { show: false },
                vendorSearch: { show: false },
                propertyOwnerSearch: { show: false },
                rentalTenantSearch: {
                  show: this.model.rentalTenantSearch.show,
                  address: addr,//this.model.rentalTenantSearch.address,
                  rentalTenantSortOrderID: so,//this.model.rentalTenantSearch.rentalTenantSortOrderID,
                  rentalTenantSortOrders: this.model.rentalTenantSearch.rentalTenantSortOrders,
                  searchResult: data
                },
                propertyManagementSupplierSearch: { show: false }
              }
            };
          })
          .subscribe(action => {
            if (!!action.contacts.rentalTenantSearch.searchResult && !!action.contacts.rentalTenantSearch.searchResult.length) {
              this.ngRedux.dispatch({ type: CONTACT_RENTALTENANT_SEARCH_RESULT_SHOW, searchResult: action.contacts.rentalTenantSearch.searchResult });
            }
            else {
              this.ngRedux.dispatch({ type: CONTACT_RENTALTENANT_SEARCH_RESULT_SHOW, searchResult: [] as RentalTenantSearchResult[] });
            }
            this._loader.hide();
          });

      });
    });
  }
  ngOnInit() {
    //this.refreshLoading();
  }
  ngOnDestroy() {
  }

  ngAfterViewInit() {
    LoadScript.gotoTop();
    var clearIntv = null;
    clearIntv = setInterval(() => {
      if (!!$("#no-more-tables > table").length) {
        clearInterval(clearIntv);
        FixedTableHeaderScript.fixMe($("table"));
        $(".up").click(function () {
          $('html, body').animate({
            scrollTop: 0
          }, 2000);
        });
      }
    }, 2000);
  }

  onClose() {
    this.ngRedux.dispatch({ type: CONTACT_RENTALTENANT_SEARCH_RESULT_CLOSE, searchResult: [] as RentalTenantSearchResult[], showSearchResult: false });
    //this._router.navigate(["/contacts"]);
  }


}
