import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from "rxjs/Subscription";
import { PropertyManagementService } from './../../propertymanagement/propertymanagement.service';
import { IDNamePair, ServiceResponse } from './../../services/authservice';

@Component({
  selector: 'app-communicationlog',
  templateUrl: './communicationlog.component.html',
  styleUrls: ['./communicationlog.component.css']
})
export class CommunicationLogComponent implements OnInit, OnDestroy {

  @Input() actiontype: "tel" | "sms" | "mailto";
  @Input() contact: string;
  @Input() staffid: string;
  @Input() sendername: string;
  @Input() contactid: string; 
  @Input() contactname: string; 
  @Input() targettypeid: number;
  @Input() mobile: string;
  @Input() email: string;

  @Output() save = new EventEmitter<ServiceResponse<any>>();

  private getCommunicationReasons_Subcription : Subscription;

  private reasons: IDNamePair<number>[] = [];
  private reasonID: number = 0;
  private notes: string;
  private message: string;
  private isError: boolean;
  private showWaitLoader: boolean;

  constructor(private sanitizer: DomSanitizer, private _service: PropertyManagementService) { }

  ngOnInit() {
    if (this.targettypeid != null && this.targettypeid != undefined) {      
      this.getCommunicationReasons_Subcription = this._service.GetCommunicationReasons(this.targettypeid)
      .subscribe(result => {
        this.reasons = result;
        if (!!result && !!result.length) {
          //this.reasonID = result[0].id;
          if (this.targettypeid == 36) {
            if (!!this.reasons.filter(res => res.id == 24).length) {
              this.reasonID = 24
            }
          }
        }
      });
    }
  }
  ngOnDestroy() {
    if (!!this.getCommunicationReasons_Subcription) this.getCommunicationReasons_Subcription.unsubscribe();
  }

  private get phoneVisible(): boolean {
    return this.actiontype == "tel";
  }
  private get smsVisible(): boolean {
    return this.actiontype == "sms";
  }
  private get emailVisible(): boolean {
    return this.actiontype == "mailto";
  }
  private get communicationMethodID(): number {
    return this.actiontype == "tel" ? 1 : (this.actiontype == "mailto" ? 4 : (this.actiontype == "sms" ? 8 : 0));
  }
  private get communicationMethodName(): string {
    return this.actiontype == "tel" ? "Phone Call" : (this.actiontype == "mailto" ? "Email" : (this.actiontype == "sms" ? "SMS" : ""));
  }
  sanitizeSMS(mobileNumber: string) {

    var ua = navigator.userAgent.toLowerCase();
    var url;
    var sms = "sms:" + mobileNumber;
    if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1){
      if (!!this.notes) {        
        sms += ";&body=" + this.notes;
      }
    }    
    else{
      if (!!this.notes) {
        sms += "?body=" + this.notes;
      }
    }
    return this.sanitizer.bypassSecurityTrustUrl(sms);
  }

  private onSaveClick() {
    if (!this.reasonID) return;
    this.showWaitLoader = true;
    let subject: string = "";
    if (!!this.reasons && !!this.reasons.length) {
      let reason = this.reasons.filter(reason => reason.id == this.reasonID);
      if (!!reason && !!reason.length) {
        subject = reason[0].name;
      }
    }
    this._service.SaveCommunicationLog
    (
      this.targettypeid,
      this.staffid,
      this.sendername,
      this.contactid,
      this.contactname,
      this.mobile,
      this.email,
      this.communicationMethodID,
      subject,
      this.reasonID,
      this.notes,
      new Date()
    )
    .subscribe(result => {
      this.isError = result.isError;
      this.message = result.message;
      this.showWaitLoader = false;
      this.save.emit(result);
    });
    
  }
}
